import moment from 'moment';
import { useQueries, useQuery } from 'react-query';
import { GetOptionChainSymboldata } from 'services/apiServices';
import {
  GetOptionChainResultDataType,
  SymbolOptionChainDataType,
} from 'types/ApiServicesTypes';

export default function useGetOptionChainData(
  symbol: string,
  expiry: string,
  candle_time: string,
) {
  const objQuery = useQuery<GetOptionChainResultDataType, Error>(
    ['GetOptionChainSymboldata', symbol, expiry, candle_time],
    () =>
      GetOptionChainSymboldata(symbol, expiry, candle_time).then(res => {
        return res;
      }),
    {
      enabled: !!expiry,
    },
  );
  return objQuery;
}

export function useGetOptionChainExpiryData(
  symbol: string,
  expiry: string[],
  candle_times: string,
) {
  const queries = expiry.map(expiryDate => {
    return {
      queryKey: [`GetOptionChainSymboldata`, symbol, expiryDate, candle_times],
      queryFn: () =>
        GetOptionChainSymboldata(symbol, expiryDate, candle_times).then(res => {
          return res;
        }),
      options: {
        enable: !!expiry,
      },
    };
  });

  const objQuery = useQueries(queries)
    .map(queries => queries.data)
    .reduce((acc, curr) => {
      if (curr) {
        acc[curr.expiry] = curr.candles;
      }
      return acc;
    }, {} as Record<string, Record<string, SymbolOptionChainDataType>>);

  return objQuery;
}

export function useGetOptionChainExpiredED(symbol: string, expiry: string[]) {
  const queries = expiry.map(expiryDate => {
    return {
      queryKey: [`GetOptionChainSymboldaa`, symbol, expiryDate],
      queryFn: () =>
        GetOptionChainSymboldata(
          symbol,
          expiryDate,
          moment(expiryDate)
            .hour(15)
            .minute(30)
            .utcOffset(330)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')
            .toString(),
        ).then(res => {
          return res;
        }),
      options: {
        enable: !!expiry,
      },
    };
  });

  const objQuery = useQueries(queries)
    .map(queries => {
      return queries.data;
    })
    .reduce((acc, curr) => {
      if (curr) {
        acc[curr.expiry] = curr.candles;
      }
      return acc;
    }, {} as Record<string, Record<string, SymbolOptionChainDataType>>);

  return objQuery;
}
