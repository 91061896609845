const PureIframe = () => {
  return (
    <iframe
      id={'iframe'}
      height="1000px"
      width={'100%'}
      src="https://ide.speedbot.tech"
    ></iframe>
  );
};

export default PureIframe;
