import { SignalItemProps } from './types';
import { Box, Typography } from '@mui/material';
import { formatNumber, getDate, replaceAllChars } from 'utils/GenericFunctions';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { deepPurple } from '@mui/material/colors';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';

const SignalItem = ({
  signal,
  handleClickRegenerate,
  handleClickModify,
  isCrypto,
  isLoading,
}: SignalItemProps) => {
  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Box
        sx={{ mb: 1 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          {signal.tag && <MuiChip label={signal.tag} size="xsmallicon" />}
        </Box>
        <Typography variant="body3">
          {signal.position_type === 'EN' ? 'ENTRY' : 'EXIT'}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ flex: 1 }}>
          <Typography variant="body2" color="text.disabled">
            {getDate(signal.signal_date, true)}
          </Typography>
        </Box>
        {signal.tag.length > 0 && (
          <Box
            sx={{ flex: 'initial', pl: 2, textAlign: 'right', lineHeight: 0 }}
          >
            <MuiChip
              label={replaceAllChars(signal.order_type, '_', ' ')}
              size="xsmall"
              color="greylight"
              sx={{ maxWidth: '100px' }}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <span>{signal.trading_symbol}</span>
            <MuiChip
              label={signal.transaction_type}
              color={
                signal.transaction_type === 'BUY' ? 'infolight' : 'purplelight'
              }
              size="small"
              variant="filled"
              sx={{ ml: 1 }}
            />
          </Typography>
        </Box>
        <Box sx={{ flex: 'initial', pl: 2, textAlign: 'right' }}>
          <Typography variant="body2" color="info.main">
            {['LIMIT', 'STOPLOSS_LIMIT'].includes(signal.order_type)
              ? signal.currency_symbol +
                formatNumber(
                  signal.order_price ? parseFloat(signal.order_price) : 0,
                )
              : ''}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1, display: 'flex', gap: 1 }}>
          <Typography
            variant="body3"
            fontWeight={500}
            color={
              signal.transaction_type === 'BUY' ? 'info.main' : deepPurple[500]
            }
          >
            Qty {signal.quantity}
          </Typography>
          <Typography variant="body3">{signal.trade_id}</Typography>
        </Box>

        {!isCrypto && (
          <Box
            sx={{ flex: 'initial', pl: 2, textAlign: 'right', display: 'flex' }}
          >
            <Typography variant="body3" color="text.primary">
              {signal.product_type}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="end" sx={{ mt: 1, gap: 1 }}>
        <FeatureFlag features={[FEATURES.IS_MODIFY_SIGNAL]} fallback={<></>}>
          <MuiLoadingButton
            size="small"
            onClick={() => handleClickModify(signal)}
            variant="contained"
            color="secondary"
            loading={Boolean(isLoading)}
          >
            <FormattedMessage id="buttons.edit" />
          </MuiLoadingButton>
        </FeatureFlag>
        <MuiLoadingButton
          size="small"
          onClick={() => handleClickRegenerate(signal)}
          variant="contained"
          color="secondary"
          loading={Boolean(isLoading)}
        >
          <FormattedMessage id="buttons.regenerate" />
        </MuiLoadingButton>
      </Box>
    </Box>
  );
};

export default SignalItem;
