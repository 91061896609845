import { Box, Typography } from '@mui/material';
import { LIVEDATASYMBOL, SYMBOL } from '../utils';

type SymbolCardProps = {
  symbol: string;
  description: string;
};

const SymbolCard = ({ symbol, description }: SymbolCardProps) => {
  const feedData = localStorage.getItem(`niftySymbolData`);

  return (
    <Box
      className="m-main-spacing p-main-spacing"
      borderRadius={2}
      display={'flex'}
      bgcolor={'grey.100'}
      py={2}
      flex={1}
    >
      <Box
        sx={{
          p: 1,
          borderRadius: 2,
          boxShadow: 1,
          flexShrink: 1,
          minWidth: '120px',
          bgcolor: 'white',
        }}
      >
        <Box flexGrow={1}>
          <Typography variant="body1" textAlign={'center'}>
            {SYMBOL[`${symbol}`]}
          </Typography>
          {feedData && (
            <Typography variant="body2" textAlign={'center'}>
              {JSON.parse(feedData)[`${LIVEDATASYMBOL[`${symbol}`]}`].Close}
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ pl: 1, alignContent: 'center' }}>{description}</Box>
    </Box>
  );
};

export default SymbolCard;
