import {
  Button,
  ButtonGroup,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Radio,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { OptionType } from './utils';
import { ArrowDropUp, Close } from '@mui/icons-material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

type SelectValueType = {
  key: string;
  label: string;
  value: string;
};

type SelectWithRemoveProps = {
  options: Array<OptionType>;
  handleSelect: (selected: SelectValueType) => void;
  selected: SelectValueType;
};

const SelectWithRemove = ({
  options,
  handleSelect,
  selected,
}: SelectWithRemoveProps) => {
  const handleSelection = (selection: OptionType) => {
    handleSelect({
      key: selected.key,
      value: selection.value,
      label: selection.label,
    });
  };
  const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(showMenu);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setShowMenu(null);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        aria-label="Button group with a nested menu"
      >
        <Button
          onClick={handleMenuClick}
          endIcon={
            !selected.value ? (
              open ? (
                <ArrowDropUp />
              ) : (
                <ArrowDropDownIcon />
              )
            ) : null
          }
          sx={{
            borderRight: selected.value
              ? '0px'
              : '1px solid rgba(0, 0, 0, 0.5)',
            paddingRight: selected.value ? '5px' : '15px',
            '&:hover': {
              borderRight: selected.value
                ? '0px'
                : '1px solid rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          {selected.key === 'risk_to_reward' ? 'R/R' : selected.key}
          <Typography variant="body1" color={'#1a73e8'} paddingLeft={'4px'}>
            {selected.label}
          </Typography>
        </Button>
        {selected.value && (
          <Button
            size="small"
            onClick={() => {
              handleSelection({} as OptionType);
            }}
            sx={{
              borderLeft: selected.value
                ? '0px'
                : '1px solid rgba(0, 0, 0, 0.5)',
              paddingLeft: selected.value ? '2px' : '9px',
              '&:hover': {
                borderLeft: selected.value
                  ? '0px'
                  : '1px solid rgba(0, 0, 0, 0.5)',
              },
            }}
          >
            <Close />
          </Button>
        )}
      </ButtonGroup>
      <Paper sx={{ width: 'auto' }}>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={showMenu}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                onClick={() => {
                  handleSelection(option);
                  handleMenuClose();
                }}
                key={index}
              >
                <Radio
                  sx={{ height: '15px', width: '15px', mr: 1 }}
                  checked={option.value === selected.value}
                />
                <ListItemText>{option.label}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      </Paper>
    </>
  );
};

export default SelectWithRemove;
