import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import CircularLoader from 'app/design/uiComponents/CircularLoader';

import { Fragment, useEffect } from 'react';
import useGetReferral from 'services/Menu/useGetReferral';
import { formatDateString } from 'utils/GenericFunctions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
const ReferralTable = ({
  getPercentage,
}: {
  getPercentage: (val: number) => void;
}) => {
  const { data: referralData, isLoading } = useGetReferral();
  const isMdDown = useGetMediaQueryDown('md');
  useEffect(() => {
    getPercentage(
      referralData?.data
        ? referralData?.data?.referral_credit_reward_percentage
        : 0,
    );
  }, [getPercentage, referralData?.data]);

  return (
    <Box className="m-main-spacing">
      <CircularLoader open={isLoading} />
      {referralData && referralData?.data?.actions?.length > 0 && !isLoading ? (
        <TableContainer>
          <Table
            sx={{
              '& th': { whiteSpace: 'nowrap' },
              '& td': { fontWeight: 400 },
              '& th,td': {
                '&:first-child': {
                  pl: 0,
                },
                '&:last-child': {
                  pr: 0,
                },
              },
            }}
          >
            <TableHead>
              {!isMdDown && (
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="referral_page.date" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id=" referral_page.user" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="referral_page.action" />
                  </TableCell>
                  <TableCell align="right">
                    <FormattedMessage id="referral_page.credit" />
                  </TableCell>
                </TableRow>
              )}
              {isMdDown && (
                <TableRow>
                  <TableCell>
                    Date
                    <br />
                    User
                  </TableCell>
                  <TableCell align="right">
                    Action
                    <br />
                    Credit
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {referralData &&
                referralData.data.actions.length > 0 &&
                referralData.data.actions.map((queue, i) => (
                  <Fragment key={i}>
                    {!isMdDown && (
                      <TableRow>
                        <TableCell>
                          {formatDateString(queue.date, 'DD MMM YYYY')}
                        </TableCell>
                        <TableCell>{queue.user}</TableCell>
                        <TableCell>
                          <MuiChip
                            label={queue.action}
                            color={
                              queue.action == 'Signup'
                                ? 'purplelight'
                                : queue.action == 'Credit Purchase'
                                ? 'infolight'
                                : 'successlight'
                            }
                            key={i}
                          />

                          {/* {queue.action} */}
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={
                            queue.credit > 0
                              ? { whiteSpace: 'nowrap', color: '#4BB543' }
                              : { whiteSpace: 'nowrap' }
                          }
                        >
                          {queue.credit}
                        </TableCell>
                      </TableRow>
                    )}
                    {isMdDown && (
                      <>
                        <TableRow>
                          <TableCell
                            {...(['pending', 'executed', 'completed'].includes(
                              queue.action,
                            ) && {
                              sx: {
                                borderBottomStyle: 'none',
                                pb: 1,
                              },
                            })}
                          >
                            <div>
                              {' '}
                              {formatDateString(queue.date, 'DD MMM YYYY')}
                            </div>
                            <br />
                            {queue.user}
                          </TableCell>
                          <TableCell
                            align="right"
                            {...(['pending', 'executed', 'completed'].includes(
                              queue.action,
                            ) && {
                              sx: {
                                borderBottomStyle: 'none',
                                pb: 1,
                              },
                            })}
                          >
                            <div>
                              <MuiChip
                                label={queue.action}
                                color={
                                  queue.action == 'signup'
                                    ? 'purplelight'
                                    : 'successlight'
                                }
                                key={i}
                              />
                            </div>

                            <br />

                            <div
                              style={
                                queue.credit > 0 ? { color: '#4BB543' } : {}
                              }
                            >
                              {queue.credit}
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption" fontSize={16} fontStyle={'italic'}>
            <FormattedMessage id="referral_page.refer_friend" />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ReferralTable;
