import React, { Fragment } from 'react';
import { Divider, Pagination, Box } from '@mui/material';
import { BotSignal } from 'types/ApiServicesTypes';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import BotSignalsTable from '../speedBot/ComponentItem/Bots/BotSignalsTable';
import { SignalItem } from '../speedBot/EntityItem';

const SignalListLayout = ({
  data,
  page,
  totalPages,
  handleChangePagination,
  handleSelectSignal,
  exchanges,
  instrumentLoading,
  handleClickSelectSignal,
  selectedItems,
}: {
  data: BotSignal[];
  page: number;
  totalPages: number;
  exchanges: string[];
  handleChangePagination: (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => void;
  handleSelectSignal: (val: BotSignal, isModify: boolean) => void;
  instrumentLoading?: boolean;
  handleClickSelectSignal?: (signal: BotSignal) => void;
  selectedItems?: number[] | null;
}) => {
  const isMdUp = useGetMediaQueryUp('md');

  // const handleClickRegenerate = (signal: BotSignal) => {
  //   const req: PostBotSignalParams = {
  //     signals: [
  //       {
  //         instrument_id: signal.instrument_id,
  //         product_type: signal.product_type,
  //         order_type: signal.order_type,
  //         trade_id: signal.trade_id,
  //         transaction_type: signal.transaction_type,
  //         order_duration: signal.order_duration,
  //         quantity: signal.quantity ? signal.quantity : 0,
  //         tag: signal.tag,
  //         position_type: signal.position_type,
  //         validity: '0',
  //         order_price: signal.order_price ? signal.order_price : '',
  //       },
  //     ],
  //   };

  //   mutation.mutate(req, {
  //     onSuccess: res => {
  //       if (res.success) {
  //         showAlert!(t('bot_signals.signal_regenerated'), 'success');
  //       }
  //     },
  //   });
  // };
  const isCrypto = exchanges.includes('CCXT');

  const handleClickRegenerate = (signal: BotSignal) => {
    const duplicateSignal = { ...signal };
    duplicateSignal.signal_id = '';
    handleSelectSignal(duplicateSignal, false);
  };
  const handleClickModify = (signal: BotSignal) => {
    handleSelectSignal(signal, true);
  };
  const handleClickExit = (signal: BotSignal) => {
    const exitSignal = { ...signal };
    exitSignal.transaction_type =
      signal.transaction_type === 'BUY' ? 'SELL' : 'BUY';
    exitSignal.position_type = 'EX';
    handleSelectSignal(exitSignal, false);
  };

  return (
    <>
      <Box sx={{ py: 1, height: '100%' }}>
        {isMdUp ? (
          <BotSignalsTable
            handleClickExit={handleClickExit}
            isCrypto={isCrypto}
            page={page}
            data={data}
            handleClickRegenerate={handleClickRegenerate}
            handleClickModify={handleClickModify}
            isLoading={instrumentLoading}
            handleClickSelectSignal={handleClickSelectSignal}
            selectedItems={selectedItems}
          />
        ) : (
          data &&
          data.map((signal: BotSignal, i) => (
            <Fragment key={signal.id}>
              <SignalItem
                signal={signal}
                isCrypto={isCrypto}
                handleClickRegenerate={handleClickRegenerate}
                handleClickModify={handleClickModify}
                isLoading={instrumentLoading}
              />
              {data.length - 1 !== i && <Divider className="m-main-spacing" />}
            </Fragment>
          ))
        )}
        {data && data.length > 0 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePagination}
            color="primary"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: '1.5rem',
              pb: 3,
            }}
          />
        )}
      </Box>
    </>
  );
};

export default SignalListLayout;
