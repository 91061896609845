import { ReactElement } from 'react';
import { Box, Dialog } from '@mui/material';
// import Transition from 'app/design/uiComponents/Transition';
import PageHeader from 'app/design/speedBot/PageHeader';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
// import { title } from 'process';

export default function MuiFullScreenModal({
  open,
  handleClose,
  body,
  footer,
  // isNoBackButton,
  isDisableEnforceFocus,
  isDisableEscapeKeyDown,
}: // title,
{
  open: boolean;
  handleClose: (isSuccess, message?: string) => void;
  body?: ReactElement;
  footer?: ReactElement;
  isNoBackButton?: boolean;
  isDisableEnforceFocus?: boolean;
  isDisableEscapeKeyDown?: boolean;
  title: string;
}) {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      <Dialog
        onBackdropClick={handleClose}
        // disableEnforceFocus
        // disableEscapeKeyDown
        {...(isDisableEnforceFocus !== false && {
          disableEnforceFocus: true,
        })}
        {...(isDisableEscapeKeyDown !== false && {
          disableEscapeKeyDown: true,
        })}
        {...(!isMdUp && { fullScreen: true })}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        PaperProps={{
          sx: {
            width: { md: '480px', xl: '407px' },
            maxWidth: { md: 'initial' },
          },
        }}
      >
        <Box
          sx={{
            pt: { md: 3 },
            pb: { xs: 3 },
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {
            <Box
              className="text-ellipsis"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div></div>
              {/* <PageHeader variant="default" title={title} /> */}
              <Box sx={{ mt: -2 }}>
                <PageHeader variant="close" buttonHandleClick={handleClose} />
              </Box>
            </Box>
          }
          <Box sx={{ overflow: 'auto', flex: 1 }}>{body}</Box>
          {footer ? <Box className="m-main-spacing">{footer}</Box> : <></>}
        </Box>
      </Dialog>
    </>
  );
}
