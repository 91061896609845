export enum FEATURES {
  IS_GOLIVE = 'isGoLive',
  IS_GOOGLE_LOGIN = 'isGoogleLogin',
  IS_MOBILE_GOOGLE_LOGIN = 'isMobileGoogleLogin',
  IS_PLAN_UPGRADE = 'isPlanUpgrade',
  IS_BACKTESTING = 'isBacktesting',
  IS_TRAILING_STOPLOSS = 'isTrailingStoploss',
  IS_TRADING_VIEW_CHART_BUTTON = 'iSTradingViewChartButton',
  IS_DOWNLOAD_EXCEL_ORDERS = 'isDownloadExcelOrders',
  IS_SELL_ON_MARKET = 'isSellOnMarket',
  IS_PROFITLOSS_REPORT_BY_DATE = 'isProfitLossByDate',
  IS_AUTHOR_PROFILE = 'isAuthorPofile',
  IS_STBT_OPTION = 'isSTBTOption',
  IS_ADD_HEDGE_ENABLED = 'isAddHedgeEnabled',
  IS_BACKTEST_DISCLAIMER = 'isBacktestDisclaimer',
  IS_DELETE_BOT = 'isDeleteBot',
  IS_SHOW_DD_IN_AMOUNT = 'isShowDDInAmount',
  IS_EXIT_ALL_AND_STOP = 'isExitAllAndstop',
  IS_EQUITY_BOTBUILDER = 'isEquityBotBuilder',
  IS_OPTION_BOTBUILDER = 'isOptionBotBuilder',
  IS_OPTION_BOTBUILDER_STRATEGY_STOPLOSS = 'isOptionBotBuilderStrategyStopLoss',
  IS_OPTION_BOTBUILDER_STRATEGY_TARGETPROFIT = 'isOptionBotBuilderStrategyTargetProfit',
  IS_OPTION_BOTBUILDER_STRATEGY_TARGETSTOPLOSS = 'isOptionBotBuilderStrategyTargetStopLoss',
  IS_OPTION_BOTBUILDER_STRATEGY_LEG_TEMPLATES = 'isOptionBotBuilderStrategyLegTemplates',
  IS_OPTION_BOTBUILDER_REENTRY_REEXECUTION = 'isOptionStrategySetReEntryReExecution',
  IS_OPTION_BOTBUILDER_POSITIONAL = 'isOptionBotBuilderPositional',
  IS_OPTION_BOTBUILDER_RANGE_BREAKOUT = 'isOptionBotBuilderRangeBreakout',
  IS_BOT_SIGNAL = 'isBotSignal',
  IS_SHOW_RESULT = 'isShowResult',
  IS_SIGNAL_TEMPLATE = 'isSignalTemplate',
  IS_DUPLICATE_BOT = 'isDuplicateBot',
  IS_PAPER_TRADING = 'isPaperTrading',
  IS_COMPARE_BOTS = 'isCompareBots',
  IS_LOT_SIZE_VALIDATION = 'isLotSizeValidation',
  IS_SL_TARGET_SIGNAL = 'isSLTargetSignal',
  IS_MODIFY_SIGNAL = 'isModifySignal',
  IS_LEVERAGE_SIGNAL = 'isLeverageSignal',
  IS_ABSOLUTE_CAPITAL_SIGNAL = 'isAbsoluteCapitalSignal',
  IS_DEPLOYED_CAPITAL_SIGNAL = 'isDeployedCapitalSignal',
  IS_AVAILABLE_BALANCE_SIGNAL = 'isAvailableBalanceSignal',
  IS_NOTIFICATION_NOTES = 'isNotificationNotes',
  IS_FREE_PLAN_TRIAL = 'isFreePlanTrial',
  IS_DISPLAY_BROKER_IN_PORTFOLIO = 'isDisplayBrokerInPortfolio',
  IS_STATISTICS_CALCULATOR = 'isStatisticsCalculator',
  IS_CREDIT_WALLET = 'isCreditWallet',
  IS_CREDIT_TRANSFER = 'isCreditTransfer',
  IS_CHARGES_AND_PROFIT = 'isChargesAndProfit',
  IS_TEMPLATES = 'isTemplates',
  IS_DISCOVER = 'isDiscover',
  IS_TRADERS = 'isTraders',
  IS_BOTS = 'isBots',
  IS_PLANS = 'isPlans',
  IS_MOBILE_APP_BAR_LOGO = 'isMobileAppBarLogo',
  IS_SUPPORT_MENU = 'isSupportMenu',
  IS_SHOW_LAST_MONTH_RETURN = 'isShowLastMonthReturn',
  IS_SHOW_MDD = 'isShowMDD',
  IS_MY_PORTFOLIO = 'isMyPortfolio',
  IS_IOS_NEW_TAB = 'isIosNewTab',
  IS_STRATEGY_PARAMETER_FORM = 'isStrategyParameterForm',
  IS_EQUITY_BOTBUILDER_LIMIT_ORDER = 'isEquityBotBuilderLimitOrder',
  IS_COUPON_CODE = 'isCouponCode',
  IS_REFERRAL_CODE = 'isReferralCode',
  IS_PAY_AS_YOU_GO_PLAN = 'isPayAsYouGoPlan',
  IS_ALLOW_PUBLIC_PAGES = 'isAllowPublicPages',
  IS_POSITION_METADATA = 'isPositionMetaData',
  IS_BROKERAGE_FEES = 'isBrokerageFees',
  IS_OTP_VERIFICATION = 'isOTPVerification',
  IS_REPORT = 'isReport',
  IS_CODE_YOUR_BOT = 'isCodeYourBot',
  IS_APPLE_LOGIN = 'isAppleLogin',
  IS_ADD_CREDIT = 'isAddCredit',
  IS_VERIFY_PHONE_DIALOG = 'isVerifyPhoneDialog',
  IS_REFERRAL_OAUTH_USER = 'isReferralOauthUser',
  IS_LOGS = 'isLogs',
  IS_SHARE_PORTFOLIO = 'isSharePortfolio',
  IS_CONDITION_IN_OPTION = 'isConditionInOption',
  IS_OPTION_BOTBUILDER_CRUDE_OIL = 'isOptionBotBuilderCrudeOil',
  IS_OPTION_BOTBUILDER_MID_CAP = 'isOptionBotBuilderMidCap',
  IS_EXIT_POSITION_PORTFOLIO = 'isExitPositionPortfolio',
  IS_OPTION_ENTER_IF_MISSED = 'isOptionEnterIfMissed',
  IS_OPTION_BOTBUILDER_STOCK_SYMBOLS = 'isOptionBotBuilderStockSymbols',
  IS_EXPORT_BOT_DATA = 'isExportBotData',
  IS_EQUITY_BOTBUILDER_STOCK_TEMPLATES = 'isEquityBotBuilderStockTemplates',
  IS_HELP_DESK_IN_SB = 'isHelpDeskInSb',
  IS_HOME_PAGE = 'isHomePage',
  IS_NOTIFICATION_ON_HOME_PAGE = 'isNotificationOnHomePage',
  IS_BOT_DISCRIPTION = 'isBotDescription',
  IS_ADVANCE_SYMBOL_SELECT = 'isAdvanceSymbolSelect',
  IS_MOMENTUM_ENABLED_AT_SET = 'isMomentumEnabledAtSet',
  IS_UNIT_SHOW_IN_SIGNAL = 'isUnitShowInSignal',
  IS_MOBILE_NUMBER_LOGIN = 'isMobileNumberLogin',
  IS_MANUAL_TRADING = 'isManualTrading',
  IS_SL_ENTRY_SIGNAL = 'isSLEntrySignal',
  IS_NOTE_FOR_TEMPLATE_DETAIL = 'isNoteForTemplateDetail',
  IS_INDICATOR_AT_LEG = 'isIndicatorAtleg',
  IS_EXECUTION_SETTING = 'isExecutionSetting',
  IS_IFRAME_MONACO = 'isIframeMonaco',
  IS_OPTION_BOTBUILDER_POSITIONAL_LEG = 'isOptionBotBuilderPositionalLeg',
  IS_STOP_LOSS_MARKET = 'isStopLossMarket',
  IS_USA_SUPPORT_IN_EQ = 'isUSAsupporInEq',
  IS_EXIT_SIGNAL_SLM = 'isExitSignalSLM',
  IS_EXTERNAL_SIGNAL = 'isExternalSignal',
  IS_SPOT_FUTURES_ATM = 'isSpotFuturesAtm',
  IS_VIEW_ALL_POSITIONS = 'isViewAllPositions',
  IS_MYBOTS_IN_PORTFOLIO = 'isMybotsInPortfolio',
  IS_GET_ORDERSHEET = 'isGetOrderSheet',
  IS_US_SYMBOL_FOR_OPTION_BUILDER = 'isUSSymbolForOptionBuilder',
  IS_DTE_ENABLED_USA = 'isDTEenabledUSA',
  IS_OPTION_FUTURE_LEG = 'isOptionFutureLeg',
  IS_OPTION_EQUITY_LEG = 'isOptionEquityLeg',
  IS_LOCK_PROFIT = 'isLockProfit',
  IS_TRAIL_LOCK_PROFIT = 'isTrailLockProfit',
  IS_MAX_ENTRY_TIME = 'isMaxEntryTime',
  IS_LIVE_PAPER_IN_BACKTEST = 'isLivePaperInBacktest',
  IS_SHOW_RESTART_BUTTON = 'isShowRestartButton',
  IS_SHOW_POLICY_BUTTON = 'isShowPolicyButtons',
  IS_OPTION_BOTBUILDER_BANKEX_SYMBOL = 'isOptionBotBuilderBankexSymbol',
  IS_CHOOSE_YOUR_MARKET = 'isChooseYourMarket',
  IS_PAYMENT_MODE_AUTO = 'isPaymentModeAuto',
  IS_DELTA_IN_CONTRACT_TYPE = 'isDeltaInContractType',
  IS_OPTIONCHAIN = 'isOptionChain',
  IS_INCLUDE_GST_IN_PLANS = 'isIncludeGstInPlans',
  IS_IMPORT_EXPORT_STRATEGY = 'isImportExportStrategy',
  IS_SHOW_TICKS_DATA_WITHOUT_BROKER = 'isShowTicksDataWithoutBroker',
  IS_SHOW_US_STOCK_SYMBOLS = 'isShowUSAStockSymbols',
  IS_SHOW_MTM_GRAPH = 'isShowMTMGraph',
  IS_SHOW_DELTA_AND_STRIKE_IN_TGSL = 'isShowDeltaAndStrikeInTGSL',
  IS_TRADE_IDEAS = 'isTradeIdeas',
  IS_SIDEBAR_VIEW = 'isSideBarView',
  IS_SELECTED_SIGNAL_EXIT = 'isSelectedSignalExit',
  IS_CONTINUOUS_SET = 'isContinuousSet',
}
