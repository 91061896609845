import { useState } from 'react';
import { Box, Typography, Divider, Button, Tooltip } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { ProfileCapability } from 'types/ApiServicesTypes';
import { decimalFormat, formatNumber } from 'utils/GenericFunctions';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
type PlanInfoProps = {
  price: number;
  monthToMonth: number;
  capabilities: ProfileCapability[];
  disabled?: boolean;
  currencySymbol: string;
};

const PlanInfo = ({
  price,
  monthToMonth,
  capabilities,
  disabled,
  currencySymbol,
}: PlanInfoProps) => {
  const monthlyPrice = price
    ? parseFloat(decimalFormat(price / 12))
    : monthToMonth;
  const [openTooltipIndex, setOpenTooltipIndex] = useState<number | null>(null);

  const handleTooltipToggle = (index: number) => {
    setOpenTooltipIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <Box display="flex">
      <Box flex={1}>
        <Typography
          variant="h5"
          display="flex"
          alignItems="end"
          fontWeight={400}
          color={disabled ? 'text.disabled' : 'text.primary'}
        >
          {currencySymbol}
          {Math.round(monthlyPrice)}
          <Typography
            variant="body2"
            fontWeight={400}
            color={disabled ? 'text.disabled' : 'text.secondary'}
            sx={{ lineHeight: '32px', pl: 0.5 }}
          >
            / <FormattedMessage id="menu.month" />
          </Typography>
        </Typography>
        {price > 0 &&
          (monthToMonth > 0 ? (
            <Typography
              variant="body2"
              fontWeight={400}
              color={disabled ? 'text.disabled' : 'text.secondary'}
            >
              {formatNumber(
                ((monthToMonth - monthlyPrice) * 100) / monthToMonth,
                false,
                2,
              )}
              % off
            </Typography>
          ) : (
            <></>
          ))}

        <Typography
          variant="body3"
          color={disabled ? 'text.disabled' : 'text.secondary'}
          sx={{ pt: 0.5 }}
          component="div"
        >
          <FormattedMessage id="menu.billed_annualy_or" /> {currencySymbol}
          {monthToMonth > 0
            ? Math.round(monthToMonth)
            : Math.round(monthlyPrice)}{' '}
          <FormattedMessage id="menu.month_to_month" />
        </Typography>
      </Box>
      <Box sx={{ mx: 1, py: 1 }} display="flex">
        <Divider orientation="vertical" sx={{ borderColor: 'grey.300' }} />
      </Box>

      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        {capabilities.map((capability, index: number) => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
            sx={{ mb: 0.5 }}
            key={capability.key}
          >
            <Box>
              <TaskAltIcon
                color="success"
                style={{ fontSize: 15, marginRight: 2 }}
              />

              <Typography
                variant="body3"
                fontWeight={500}
                color={disabled ? 'text.disabled' : 'text.primary'}
              >
                {capability.credit === 0 ? '' : capability.credit}
              </Typography>
              <Typography
                variant="body3"
                color={disabled ? 'text.disabled' : 'text.secondary'}
                sx={{ pl: 0.5 }}
              >
                {capability.name}
              </Typography>
            </Box>
            {capability.description && (
              <Box sx={{ display: 'inline' }}>
                <Tooltip
                  title={capability.description}
                  placement="top"
                  open={openTooltipIndex === index}
                  // disableFocusListener
                  // disableTouchListener
                  onMouseEnter={() => handleTooltipToggle(index)}
                  onMouseLeave={() => setOpenTooltipIndex(null)}
                >
                  <Button
                    style={{ minWidth: 0 }}
                    onClick={function () {
                      handleTooltipToggle(index);
                      handleTooltipToggle(index);
                    }}
                  >
                    <InfoOutlinedIcon style={{ fontSize: 15 }} />
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PlanInfo;
