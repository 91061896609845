import SignupStartPage from './SignupStart';
import SigninPage from './SignIn';
import ForgotPassPage from './ForGotPass';
import { useState } from 'react';
import SignupVerifyCodePage from './SignupVerifyCodePage';
import ResetConfirmCodePage from './ResetConfirmCode';
import ResetPassPage from './ResetPassPage';
import HomePage from './HomePage';
import MuiFullScreenModal from './SignInFullScreenModal';
import { isUserLoggedIn } from 'utils/auth';
// import { getQueryParam } from 'utils/GenericFunctions';

export interface ObjProps {
  ccode: string;
  pnum: string;
  password: string;
  email: string;
}
export interface ForgotPassPageProps {
  code: string;
  emailOrPhone: string;
  type: string;
}
export interface ResetPageProps {
  code: string;
  emailOrPhone: string;
  otp: string;
}
function SignInModal({
  open,
  handleClose,
  type,
  data,
}: {
  open: boolean;
  handleClose: () => void;
  type?: string;
  data?: string;
}) {
  const [currentAuthPage, setCurrentAuthPage] = useState<string>(
    type ? type : 'Sign In',
  );
  const [signUpData, setSignupData] = useState<ObjProps>({
    ccode: '',
    email: '',
    password: '',
    pnum: '',
  });
  const isLoggedIn = isUserLoggedIn();

  // eslint-disable-next-line no-console
  const [forResetPass, setForResetPass] = useState<ResetPageProps>({
    code: '',
    emailOrPhone: '',
    otp: '',
  });
  const [forgotPassData, setForgotPassData] = useState<ForgotPassPageProps>({
    code: '',
    emailOrPhone: '',
    type: '',
  });
  // useEffect(() => {
  //   setCurrentAuthPage(type || currentAuthPage);
  // }, [currentAuthPage, type]);
  const getDataForReset = (data: ResetPageProps) => {
    setForResetPass(data);
  };
  const handleAuthChange = (view: string) => {
    setCurrentAuthPage(view);
  };
  const handleUseParams = (obj: ObjProps) => {
    setSignupData(obj);
  };
  const getForgotPassDate = (forgotPassObj: ForgotPassPageProps) => {
    setForgotPassData({ ...forgotPassData, ...forgotPassObj });
  };
  // const [keepModalOpen, setKeepModalOpen] = useState(false);
  // useEffect(() => {
  //   const modalopen = getQueryParam('modal');

  //   if (modalopen === 'india' || modalopen === 'usa') {
  //     setKeepModalOpen(true);
  //   }
  //   return;
  // }, []);
  const handleBothClose = () => {
    handleClose();
    // setKeepModalOpen(false);
    setCurrentAuthPage('Sign In');
  };
  return (
    <MuiFullScreenModal
      title={currentAuthPage}
      isNoBackButton={true}
      open={isLoggedIn ? false : open}
      handleClose={() => {
        handleBothClose();
        // setKeepModalOpen(false);
      }}
      body={
        <>
          {currentAuthPage == 'Sign In' && (
            <SigninPage onAuthPageChange={handleAuthChange} />
          )}
          {currentAuthPage == 'Sign Up' && (
            <SignupStartPage
              onAuthPageChange={handleAuthChange}
              handleUseParams={handleUseParams}
              data={data}
            />
          )}
          {currentAuthPage == 'Forgot Password' && (
            <ForgotPassPage
              onAuthPageChange={handleAuthChange}
              handleForgotPassData={getForgotPassDate}
            />
          )}
          {currentAuthPage == 'SignUp Verify' && (
            <SignupVerifyCodePage
              propsData={signUpData}
              onAuthPageChange={handleAuthChange}
            />
          )}
          {currentAuthPage == 'Confirm Code' && (
            <ResetConfirmCodePage
              getDataForReset={getDataForReset}
              propsData={forgotPassData}
              onAuthPageChange={handleAuthChange}
            />
          )}
          {currentAuthPage == 'Reset Password' && (
            <ResetPassPage
              propsData={forResetPass}
              onAuthPageChange={handleAuthChange}
            />
          )}
          {currentAuthPage == 'homepage' && (
            <HomePage onAuthPageChange={handleAuthChange} />
          )}
        </>
      }
    />
  );
}

export default SignInModal;
