/* eslint-disable no-console */
// import { PositionMetaData } from '../OptionChain';

import {
  TableColumns,
  TableRow,
  TableRowCellsData,
} from 'app/components/Common/CommonTable/type';
import { PositionMetaData } from '../OptionChain/types';
import { PositionsGreekDataForTable } from 'types/ApiServicesTypes';
import { OptionChainWithExpiryType } from 'app/pages/Simulator';

// export const deltaGammaVegaThetaCalc = (deltaGamma: number[]) => {
//   deltaGamma.reduce((acc, crr) => {
//     return acc + crr;
//   }, 0);
// };
// export const mtmCalculation = (
//   entryLtpobj: { entry_price: number; ltp: number }[],
// ) => {
//   entryLtpobj.reduce((acc, crr) => {
//     return acc + (crr.entry_price - crr.ltp);
//   }, 0);
// };

export class PortfolioGreeksCalculator {
  positions: PositionMetaData[];
  constructor(positions: PositionMetaData[]) {
    this.positions = positions;
  }

  calculateGreeks() {
    const portfolioGreeks = {
      delta: 0,
      gamma: 0,
      theta: 0,
      vega: 0,
    };

    this.positions.forEach(position => {
      Object.values(position.positionCandleWise).map(greekData => {
        if (greekData.contractDetails && greekData.quantity) {
          const lotSize = greekData.lots || 1;

          const multiplier = greekData.direction === 'long' ? 1 : -1;
          portfolioGreeks.delta +=
            (greekData.contractDetails.delta || 0) *
            greekData.quantity *
            multiplier;
          portfolioGreeks.gamma +=
            (greekData.contractDetails.gamma || 0) *
            greekData.quantity *
            multiplier;
          portfolioGreeks.theta +=
            (greekData.contractDetails.theta || 0) *
            greekData.quantity *
            lotSize *
            multiplier;
          portfolioGreeks.vega +=
            (greekData.contractDetails.vega || 0) *
            greekData.quantity *
            lotSize *
            multiplier;
        }
      });
    });

    // Adjusting the results to match the expected results
    portfolioGreeks.delta = Number(portfolioGreeks.delta.toFixed(2));
    portfolioGreeks.gamma = Number(portfolioGreeks.gamma.toFixed(5));
    portfolioGreeks.theta = Number(portfolioGreeks.theta.toFixed(0));
    portfolioGreeks.vega = Number(portfolioGreeks.vega.toFixed(0));

    return portfolioGreeks;
  }
}
export const generateData = (
  expiry_payoffs: number[],
  real_time_payoffs: number[],
  minStrike: number,
  maxStrike: number,
) => {
  const data: {
    underlyingPrice: number;
    payoff: number;
    currentPayoff: number;
    positive: number | boolean;
    negative: number | boolean;
  }[] = [];

  for (let i = 0, price = minStrike; price <= maxStrike; i++, price += 3) {
    const payoff = expiry_payoffs[i];
    const currentPayoff = real_time_payoffs[i];

    data.push({
      underlyingPrice: price,
      payoff,
      currentPayoff,
      positive: payoff > 0 ? payoff : false,
      negative: payoff < 0 ? payoff : false,
    });
  }

  return data;
};
// export function calculatePOPForMultiplePositions(
//   positions: PositionMetaData[],
// ) {
//   function calculatePOP(
//     strikePrice,
//     currentPrice,
//     delta: number | undefined,
//     iv: number,
//     expiryDate: string | undefined,
//     optionType: string,
//   ) {
//     // Convert expiry date to days to expiration using moment
//     const currentDate = moment();
//     const expirationDate = moment(expiryDate, 'YYYY-MM-DD');
//     const daysToExpiration = expirationDate.diff(currentDate, 'days');

//     // Convert days to expiration to years
//     const T = daysToExpiration / 365.0;

//     let basicPOP: number | undefined;
//     // Basic POP calculation using delta
//     if (delta && optionType.toLowerCase() === 'ce') {
//       basicPOP = 1 - delta;
//     } else if (optionType.toLowerCase() === 'pe') {
//       basicPOP = delta;
//     }

//     // Advanced POP calculation using Black-Scholes
//     const d1 =
//       (Math.log(currentPrice / strikePrice) + 0.5 * Math.pow(iv, 2) * T) /
//       (iv * Math.sqrt(Math.abs(T)));
//     const d2 = d1 - iv * Math.sqrt(Math.abs(T));

//     let advancedPOP: number;
//     if (optionType === 'ce') {
//       advancedPOP = normalCDF(d2);
//     } else {
//       advancedPOP = normalCDF(-d2);
//     }

//     return {
//       basicPOP: basicPOP,
//       advancedPOP: advancedPOP,
//     };
//   }

//   function normalCDF(x) {
//     return (1.0 + erf(x / Math.sqrt(2.0))) / 2.0;
//   }

//   function erf(x: number) {
//     const sign = x >= 0 ? 1 : -1;
//     x = Math.abs(x);

//     const a1 = 0.254829592;
//     const a2 = -0.284496736;
//     const a3 = 1.421413741;
//     const a4 = -1.453152027;
//     const a5 = 1.061405429;
//     const p = 0.3275911;

//     const t = 1.0 / (1.0 + p * x);
//     const y =
//       1.0 -
//       ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

//     return sign * y;
//   }

//   const results = positions.map(position => {
//     const { strike, premium, delta, iv, expiry_date, contractType } = position;
//     return calculatePOP(strike, premium, delta, iv, expiry_date, contractType);
//   });

//   return results;
// }

// Example usage
// const positions: PositionMetaData[] = [
//   {
//     strike: '100',
//     premium: 105,
//     delta: 0.3,
//     iv: 0.2,
//     expiry_date: '2024-07-20',
//     contractType: 'CE',
//   },
//   {
//     strike: '95',
//     premium: 90,
//     delta: 0.25,
//     iv: 0.25,
//     expiry_date: '2024-08-15',
//     contractType: 'PE',
//   },
//   // Add more positions as needed
// ];

// const results = calculatePOPForMultiplePositions(positions);
// eslint-disable-next-line no-console
// console.log(results);

// export {};
export const createPositionGreeksRow = (
  data: PositionsGreekDataForTable[],
): {
  rows: TableRow<PositionsGreekDataForTable>[];
  columns: TableColumns[];
} => {
  // eslint-disable-next-line no-console

  let rowCellsData: TableRowCellsData[] = [];
  const rows: TableRow<PositionsGreekDataForTable>[] = [];
  const columns: TableColumns[] = [
    { text: 'Instrument', align: 'left' },
    { text: 'IV', align: 'right' },
    {
      text: 'Delta',
      align: 'right',
      key: 'quantity',
    },
    { text: 'Gamma', align: 'right' },
    {
      text: 'Theta',
      align: 'right',
      key: 'entry_price',
    },
    { text: 'Vega', align: 'right' },
  ];
  if (!data) return { rows, columns };

  data.map(item => {
    rowCellsData = [];
    rowCellsData = [
      {
        content: item.contractType,
        fontWeight: 500,
        align: 'left',
      },
      {
        content: item.iv,
        align: 'right',
      },
      {
        content: item.delta,
        align: 'right',
      },
      {
        content: item.gamma,
        align: 'right',
      },
      {
        content: item.theta,
        align: 'right',
      },
      {
        content: item.vega,
        align: 'right',
      },
    ];
    rows.push({ rowCellsData, rowData: item });
  });
  return { rows, columns };
};

export const generateDataPoint = ({
  minStrike,
  maxStrike,
}: {
  minStrike: number;
  maxStrike: number;
}) => {
  return Array.from(
    { length: Math.ceil((maxStrike - minStrike + 1) / 3) },
    (_, i) => minStrike + i * 3,
  );
};
export const calaculatePopFunction = (
  optionChainData: OptionChainWithExpiryType,
  breakevens: number[],
  maxProfit: string | number,
  maxLoss: string | number,
): number => {
  try {
    if (!optionChainData || Object.keys(optionChainData).length === 0) {
      throw new Error('Invalid optionChainData');
    }

    const chainData = Object.values(optionChainData)[0];
    if (!chainData || Object.keys(chainData).length === 0) {
      throw new Error('Invalid chainData in optionChainData');
    }

    const strikes = Object.keys(chainData).map(strike => parseFloat(strike));

    const getDataByStrike = (strikeprice: number) => {
      return chainData[strikeprice.toFixed(1)];
    };

    const getNearestStrikes = (breakeven: number) => {
      const pos = strikes.findIndex(strike => strike >= breakeven);
      const lowerStrikes = strikes.slice(Math.max(pos - 1, 0), pos);
      const upperStrikes = strikes.slice(
        pos,
        Math.min(pos + 1, strikes.length),
      );
      return [...lowerStrikes, ...upperStrikes];
    };

    const interpolate = (
      strikes: number[],
      deltas: number[],
      breakeven: number,
    ): number => {
      const lDelta = deltas[0];
      const uDelta = deltas[1];

      if (lDelta === undefined || uDelta === undefined) {
        throw new Error('Delta values are missing for interpolation');
      }

      let d = Math.abs(lDelta - uDelta) / 50;
      if (lDelta) {
        d = d * (strikes[0] - breakeven) + lDelta;
      } else {
        d = d * (breakeven - strikes[1]) + uDelta;
      }

      return Math.abs(d);
    };

    const calculateBreakevenDelta = (breakeven: number) => {
      const nearestStrikes = getNearestStrikes(breakeven);

      if (nearestStrikes.length < 2) {
        throw new Error('Insufficient strikes for interpolation');
      }

      const callDeltas = nearestStrikes.map(
        strike => getDataByStrike(strike)?.CE?.delta || 0,
      );
      const putDeltas = nearestStrikes.map(
        strike => getDataByStrike(strike)?.PE?.delta || 0,
      );

      const interpolatedCallDelta = interpolate(
        nearestStrikes,
        callDeltas,
        breakeven,
      );
      const interpolatedPutDelta = interpolate(
        nearestStrikes,
        putDeltas,
        breakeven,
      );

      return {
        breakeven,
        callDelta: interpolatedCallDelta,
        putDelta: interpolatedPutDelta,
      };
    };

    if (!Array.isArray(breakevens) || breakevens.length === 0) {
      throw new Error('Invalid breakevens');
    }

    const greeksAtBreakeven = breakevens.map(breakeven =>
      calculateBreakevenDelta(breakeven),
    );

    if (greeksAtBreakeven.length === 0) {
      throw new Error('No valid breakevens found');
    }

    let pop: number;

    if (greeksAtBreakeven.length === 2) {
      if (maxProfit !== Infinity && maxLoss !== Infinity) {
        // Case for limited profit/loss
        pop =
          Math.abs(greeksAtBreakeven[0].callDelta) +
          Math.abs(greeksAtBreakeven[1].putDelta) -
          1;
      } else {
        // Case for unlimited profit
        pop =
          Math.abs(greeksAtBreakeven[0].putDelta) +
          Math.abs(greeksAtBreakeven[1].callDelta);
      }
    } else {
      // Case for single breakeven
      pop = greeksAtBreakeven[0].callDelta;
    }

    pop = Math.round(pop * 100 * 100) / 100; // rounding to 2 decimal places

    return pop;
  } catch (error) {
    console.error('Error calculating POP:', error);
    return 0; // Default fallback value
  }
};
