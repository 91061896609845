import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryErrorResetBoundary } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { Box, Tooltip, Typography } from '@mui/material';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import CommonOnboarding from 'app/design/speedBot/ComponentItem/Portfolio/CommonOnboarding';
import PageHeader from 'app/design/speedBot/PageHeader';
import ActivePaperTabs from 'app/components/Portfolio/ActivePaperTabs';
import { useCheckPortfolioDataExist } from 'services/Portfolio/useGetPortfolioActivePaper';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallBack from 'app/components/Common/ErrorFallBack';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { getQueryParam, groupBy } from 'utils/GenericFunctions';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import useGetProfile from 'services/Menu/useGetProfile';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
// import useReportUserAcitivity from 'services/UserActivity/useReportUserActivity';
// import { UserActivityTypes } from 'services/UserActivity/types';
// import HaveReferralDialog from 'app/components/Portfolio/HaveReferralDialog';
import { ToggleContext } from 'app/components/Context/ToggleViewContext';
import { PositionsListLayoutProps } from 'types/ComponentTypes';
import AllTodaysPositionLayout from 'app/design/layouts/AllTodaysPosition';
import useGetBotAllTodayPosition from 'services/Portfolio/useGetAllTodayPosition';
import BotHistoryPositionsModal from 'app/components/Bots/BotHistory/BotHistoryPositionsModal';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { Position } from 'types/ApiServicesTypes';
import MuiSwitchUnstyled from 'app/design/uiComponents/MuiUnstyledSwitch';
import { useGetTickData } from 'app/hooks/useGetTickData';
import useSetReferral from 'services/Menu/useSetReferral';
// import { Position } from 'vscode';

// import { Position } from 'vscode';

export function PortfolioPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const isMdUp = useGetMediaQueryUp('md');
  const { ptypeOld } = useParams<{ ptypeOld?: string }>();
  const startsWith = window.location.pathname.startsWith('/bot');
  const ptype = ptypeOld
    ? ptypeOld
    : getQueryParam('ptype', startsWith ? 'mybots' : 'active');
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(
    null,
  );
  const [openModal, setOpenModal] = useState<boolean>(false);

  const breadcrumb = useBreadcrumbManager();
  const { reset } = useQueryErrorResetBoundary();
  const [selectedTab, setSelectedTab] = useState<number>(
    // ptype.toLowerCase() === 'active' || ptype.toLowerCase() === 'live'
    //   ? 0
    //   : ptype.toLowerCase() === 'paper'
    //   ? 1
    //   : 2,
    ptype.toLowerCase() == 'manual'
      ? 3
      : ptype.toLowerCase() === 'mybots'
      ? 2
      : ptype.toLowerCase() === 'paper'
      ? 1
      : 0,
  );

  const objQuery = useCheckPortfolioDataExist();
  const { data: profileDetails } = useGetProfile();
  const isManualTrading = useIsFeatureFlag([FEATURES.IS_MANUAL_TRADING]);
  const isViewAllPositions = useIsFeatureFlag([FEATURES.IS_VIEW_ALL_POSITIONS]);
  // const isMybotsInPortfolio = useIsFeatureFlag([
  //   FEATURES.IS_MYBOTS_IN_PORTFOLIO,
  // ]);
  // const isReferralOauthUser = useIsFeatureFlag([
  //   FEATURES.IS_REFERRAL_OAUTH_USER,
  // ]);
  const { open, toggle } = useContext(ToggleContext);
  // const [open, setOpen] = useState(false);
  // const toggle = () => {
  //   setOpen(!open);
  // };
  const handleChange = (newValue: number) => {
    // if (isPaperTrading) {
    setSelectedTab(newValue);
    if (open) {
      toggle && toggle();
    }
    // }
  };

  useEffect(() => {
    breadcrumb.resetBreadcrumpPaths();
  }, [breadcrumb]);

  const { data, isLoading } = useGetBotAllTodayPosition(Boolean(open), ptype);
  const symbols = (data && data.map(symbol => symbol.trading_symbol)) || [];
  const liveFeedData = useGetTickData(symbols);
  const botPositions = data && data.length > 0 ? data : [];
  const botGrpPositions = groupBy(
    botPositions,
    'exit_time',
    true,
  ) as PositionsListLayoutProps;
  //change ptype from url
  useEffect(() => {
    history.replace({
      search: `ptype=${
        selectedTab === 0
          ? 'active'
          : selectedTab === 1
          ? 'paper'
          : selectedTab === 2
          ? 'mybots'
          : 'manual'
      }`,
    });
    void objQuery.refatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, selectedTab]);
  const handleModalOpen = (positionData: Position) => {
    setSelectedPosition(positionData);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedPosition(null);
  };

  const tabs = [
    {
      label: t('bot_status.live'),
      ...(objQuery.isActiveWarning && {
        iconProps: {
          icon: <WarningAmberIcon />,
          color: 'warning.main',
        },
      }),
    },
    {
      label: isMdUp ? t('bot_status.paper') : 'Forward',
      ...(objQuery.isPaperWarning && {
        iconProps: {
          icon: <WarningAmberIcon />,
          color: 'warning.main',
        },
      }),
    },
    {
      label: 'All Bots',
    },
    {
      label: `Manual ${isMdUp ? 'Trading' : ''}`,
      ...(objQuery.isPaperWarning && {
        iconProps: {
          icon: <WarningAmberIcon />,
          color: 'warning.main',
        },
      }),
    },
  ];
  const referralSetMutation = useSetReferral();
  const referralCode = localStorage.getItem('referral');
  useEffect(() => {
    if (
      referralCode &&
      profileDetails &&
      !profileDetails.referred_by &&
      profileDetails.is_oauth_user
    ) {
      referralSetMutation.mutate(
        { referralCode: referralCode },
        {
          onSuccess: res =>
            res.success === true
              ? localStorage.removeItem('referral')
              : localStorage.removeItem('referral'),
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const ToggleContext = createContext<boolean>({toggle:false,})

  return (
    <>
      <Helmet>
        <title>Portfolio</title>
        <meta name="description" content="Portfolio Page" />
      </Helmet>
      <CircularLoader open={objQuery.isLoading} />
      {!objQuery.isLoading ? (
        // !objQuery.isDataExist ? (
        //   <CommonOnboarding type={ptype} />
        // ) :
        <React.Fragment>
          <Box className="sticky-top">
            <PageHeader
              variant="portfolio"
              title={`${
                isMdUp
                  ? `${
                      profileDetails && profileDetails.name
                        ? profileDetails.name + "'s "
                        : ''
                    }`
                  : ''
              }${!open ? t('portfolio.page_title.portfolio') : 'Positions'}`}
              tabs={
                !objQuery.isLoading ? (
                  <Box display={'flex'} flexDirection={'row'}>
                    <ContentTabs
                      value={selectedTab}
                      onChange={(e, newVal) => handleChange(newVal)}
                      tabs={
                        !isManualTrading
                          ? tabs.filter(tab => !tab.label.includes('Manual'))
                          : tabs
                      }
                    />
                    {isMdUp &&
                      isViewAllPositions &&
                      (ptype === 'active' || ptype === 'paper') && (
                        <Box
                          // flex={1}
                          display="flex"
                          justifyContent="flex-end"
                          className="p-main-spacing"
                          alignItems="center"
                          gap={1}
                        >
                          <Typography variant="body2">
                            {/* {toTitleCase(
                              queryParameters === 'active' ? 'Live' : queryParameters,
                            ) + ' '} */}
                            Bots
                          </Typography>
                          <Tooltip
                            title={!open ? 'See all Positions' : 'See all bots'}
                          >
                            <MuiSwitchUnstyled
                              checked={open}
                              onChange={toggle}
                            />
                          </Tooltip>
                          <Typography variant="body2">
                            {/* {toTitleCase(
                              queryParameters === 'active' ? 'Live' : queryParameters,
                            ) + ' '} */}
                            Positions
                          </Typography>
                        </Box>
                      )}
                  </Box>
                ) : (
                  <></>
                )
              }
            />
          </Box>
          <ErrorBoundary
            key={location.pathname}
            FallbackComponent={ErrorFallBack}
            onReset={reset}
          >
            {!objQuery.isDataExist &&
            (ptype === 'active' || ptype === 'paper') ? (
              <CommonOnboarding type={ptype} />
            ) : !open ? (
              <ActivePaperTabs
                selectedTab={selectedTab}
                handleChangeTab={handleChange}
                ptype={ptype == 'live' ? 'active' : ptype}
              />
            ) : (
              <AllTodaysPositionLayout
                liveFeedData={liveFeedData}
                notMdUpData={botPositions}
                data={botGrpPositions}
                handleClick={handleModalOpen}
                handleChangeTab={handleChange}
                isloading={isLoading}
              />
            )}
          </ErrorBoundary>
        </React.Fragment>
      ) : (
        <></>
      )}
      {/* {isReferralOauthUser &&
        profileDetails?.is_oauth_user &&
        !profileDetails?.referred_by && (
          <HaveReferralDialog referralCode={profileDetails?.referral_code} />
        )} */}
      {selectedPosition && (
        <BotHistoryPositionsModal
          open={openModal}
          handleClose={handleModalClose}
          data={selectedPosition}
          title={<FormattedMessage id="buttons.position" />}
        />
      )}
    </>
  );
}
