import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Badge,
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import {
  // ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  convertToCheckBoxValues,
  getCheckedValues,
  Deployment,
  durationValueFromDate,
} from './utils';
import FilterRadioGroup from './FilterRadiogroup';
import { Formik } from 'formik';
import FilterCheckBoxGroup from './FilterCheckBoxGroup';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import MyDatePicker from './Calendar';
import CategorySelect from './StrategyDropDown';
import moment from 'moment';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { getStartDateFromDuration } from 'utils/GenericFunctions';
import {
  GetReportDropDownData,
  ReportSearchParams,
} from 'types/ApiServicesTypes';
// import dayjs from 'dayjs';
type ReportFilterProps = {
  isMobile?: boolean;
  filters: ReportSearchParams;
  handleClose?: () => void;
  handleAppyFilter: (filters: ReportSearchParams) => void;
  handleApplyFilterCount?: (count: number) => void;
  filterMetaData: GetReportDropDownData | undefined;
};

const durations = [
  'Last 1 Month',
  'Last 3 Months',
  'Last 6 Months',
  'Last 1 Year',
  'Custom',
];

function ReportFilter({
  isMobile,
  handleClose,
  filters,
  handleAppyFilter,
  handleApplyFilterCount,
  filterMetaData,
}: ReportFilterProps) {
  const [openFilter, setOpenFilter] = useState(false);
  const [dropDownvalue, setDropDownValue] = useState(
    filters && filters.startDate && filters.endDate
      ? durationValueFromDate(filters.startDate, filters.endDate)
      : 'Last 1 Month',
  );
  const [filterCount, setFilterCount] = useState(0);
  // const { data: filterMetaData } = useGetReportMetaData();

  const toggleOpenFilter = useCallback(() => {
    setOpenFilter(!openFilter);
    handleClose?.();
  }, [handleClose, openFilter]);
  // const clearRef =useRef()
  const oneMonthsAgo = moment().subtract(1, 'month').format('YYYY-MM-DD');
  // const threeMonthsAgo = moment().subtract(3, 'month').format('YYYY-MM-DD');

  useEffect(() => {
    const appliedFilters: string[] = [];

    if (filters) {
      if (filters.startDate && filters.startDate !== oneMonthsAgo) {
        appliedFilters.push('startDate');
      }
      if (
        filters.endDate &&
        filters.endDate !== moment().format('YYYY-MM-DD')
      ) {
        appliedFilters.push('endDate');
      }
      if (filters.strategy?.length) {
        appliedFilters.push('strategy');
      }
      if (filters.brokers?.length) {
        appliedFilters.push('brokers');
      }
      if (filters.exchanges?.length) {
        appliedFilters.push('exchanges');
      }
      if (filters.deployment && filters.deployment !== 'both') {
        appliedFilters.push('deployment');
      }
      setFilterCount(appliedFilters.length);
      if (handleApplyFilterCount) {
        handleApplyFilterCount(appliedFilters.length);
      }
    }
  }, [filters, oneMonthsAgo, handleApplyFilterCount]);

  return (
    <>
      {filterMetaData && (
        <>
          <Box className="p-main-spacing">
            <Formik
              enableReinitialize
              initialValues={{
                duration:
                  filters && filters.startDate && filters.endDate
                    ? durationValueFromDate(filters.startDate, filters.endDate)
                    : 'Last 1 Month',
                startDate:
                  filters && filters.startDate
                    ? filters.startDate
                    : oneMonthsAgo,
                endDate:
                  filters && filters.endDate
                    ? filters.endDate
                    : moment().format('YYYY-MM-DD'),
                strategy: filters.strategy || [],
                brokers: filters.brokers || [],
                deployment:
                  filters.deployment && filters.deployment !== 'both'
                    ? filters.deployment
                    : 'both',

                exchanges: convertToCheckBoxValues(
                  filterMetaData.exchanges,
                  filters.exchanges || [],
                ),
              }}
              onSubmit={values => {
                handleAppyFilter({
                  startDate: values.startDate,
                  endDate: values.endDate,
                  strategy: values.strategy,
                  brokers: values.brokers,
                  exchanges: getCheckedValues(values.exchanges),
                  deployment:
                    values.deployment === 'both'
                      ? undefined
                      : values.deployment,
                });
                toggleOpenFilter();
              }}
            >
              {({ values, setFieldValue, handleSubmit, touched, errors }) => {
                return (
                  <>
                    {!isMobile && (
                      <Box
                        sx={{
                          py: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          px: 0,
                        }}
                      >
                        <MuiButton
                          variant="text"
                          startIcon={<FilterListIcon color="primary" />}
                          onClick={toggleOpenFilter}
                          {...(filterCount && {
                            endIcon: (
                              <Badge
                                color="primary"
                                badgeContent={filterCount}
                                sx={{ mx: 1 }}
                              />
                            ),
                          })}
                        >
                          Filter
                        </MuiButton>
                        <MuiButton
                          variant="outlined"
                          onClick={() => {
                            setFieldValue('duration', 'Last 1 Month');
                            setFieldValue('startDate', oneMonthsAgo);
                            setFieldValue(
                              'endDate',
                              moment().format('YYYY-MM-DD'),
                            );
                            setFieldValue('strategy', []);
                            setFieldValue('brokers', []);
                            setFieldValue('deployment', 'both');

                            setFieldValue(
                              'exchanges',
                              convertToCheckBoxValues(
                                filterMetaData.exchanges,
                                [],
                              ),
                            );
                            setDropDownValue('Last 1 Month');
                            handleSubmit();
                            toggleOpenFilter();
                          }}
                        >
                          Reset
                        </MuiButton>
                      </Box>
                    )}
                    <ItemSeparator variant="1px" />
                    <Collapse in={isMobile || openFilter}>
                      <Grid container py={2} columnSpacing={4} rowSpacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <FormControl fullWidth sx={{ mb: 0 }}>
                                <InputLabel id="Duration">
                                  <FormattedMessage id="user_backtesting.form.duration" />
                                </InputLabel>
                                <Select
                                  labelId="label-duration"
                                  name="duration"
                                  value={values.duration}
                                  label={
                                    <FormattedMessage id="user_backtesting.form.duration" />
                                  }
                                  onChange={e => {
                                    setFieldValue('duration', e.target.value);
                                    if (!(e.target.value === 'Custom')) {
                                      setFieldValue(
                                        'startDate',
                                        getStartDateFromDuration(
                                          e.target.value,
                                        ),
                                      );
                                    }
                                    setDropDownValue(e.target.value);
                                  }}
                                >
                                  {durations.map((duration, i) => (
                                    <MenuItem value={duration} key={i}>
                                      {duration}
                                    </MenuItem>
                                  ))}
                                  {/* <MenuItem value="custom">
                                    <FormattedMessage id="user_backtesting.form.custom" />
                                  </MenuItem> */}
                                </Select>
                                {touched.duration && errors.duration && (
                                  <FormHelperText error>
                                    {errors.duration}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            {dropDownvalue === 'Custom' ? (
                              <>
                                <Grid item xs={12} md={4}>
                                  <MyDatePicker
                                    whichDate="Start Date"
                                    // ref={clearRef}
                                    value={values.startDate}
                                    datetype="start"
                                    onChange={date => {
                                      setFieldValue('startDate', date);
                                      if (
                                        moment(values.endDate).diff(
                                          moment(date),
                                          'day',
                                        ) > 365
                                      ) {
                                        setFieldValue(
                                          'endDate',
                                          moment(date)
                                            .add(12, 'month')
                                            .format('YYYY-MM-DD'),
                                        );
                                      } else if (
                                        moment(values.endDate).diff(
                                          moment(date),
                                          'day',
                                        ) < 0
                                      ) {
                                        setFieldValue(
                                          'endDate',
                                          moment().format('YYYY-MM-DD'),
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <MyDatePicker
                                    // ref={clearRef}
                                    whichDate="End Date"
                                    value={values.endDate}
                                    startDate={values.startDate}
                                    datetype="end"
                                    onChange={date => {
                                      setFieldValue('endDate', date);
                                      if (
                                        moment(date).diff(
                                          moment(values.startDate),
                                          'day',
                                        ) > 365
                                      ) {
                                        setFieldValue(
                                          'startDate',
                                          moment(date)
                                            .subtract(12, 'month')
                                            .format('YYYY-MM-DD'),
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <> </>
                            )}
                            <Grid
                              item
                              xs={12}
                              md={dropDownvalue === 'Custom' ? 6 : 4}
                            >
                              <CategorySelect
                                value={values.strategy}
                                onChange={(
                                  event: SelectChangeEvent<string[]>,
                                ) => {
                                  const selectedValue = event.target.value;
                                  setFieldValue('strategy', selectedValue);
                                }}
                                options={filterMetaData.strategies}
                                labelName="Bots"
                                resetSelectedValues={() => {
                                  setFieldValue('strategy', []);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={dropDownvalue === 'Custom' ? 6 : 4}
                            >
                              <CategorySelect
                                value={values.brokers}
                                onChange={(
                                  event: SelectChangeEvent<string[]>,
                                ) => {
                                  const selectedValue = event.target.value;
                                  setFieldValue('brokers', selectedValue);
                                }}
                                options={filterMetaData.broker_accounts}
                                labelName="Brokers"
                                resetSelectedValues={() => {
                                  setFieldValue('brokers', []);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={isMobile ? 6 : 6}>
                          <Typography variant="body1">Deployment</Typography>
                          <FilterRadioGroup
                            controlName="deployment"
                            radioItems={Deployment}
                            handleChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const { name, value } = event.target;
                              setFieldValue(name, value);
                            }}
                            selectedValue={values.deployment}
                          />
                        </Grid>

                        <Grid item xs={6} md={6}>
                          <FilterCheckBoxGroup
                            // inline
                            label="Exchanges"
                            controlName="exchanges"
                            checkBoxItems={filterMetaData.exchanges}
                            handleChange={(name: string, checked: boolean) => {
                              setFieldValue(name, checked);
                            }}
                            selectedValues={values.exchanges}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          {...(isMobile && {
                            sx: {
                              position: 'sticky',
                              bottom: '0px',
                              left: '8px',
                              padding: 2,
                              backgroundColor: '#fff',
                            },
                          })}
                        >
                          <Box
                            display={'flex'}
                            justifyContent={
                              isMobile ? 'space-between' : 'flex-end'
                            }
                            sx={{
                              '& .MuiButton-root': {
                                minWidth: { md: '8rem' },
                              },
                            }}
                          >
                            {isMobile ? (
                              <MuiButton
                                variant="outlined"
                                onClick={() => {
                                  setFieldValue('duration', 'Last 1 Month');
                                  setFieldValue('startDate', oneMonthsAgo);
                                  setFieldValue(
                                    'endDate',
                                    moment().format('YYYY-MM-DD'),
                                  );
                                  setFieldValue('strategy', []);
                                  setFieldValue('brokers', []);
                                  setFieldValue('deployment', 'both');

                                  setFieldValue(
                                    'exchanges',
                                    convertToCheckBoxValues(
                                      filterMetaData.exchanges,
                                      [],
                                    ),
                                  );
                                  setDropDownValue('Last 1 Month');
                                }}
                              >
                                Reset
                              </MuiButton>
                            ) : (
                              <></>
                            )}
                            <Box display={'flex'} gap={2}>
                              <MuiButton
                                color="error"
                                onClick={toggleOpenFilter}
                              >
                                Cancel
                              </MuiButton>
                              <MuiButton
                                variant="contained"
                                onClick={handleSubmit}
                              >
                                Apply
                              </MuiButton>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </>
                );
              }}
            </Formik>
          </Box>
          <ItemSeparator variant="2px" />
        </>
      )}
    </>
  );
}

export default memo(ReportFilter);
