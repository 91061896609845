import React, { useCallback, useEffect, useState, Fragment } from 'react';
import {
  ControlsType,
  DropdownFieldValue,
  FieldParam,
  FieldParamValue,
  OptionLegParam,
  ValidateState,
} from '../types';
import {
  TextField,
  Grid,
  Box,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { cloneDeep } from 'lodash';

type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, string>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

export const TimeRenderer = (props: Props) => {
  const [isValidStatus, setIsValidStatus] = useState<ValidateState>({
    error: '',
    status: false,
  });
  const { param, onChange, sendValidation, isReadOnly } = props;
  const selected = props.selected as Record<string, string>;
  const { controls, name, parentKey, myStyle, addClass, width } = param;

  const [userInput, setUserInput] = useState(cloneDeep(selected));

  const theme = React.useMemo(() => {
    return createTheme({
      components: {
        MuiIconButton: {
          styleOverrides: {
            sizeMedium: {
              fontSize: '16px',
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              border: 'none',
            },
            root: {
              minWidth: '116px',
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              width: width ? `${width}px` : '192px',
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              top: '7px',
            },
            shrink: {
              top: '0px',
            },
          },
          defaultProps: {
            style: {
              backgroundColor: '#fafafa',
              padding: '0px 7px',
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              display: `${isReadOnly ? 'none' : 'block'}`,
            },
          },
        },
      },
    });
  }, [isReadOnly, width]);

  const validate = useCallback(
    (formData: Record<string, string>) => {
      let validStatus = {
        status: false,
        error: '',
      };
      Object.keys(formData).forEach((item, index) => {
        if (controls[index].isRequired && !formData[item]) {
          validStatus = {
            status: true,
            error: 'Please enter valid data',
          };
        }
      });
      return validStatus;
    },
    [controls],
  );

  useEffect(() => {
    const validStatus = validate(selected);
    setIsValidStatus(validStatus);
    sendValidation(parentKey, !validStatus.status);
  }, [validate, parentKey, sendValidation, selected]);

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    control: ControlsType,
  ) => {
    userInput[control.key] = event.target.value;
    const validStatus = validate(userInput);
    setIsValidStatus(validStatus);
    setUserInput(cloneDeep(userInput));
  };

  const onSelectChange = (event: SelectChangeEvent, control: ControlsType) => {
    selected[control.key] = event.target.value;
    const validStatus = validate(userInput);
    setIsValidStatus(validStatus);
    onChange(parentKey, selected);
  };

  const onFocusOutHandler = (control: ControlsType) => {
    selected[control.key] = userInput[control.key];
    const validStatus = validate(selected);
    sendValidation(parentKey, !validStatus.status);
    onChange(parentKey, selected);
  };

  return (
    <Grid
      item
      className={addClass}
      style={
        width
          ? { marginBottom: '24px', width: `${width}px` }
          : { marginBottom: '24px' }
      }
    >
      <GlobalStyles
        styles={{
          [theme.breakpoints.down('md')]: myStyle?.small
            ? myStyle.small
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('md')]: myStyle?.medium
            ? myStyle.medium
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('lg')]: myStyle?.large
            ? myStyle.large
            : myStyle?.root
            ? myStyle.root
            : myStyle,
        }}
      />
      <ThemeProvider theme={theme}>
        <Box className="multi-select-box">
          {controls.map((control, index) =>
            control.enabled ? (
              <Fragment key={control.key}>
                {index !== 0 ? (
                  <Box
                    sx={{
                      height: '36px',
                      borderLeft: '1px solid #ccc !important',
                    }}
                  />
                ) : null}
                <FormControl key={control.key}>
                  {control.type === 'dropdown' ? (
                    <Select
                      onChange={(event: SelectChangeEvent<string>) => {
                        onSelectChange(event, control);
                      }}
                      key={control.key}
                      name={control.key}
                      id={name}
                      value={selected[control.key]}
                      disabled={isReadOnly}
                      error={isValidStatus.status}
                    >
                      {control.values?.map((value: DropdownFieldValue) => (
                        <MenuItem
                          key={value.key}
                          value={value.key}
                          className="select-option"
                        >
                          {value.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : control.type === 'time' ? (
                    <TextField
                      key={index}
                      // style={width ? { width: `${width}px` } : {}}
                      type="time"
                      label={name}
                      variant="outlined"
                      // className="comman-input"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onInputChange(event, control)
                      }
                      onBlur={() => onFocusOutHandler(control)}
                      value={
                        (userInput[control.key] ||
                          control.defaultValue) as string
                      }
                      error={isValidStatus.status}
                      disabled={isReadOnly}
                    />
                  ) : null}
                </FormControl>
              </Fragment>
            ) : null,
          )}
        </Box>
      </ThemeProvider>
    </Grid>
  );
};
