import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const BackTestingCard = ({
  percentage,
  name,
}: {
  percentage: number;
  name: string;
}) => {
  return (
    <Card
      //   key={backtest.id}
      sx={{
        mt: 1,
        borderRadius: '1em',
        height: '200px',
        width: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        padding={1}
      >
        <Box
          sx={{
            height: 30,
            width: 30,
            alignSelf: 'center',
          }}
        >
          <CircularProgressbar
            strokeWidth={50}
            styles={buildStyles({
              pathColor: '#4E9F3D',
              trailColor: '#EEEEEE',
              strokeLinecap: 'butt',
            })}
            value={percentage}
          />
        </Box>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {name}
        </Typography>
      </Box>
    </Card>
  );
};

export default BackTestingCard;
