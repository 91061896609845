import { SignalModalItemProps } from './types';
import { Box, Typography } from '@mui/material';
import MuiChip from '../../uiComponents/MuiChip';
import { deepPurple } from '@mui/material/colors';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const SignalModalItem = ({
  signalData,
  isNoPadding,
  handleMenuClick,
  index,
}: SignalModalItemProps) => {
  return (
    <Box
      {...(!isNoPadding && { className: 'p-main-spacing' })}
      alignItems="center"
      gap={1}
      sx={{
        py: 2,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box flex={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <Box sx={{ flex: 1 }}>
            <Typography
              component="div"
              variant="body1"
              color="text.primary"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <span>{signalData.symbol}</span>
              <MuiChip
                label={signalData.transaction_type}
                color={
                  signalData.transaction_type === 'BUY'
                    ? 'infolight'
                    : 'greylight'
                }
                size="xsmall"
                variant="filled"
                sx={{ ml: 1 }}
              />
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 'inherit',
              textAlign: 'right',
              whiteSpace: 'nowrap',
              pl: 2,
            }}
          >
            <Typography variant="body2">{signalData.order_type}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
          <Box display="flex" sx={{ flex: 1 }} alignItems="center">
            <Typography
              variant="body3"
              fontWeight={500}
              color={
                signalData.transaction_type === 'BUY'
                  ? 'info.main'
                  : deepPurple[500]
              }
            >
              {signalData.quantity}
            </Typography>

            {signalData.tag && signalData.trade_id && (
              <MuiChip
                label={signalData.trade_id}
                size="xsmallicon"
                color="greylight"
                sx={{ ml: 1, txetAlign: 'center', minWidth: '2.5rem' }}
              />
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              flex: 'inherit',
              whiteSpace: 'nowrap',
              textAlign: 'right',
              pl: 2,
            }}
          >
            <Typography variant="body3">
              {signalData.position_type} | {signalData.product_type}
            </Typography>
          </Box>
        </Box>
      </Box>
      <IconButton onClick={e => handleMenuClick(e, signalData, index)}>
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
};

export default SignalModalItem;
