import {
  Box,
  InputLabel,
  FormControl,
  Button,
  Grid,
  ButtonGroup,
} from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';

import {
  FieldParam,
  DropdownFieldValue,
  ButtonGroupParam,
  FieldParamValue,
  ValidateState,
  ControlsType,
  OptionLegParam,
} from '../types';
import { v4 } from 'uuid';

type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, string[]>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '15px',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          height: '42px',
          width: '42px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: 'rgba(0, 0, 0, 0.87)',
        },
        outlined: {
          border: '1px solid rgba(0, 0, 0, 0.87)',
          color: 'rgb(0, 0, 0)',
        },
      },
    },
  },
});

export const ButtonGroupRenderer = (props: Props) => {
  const [isValidStatus, setIsValidStatus] = useState<ValidateState>({
    error: '',
    status: false,
  });
  const { param, onChange, sendValidation, isReadOnly } = props;
  const selected = props.selected as Record<string, string[]>;
  const { name, controls, renderingType, parentKey, myStyle, addClass, width } =
    param as ButtonGroupParam;
  // const selected = props.selected as string[];

  const validate = useCallback(
    (formData: Record<string, string[]>) => {
      let validStatus = {
        status: false,
        error: '',
      };
      Object.keys(formData).forEach((item, index) => {
        if (controls[index].isRequired && formData[item].length === 0) {
          validStatus = {
            status: true,
            error: 'Please enter valid data',
          };
        }
      });
      return validStatus;
    },
    [controls],
  );

  useEffect(() => {
    const validStatus = validate(selected);
    setIsValidStatus(validStatus);
    sendValidation(parentKey, !validStatus.status);
  }, [validate, sendValidation, selected, parentKey]);

  const buttonClickHandle = (value: string, control: ControlsType) => {
    const data = selected[control.key];
    const foundIndex = data.findIndex(e => e === value);
    if (foundIndex >= 0) {
      data.splice(foundIndex, 1);
    } else {
      data.push(value);
    }
    selected[control.key] = data;
    const validStatus = validate(selected);
    sendValidation(parentKey, !validStatus.status);
    onChange(parentKey, selected);
  };

  return (
    <Grid
      item
      style={
        width
          ? { marginBottom: '24px', alignSelf: 'center', width: `${width}px` }
          : { marginBottom: '24px', alignSelf: 'center' }
      }
      className={addClass}
    >
      <GlobalStyles
        styles={{
          [theme.breakpoints.down('md')]: myStyle?.small
            ? myStyle.small
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('md')]: myStyle?.medium
            ? myStyle.medium
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('lg')]: myStyle?.large
            ? myStyle.large
            : myStyle?.root
            ? myStyle.root
            : myStyle,
        }}
      />
      <ThemeProvider theme={theme}>
        <FormControl
          variant="outlined"
          size={'small'}
          style={{ width: '100%' }}
        >
          {controls.map(control => {
            return (
              <div key={v4()}>
                <Box
                  sx={{
                    display: { xs: 'block', sm: 'flex' },
                    alignItems: 'center',
                  }}
                  key={v4()}
                >
                  <InputLabel
                    style={{
                      position: 'relative',
                      transform: 'none',
                      marginRight: '12px',
                      fontSize: '12px',
                    }}
                    id="name"
                    sx={{ mb: { xs: 1, sm: 0 } }}
                  >
                    {name}
                  </InputLabel>
                  <ButtonGroup
                    size="small"
                    aria-label="small button group outlined primary"
                    orientation={renderingType}
                    style={{
                      border: isValidStatus.status ? '1px solid red' : 'none',
                    }}
                    sx={{ ml: { xs: -0.5, sm: 0 } }}
                  >
                    {control.values?.map((value: DropdownFieldValue) => (
                      <Button
                        sx={width ? { width: `${width}px` } : {}}
                        size="small"
                        style={{
                          marginLeft: '4px',
                          marginRight: '4px',
                          paddingLeft: '3px',
                          paddingRight: '3px',
                          textTransform: 'capitalize',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: `${
                            selected[control.key].includes(value.key)
                              ? 'rgba(0, 0, 0, 0.87)'
                              : 'rgba(0, 0, 0, 0.6)'
                          }`,
                          border: `1px solid ${
                            selected[control.key].includes(value.key)
                              ? '#9E9E9E'
                              : '#E0E0E0'
                          }`,
                          backgroundColor: `${
                            selected[control.key].includes(value.key)
                              ? '#EEEEEE'
                              : '#F5F5F5'
                          }`,
                        }}
                        key={v4()}
                        onClick={() => {
                          buttonClickHandle(value.key, control);
                        }}
                        variant={
                          selected[control.key].includes(value.key)
                            ? 'contained'
                            : 'outlined'
                        }
                        disabled={isReadOnly}
                      >
                        {value.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Box>
              </div>
            );
          })}
        </FormControl>
      </ThemeProvider>
    </Grid>
  );
};
