import { Box, Divider, Grid } from '@mui/material';
import OptionChainContent from 'app/components/Simulator/OptionChain';
import { PositionWithExpiryData } from 'app/components/Simulator/OptionChain/types';
// import { calculatePOPForMultiplePositions } from 'app/components/Simulator/PayoffChart/utils';
import TimeLineContent from 'app/components/Simulator/TimeLine';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import moment from 'moment';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import useGetOptionChainMetadata from 'services/Simulator/useGetOptionChainMetadata';
import useGetOptionChainData, {
  useGetOptionChainExpiredED,
  useGetOptionChainExpiryData,
} from 'services/Simulator/useGetOptionChianData';
import { SymbolOptionChainDataType } from 'types/ApiServicesTypes';
import OptionPayoff from 'app/components/Simulator/PayoffChart/OptionPayoff/OptionPayoff';
import PositionSquareOffModal from 'app/components/Simulator/OptionChain/PositionSquareOffModal';
import {
  expiredDateSquarOffPosition,
  getExpiredPositions,
} from 'app/components/Simulator/OptionChain/utils';
import PageHeader from 'app/design/speedBot/PageHeader';

export type OptionTabMetaData = {
  symbol: string;
  optionExpiry: string;
};

export type OptionChainWithExpiryType = Record<
  string,
  Record<string, SymbolOptionChainDataType>
>;

const Simulator = () => {
  const [date, setDate] = useState<string>('2024-03-01 09:16:00');
  const [open, setOpen] = useState(false);
  const [maxProfitLoss, setMaxProfitLoss] = useState<{
    max_profit: string | number;
    max_loss: string | number;
    breakevenPoints: number[];
  }>({ max_profit: Infinity, max_loss: Infinity, breakevenPoints: [] });
  const { data: metaData } = useGetOptionChainMetadata(
    moment.utc(date).format('YYYY-MM-DD').toString(),
  );

  const indexes =
    metaData && metaData.data
      ? Object.keys(metaData.data.lot_sizes)
      : ['NIFTY 50'];
  const optionExpiry =
    metaData && metaData.data && metaData.data.options[indexes[0]];
  const [optionTabMetaData, setOptionTabMetaData] = useState<OptionTabMetaData>(
    {
      symbol: indexes[0],
      optionExpiry: optionExpiry ? optionExpiry[0] : '',
    },
  );
  const [positions, setPositions] = useState<PositionWithExpiryData>({});
  const optionchainWithExpiryData = useGetOptionChainExpiryData(
    optionTabMetaData.symbol,
    Object.keys(positions),
    moment(date, 'YYYYMMDD HH:mm')
      .utcOffset(330)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')
      .toString(),
  );

  const lotSize = metaData
    ? metaData.data?.lot_sizes[optionTabMetaData.symbol]
    : 25;

  const { data: optionChainStrikeData } = useGetOptionChainData(
    optionTabMetaData.symbol,
    optionTabMetaData.optionExpiry,
    moment(date, 'YYYYMMDD HH:mm')
      .utcOffset(330)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss')
      .toString(),
  );
  const [optionChainDatawithExpiry, setOptionChainDatawithExpiry] =
    useState<OptionChainWithExpiryType>({
      [`${optionTabMetaData.optionExpiry}`]: optionChainStrikeData
        ? optionChainStrikeData.candles
        : ({} as Record<string, SymbolOptionChainDataType>),
    });

  const [optionChianData, setOptionChainData] = useState({
    [optionTabMetaData.optionExpiry]: optionChainStrikeData,
  });

  const expiredEDate: string[] = Object.keys(positions)
    .filter(expiry => !optionExpiry?.includes(expiry))
    .flatMap(expireE => {
      return Object.values(positions[expireE]).map(position => {
        if (!position.isSquareOff) return expireE;
      });
    })
    .filter((expiry): expiry is string => expiry !== undefined);

  let expireED = expiredEDate.filter(
    (expiry, index) => expiredEDate.indexOf(expiry) === index,
  );
  const optionChainForExpiryDate = useGetOptionChainExpiredED(
    optionTabMetaData.symbol,
    expireED,
  );
  const expPositions =
    optionChainForExpiryDate &&
    expiredDateSquarOffPosition(
      getExpiredPositions(expireED, positions),
      optionChainForExpiryDate,
      lotSize,
    );

  const handleCloseSquareOffModal = (success: boolean) => {
    if (success) {
      let newSquareOffPositions = {} as PositionWithExpiryData;
      expPositions.forEach(position => {
        if (position) {
          newSquareOffPositions = {
            [`${position.expiryDate!}`]: {
              ...newSquareOffPositions[`${position.expiryDate!}`],
              [`${position.contractType.toUpperCase()}_${position.strike}`]:
                position,
            },
          };
        }
      });
      setPositions({ ...positions, ...newSquareOffPositions });
      expireED = [];
      setOpen(false);
    } else {
      setDate(
        moment(expireED[0])
          .hour(15)
          .minute(30)
          .format('YYYY-MM-DD HH:mm')
          .toString(),
      );
      setOpen(false);
    }
  };

  React.useEffect(() => {
    setOptionTabMetaData({
      symbol: indexes[0],
      optionExpiry: optionExpiry ? optionExpiry[0] : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData]);
  React.useEffect(() => {
    setOptionChainDatawithExpiry({
      ...optionChainDatawithExpiry,
      [`${optionTabMetaData.optionExpiry}`]: optionChainStrikeData
        ? optionChainStrikeData.candles
        : ({} as Record<string, SymbolOptionChainDataType>),
    });
    setOptionChainData({
      ...optionChianData,
      [optionTabMetaData.optionExpiry]:
        optionChainStrikeData === undefined
          ? Object.values(optionChianData)[
              Object.values(optionChianData).length - 1
            ]
          : optionChainStrikeData,
    });
    if (expireED.length > 0) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, optionChainStrikeData]);

  return (
    <>
      <CircularLoader open={false} zIndex={1302} />
      <Helmet>
        <title>Simulator</title>
        <meta name="description" content="Simulator Page" />
      </Helmet>
      <Box className="sticky-top">
        <PageHeader variant={'common'} title={'Simulator'} />
        <Divider sx={{ borderBottom: 2, borderColor: 'grey.300' }} />
      </Box>
      <Box
        className="p-main-spacing"
        sx={{
          // position: 'absolute',
          backgroundColor: 'white',
          left: '1px',
          width: `100%`,
          height: '100vh',
        }}
      >
        <Grid container columnSpacing={1}>
          <Grid item sm={12} py={1}>
            <TimeLineContent date={date} setDate={setDate} />
          </Grid>
          <Grid item sm={12} lg={6}>
            <OptionChainContent
              date={date}
              optionTabMetaData={optionTabMetaData}
              setOptionTabMetaData={setOptionTabMetaData}
              optionChainData={optionchainWithExpiryData}
              optionChainCurrentExpiryData={
                optionChianData[optionTabMetaData.optionExpiry] ||
                optionChainStrikeData
              }
              optionChainMetadata={metaData?.data}
              lotSize={lotSize}
              positions={positions}
              setPositions={setPositions}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            {
              <Box
                sx={{
                  height: '100%',
                  border: '1px solid rgb(0 0 0 / 23%)',
                  borderRadius: '1em',
                }}
              >
                <OptionPayoff
                  optionChainDatawithExpiry={optionchainWithExpiryData}
                  date={date}
                  maxProfitLoss={maxProfitLoss}
                  setMaxProfitLoss={setMaxProfitLoss}
                  position={positions}
                  lotSize={lotSize}
                />
              </Box>
            }
          </Grid>
        </Grid>
      </Box>
      <PositionSquareOffModal
        open={open}
        handleClose={handleCloseSquareOffModal}
        positions={getExpiredPositions(expireED, positions)}
        currentLTP={optionChainForExpiryDate}
      />
    </>
  );
};

export default Simulator;
