import { Box, Typography } from '@mui/material';
import { OptionLegExecutionParam } from 'app/components/Builder/OptionsStrategy/types';
import { toTitleCase } from 'utils/GenericFunctions';

const ExecutionReadonlyView = ({
  values,
}: {
  values: OptionLegExecutionParam;
}) => {
  const filteredValues = Object.fromEntries(
    Object.entries(values).filter(
      ([key, value]) =>
        (values.order_type === 'market' && key === 'order_type') ||
        (values.order_type !== 'market' && value !== 0),
    ),
  );

  return (
    <Box
      maxWidth={
        Object.keys(filteredValues).length >= 4 ? '480px' : 'fit-content'
      }
      sx={{
        marginTop: 0,
        alignSelf: 'flex-start',
        border: '1px solid #ddd',
        borderRadius: '0.5em',
        padding: '0.5em',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5em',
      }}
    >
      {Object.entries(filteredValues).map(([key, value], i) => {
        return (
          <Box
            key={key}
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '0.5px solid #f0f0f0',
              borderRadius: '0.5em',
              paddingRight: '3px',
            }}
          >
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{
                fontWeight: 'bold',
                backgroundColor: '#f0f0f0',
                padding: '0.2em 0.4em',
                borderRadius: '0.25em',
                marginRight: '2px',
              }}
            >
              {toTitleCase(key.replace(/_/g, ' '))}:
            </Typography>
            <Typography
              variant="caption"
              color="primary.main"
              sx={{ fontWeight: '600', marginLeft: '0.2em' }}
            >
              {typeof value === 'number' ? value : toTitleCase(value)}
            </Typography>
            {i < Object.entries(filteredValues).length - 1 && (
              <Typography variant="subtitle2" sx={{ marginLeft: '0.2em' }}>
                ,
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default ExecutionReadonlyView;
