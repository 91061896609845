import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PageTitleTabsWithBackVariantProps } from '../types';
import MuiButton from 'app/design/uiComponents/MuiButton';
import MuiBreadcrumbs from 'app/design/uiComponents/MuiBreadcrumbs';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const PageTitleTabsWithBackVariant = ({
  buttonText,
  buttonHandleClick,
  tabs,
  breadcrumbLinks,
  breadcrumb,
}: PageTitleTabsWithBackVariantProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <Box className="sticky-top">
      <Box sx={{ pt: 3, pb: 0 }}>
        {!isMdUp ? (
          <MuiButton
            size="small"
            className="m-main-spacing"
            onClick={buttonHandleClick}
            startIcon={<ArrowBackIcon />}
            sx={{ px: '0 !important', mb: 1.25 }}
          >
            <Box className="text-ellipsis">{buttonText}</Box>
          </MuiButton>
        ) : breadcrumb !== undefined ? (
          <Box className="m-main-spacing">{breadcrumb}</Box>
        ) : (
          <Box className="m-main-spacing">
            <MuiBreadcrumbs links={breadcrumbLinks!} />
          </Box>
        )}
        <Box>{tabs}</Box>
      </Box>
    </Box>
  );
};

export default PageTitleTabsWithBackVariant;
