import { Helmet } from 'react-helmet-async';
import PageHeader from 'app/design/speedBot/PageHeader';
import EditBrokerForm from 'app/components/Menu/EditBrokerForm';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

export function EditBrokerPage() {
  return (
    <>
      <Helmet>
        <title>Edit Broker</title>
        <meta name="description" content="Edit Broker Page" />
      </Helmet>

      <PageHeader
        variant="inactive"
        buttonText={<FormattedMessage id="menu.page_title.brokers" />}
        title={<FormattedMessage id="menu.page_title.edit_broker" />}
        buttonHandleClick="/brokers"
        breadcrumb={<Breadcrumbs page={PAGES.EDIT_BROKER} data={{}} />}
      />

      <EditBrokerForm />
    </>
  );
}
