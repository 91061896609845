import { Box, Typography } from '@mui/material';
import emptyposition from '../../../../assets/images/emptyposition.svg';
const CommonMessage = ({
  title,
  desc1,
  desc2,
}: {
  title: string;
  desc1: string;
  desc2: string;
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        // border: '1px solid rgb(0 0 0 / 23%)',
        // borderRadius: '1em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img
        width={350}
        src={emptyposition}
        alt="Add some positions from optionchain"
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        mt={2}
      >
        <Typography
          variant="body1"
          fontWeight={'600'}
          mb={1}
          color={'GrayText'}
        >
          {title}
        </Typography>
        <Typography variant="body2" fontStyle={'italic'} color={'GrayText'}>
          {desc1}
        </Typography>
        <Typography variant="body2" fontStyle={'italic'} color={'GrayText'}>
          {desc2}
        </Typography>
      </Box>
    </Box>
  );
};

export default CommonMessage;
