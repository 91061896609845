import { FieldData } from './types';
import { FieldKeyToField } from './fieldsData';
import useClasses from '../utils/useClasses';
import { StrategyMode } from '../utils/strategy-data';
import { IconButton } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

// Style const
const styles = () => ({
  container: {
    height: '44px',
    margin: '0px 3px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '4px 0px',
  },
  logicalOperator: {
    background: '#f0ebf8',
  },
  operator: {
    background: '#f5f5f5',
  },
  logicalName: {
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0px 12px',
    color: '#362454',
    width: 'max-content',
  },
  operatorName: {
    fontFamily: 'Arial',
    fontSize: '32px',
    color: '#424242',
    letterSpacing: '0.15px',
    lineHeight: '36.8px',
    margin: '0px 12px',
  },
  description: {
    fontSize: '12px',
    color: '#626262',
    letterSpacing: '0.15px',
    lineHeight: '15.6px',
    marginRight: '12px',
    width: 'max-content',
  },
  icon: {
    width: '4px',
    height: '16px',
    color: '#202020',
    marginRight: '6px',
  },
  invalid: {
    border: '1px dashed #f44336 !important',
  },
});

interface Props {
  fieldData: FieldData;
  mode: string;
  onEdit: () => void;
  onRemove: () => void;
  isValid?: {
    status: boolean;
    errorIndex: number;
  };
  fieldIndex: number;
  isMathValid?: {
    status: boolean;
    mathErrorIndex: number;
  };
}

export function OperatorField(props: Props) {
  const { fieldData, mode, onEdit, isValid, fieldIndex, isMathValid } = props;
  const classes = useClasses(styles);
  const { key } = fieldData;
  const indicator = FieldKeyToField()[key];

  return (
    <div
      className={`${classes.container} ${
        key === 'and' || key === 'or'
          ? classes.logicalOperator
          : classes.operator
      } ${
        (isValid && isValid.status && isValid.errorIndex < fieldIndex) ||
        (isMathValid &&
          !isMathValid.status &&
          isMathValid.mathErrorIndex < fieldIndex)
          ? classes.invalid
          : ''
      }`}
    >
      <div
        className={`${
          key === 'and' || key === 'or'
            ? classes.logicalName
            : classes.operatorName
        }`}
      >
        {indicator.name}
      </div>
      {mode === StrategyMode.EDIT && indicator.description ? (
        <div className={classes.description}>{indicator.description}</div>
      ) : (
        <></>
      )}
      {mode === StrategyMode.EDIT ? (
        <IconButton id="menu" onClick={onEdit} className={classes.icon}>
          <CreateIcon fontSize={'small'} />
        </IconButton>
      ) : (
        <></>
      )}
    </div>
  );
}
