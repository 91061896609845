import { FormControl, Button, Grid, ButtonGroup } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { v4 } from 'uuid';

import {
  FieldParam,
  DropdownFieldValue,
  ButtonGroupParam,
  FieldParamValue,
  ValidateState,
  ControlsType,
  OptionLegParam,
} from '../types';
type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, string>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '15px',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          minHeight: '42px',
          height: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: 'rgba(0, 0, 0, 0.87)',
        },
        outlined: {
          border: '1px solid rgba(0, 0, 0, 0.87)',
          color: 'rgb(0, 0, 0)',
        },
      },
    },
  },
});

export const ToggleButtonGroupRenderer = (props: Props) => {
  const [isValidStatus, setIsValidStatus] = useState<ValidateState>({
    error: '',
    status: false,
  });
  const { param, onChange, sendValidation, isReadOnly } = props;
  const selected = props.selected as Record<string, string>;
  const { controls, renderingType, parentKey, myStyle, addClass, width } =
    param as ButtonGroupParam;

  const validate = useCallback(
    (formData: Record<string, string>) => {
      let validStatus = {
        status: false,
        error: '',
      };
      Object.keys(formData).forEach((item, index) => {
        if (controls[index].isRequired && !formData[item]) {
          validStatus = {
            status: true,
            error: 'Please enter valid data',
          };
        }
      });
      return validStatus;
    },
    [controls],
  );

  useEffect(() => {
    const validStatus = validate(selected);
    setIsValidStatus(validStatus);
    sendValidation(parentKey, !validStatus.status);
  }, [validate, parentKey, sendValidation, selected]);

  const buttonClickHandle = (value: string, control: ControlsType) => {
    selected[control.key] = value;
    const validStatus = validate(selected);
    sendValidation(parentKey, !validStatus.status);
    onChange(parentKey, selected);
  };

  return (
    <Grid
      item
      className={addClass}
      style={
        width
          ? { marginBottom: '24px', width: `${width}px` }
          : { marginBottom: '24px' }
      }
      sx={{ width: { xs: '100%', md: 'auto' } }}
    >
      <GlobalStyles
        styles={{
          [theme.breakpoints.down('md')]: myStyle?.small
            ? myStyle.small
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('md')]: myStyle?.medium
            ? myStyle.medium
            : myStyle?.root
            ? myStyle.root
            : myStyle,
          [theme.breakpoints.up('lg')]: myStyle?.large
            ? myStyle.large
            : myStyle?.root
            ? myStyle.root
            : myStyle,
        }}
      />
      <ThemeProvider theme={theme}>
        <FormControl
          variant="outlined"
          size={'small'}
          style={{ width: '100%', height: '100%' }}
          className="toggle-button-group"
        >
          {controls.map(control => (
            <ButtonGroup
              key={v4()}
              size="small"
              aria-label="small button group"
              orientation={renderingType}
              style={{
                border: isValidStatus.status ? '1px solid red' : 'none',
                paddingLeft: '0',
                position: 'relative',
              }}
            >
              {(!isReadOnly
                ? control.values
                : control.values?.filter(e => e.key === selected[control.key])
              )?.map((value: DropdownFieldValue) => (
                <Button
                  sx={width ? { width: `${width}px` } : {}}
                  size="small"
                  key={v4()}
                  onClick={() => {
                    buttonClickHandle(value.key, control);
                  }}
                  disabled={isReadOnly}
                  className={`${
                    value.key === selected[control.key] ? 'active' : ''
                  }`}
                >
                  {value.label}
                </Button>
              ))}
            </ButtonGroup>
          ))}
        </FormControl>
      </ThemeProvider>
    </Grid>
  );
};
