import { FieldData } from './types';
import { FieldKeyToField } from './fieldsData';
import useClasses from '../utils/useClasses';
import { StrategyMode } from '../utils/strategy-data';
import { IconButton } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

// Style const
const styles = () => ({
  container: {
    height: '44px',
    margin: '0px 3px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '4px 0px',
    background: '#f5f5f5',
  },
  description: {
    fontFamily: 'Arial',
    fontSize: '32px',
    color: '#424242',
    letterSpacing: '0.15px',
    lineHeight: '36.8px',
    marginRight: '12px',
    marginLeft: '12px',
    width: 'max-content',
  },
  name: {
    fontSize: '12px',
    color: '#626262',
    letterSpacing: '0.15px',
    lineHeight: '15.6px',
    marginRight: '12px',
  },
  icon: {
    width: '4px',
    height: '16px',
    color: '#202020',
    marginRight: '6px',
  },
  invalid: {
    border: '1px dashed #f44336 !important',
  },
});

interface Props {
  fieldData: FieldData;
  mode: string;
  onEdit: () => void;
  onRemove: () => void;
  isValid?: {
    status: boolean;
    errorIndex: number;
  };
  fieldIndex: number;
  isMathValid?: {
    status: boolean;
    mathErrorIndex: number;
  };
}

interface WidthMapping {
  [key: string]: string;
}

const widthMapping: WidthMapping = {
  equal_to: '35px',
  crosses_above: '50px',
  crosses_below: '50px',
  lower_than_equal_to: '70px',
  lower_than: '40px',
  higher_than_equal_to: '75px',
  higher_than: '40px',
};

export function ComparatorField(props: Props) {
  const { fieldData, mode, onEdit, isValid, fieldIndex, isMathValid } = props;
  const classes = useClasses(styles);
  const { key } = fieldData;
  const indicator = FieldKeyToField()[key];

  return (
    <div
      className={`${classes.container} ${
        (isValid && isValid.status && isValid.errorIndex < fieldIndex) ||
        (isMathValid &&
          !isMathValid.status &&
          isMathValid.mathErrorIndex < fieldIndex)
          ? classes.invalid
          : ''
      }`}
    >
      {indicator.description ? (
        <div className={classes.description}>{indicator.description}</div>
      ) : indicator.icon ? (
        <div className={classes.description}>{indicator.icon}</div>
      ) : (
        <></>
      )}
      {!indicator.description || mode === StrategyMode.EDIT ? (
        <div
          className={classes.name}
          style={{
            width: widthMapping[indicator.key],
            marginLeft:
              !indicator.description && !indicator.icon ? '12px' : '0px',
          }}
        >
          {indicator.name}
        </div>
      ) : (
        <></>
      )}
      {mode === StrategyMode.EDIT ? (
        <IconButton id="menu" onClick={onEdit} className={classes.icon}>
          <CreateIcon fontSize={'small'} />
        </IconButton>
      ) : (
        <></>
      )}
    </div>
  );
}
