import { useEffect, useState } from 'react';
import { isEqual as _isEqual, cloneDeep, differenceWith, sortBy } from 'lodash';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Checkbox,
  Box,
  Collapse,
  Divider,
  Typography,
  createSvgIcon,
  Alert,
  Link,
} from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// Service
import {
  NotificationSetting,
  GetNotificationSettingsResult,
  EditNotificationParam,
} from 'types/ApiServicesTypes';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import useGetNotificationSettings from 'services/Menu/useGetNotificationSettings';
import useEditNotificationSettings from 'services/Menu/useEditNotificationSettings';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { isBlankHtmlString } from 'utils/GenericFunctions';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

// eslint-disable-next-line @typescript-eslint/unbound-method
const isEqual = _isEqual;

export function MenuNotificationsPage() {
  const { data: siteInfo } = useGetSiteInfo();
  const { data: notificationSettings, isLoading } =
    useGetNotificationSettings();
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Notification Settings</title>
        <meta name="description" content="Notification Settings Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="inactive"
        buttonText={<FormattedMessage id="menu.page_title.menu" />}
        title={<FormattedMessage id="menu.notifications" />}
        buttonHandleClick="/menu"
        breadcrumb={<Breadcrumbs page={PAGES.PROFILE_NOTIFICATION} data={{}} />}
      />
      <FeatureFlag features={[FEATURES.IS_NOTIFICATION_NOTES]} fallback={<></>}>
        <Box className="p-main-spacing">
          <Alert severity="info" icon={false}>
            <Typography variant="body2" color="text.secondary" fontWeight={400}>
              {siteInfo && siteInfo.notes_menu_notification ? (
                <Typography
                  component={'div'}
                  variant={'body2'}
                  sx={{ fontWeight: 400, color: 'text.secondary' }}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: siteInfo.notes_menu_notification,
                  }}
                ></Typography>
              ) : (
                <>
                  <FormattedMessage id="menu.telegram_notes.title" />{' '}
                  <ul
                    style={{
                      padding: '0 0.5rem !important',
                      margin: 0,
                      paddingLeft: '1rem',
                      paddingTop: '0.25rem',
                    }}
                  >
                    <li>
                      <FormattedMessage id="menu.telegram_notes.step_1" />{' '}
                      <Link
                        target="_blank"
                        href={
                          siteInfo && siteInfo.telegram_bot_link
                            ? siteInfo.telegram_bot_link
                            : 'http://t.me/speedbot_trade_bot'
                        }
                      >
                        {t('user_bot_startegy.option_builder_note3_click_here')}
                      </Link>
                    </li>
                    <li>
                      <FormattedMessage id="menu.telegram_notes.step_2" />
                    </li>
                  </ul>
                </>
              )}
            </Typography>
          </Alert>
        </Box>
      </FeatureFlag>
      {notificationSettings && (
        <MenuNotificationFlags {...notificationSettings} />
      )}
    </>
  );
}

function MenuNotificationFlags({
  channels,
  settings,
}: GetNotificationSettingsResult) {
  const [notificationSettings, setNotificationSettings] = useState<
    NotificationSetting[]
  >(cloneDeep(settings));
  const mutation = useEditNotificationSettings();
  const isMdUp = useGetMediaQueryUp('md');
  const [openedSettingDetails, setOpenedSettingDetails] = useState(0);

  const disabledChannels = channels.reduce((acc, crr) => {
    if (!crr.active) {
      acc.add(crr.id);
    }
    return acc;
  }, new Set<number>());
  const sortedChannels = channels.sort((a, b) => a.id - b.id);

  useEffect(() => {
    setNotificationSettings(cloneDeep(settings));
  }, [settings]);

  const handleChange = (obj: EditNotificationParam) => {
    const copyArr = notificationSettings.map(setting => {
      if (setting.id == obj.setting_id) {
        const channel = setting.channels.find(a => a.id == obj.channel_id);
        if (channel) {
          channel.value = obj.value;
        }
      }
      return setting;
    });
    setNotificationSettings(copyArr);
  };

  const handleSubmit = () => {
    const params = notificationSettings.reduce((acc, crr) => {
      const oldSetting = settings.find(s => s.id == crr.id);
      if (oldSetting) {
        const diff = differenceWith(crr.channels, oldSetting.channels, isEqual);
        if (diff.length > 0) {
          diff.forEach(val => {
            acc.push({
              setting_id: crr.id,
              channel_id: val.id,
              value: val.value,
            });
          });
        }
      }
      return acc;
    }, [] as EditNotificationParam[]);
    mutation.mutate({ settings: params });
  };

  const isChanged = notificationSettings.reduce((acc, crr) => {
    if (acc === true) {
      return acc;
    } else {
      const settingChannels = settings.find(val => val.id == crr.id)?.channels;
      const diff = !isEqual(
        sortBy(settingChannels, ['id']),
        sortBy(crr.channels, ['id']),
      );
      return diff;
    }
  }, false);

  return (
    <>
      <CircularLoader open={mutation.isLoading} />
      <Grid
        container
        className="p-main-spacing"
        sx={{ alignItems: 'center', my: 2 }}
        rowGap={1}
      >
        <Grid container mb={1}>
          <Grid item xs></Grid>
          {sortedChannels.map(channel => {
            const Icon = createSvgIcon(
              <path d={channel.icon_svg} />,
              `custom_${channel.icon_name}`,
            );
            return (
              channel.active && (
                <Grid item key={channel.id} xs={1.5} textAlign={'center'}>
                  <Icon htmlColor={channel.icon_color} />
                  {isMdUp && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'primary.main',
                        mt: 1,
                      }}
                    >
                      {channel.label}
                    </Typography>
                  )}
                </Grid>
              )
            );
          })}
        </Grid>
        {notificationSettings.map(setting => (
          <MenuNotificationSetting
            key={setting.id}
            {...setting}
            openedSetting={openedSettingDetails}
            setOpen={setOpenedSettingDetails}
            disabledChannels={disabledChannels}
            handleChange={handleChange}
          />
        ))}
      </Grid>
      <Grid
        container
        className="p-main-spacing"
        sx={{
          justifyContent: 'flex-end',
          '&': {
            marginTop: { xs: 'auto', md: 2 },
          },
        }}
      >
        <Grid item xs md={1.5} textAlign={'center'}>
          {isChanged && (
            <Box>
              <MuiButton
                onClick={() => handleSubmit()}
                variant="contained"
                fullWidth
              >
                Save
              </MuiButton>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export function MenuNotificationSetting({
  id,
  label,
  setOpen,
  channels,
  description,
  handleChange,
  openedSetting,
  disabledChannels,
}: NotificationSetting & {
  handleChange: (setting: EditNotificationParam) => void;
  disabledChannels: Set<number>;
  openedSetting: number;
  setOpen: (id: number) => void;
}) {
  const isDescription = !isBlankHtmlString(description);
  const sortedChannels = channels.sort((a, b) => a.id - b.id);
  return (
    <>
      <Grid container item xs>
        <Grid item xs>
          <Divider
            className="p-main-spacing"
            sx={{ borderColor: 'grey.200' }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        onClick={() => isDescription && setOpen(openedSetting == id ? 0 : id)}
        sx={{ cursor: isDescription ? 'pointer' : undefined, mt: 1 }}
      >
        <Grid container item xs alignItems={'center'} gap={1}>
          {isDescription ? (
            openedSetting == id ? (
              <KeyboardArrowDown />
            ) : (
              <ChevronRight />
            )
          ) : null}
          <Typography variant="body2" color={'primary.main'}>
            {label}
          </Typography>
        </Grid>
        {sortedChannels.map(
          channel =>
            !disabledChannels.has(channel.id) && (
              <Grid item key={channel.id} xs={1.5} textAlign={'center'}>
                <Checkbox
                  checked={channel.value}
                  disabled={!channel.enabled}
                  onChange={e => {
                    handleChange({
                      setting_id: id,
                      channel_id: channel.id,
                      value: e.target.checked,
                    });
                  }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                />
              </Grid>
            ),
        )}
      </Grid>
      <Collapse in={openedSetting === id} sx={{ width: '100%' }}>
        {!isBlankHtmlString(description) && (
          <Alert severity="info" icon={false}>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __html: description,
              }}
            ></Typography>
          </Alert>
        )}
      </Collapse>
    </>
  );
}
