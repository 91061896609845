import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { SubscriptionTransformPlan } from 'types/SubscriptionPlans';
import { formatNumber } from 'utils/GenericFunctions';
import PlanInfo from './PlanInfo';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useEffect } from 'react';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

type PlanConfirmInfoProp = {
  plan: SubscriptionTransformPlan;
  billingType: string;
  handleChangeBillingType: (event) => void;
  paymentMode: string;
  handleChangePaymentMode: (event) => void;
  handleCouponCodeChange: (e) => void;
  applyClick: (value) => void;
  couponCodeValue: string;
  planWithCouponCode: SubscriptionTransformPlan[] | null;
  getSubtotal: (value) => void;
  currencySymbol: string;
};

const PlanConfirmInfo = ({
  plan,
  billingType,
  handleChangeBillingType,
  paymentMode,
  handleChangePaymentMode,
  handleCouponCodeChange,
  applyClick,
  couponCodeValue,
  planWithCouponCode,
  getSubtotal,
  currencySymbol,
}: PlanConfirmInfoProp) => {
  const buttonClick = () => {
    applyClick(couponCodeValue);
  };
  const calculateDiscountedPrice = () => {
    if (
      !planWithCouponCode ||
      plan.id < 1 ||
      plan.id > planWithCouponCode.length
    ) {
      return null;
    }

    const couponData = planWithCouponCode[plan.id - 1]?.coupon_data;

    if (!couponData) {
      return billingType === 'annual'
        ? plan.recurring_price_yearly
        : plan.recurring_price_monthly;
    }

    if (couponData.coupon_type === 'percentage') {
      const discountPercentage = Number(couponData.value) / 100;
      const discountedPrice =
        billingType === 'annual'
          ? planWithCouponCode[plan.id - 1].recurring_price_yearly *
            (1 - discountPercentage)
          : planWithCouponCode[plan.id - 1].recurring_price_monthly *
            (1 - discountPercentage);

      return discountedPrice;
    } else if (couponData.coupon_type === 'fixed') {
      const discountedPrice =
        billingType === 'annual'
          ? planWithCouponCode[plan.id - 1].recurring_price_yearly -
            Number(couponData.value)
          : planWithCouponCode[plan.id - 1].recurring_price_monthly -
            Number(couponData.value);

      return discountedPrice;
    }

    return null;
  };
  const isPaymentModeAuto = useIsFeatureFlag([FEATURES.IS_PAYMENT_MODE_AUTO]);
  const isCouponCode = useIsFeatureFlag([FEATURES.IS_COUPON_CODE]);
  const greenPrice = calculateDiscountedPrice();
  useEffect(() => {
    getSubtotal(greenPrice);
  }, [greenPrice, getSubtotal]);
  const isIncludeGstInPlans = useIsFeatureFlag([
    FEATURES.IS_INCLUDE_GST_IN_PLANS,
  ]);
  return (
    <Box className="m-main-spacing" sx={{ mb: 2 }}>
      <Box sx={{ py: 2 }}>
        <Typography variant="h6">{plan.plan_name}</Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <PlanInfo
          currencySymbol={currencySymbol}
          price={plan.recurring_price_yearly}
          monthToMonth={plan.recurring_price_monthly}
          capabilities={plan.capabilities}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <FormControl>
          <Typography
            variant="body1"
            fontWeight={400}
            sx={{ color: 'text.secondary' }}
          >
            <FormattedMessage id="menu.billing_cycle" />
          </Typography>
          <RadioGroup
            row
            name="billing-cycle-radio"
            value={billingType}
            onChange={handleChangeBillingType}
          >
            {plan.recurring_price_yearly > 0 && (
              <FormControlLabel
                value="annual"
                control={<Radio />}
                label={<FormattedMessage id="menu.annual" />}
              />
            )}
            {plan.recurring_price_monthly > 0 && (
              <FormControlLabel
                value="monthly"
                control={<Radio />}
                label={<FormattedMessage id="menu.monthly" />}
              />
            )}
          </RadioGroup>
          <Typography
            variant="body1"
            fontWeight={400}
            sx={{ color: 'text.secondary', mt: 1 }}
          >
            <FormattedMessage id="menu.payment_mode" />
          </Typography>
          <RadioGroup
            row
            name="payment-mode"
            value={paymentMode}
            onChange={handleChangePaymentMode}
          >
            <FormControlLabel
              value="one_time"
              control={<Radio />}
              label={<FormattedMessage id="menu.one_time" />}
            />
            {isPaymentModeAuto && (
              <FormControlLabel
                value="auto"
                control={<Radio />}
                label={<FormattedMessage id="menu.auto" />}
              />
            )}
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider />
      <Box sx={{ mt: 2 }}>
        {paymentMode == 'one_time' && isCouponCode && (
          <Box display={'flex'}>
            <FormControl
              sx={{ mb: 2, flexDirection: 'row', width: '100%' }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Coupon Code
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-coupon"
                fullWidth
                onChange={handleCouponCodeChange}
                type="text"
                value={couponCodeValue}
                label="coupon_code"
              />
              <MuiButton
                variant="contained"
                color="primary"
                aria-label="apply coupon"
                onClick={buttonClick}
                sx={{ width: '20ch' }}
              >
                <Typography>Apply</Typography>
              </MuiButton>
            </FormControl>
          </Box>
        )}
        <Typography variant="body1" sx={{ mb: 2 }}>
          {plan.plan_name} Plan
        </Typography>
        {isIncludeGstInPlans && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="body1" color="text.secondary">
                <FormattedMessage id="menu.plan_total" />
              </Typography>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="body1" fontWeight={600}>
                  {currencySymbol}
                  {billingType === 'annual'
                    ? formatNumber(plan.recurring_price_yearly, false, 0)
                    : formatNumber(plan.recurring_price_monthly, false, 0)}
                </Typography>
              </div>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="body1" color="text.secondary">
                <FormattedMessage id="menu.plan_gst" />
              </Typography>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography variant="body1" fontWeight={600}>
                  +18%
                </Typography>
              </div>
            </Box>
            {planWithCouponCode &&
              planWithCouponCode[plan.id - 1].coupon_data !== null && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Typography variant="body1" color="text.secondary">
                    <FormattedMessage id="menu.coupon_discount" />
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant="body1" fontWeight={600}>
                      -{planWithCouponCode[plan.id - 1].coupon_data?.value}%
                    </Typography>
                  </div>
                </Box>
              )}
          </>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body1" color="text.secondary">
            <FormattedMessage id="menu.subtotal" />
          </Typography>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography
              variant="body1"
              fontWeight={600}
              style={
                paymentMode !== 'auto' &&
                planWithCouponCode &&
                planWithCouponCode.length > plan.id - 1 &&
                planWithCouponCode[plan.id - 1]?.coupon_data?.code !== undefined
                  ? {
                      textDecoration: 'line-through',
                      color: '#00000099',
                      fontWeight: 'lighter',
                      fontSize: '14px',
                    }
                  : {}
              }
            >
              {currencySymbol}
              {billingType === 'annual'
                ? formatNumber(
                    planWithCouponCode![plan.id - 1].recurring_price_yearly,
                    false,
                    0,
                  )
                : formatNumber(
                    planWithCouponCode![plan.id - 1].recurring_price_monthly,
                    false,
                    0,
                  )}
            </Typography>
            {paymentMode !== 'auto' &&
              planWithCouponCode &&
              planWithCouponCode.length > plan.id - 1 &&
              planWithCouponCode[plan.id - 1]?.coupon_data?.code !==
                undefined && (
                <Typography
                  variant="body1"
                  fontWeight={600}
                  style={{
                    fontSize: 18,
                    color: '#000000de',
                    marginLeft: '8px',
                  }}
                >
                  {currencySymbol}
                  {formatNumber(greenPrice || 0, false, 0)}
                </Typography>
              )}
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default PlanConfirmInfo;
