import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { decodeUrlData } from 'utils/GenericFunctions';
import PageHeader from 'app/design/speedBot/PageHeader';
import InfoTradeStats from 'app/components/Bots/Backtesting/InfoTradeStats';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

export function BotTradeStatsPage(props) {
  const { title } = useParams<{ id: string; title: string; sid: string }>();
  const history = useHistory();
  const baseApiUrl = (props.location.state.baseApiUrl as string).replace(
    'discover',
    'marketplace',
  );

  return (
    <>
      <Helmet>
        <title>Backtesting Trade Statistics</title>
        <meta name="description" content="Backtesting Trade Statistics" />
      </Helmet>
      <PageHeader
        variant="inactive"
        buttonText={`${decodeUrlData(title)} | ${
          props.location.state.subtitle
        }`}
        title={<FormattedMessage id="buttons.trade_stats" />}
        buttonHandleClick={() => history.goBack()}
      />
      <InfoTradeStats baseApiUrl={baseApiUrl} />
    </>
  );
}
