import { Tabs, Tab, Box, styled } from '@mui/material';
import { ContentTabsProps } from 'types/ComponentTypes';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

const MuiTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  [theme.breakpoints.up('md')]: {
    maxWidth: theme.spacing(24),
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.spacing(21),
  },
}));

const ContentTabs = ({
  value,
  onChange,
  tabs,
  scrollable,
  noPadding,
}: ContentTabsProps) => {
  const isMdUp = useGetMediaQueryUp('md');

  return (
    <Box
      sx={{
        flex: 1,
        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: '-2px',
          width: '100%',
          borderBottom: '2px solid #e0e0e0',
          zIndex: 0,
        },
      }}
    >
      <Tabs
        value={value}
        onChange={onChange}
        centered={!isMdUp}
        className={noPadding ? '' : 'p-main-spacing'}
        sx={{
          position: 'relative',
          bottom: '-2px',
        }}
        {...(scrollable && {
          variant: 'scrollable',
          scrollButtons: true,
        })}
      >
        {tabs.length > 0 &&
          tabs.map((tab, i) => (
            <MuiTab
              key={i}
              label={tab.label}
              sx={{
                flex: 1,
                minHeight: 'auto',
                '& .MuiSvgIcon-root': {
                  position: 'absolute',
                  left: theme => theme.spacing(0.5),
                  color: tab.iconProps ? tab.iconProps.color : 'text.primary',
                },
              }}
              {...(tab.iconProps && {
                icon: tab.iconProps.icon,
                iconPosition: 'start',
              })}
            />
          ))}
      </Tabs>
    </Box>
  );
};
export default ContentTabs;
