import { Box, Divider, IconButton, Typography } from '@mui/material';
import TaskAlt from '@mui/icons-material/TaskAlt';
import ContentCopy from '@mui/icons-material/ContentCopy';

import { useState } from 'react';

import ShareIcons from '../Common/ShareIcons';
import MuiModal from 'app/design/uiComponents/MuiModal';
type CapitalChangeModalProps = {
  generatedURL: string;
  open: boolean;
  handleClose: (success: boolean) => void;
};
const SocialShareModal = ({
  open,
  handleClose,
  generatedURL,
}: CapitalChangeModalProps) => {
  const [showGreenTick, setShowGreenTick] = useState(false);
  const copyToClipboard = () => {
    void navigator.clipboard.writeText(generatedURL);
    setShowGreenTick(true);
    setTimeout(() => {
      setShowGreenTick(false);
    }, 700);
  };
  return (
    <MuiModal
      isAutoHeight={true}
      open={open}
      handleClose={() => handleClose(false)}
      body={
        <Box sx={{ px: 2 }}>
          <Typography variant="h6">Share your portfolio</Typography>
          <Box
            sx={{
              backgroundColor: '#f0f0f0',
              p: 1,
              pl: 2,
              borderRadius: '2.5rem',
              mb: 2,
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              overflow: 'hidden',
            }}
          >
            <input
              type="text"
              value={generatedURL}
              readOnly
              style={{
                flex: 1,
                fontSize: '16px',
                border: 'none',
                outline: 'none',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                backgroundColor: '#f0f0f0',
              }}
            />
            <IconButton aria-label="Copy" onClick={copyToClipboard}>
              {showGreenTick ? (
                <TaskAlt sx={{ color: 'green' }} />
              ) : (
                <ContentCopy />
              )}
            </IconButton>
          </Box>
        </Box>
      }
      footer={
        <>
          <Divider sx={{ mt: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', m: 2 }}>
            <ShareIcons
              style={{ backgroundColor: 'white' }}
              title={`my portfolio`}
              variant="medium"
              referralURL={generatedURL}
              whichIconYouWant={[
                'twitter',
                'facebook',
                'whatsapp',
                'email',
                'telegram',
              ]}
            />
          </Box>
        </>
      }
    />
  );
};

export default SocialShareModal;
