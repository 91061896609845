import CommonTable from 'app/components/Common/CommonTable';
import { createPositionTableRows, detailPositions } from './utils';
import { PositionData, PositionMetaData } from '../types';
import { OptionChainWithExpiryType } from 'app/pages/Simulator';

type Props = {
  optionChainData: OptionChainWithExpiryType;
  handleOptionClick: (position: PositionData) => void;
  positions: PositionMetaData[];
  date: string;
  updatePositions: (positions: PositionMetaData) => void;
  lotSize: number;
  removePosition: (position: PositionMetaData) => void;
};

const DetailsRowRendererTable = ({
  rowData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleClose,
  handleClick,
}: {
  rowData: PositionMetaData;
  handleClose: () => void;
  handleClick?: (position: PositionMetaData) => void;
}) => {
  const { rows, columns } = detailPositions(rowData, handleClick);
  return (
    <>
      {rows && (
        <CommonTable
          sx={{ backgroundColor: 'grey.100' }}
          rows={rows}
          columns={columns}
        />
      )}
    </>
  );
};

const PositionCustomTable = ({
  optionChainData,
  positions,
  date,
  updatePositions,
  lotSize,
  removePosition,
}: Props) => {
  const { rows, columns } = createPositionTableRows(
    optionChainData,
    positions,
    date,
    lotSize,
    removePosition,
    updatePositions,
  );

  return (
    <CommonTable
      containerStyle={{
        height: '79vh',
        scrollbarWidth: 'none',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
          width: '0.4em',
        },
      }}
      headerSticky={true}
      rows={rows}
      columns={columns}
      DetailsRowRenderer={DetailsRowRendererTable}
      handleOnClickInsideRow={updatePositions}
    />
  );
};

export default PositionCustomTable;
