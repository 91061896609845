import { FEATURES } from 'types/Feature';

// Return bool value as per its valid or not
export const checkFeatureStatus = (key: string) => {
  if (!process.env[key]) return false;
  return (process.env[key] as string) === 'true';
};

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export const checkDeviceType = (): boolean => {
  return !detectMob();
};

export const featureChecker: Record<string, boolean> = {
  // Return bool value as per its valid or not
  [FEATURES.IS_GOLIVE]: checkFeatureStatus('REACT_APP_FEATURE_IS_GOLIVE'),
  [FEATURES.IS_GOOGLE_LOGIN]: checkFeatureStatus(
    'REACT_APP_FEATURE_GOOGLE_LOGIN',
  ),
  [FEATURES.IS_MOBILE_GOOGLE_LOGIN]: checkDeviceType(),
  [FEATURES.IS_PLAN_UPGRADE]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_PLAN_UPGRADE',
  ),
  [FEATURES.IS_BACKTESTING]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_BACKTESTING',
  ),
  [FEATURES.IS_TRAILING_STOPLOSS]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_TRAILING_STOPLOSS',
  ),
  [FEATURES.IS_TRADING_VIEW_CHART_BUTTON]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_TRADING_VIEW_CHART_BUTTON',
  ),
  [FEATURES.IS_DOWNLOAD_EXCEL_ORDERS]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_DOWNLOAD_EXCEL_ORDERS',
  ),
  [FEATURES.IS_SELL_ON_MARKET]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_SELL_ON_MARKET',
  ),
  [FEATURES.IS_PROFITLOSS_REPORT_BY_DATE]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_PROFITLOSS_REPORT_BY_DATE',
  ),
  [FEATURES.IS_AUTHOR_PROFILE]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_AUTHOR_PROFILE',
  ),
  [FEATURES.IS_STBT_OPTION]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_STBT_OPTION',
  ),
  [FEATURES.IS_ADD_HEDGE_ENABLED]: checkFeatureStatus(
    'REACT_APP_IS_ADD_HEDGE_ENABLED',
  ),
  [FEATURES.IS_BACKTEST_DISCLAIMER]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_BACKTEST_DISCLAIMER',
  ),
  [FEATURES.IS_DELETE_BOT]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_DELETE_BOT',
  ),
  [FEATURES.IS_SHOW_DD_IN_AMOUNT]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_SHOW_DD_IN_AMOUNT',
  ),
  [FEATURES.IS_EXIT_ALL_AND_STOP]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_EXIT_ALL_AND_STOP',
  ),
  [FEATURES.IS_EQUITY_BOTBUILDER]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_EQUITY_BOTBUILDER',
  ),
  [FEATURES.IS_OPTION_BOTBUILDER]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_OPTION_BOTBUILDER',
  ),
  [FEATURES.IS_OPTION_BOTBUILDER_STRATEGY_STOPLOSS]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_OPTION_BOTBUILDER_STRATEGY_STOPLOSS',
  ),
  [FEATURES.IS_OPTION_BOTBUILDER_STRATEGY_TARGETPROFIT]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_OPTION_BOTBUILDER_STRATEGY_TARGETPROFIT',
  ),
  [FEATURES.IS_OPTION_BOTBUILDER_STRATEGY_TARGETSTOPLOSS]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_OPTION_BOTBUILDER_STRATEGY_TARGETSTOPLOSS',
  ),
  [FEATURES.IS_OPTION_BOTBUILDER_STRATEGY_LEG_TEMPLATES]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_OPTION_BOTBUILDER_STRATEGY_LEG_TEMPLATES',
  ),
  [FEATURES.IS_BOT_SIGNAL]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_BOT_SIGNAL',
  ),
  [FEATURES.IS_SHOW_RESULT]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_SHOW_RESULT',
  ),
  [FEATURES.IS_SIGNAL_TEMPLATE]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_SIGNAL_TEMPLATE',
  ),
  [FEATURES.IS_DUPLICATE_BOT]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_DUPLICATE_BOT',
  ),
  [FEATURES.IS_PAPER_TRADING]: checkFeatureStatus('REACT_APP_IS_PAPER_TRADING'),
  [FEATURES.IS_REFERRAL_CODE]: checkFeatureStatus('REACT_APP_IS_REFERRAL_CODE'),

  [FEATURES.IS_COMPARE_BOTS]: checkFeatureStatus('REACT_APP_IS_COMPARE_BOTS'),
  [FEATURES.IS_LOT_SIZE_VALIDATION]: checkFeatureStatus(
    'REACT_APP_IS_LOT_SIZE_VALIDATION',
  ),
  [FEATURES.IS_IOS_NEW_TAB]: checkFeatureStatus(
    'REACT_APP_FEATURE_IS_IOS_NEW_TAB',
  ),
  [FEATURES.IS_COUPON_CODE]: checkFeatureStatus(' REACT_APP_IS_COUPON_CODE'),
  [FEATURES.IS_POSITION_METADATA]: checkFeatureStatus(
    ' REACT_APP_IS_POSITION_METADATA',
  ),
  [FEATURES.IS_BROKERAGE_FEES]: checkFeatureStatus(
    ' REACT_APP_IS_BROKERAGE_FEES',
  ),

  [FEATURES.IS_OTP_VERIFICATION]: checkFeatureStatus(
    'REACT_APP_IS_OTP_VERIFICATION',
  ),

  [FEATURES.IS_CREDIT_TRANSFER]: checkFeatureStatus(
    'REACT_APP_IS_CREDIT_TRANSFER',
  ),
  [FEATURES.IS_CODE_YOUR_BOT]: checkFeatureStatus(
    ' REACT_APP_IS_CODE_YOUR_BOT',
  ),

  [FEATURES.IS_ADD_CREDIT]: checkFeatureStatus('REACT_APP_IS_ADD_CREDIT'),
  [FEATURES.IS_VERIFY_PHONE_DIALOG]: checkFeatureStatus(
    'REACT_APP_IS_VERIFY_PHONE_DIALOG',
  ),
  [FEATURES.IS_REFERRAL_OAUTH_USER]: checkFeatureStatus(
    'REACT_APP_IS_REFERRAL_OAUTH_USER',
  ),
  [FEATURES.IS_LOGS]: checkFeatureStatus('REACT_APP_IS_LOGS'),
  [FEATURES.IS_SHARE_PORTFOLIO]: checkFeatureStatus(
    'REACT_APP_IS_SHARE_PORTFOLIO',
  ),
  [FEATURES.IS_CONDITION_IN_OPTION]: checkFeatureStatus(
    'REACT_APP_IS_CONDITION_IN_OPTION',
  ),
  [FEATURES.IS_EXIT_POSITION_PORTFOLIO]: checkFeatureStatus(
    'REACT_APP_IS_EXIT_POSITION_PORTFOLIO',
  ),
  [FEATURES.IS_OPTION_ENTER_IF_MISSED]: checkFeatureStatus(
    'REACT_APPIS_OPTION_ENTER_IF_MISSED',
  ),
  [FEATURES.IS_HELP_DESK_IN_SB]: checkFeatureStatus(
    'REACT_APP_IS_HELP_DESK_IN_SB',
  ),
  [FEATURES.IS_HOME_PAGE]: checkFeatureStatus('REACT_APP_IS_HOME_PAGE'),
  [FEATURES.IS_NOTIFICATION_ON_HOME_PAGE]: checkFeatureStatus(
    'REACT_APP_IS_NOTIFICATION_ON_HOME_PAGE',
  ),
  [FEATURES.IS_BOT_DISCRIPTION]: checkFeatureStatus(
    'REACT_APP_IS_BOT_DESCRIPTION',
  ),
  [FEATURES.IS_ADVANCE_SYMBOL_SELECT]: checkFeatureStatus(
    'REACT_APP_IS_ADVANCE_SYMBOL_SELECT',
  ),
  [FEATURES.IS_MOMENTUM_ENABLED_AT_SET]: checkFeatureStatus(
    'REACT_APP_IS_MOMENTUM_AT_SET',
  ),
  [FEATURES.IS_UNIT_SHOW_IN_SIGNAL]: checkFeatureStatus(
    'REACT_APP_IS_UNIT_SHOW_IN_SIGNAL',
  ),
  [FEATURES.IS_MOBILE_NUMBER_LOGIN]: checkFeatureStatus(
    'REACT_APP_IS_MOBILE_NUMBER_LOGIN',
  ),
  [FEATURES.IS_MANUAL_TRADING]: checkFeatureStatus(
    'REACT_APP_IS_MANUAL_TRADING',
  ),
  [FEATURES.IS_NOTE_FOR_TEMPLATE_DETAIL]: checkFeatureStatus(
    'REACT_APP_IS_NOTE_FOR_TEMPLATE_DETAIL',
  ),
  [FEATURES.IS_INDICATOR_AT_LEG]: checkFeatureStatus(
    'REACT_APP_IS_INDICATOR_AT_LEG',
  ),
  [FEATURES.IS_EXECUTION_SETTING]: checkFeatureStatus(
    'REACT_APP_IS_EXECUTION_SETTING',
  ),
  [FEATURES.IS_IFRAME_MONACO]: checkFeatureStatus('REACT_APP_IS_IFRAME_MONACO'),
  [FEATURES.IS_STOP_LOSS_MARKET]: checkFeatureStatus(
    'REACT_APP_IS_STOP_LOSS_MARKET',
  ),
  [FEATURES.IS_USA_SUPPORT_IN_EQ]: checkFeatureStatus(
    'REACT_APP_IS_USA_SUPPORT_IN_EQ',
  ),
  [FEATURES.IS_EXIT_SIGNAL_SLM]: checkFeatureStatus(
    'REACT_APP_IS_EXIT_SIGNAL_SLM',
  ),
  [FEATURES.IS_EXTERNAL_SIGNAL]: checkFeatureStatus(
    '[REACT_APP_IS_EXTERNAL_SIGNAL]',
  ),
  [FEATURES.IS_SPOT_FUTURES_ATM]: checkFeatureStatus(
    '[REACT_APP_IS_SPOT_FUTURES_ATM]',
  ),
  [FEATURES.IS_VIEW_ALL_POSITIONS]: checkFeatureStatus(
    'REACT_APP_IS_VIEW_ALL_POSITIONS',
  ),
  [FEATURES.IS_MYBOTS_IN_PORTFOLIO]: checkFeatureStatus(
    'REACT_APP_IS_IS_MYBOTS_IN_PORTFOLIO',
  ),
  [FEATURES.IS_GET_ORDERSHEET]: checkFeatureStatus(
    'REACT_APP_IS_GET_ORDERSHEET',
  ),
  [FEATURES.IS_US_SYMBOL_FOR_OPTION_BUILDER]: checkFeatureStatus(
    'REACT_APP_IS_US_SYMBOL_FOR_OPTION_BUILDER',
  ),
  [FEATURES.IS_DTE_ENABLED_USA]: checkFeatureStatus(
    'REACT_APP_IS_DTE_ENABLED_USA',
  ),
  [FEATURES.IS_OPTION_FUTURE_LEG]: checkFeatureStatus(
    'REACT_APP_IS_OPTION_FUTURE_LEG',
  ),
  [FEATURES.IS_OPTION_EQUITY_LEG]: checkFeatureStatus(
    'REACT_APP_IS_OPTION_EQUITY_LEG',
  ),
  [FEATURES.IS_LOCK_PROFIT]: checkFeatureStatus('REACT_APP_IS_LOCK_PROFIT'),
  [FEATURES.IS_TRAIL_LOCK_PROFIT]: checkFeatureStatus(
    'REACT_APP_IS_TRAIL_LOCK_PROFIT',
  ),
  [FEATURES.IS_MAX_ENTRY_TIME]: checkFeatureStatus(
    'REACT_APP_IS_MAX_ENTRY_TIME',
  ),
  [FEATURES.IS_LIVE_PAPER_IN_BACKTEST]: checkFeatureStatus(
    'REACT_APP_IS_LIVE_PAPER_IN_BACKTEST',
  ),
  [FEATURES.IS_SHOW_RESTART_BUTTON]: checkFeatureStatus(
    'REACT_APP_IS_SHOW_RESTART_BUTTON',
  ),
  [FEATURES.IS_SHOW_POLICY_BUTTON]: checkFeatureStatus(
    'REACT_APP_IS_SHOW_POLICY_BUTTON',
  ),
  [FEATURES.IS_OPTION_BOTBUILDER_BANKEX_SYMBOL]: checkFeatureStatus(
    'IS_OPTION_BOTBUILDER_BANKEX_SYMBOL',
  ),
  [FEATURES.IS_CHOOSE_YOUR_MARKET]: checkFeatureStatus(
    'REACT_APP_IS_CHOOSE_YOUR_MARKET',
  ),
  [FEATURES.IS_PAYMENT_MODE_AUTO]: checkFeatureStatus(
    'REACT_APP_IS_PAYMENT_MODE_AUTO',
  ),
  [FEATURES.IS_DELTA_IN_CONTRACT_TYPE]: checkFeatureStatus(
    'REACT_APP_IS_DELTA_IN_CONTRACT_TYPE',
  ),
  [FEATURES.IS_OPTIONCHAIN]: checkFeatureStatus('REACT_APP_IS_OPTIONCHAIN'),
  [FEATURES.IS_INCLUDE_GST_IN_PLANS]: checkFeatureStatus(
    'REACT_APP_IS_INCLUDE_GST_IN_PLANS',
  ),
  [FEATURES.IS_IMPORT_EXPORT_STRATEGY]: checkFeatureStatus(
    'REACT_APP_IS_IMPORT_EXPORT_STRATEGY',
  ),
  [FEATURES.IS_SHOW_TICKS_DATA_WITHOUT_BROKER]: checkFeatureStatus(
    'REACT_APP_IS_SHOW_TICKS_DATA_WITHOUT_BROKER',
  ),
  [FEATURES.IS_SHOW_US_STOCK_SYMBOLS]: checkFeatureStatus(
    'REACT_APP_IS_SHOW_US_STOCK_SYMBOLS',
  ),
  [FEATURES.IS_SHOW_MTM_GRAPH]: checkFeatureStatus(
    'REACT_APP_IS_SHOW_MTM_GRAPH',
  ),
  [FEATURES.IS_SHOW_DELTA_AND_STRIKE_IN_TGSL]: checkFeatureStatus(
    'REACT_APP_IS_SHOW_DELTA_AND_STRIKE_IN_TGSL',
  ),
  [FEATURES.IS_TRADE_IDEAS]: checkFeatureStatus('REACT_APP_IS_TRADE_IDEAS'),
  [FEATURES.IS_SIDEBAR_VIEW]: checkFeatureStatus('REACT_APP_IS_SIDEBAR_VIEW'),
  [FEATURES.IS_CONTINUOUS_SET]: checkFeatureStatus(
    'REACT_APP_IS_CONTINUOUS_SET',
  ),
  [FEATURES.IS_SELECTED_SIGNAL_EXIT]: checkFeatureStatus(
    'REACT_APP_IS_SELECTED_SIGNAL_EXIT',
  ),
};
