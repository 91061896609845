import { Box } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { decodeUrlData } from 'utils/GenericFunctions';
import PageHeader from 'app/design/speedBot/PageHeader';
import InfoAdvanceStats from 'app/components/Bots/Backtesting/InfoAdvanceStats';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

export function BotAdvanceStatsPage(props) {
  const history = useHistory();
  const { title } = useParams<{ id: string; title: string; sid: string }>();
  const baseApiUrl = (props.location.state.baseApiUrl as string).replace(
    'discover',
    'marketplace',
  );

  return (
    <>
      <Helmet>
        <title>Backtesting Advance Statistics</title>
        <meta name="description" content="Backtesting Advance Statistics" />
      </Helmet>
      <PageHeader
        variant="inactive"
        buttonText={`${decodeUrlData(title)} | ${
          props.location.state.subtitle
        }`}
        title={<FormattedMessage id="buttons.adv_stats" />}
        buttonHandleClick={() => history.goBack()}
      />
      <Box>
        <InfoAdvanceStats baseApiUrl={baseApiUrl} />
      </Box>
    </>
  );
}
