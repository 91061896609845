import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import Item from 'app/design/speedBot/EntityItem/Item';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiButton from 'app/design/uiComponents/MuiButton';
import PlanCancelModal from 'app/components/Bots/PlanUpgrade/PlanCancelModal';
import { formatDateString, openInNewTab } from 'utils/GenericFunctions';
import PlanChangeModal from 'app/components/Menu/PlanChangeModal';
import PlanSubscribeModal from 'app/components/Menu/PlanSubscribeModal';
import PlanSubscribeCapabilities from 'app/components/Menu/PlanSubscribeCapabilities';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

// Service
import useGetProfile from 'services/Menu/useGetProfile';
import useCancelPlan from 'services/Menu/useCancelPlan';
import { CancelPlanResult } from 'types/SubscriptionPlans';
import { Close } from '@mui/icons-material';
import { loadScript } from 'utils/loadScript';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { getPlatform } from 'utils/auth';
import { FEATURES } from 'types/Feature';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import ActivatePayAsYouGo from 'app/components/Menu/ActivatePayAsYouGo';

export function PlanDetailsPage() {
  const platform = getPlatform();
  const { t } = useTranslation();
  const theme = useTheme();
  const breadcrumb = useBreadcrumbManager();
  const { action } = useParams<{ action?: string }>();
  const { data: profileDetails, isLoading } = useGetProfile();
  const [openIframe, setOpenIframe] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openSubscribePlanModal, setOpenSubscribePlanModal] =
    useState<boolean>(false);
  const [openChangePlanModal, setOpenChangePlanModal] =
    useState<boolean>(false);

  const userPlan = profileDetails ? profileDetails.user_plan : null;
  const isFree = Boolean(
    userPlan && userPlan.plan && userPlan.recurring_price <= 0,
  );

  const handleOpenCancelModal = useCallback(() => setOpenCancelModal(true), []);
  const handleCloseCancelModal = useCallback(
    () => setOpenCancelModal(false),
    [],
  );

  const isIosNewTab = useIsFeatureFlag([FEATURES.IS_IOS_NEW_TAB]);

  const handleOpenSubscribePlanModal = useCallback(() => {
    if (isIosNewTab && platform === 'ios') openInNewTab('/plan-upgrade');
    else setOpenSubscribePlanModal(true);
  }, [isIosNewTab, platform]);

  const handleCloseSubscribePlanModal = useCallback(
    () => setOpenSubscribePlanModal(false),
    [],
  );

  const handleOpenChangePlanModal = useCallback(() => {
    if (isIosNewTab && platform === 'ios') openInNewTab('/plan-change');
    else setOpenChangePlanModal(true);
  }, [isIosNewTab, platform]);

  const handleCloseChangePlanModal = useCallback(
    () => setOpenChangePlanModal(false),
    [],
  );

  // For open when come from subscribe button
  useEffect(() => {
    if (isFree && action) handleOpenSubscribePlanModal();
    if (!isFree && action) handleOpenChangePlanModal();
  }, [isFree, action, handleOpenSubscribePlanModal, handleOpenChangePlanModal]);

  useEffect(() => {
    void loadScript(
      'https://checkout.razorpay.com/v1/checkout.js',
      'razorpay_script',
    );

    return () => {
      document.getElementById('razorpay_script')!.remove();
    };
  }, []);

  const successCallback = (res: CancelPlanResult) => {
    if (res.success && !res.error) {
      handleCloseCancelModal();
      breadcrumb.goBackPathPage(`/menu/plan-details`);
    }
  };

  const mutation = useCancelPlan(successCallback);

  const handleConfirm = () => {
    if (userPlan)
      mutation.mutate({ subscription_id: userPlan.subscription_id });
  };

  const endDate =
    userPlan && userPlan.end_date
      ? formatDateString(userPlan.end_date, 'dddd, MMMM Do YYYY')
      : '';
  // const endDate =
  //   userPlan && userPlan.end_date
  //     ? formatDateString(new Date(), 'dddd, MMMM Do YYYY')
  //     : '';

  return (
    <>
      <Helmet>
        <title>Plan Detail Page</title>
        <meta name="description" content="Plan Detail Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <PageHeader
            variant="inactive"
            title={<FormattedMessage id="menu.plan" />}
            buttonHandleClick="/menu"
            buttonText={<FormattedMessage id="menu.page_title.menu" />}
            breadcrumb={<Breadcrumbs page={PAGES.PLAN_DETAIL} data={{}} />}
          />
        </Box>

        <Box className="m-main-spacing" sx={{ pb: 3 }}>
          <PlanSubscribeCapabilities />
        </Box>
        {userPlan && (
          <>
            {!isFree ? (
              <Box>
                {userPlan &&
                  userPlan.plan &&
                  userPlan.plan.plan_status === 'halted' &&
                  userPlan.plan.subscription_link && (
                    <>
                      <ItemSeparator variant="1px" className="m-main-spacing" />
                      <Item
                        variant="itemIcon"
                        title={
                          <FormattedMessage id="menu.update_payment_status" />
                        }
                        icon={<ChevronRightIcon />}
                        titleColor="error.main"
                        handleClick={() => {
                          setOpenIframe(true);
                        }}
                      />
                      {openIframe && (
                        <>
                          <iframe
                            src={userPlan.plan.subscription_link}
                            style={{
                              height: '100%',
                              width: '100%',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              zIndex: '9998',
                              border: 0,
                            }}
                          ></iframe>
                          <IconButton
                            color="primary"
                            sx={{
                              position: 'absolute',
                              top: 4,
                              right: 4,
                              zIndex: '9999',
                              p: 0.5,
                              backgroundColor: 'white',
                              [theme.breakpoints.down('md')]: {
                                right: 'calc(50% - 20px)',
                              },
                              '&:hover,:active': {
                                backgroundColor: 'white',
                              },
                            }}
                            onClick={() => setOpenIframe(false)}
                          >
                            <Close />
                          </IconButton>
                        </>
                      )}
                    </>
                  )}
                <ItemSeparator variant="1px" className="m-main-spacing" />
                <Item
                  variant="itemSubtextIcon"
                  subtext={''}
                  title={<FormattedMessage id="menu.billing_details" />}
                  icon={<ChevronRightIcon />}
                  handleClick={() =>
                    breadcrumb.push(
                      `/menu/billing-details`,
                      PAGES.PLAN_DETAIL,
                      `/menu/plan-details`,
                      t('menu.plan'),
                    )
                  }
                  {...(userPlan &&
                    userPlan.plan.payment_type === 'one_time' &&
                    userPlan.is_pending_invoice && {
                      chip: (
                        <MuiChip
                          color="warninglight"
                          variant="filled"
                          label={t('menu.payment_pending')}
                          sx={{ ml: 1 }}
                          size="small"
                        />
                      ),
                    })}
                />
                <ItemSeparator variant="1px" className="m-main-spacing" />
                <Item
                  variant="itemIcon"
                  title={<FormattedMessage id="menu.change_plan" />}
                  icon={<ChevronRightIcon />}
                  handleClick={handleOpenChangePlanModal}
                />
                <ItemSeparator variant="1px" className="m-main-spacing" />
                {!userPlan.is_closed && (
                  <Item
                    variant="itemIcon"
                    title={<FormattedMessage id="menu.cancel_plan" />}
                    titleColor="error.main"
                    icon={<ChevronRightIcon />}
                    handleClick={handleOpenCancelModal}
                  />
                )}
              </Box>
            ) : (
              <Box className="m-main-spacing" sx={{ pt: 1 }}>
                <MuiButton
                  fullWidth
                  variant="outlined"
                  color="primary"
                  size="xlarge"
                  endIcon={<UpgradeIcon />}
                  sx={{ justifyContent: 'space-between' }}
                  onClick={handleOpenSubscribePlanModal}
                >
                  <FormattedMessage id="buttons.subscribe_plan" />
                </MuiButton>
                {profileDetails?.previous_active_plan && (
                  <Box
                    sx={{
                      gap: 1,
                      display: 'flex',
                      alignItems: 'center',
                      mt: 1,
                    }}
                  >
                    <Typography variant={'body1'}>
                      Your current plan, {profileDetails.previous_active_plan}{' '}
                      is active. You can switch Back
                    </Typography>
                    <MuiButton onClick={handleConfirm} variant="contained">
                      <FormattedMessage id="menu.switch_back" />
                    </MuiButton>
                  </Box>
                )}
              </Box>
            )}
            {userPlan.plan.plan_type !== 'pay_as_you_go' && (
              <Box className="m-main-spacing" mt={2}>
                <ActivatePayAsYouGo />
              </Box>
            )}
          </>
        )}
      </Box>

      <PlanCancelModal
        date={endDate}
        open={openCancelModal}
        handleConfirm={handleConfirm}
        handleClose={handleCloseCancelModal}
        isLoading={mutation.isLoading}
      />

      <>
        <PlanChangeModal
          open={openChangePlanModal}
          handleClose={handleCloseChangePlanModal}
        />
        <PlanSubscribeModal
          open={openSubscribePlanModal}
          handleClose={handleCloseSubscribePlanModal}
        />
      </>
    </>
  );
}
