import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FilterList, OptionType } from './utils';
import SelectWithRemove from './SelectWithRemove';

type TradeFilterProps = {
  handleFilter: (filter: Record<string, string>) => void;
  defaultValue: {
    symbol: string;
    view: string;
  };
};
const TradeFilter = ({ handleFilter, defaultValue }: TradeFilterProps) => {
  const [symbol, setSymbol] = useState(defaultValue.symbol);
  const [selected, setSelected] = useState<Record<string, OptionType>>({
    symbol: { label: 'symbol', value: defaultValue.symbol },
    view: { label: 'view', value: defaultValue.view },
  });

  const handleSelect = (selectedOpt: {
    key: string;
    label: string;
    value: string;
  }) => {
    setSelected({
      ...selected,
      [`${selectedOpt.key}`]: {
        label: selectedOpt.label,
        value: selectedOpt.value,
      },
    });
    let filtered = {};
    Object.keys(selected).forEach(select => {
      filtered = {
        ...filtered,
        [`${select}`]: selected[select].value,
      };
    });
    handleFilter({
      ...filtered,
      [`${selectedOpt.key}`]: selectedOpt.value,
    });
  };
  return (
    <Grid container columnGap={1} pl={1} rowGap={1} my={1}>
      <Grid item>
        <FormControl>
          <Select
            labelId="symbol"
            name="Symbol"
            value={symbol}
            onChange={e => {
              setSymbol(e.target.value);
              handleSelect({
                key: 'symbol',
                label: 'symbol',
                value: e.target.value,
              });
            }}
            sx={{
              '& .MuiSelect-select': {
                py: '7px',
              },
            }}
            renderValue={selected => (
              <Typography variant="body1" alignItems={'center'}>
                {selected}
              </Typography>
            )}
          >
            {['NIFTY 50', 'NIFTY BANK', 'NIFTY FIN SERVICE'].map((index, i) => (
              <MenuItem key={i} value={index}>
                {index}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <ButtonGroup
            variant="outlined"
            aria-label="Button group with a nested menu"
          >
            <Button
              onClick={() =>
                handleSelect({ key: 'view', label: 'view', value: 'bullish' })
              }
            >
              <Typography
                variant="body1"
                color={selected['view'].value === 'bullish' ? '#1a73e8' : ''}
              >
                Bullish
              </Typography>
            </Button>
            <Button
              onClick={() =>
                handleSelect({ key: 'view', label: 'view', value: 'bearish' })
              }
            >
              <Typography
                variant="body1"
                color={selected['view'].value === 'bearish' ? '#1a73e8' : ''}
              >
                Bearish
              </Typography>
            </Button>
            <Button
              onClick={() =>
                handleSelect({ key: 'view', label: 'view', value: 'sideways' })
              }
            >
              <Typography
                variant="body1"
                color={selected['view'].value === 'sideways' ? '#1a73e8' : ''}
              >
                SideWays
              </Typography>
            </Button>
            <Button
              onClick={() =>
                handleSelect({
                  key: 'view',
                  label: 'view',
                  value: 'directional',
                })
              }
            >
              <Typography
                variant="body1"
                color={
                  selected['view'].value === 'directional' ? '#1a73e8' : ''
                }
              >
                Directional
              </Typography>
            </Button>
          </ButtonGroup>
        </FormControl>
      </Grid>

      {FilterList.map((filter, index) => {
        return (
          <Grid item key={index}>
            <FormControl>
              <SelectWithRemove
                options={filter.Options}
                handleSelect={handleSelect}
                selected={{
                  key: filter.key,
                  label: selected[filter.key]?.label || '',
                  value: selected[filter.key]?.value || '',
                }}
              />
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TradeFilter;
