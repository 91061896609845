import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Chip,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import { usePostStrategyData } from 'services/Bots/usePostStrategyData';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useTranslation } from 'react-i18next';
import MuiButton from 'app/design/uiComponents/MuiButton';
// import * as Yup from 'yup';
type Dialogprops = {
  open: boolean;
  handleClose: (val: boolean) => void;
};

const ImportStrategyDialog = ({ open, handleClose }: Dialogprops) => {
  const handleCloseDialog = () => {
    handleClose(false);
  };
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const mutation = usePostStrategyData(handleCloseDialog);
  const breadcrumb = useBreadcrumbManager();
  const formik = useFormik({
    // validationSchema: Yup.object().shape({
    //   bot_name: Yup.string().required(),
    // }),
    initialValues: {
      uploadFile: null,
      bot_name: '',
    },
    // onSubmit: values => {
    //   if (values.uploadFile) {
    //     const formData = new FormData();
    //     formData.append('file', values.uploadFile);

    //     // Log the formData to see if it contains the file
    //     formData.forEach((value, key) => {
    //       // eslint-disable-next-line no-console
    //       console.log('key %s: value %s', key, value);
    //     });

    //     // Use your mutation or submit method here
    //     mutation.mutate({ body: formData.get('file') });
    //   } else {
    //     // eslint-disable-next-line no-console
    //     console.error('No file selected');
    //   }
    // }
    onSubmit: values => {
      const splitString = String(values.uploadFile).split(',');
      const result = splitString[1];

      if (values.uploadFile)
        mutation.mutate(
          {
            attachment: result,
            bot_name: values.bot_name,
          },
          {
            onSuccess: res => {
              if (res.success && res.data.bot_id) handleCloseDialog();
              // if (isRequireToRedirect) {
              breadcrumb.push(
                `/bot-builder/${res.data.bot_id}`,
                PAGES.BOTS,
                '/bots',
                t('user_bot_startegy.page_title3'),
              );
            },
          },
        );
    },
  });

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        alert('File size too large. Try to select a file with less than 20MB.');
        return;
      }
      if (file.name.split('.').pop() !== 'speedbot') {
        alert('Invalid file type. Please upload a .speedbot file.');
        return;
      }

      setSelectedFile(file);
      const base64Data = await new Promise<string>(resolve => {
        const reader = new FileReader();
        reader.onload = function (e) {
          if (e.target) {
            resolve(e.target.result as string);
          }
        };
        reader.readAsDataURL(file);
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      formik.setFieldValue('uploadFile', base64Data);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Import Strategy
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 1 }}>
        <Box
          sx={{
            backgroundColor: 'white',
            p: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <FormControl
            fullWidth
            error={Boolean(formik.touched.bot_name && formik.errors.bot_name)}
            variant="outlined"
          >
            <InputLabel htmlFor="label-bot_name">
              {/* <FormattedMessage id="menu.wallet_modal.credits" /> */}
              Strategy Name
            </InputLabel>
            <OutlinedInput
              size="medium"
              id="label-bot_name"
              name="bot_name"
              type="text"
              value={formik.values.bot_name}
              onBlur={formik.handleBlur}
              onChange={e => formik.setFieldValue('bot_name', e.target.value)}
              label={'Strategy Name'}
            />
            {formik.touched.bot_name && formik.errors.bot_name && (
              <FormHelperText error sx={{ mx: 0 }}>
                {formik.errors.bot_name}
              </FormHelperText>
            )}
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px dashed',
              borderColor: theme => theme.palette.grey[400],
              borderRadius: 2,
              cursor: 'pointer',
              width: '100%',
              '&:hover': {
                backgroundColor: 'secondary.light',
              },
            }}
            onClick={() => document.getElementById('fileInput')?.click()}
          >
            {!selectedFile ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  py: 1,
                  gap: 1,
                }}
              >
                <Typography variant="body1" color="primary.main">
                  Import Your File a
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign={'center'}
                >
                  <b>.speedbot</b> file less than 20MB (recommended)
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Click below to select a file:
                </Typography>
                <MuiButton
                  variant="contained"
                  component="span"
                  onClick={(e: React.BaseSyntheticEvent<MouseEvent>) => {
                    document.getElementById('fileInput')?.click();
                    e.stopPropagation();
                  }}
                >
                  Browse files
                </MuiButton>
                <input
                  id="fileInput"
                  type="file"
                  hidden
                  accept=".speedbot"
                  onChange={handleFileChange}
                />
              </Box>
            ) : (
              <Box
                mt={1}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <InsertDriveFileOutlined sx={{ fontSize: 60 }} />
                <Chip
                  size="xsmall"
                  sx={{ mb: 1, fontSize: 10 }}
                  label={selectedFile.name}
                  onDelete={() => {
                    setSelectedFile(null);
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    formik.setFieldValue('uploadFile', null);
                  }}
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              width: '100%',
            }}
          >
            <MuiButton variant="text" onClick={handleCloseDialog}>
              Cancel
            </MuiButton>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={formik.submitForm}
              disabled={!selectedFile}
            >
              Import
            </MuiButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImportStrategyDialog;
