import { lazyLoad } from 'utils/loadable';
import { Route, Switch, useLocation } from 'react-router-dom';
import MainLayout from 'app/components/Layouts/MainLayout';
// pages
import { BotTemplatesPage } from 'app/pages/BotTemplates/BotTemplatesPage';
import { BotTemplateDetailsPage } from 'app/pages/BotTemplates/BotTemplateDetailsPage';
import { TemplateBotBuilder } from 'app/pages/BotTemplates/TemplateBotBuilderPage';
import { BotStrategyBuilderPage } from 'app/pages/Bots/BotStrategyBuilderPage';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { isUserLoggedIn } from 'utils/auth';
import PublicPage from 'app/pages/PublicPage';
import HomePage from 'app/pages/Home';

// Lazy loaded pages
const BotTemplateCategoryPage = lazyLoad(
  () => import('app/pages/BotTemplates/BotTemplateCategoryPage'),
  module => module.BotTemplateCategoryPage,
);

const BotTemplateSearchPage = lazyLoad(
  () => import('app/pages/BotTemplates/BotTemplateSearchPage'),
  module => module.BotTemplateSearchPage,
);

//-----------------------|| PUBLIC ROUTING ||-----------------------//

const PublicRoutes = () => {
  const location = useLocation();
  const isAllowPublicPages =
    useIsFeatureFlag([FEATURES.IS_ALLOW_PUBLIC_PAGES]) || isUserLoggedIn();

  return (
    <Route
      path={[
        '/templates',
        '/templates/category',
        '/templates/search',
        '/templates/bot/:id/:title?',
        '/template-bot-builder/:id',
        '/bot-builder/:id?/:sid?',
        '/portfoliopnl/share/:uuid?',
        '/home',
      ]}
    >
      <MainLayout>
        <Switch location={location} key={location.pathname}>
          {isAllowPublicPages && (
            <>
              <Route
                exact
                path="/bot-builder/:id?/:sid?"
                component={BotStrategyBuilderPage}
              />
              <Route exact path="/templates" component={BotTemplatesPage} />
              <Route
                exact
                path="/templates/category"
                component={BotTemplateCategoryPage}
              />
              <Route
                exact
                path="/templates/search"
                component={BotTemplateSearchPage}
              />
              <Route
                exact
                path="/templates/bot/:id/:title?"
                component={BotTemplateDetailsPage}
              />
              <Route
                exact
                path="/template-bot-builder/:id"
                component={TemplateBotBuilder}
              />
              <Route
                exact
                path="/portfoliopnl/share/:uuid?"
                component={PublicPage}
              />
              <Route path="/home" component={HomePage} />
            </>
          )}
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default PublicRoutes;
