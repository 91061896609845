import { Helmet } from 'react-helmet-async';
import PageHeader from 'app/design/speedBot/PageHeader';
import AddBrokerForm from 'app/components/Menu/AddBrokerForm';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';

export function AddBrokerPage() {
  return (
    <>
      <Helmet>
        <title>Add Broker</title>
        <meta name="description" content="Add Broker Page" />
      </Helmet>

      <PageHeader
        variant="inactive"
        buttonText={<FormattedMessage id="menu.page_title.brokers" />}
        title={<FormattedMessage id="menu.page_title.add_broker" />}
        buttonHandleClick="/brokers"
        breadcrumb={<Breadcrumbs page={PAGES.ADD_BROKER} data={{}} />}
      />

      <AddBrokerForm />
    </>
  );
}
