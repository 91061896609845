import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import ProfileUploadButton from 'app/design/uiComponents/ProfileUploadButton';
import { ItemHead } from 'app/design/speedBot/EntityItem';
import { trimChars } from 'utils/GenericFunctions';
import MarketplaceItems from 'app/design/itemsComponent/MarketplaceItems';

// Service
import useGetAuthor from 'services/Marketplace/useGetAuthor';
import useGetSiteInfo from 'services/common/useGetSiteInfo';

export function AuthorProfilePage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [profilePic, setProfilePic] = useState<File | string | null>(null);

  const { data: profileDetails, isLoading } = useGetAuthor(parseInt(id));
  const { data: stiteInfo } = useGetSiteInfo();

  const address = trimChars(
    `${profileDetails?.address.city}, ${profileDetails?.address.state}`,
    ',',
  );

  useEffect(() => {
    if (profileDetails && profileDetails.picture)
      setProfilePic(`data:image/png;base64,${profileDetails.picture}`);
  }, [profileDetails]);

  return (
    <>
      <Helmet>
        <title>Author Profile Page</title>
        <meta name="description" content="Author Profile Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <Box sx={{ backgroundColor: '#eeeeee', pt: 3 }}>
        {profileDetails && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <Card
                sx={{ p: 0, pb: 2, backgroundColor: '#fafafa' }}
                className="m-main-spacing-sm ml-main-spacing-md"
              >
                <Box
                  sx={{
                    height: '150px',
                    backgroundImage:
                      'url(/buy-sell-trend-forex-trading-background.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></Box>

                <ProfileUploadButton
                  value={profilePic}
                  id="backgroundImage"
                  isEditable={false}
                  handleChange={(file: File | null) => {
                    setProfilePic(file);
                  }}
                />

                <Box
                  textAlign="center"
                  sx={{ my: 2 }}
                  className="p-main-spacing"
                >
                  <Typography variant="body1">{profileDetails.name}</Typography>
                  <Typography variant="body3">{address}</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  className="p-main-spacing"
                >
                  {profileDetails.social_media_links &&
                    profileDetails.social_media_links.map(link => (
                      <Link
                        sx={{ mr: 1 }}
                        href={`${
                          link.social_platform_name === 'Email' ? 'mailto:' : ''
                        }${link.social_profile_url}`}
                        key={link.social_platform_name}
                        {...(link.social_platform_name !== 'Email' && {
                          target: '_blank',
                        })}
                      >
                        <img
                          src={`data:image/png;base64,${link.social_media_icon}`}
                          alt={stiteInfo && stiteInfo.name}
                          height="24px"
                        />
                      </Link>
                    ))}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Card
                sx={{ backgroundColor: '#fafafa', mb: 3 }}
                className="m-main-spacing-sm mr-main-spacing-md"
              >
                <CardContent sx={{ p: 0 }}>
                  <Box>
                    <ItemHead
                      variant="large"
                      title={t('market.author.about')}
                    />
                  </Box>
                  <Box className="p-main-spacing">
                    <Typography variant="body2" fontWeight="400">
                      {profileDetails.about}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{ backgroundColor: '#fafafa', mb: 3 }}
                className="m-main-spacing-sm mr-main-spacing-md"
              >
                <CardContent sx={{ p: '0 !important' }}>
                  <Box>
                    <ItemHead
                      variant="large"
                      title={t('market.author.strategies')}
                    />
                  </Box>
                  <Box>
                    <Grid container spacing={0} sx={{ flex: { lg: 1, xl: 1 } }}>
                      {profileDetails.owned_strategies &&
                        profileDetails.owned_strategies.length > 0 && (
                          <MarketplaceItems
                            bots={profileDetails.owned_strategies}
                          />
                        )}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}
