import { Chip, createTheme, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import PageHeader from 'app/design/speedBot/PageHeader';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { Helmet } from 'react-helmet-async';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { useContext, useState } from 'react';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { Form, Formik } from 'formik';
import MUIRichTextEditor from 'mui-rte';
import { useParams } from 'react-router-dom';
import useGetTicketMessageData from 'services/Menu/useGetTicketMessageData';
import { stateToHTML } from 'draft-js-export-html';
import { usePostTicketMessageData } from 'services/Menu/usePostTicketMessageData';
import { ThemeProvider } from '@mui/styles';
import { MessageItemComp } from 'app/components/Menu/HelpDeskComps/MessageItemComp';
import { GetTicketMessage } from 'types/ApiServicesTypes';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { ToastContext } from 'app/components/Context/ToastContext';
import MuiLoadingButton from 'app/design/uiComponents/MuiLoadingButton';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { getStatusChipColor } from 'app/components/Menu/HelpDeskComps/TicketListView';
import { Done } from '@mui/icons-material';
import SnakbarMessageModal from 'app/design/uiComponents/SnakbarMessageModal';
import { useTranslation } from 'react-i18next';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';

const TicketDetailPage = () => {
  const { showAlert } = useContext(ToastContext);
  const { id } = useParams<{ id: string }>();
  const { data: messageData, isLoading } = useGetTicketMessageData(Number(id));
  const mutation = usePostTicketMessageData(Number(id));
  const isMdUp = useGetMediaQueryUp('md');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const [openMessage, setOpenMessage] = useState(false);
  const { t } = useTranslation();

  const [value, setValue] = useState<string | undefined>('');
  // const validationSchema = Yup.object().shape({
  //   message: Yup.string().required('message is required'),
  // });
  // const doc = new DOMParser().parseFromString(value, 'text/html');
  // const plainText = doc.body.textContent || '';
  // // eslint-disable-next-line no-console
  // console.log(plainText);
  // Define initial form values

  const initialValues = {
    message: '',
    uploadFiles: [],
  };
  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });

  const handleSolved = () => {
    mutation.mutate({
      message: '',
      attachments: [],
      action: 'solve',
    });
    setOpenMessage(false);
  };
  // Define form submission function
  // const handleSubmit = values => {
  //   // Handle form submission logic here
  //   // eslint-disable-next-line no-console
  //   console.log('Form values:', values);

  //   // Reset the form after submission
  //   // resetForm();
  // };
  const getContentStateAsStringFromSomewhere = () => {
    // Assume you have some logic to fetch or generate content state

    const contentState = {
      blocks: [
        {
          key: '1',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 5, style: 'BOLD' },
            { offset: 6, length: 4, style: 'ITALIC' },
          ],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {},
    };

    // Convert content state to a string
    return JSON.stringify(contentState);
  };
  // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // const [isBadgeClicked, setIsBadgeClicked] = useState(false);
  // const handleClickBadge = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  //   setIsBadgeClicked(true);
  // };

  // const handleClosePopover = () => {
  //   setAnchorEl(null);
  //   setIsBadgeClicked(false);
  // };
  // useEffect(() => {
  //   if (selectedFiles.length == 0) {
  //     handleClosePopover();
  //   }
  // }, [selectedFiles.length]);
  const data = getContentStateAsStringFromSomewhere();
  return (
    <>
      <CircularLoader open={isLoading || mutation.isLoading} />
      <Helmet>
        <title>Help Desk</title>
        <meta name="description" content="Help Desk" />
      </Helmet>
      <PageHeader
        variant="inactive"
        buttonText={<FormattedMessage id="menu.page_title.help" />}
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <FormattedMessage id="menu.page_title.ticket_detail" /> */}
            <Typography variant="h5" fontWeight={600}>
              Ticket #{id}
            </Typography>
            {messageData?.data?.status !== 'Solved' ? (
              messageData?.data?.status !== 'Closed' ? (
                <MuiButton
                  startIcon={<Done />}
                  variant="contained"
                  onClick={() => setOpenMessage(true)}
                >
                  Mark as Solved
                </MuiButton>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </Box>
        }
        buttonHandleClick="/menu/help"
        breadcrumb={<Breadcrumbs page={PAGES.TICKET_DETAILS} data={{}} />}
      />
      <Box sx={{ minHeight: '100vh' }}>
        <Formik
          initialValues={initialValues}
          // validationSchema={}
          onSubmit={values => {
            const splitString = String(values.uploadFiles[0]).split(',');
            const result = splitString[1];
            const attachments = selectedFiles.map(file => ({
              name: file.name,
              mimetype: file.type,
              data: result,
            }));
            mutation.mutate(
              {
                message: value || '',
                attachments: attachments,
                action: 'reply',
              },
              {
                onSuccess: () => {
                  setSelectedFiles([]);
                  setValue('<p><br></p>');
                },
              },
            );
          }}
        >
          {({ setFieldValue, handleSubmit }) => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Box sx={{ backgroundColor: '#11111', mb: 2 }}>
                  {/* <Paper
                    elevation={3}
                    style={{ padding: '20px', marginBottom: '20px' }}
                  > */}
                  {/* Ticket Information */}
                  <Box
                    mb={2}
                    className={'p-main-spacing'}
                    sx={{ backgroundColor: 'grey.100' }}
                  >
                    <Box
                      display={'flex'}
                      pt={1}
                      justifyContent={'space-between'}
                    >
                      <Typography variant="h5" gutterBottom>
                        {messageData?.data?.name}
                      </Typography>
                      <Box>
                        <MuiChip
                          color={getStatusChipColor(
                            messageData?.data?.status || 'new',
                          )}
                          label={messageData?.data?.status}
                          size="medium"
                          sx={{ fontSize: '18px', fontWeight: '500' }}
                        />
                      </Box>
                    </Box>
                    {messageData &&
                      messageData.data &&
                      messageData.data.description && (
                        <Typography
                          variant="body1"
                          pb={1}
                          dangerouslySetInnerHTML={{
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            __html: messageData.data.description,
                          }}
                        />
                      )}
                  </Box>

                  {/* Chat Messages */}
                  {/* </Paper> */}
                </Box>
                {messageData &&
                  messageData?.data.messages.map((data: GetTicketMessage) => (
                    <Box
                      sx={{ mb: 1 }}
                      key={data.id}
                      className="m-main-spacing"
                    >
                      <MessageItemComp messages={data} />
                      <ItemSeparator variant="2px" />
                    </Box>
                  ))}
              </Box>
              {messageData?.data?.status !== 'Solved' ? (
                messageData?.data?.status !== 'closed' ? (
                  <Box
                    className="m-main-spacing"
                    style={{
                      backgroundColor: '#f5f5f5',
                      position: 'sticky',
                      bottom: 0,
                      zIndex: 100,
                      display: 'flex',
                      flexDirection: 'column',
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)',
                      borderRadius: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        padding: '15px',
                        minHeight: '50px',
                        // marginBottom: '10px',
                      }}
                    >
                      <ThemeProvider theme={myTheme}>
                        <MUIRichTextEditor
                          controls={[
                            'title',
                            'bold',
                            'italic',
                            'underline',
                            'highlight',
                            'undo',
                            'redo',
                          ]}
                          value={mutation.isSuccess && data}
                          onChange={values => {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
                            setValue(stateToHTML(values.getCurrentContent()));
                          }}
                          inlineToolbar
                          label="Start typing..."
                        />
                      </ThemeProvider>
                    </Box>
                    <Box
                      sx={{
                        ml: 'auto',
                        mb: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box p={2}>
                        {selectedFiles.map((file, index) => (
                          <Chip
                            key={index}
                            label={file.name}
                            onDelete={() => {
                              const updatedFiles = [...selectedFiles];
                              updatedFiles.splice(index, 1);
                              setSelectedFiles(updatedFiles);

                              setFieldValue('uploadFiles', updatedFiles);
                            }}
                            sx={
                              !isMdUp
                                ? { margin: 0.2, width: '125px' }
                                : { margin: 1 }
                            }
                          />
                        ))}
                      </Box>
                      <Box sx={{ ml: 'auto' }}>
                        <MuiButton
                          component="label"
                          htmlFor="fileInput"
                          sx={
                            isMdUp
                              ? { width: '140px', mr: 1 }
                              : { width: 'auto', mr: 1 }
                          }
                          size={isMdUp ? 'large' : 'small'}
                          variant="contained"
                          endIcon={<AttachFileIcon />}
                        >
                          {isMdUp ? 'Attach' : ''}
                          <input
                            multiple
                            type="file"
                            id="fileInput"
                            name="file"
                            onChange={async event => {
                              const files = event.target.files
                                ? Array.from(event.target.files)
                                : [];
                              const totalSize = files.reduce(
                                (acc, file) => acc + file.size,
                                0,
                              );

                              if (totalSize > 5 * 1024 * 1024) {
                                showAlert!(
                                  'Total file size exceeds 5MB limit.',
                                  'error',
                                );
                                return;
                              }

                              const invalidFiles = files.filter(
                                file => file.size > 1 * 1024 * 1024,
                              );

                              if (invalidFiles.length > 0) {
                                showAlert!(
                                  'Individual file size should not exceed 1MB.',
                                  'error',
                                );
                                return;
                              }
                              setSelectedFiles([...selectedFiles, ...files]);
                              await Promise.all(
                                files.map(file => {
                                  return new Promise<string>(resolve => {
                                    const reader = new FileReader();
                                    reader.onload = function (e) {
                                      if (e.target) {
                                        const base64Data = e.target
                                          .result as string;
                                        resolve(base64Data);
                                      }
                                    };
                                    reader.readAsDataURL(file);
                                  });
                                }),
                              ).then(base64Files => {
                                setFieldValue('uploadFiles', base64Files);
                              });
                            }}
                            style={{ display: 'none' }}
                          />
                        </MuiButton>
                        <MuiLoadingButton
                          loading={mutation.isLoading}
                          disabled={
                            value == '<p><br></p>' && selectedFiles.length == 0
                          }
                          onClick={handleSubmit}
                          sx={
                            isMdUp
                              ? { width: '140px', mr: 1 }
                              : { width: 'auto', mr: 1 }
                          }
                          size={isMdUp ? 'large' : 'small'}
                          variant="contained"
                          endIcon={<SendIcon />}
                        >
                          {isMdUp ? 'Send' : ''}
                        </MuiLoadingButton>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Form>
          )}
        </Formik>
      </Box>
      <SnakbarMessageModal
        open={openMessage}
        handleClose={() => setOpenMessage(false)}
        body={t('menu.transfer_credit_conformation')}
        footer={
          <>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MuiButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setOpenMessage(false)}
                >
                  Cancel
                </MuiButton>
              </Grid>
              <Grid item xs={6}>
                <MuiButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleSolved()}
                >
                  Confirm
                </MuiButton>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};

export default TicketDetailPage;
