import { Box, Typography, Divider, Grid, useMediaQuery } from '@mui/material';
import React, { useMemo, useState } from 'react';

import 'react-circular-progressbar/dist/styles.css';

import HistoryCard, {
  utcToLocalUsingMoment,
} from 'app/components/PublicPageComps/HistoryCard';
import TopCard from 'app/components/PublicPageComps/TopCard';
import useGetPortfolioShare from 'services/Portfolio/useGetPortfolioShare';
import { useParams } from 'react-router-dom';

import PageHeader from 'app/design/speedBot/PageHeader';
import PublicPageTable from 'app/components/PublicPageComps/PublicPageTable';
import moment from 'moment';
import MuiButton from 'app/design/uiComponents/MuiButton';
import PastHistory from 'app/components/PublicPageComps/PastHistory';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { getQueryParam } from 'utils/GenericFunctions';
import HistoryIcon from '@mui/icons-material/History';
import StatusMessage from 'app/components/Common/StatusMessage';
import nodatafound from '../../../assets/images/No_data_image.png';
import { useTranslation } from 'react-i18next';
import UnShareButton from 'app/components/PublicPageComps/UnShareButton';
import useGetProfile from 'services/Menu/useGetProfile';
import { isUserLoggedIn } from 'utils/auth';

interface SharedDate {
  date: string;
  net_profit_loss: number;
}
const PublicPage = () => {
  const dateCardSX = {
    ml: 'auto',
    maxWidth: 300,

    flexDirection: 'column',
    boxShadow: '0 4px 4px rgba(0,0,0,0.2)',
    marginTop: 1,

    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '1em',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  };
  const [isShowPastHistory, setIsShowPastHistory] = useState(false);
  const is1600up = useMediaQuery('(min-width:1600px)');
  const { uuid } = useParams<{ uuid: string }>();
  const dateValue = getQueryParam('date');
  const numUuid = Number(uuid);
  const { data: tableData } = useGetPortfolioShare(numUuid, dateValue);

  const { data: siteInfo } = useGetSiteInfo();
  const { t } = useTranslation();
  const isSelected = (selectedDate: string) => {
    return dateValue === selectedDate;
  };
  const groupDatesByMonth = (dates: SharedDate[]) => {
    const groupedDates: { [key: string]: SharedDate[] } = {};

    dates?.forEach(dateItem => {
      const monthYear = moment(dateItem.date).format('MMMM YYYY');

      if (!groupedDates[monthYear]) {
        groupedDates[monthYear] = [];
      }

      groupedDates[monthYear].unshift(dateItem);
    });

    return groupedDates;
  };

  const newDateArray = useMemo(
    () => groupDatesByMonth(tableData?.data.shared_dates as SharedDate[]),
    [tableData?.data.shared_dates],
  );
  const handleClose = () => {
    setIsShowPastHistory(false);
  };
  const { data: profileData } = useGetProfile();
  const isLoggedin = isUserLoggedIn();

  // useEffect(() => {
  //   // eslint-disable-next-line no-console
  //   // console.log('date changed', date, searchQuery);
  //   const urlparams = new URLSearchParams();
  //   urlparams.set('date', searchQuery);
  //   history.replace({
  //     search: urlparams.toString(),
  //   });
  // }, [searchQuery, history]);
  return (
    <Box>
      <Box className="sticky-top">
        <PageHeader variant="market" title={'Portfolio Insights'} />
      </Box>
      <Grid
        container
        spacing={2}
        className="p-main-spacing"
        justifyContent={'space-between'}
      >
        <Grid item xs={12} sm={12} md={12} lg={is1600up ? 9 : 12}>
          <Box display={'flex'} flexDirection={'column'}>
            <TopCard
              sx={{}}
              isTableData={
                tableData && tableData.data.portfolio.bots.length > 0
              }
              userId={profileData?.user_id}
              profit={tableData?.data.portfolio.total_pnl}
              date={tableData?.data.portfolio.date}
              currency={
                tableData?.data.portfolio.bots[0]?.currency_symbol || '$'
              }
              companyName={siteInfo?.name}
            />
            {!is1600up && (
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <MuiButton
                  fullWidth
                  onClick={() => setIsShowPastHistory(true)}
                  variant="contained"
                  startIcon={<HistoryIcon />}
                >
                  <FormattedMessage id="portfolio.public.see_past" />
                </MuiButton>
                {tableData &&
                  profileData &&
                  isLoggedin &&
                  profileData.user_id == uuid &&
                  tableData.data.portfolio.bots.length > 0 && <UnShareButton />}
              </Box>
            )}
          </Box>

          {tableData && tableData.data.portfolio.bots.length > 0 ? (
            <PublicPageTable tableData={tableData} />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignContent: 'center',
                // justifyContent: 'center',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                mt: 10,
              }}
            >
              <img
                src={nodatafound}
                style={{ width: '150px', height: '150px' }}
              />
              <StatusMessage
                subtitle={t('portfolio.public.no_data')}
                fontStyle="italic"
              />
            </Box>
          )}
        </Grid>
        {is1600up && (
          <Grid
            item
            xs={12}
            sm={4}
            md={12}
            lg={12}
            xl={is1600up ? 3 : 12}
            sx={{}}
          >
            <Box
              style={{
                height: '85vh',
                overflowY: 'auto',
                position: 'sticky',
                top: '76px',
              }}
              sx={dateCardSX}
            >
              <Divider />

              <Typography
                variant="h5"
                sx={{
                  py: 2,
                  paddingLeft: 2,
                  borderBottom: '1px solid #e0e0e0',
                }}
              >
                <FormattedMessage id="portfolio.public.history" />
              </Typography>
              {newDateArray &&
                Object.entries(newDateArray)
                  .reverse()
                  .map(([monthYear, dates], index) => (
                    <React.Fragment key={index}>
                      <Typography
                        variant="body1"
                        sx={{
                          py: 2,
                          paddingLeft: 2,
                          borderBottom: '1px solid #e0e0e0',
                        }}
                      >
                        {monthYear}
                      </Typography>
                      {dates.map((dateItem, dateIndex) => (
                        <HistoryCard
                          isActive={isSelected(
                            utcToLocalUsingMoment(dateItem.date).format(
                              'YYYY-MM-DD',
                            ),
                          )}
                          profit={dateItem.net_profit_loss}
                          time={dateItem.date}
                          key={dateIndex}
                        />
                      ))}
                    </React.Fragment>
                  ))}
            </Box>
          </Grid>
        )}
      </Grid>
      <PastHistory
        open={isShowPastHistory}
        handleClose={handleClose}
        tableData={tableData}
        isSelected={isSelected}
      />
    </Box>
  );
};

export default PublicPage;
