import { Box, Divider } from '@mui/material';
import TradeOptionsCard from 'app/components/TradeIdeas/TradeOptions';
import PageHeader from 'app/design/speedBot/PageHeader';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { Helmet } from 'react-helmet-async';
import useGetTradeIdeasMetaData from 'services/TradeIdeas/useGetTradeIdeadsMetaData';

const TradeOptions = () => {
  const { data: metaData } = useGetTradeIdeasMetaData();
  return (
    <Box>
      <CircularLoader open={false} zIndex={1302} />
      <Helmet>
        <title>Trade Ideas</title>
        <meta name="description" content="TradeIdeas Page" />
      </Helmet>
      <Box>
        <PageHeader variant={'common'} title={'Trade Ideas'} />
        <Divider sx={{ borderBottom: 2, borderColor: 'grey.300' }} />
      </Box>
      <Box bgcolor={'grey.100'}>
        {metaData?.metadata &&
          Object.keys(metaData.metadata).map((symbol, index) => {
            return (
              metaData.metadata[symbol] && (
                <TradeOptionsCard
                  key={index}
                  selectedSymbol={symbol}
                  description={metaData.metadata[symbol].description}
                  expiry={metaData.metadata[symbol].expiry}
                />
              )
            );
          })}
      </Box>
    </Box>
  );
};

export default TradeOptions;
