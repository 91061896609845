import { ChangeEvent, FocusEvent, useState } from 'react';
import { BrokerFormField } from 'types/ApiServicesTypes';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Grid,
  Select,
  MenuItem,
  useMediaQuery,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  InputAdornment,
  Tooltip,
  Checkbox,
} from '@mui/material';
import { FormikTouched, FormikErrors, Field } from 'formik';
import Combobox from '../Bots/Backtesting/AutoComplete';
import { InfoOutlined } from '@mui/icons-material';
import { setCookie } from 'typescript-cookie';

type FormControlItemsProps = {
  fields: BrokerFormField[];
  handleChange: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: ChangeEvent<any>): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: string | ChangeEvent<any>) => void;
  };
  handleBlur: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: FocusEvent<any>): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  values: object;
  touched: FormikTouched<unknown>;
  errors: FormikErrors<unknown>;
  maxItemInRow?: number;
};
const FormControlItems = ({
  fields,
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  maxItemInRow,
}: FormControlItemsProps) => {
  const is1600Up = useMediaQuery('(min-width:1600px)');
  const [validValue, setValidValue] = useState<boolean>(true);

  return (
    <>
      {fields.map(field => {
        if (field.type.startsWith('input')) {
          return (
            <Grid
              item
              xs={12}
              md={12}
              lg={maxItemInRow && is1600Up ? 12 / maxItemInRow : 6}
              position={'relative'}
              key={`field_${field.key}`}
            >
              <FormControl
                fullWidth
                error={Boolean(touched[field.key] && errors[field.key])}
                variant="outlined"
                // sx={{ mb: 2 }}
              >
                {field.label && (
                  <InputLabel htmlFor={`field_${field.key}`}>
                    {field.label}
                  </InputLabel>
                )}
                <Field name={field.key}>
                  {({ field: f }) => {
                    return (
                      <OutlinedInput
                        id={`field_${field.key}`}
                        type={field.type.split(':')[1]}
                        name={field.key}
                        disabled={
                          field && field.readOnly ? field.readOnly : false
                        }
                        endAdornment={
                          field.helpText !== '' &&
                          field.helpText && (
                            <InputAdornment sx={{ mr: 0 }} position="end">
                              <Tooltip title={field.helpText}>
                                <InfoOutlined
                                  sx={{ float: 'right' }}
                                  fontSize="small"
                                />
                              </Tooltip>
                            </InputAdornment>
                          )
                        }
                        onChange={e => {
                          const inputValue = e.target.value;
                          const regexPattern = field.validationRules[0]?.regex;
                          const regex = regexPattern
                            ? new RegExp(regexPattern)
                            : null;
                          const isValid = regex ? regex.test(inputValue) : true;
                          setValidValue(isValid);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        label={field.label}
                        autoComplete="new-password"
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        value={f.value}
                      />
                    );
                  }}
                </Field>
                {field.validationRules[0]?.regex !== '' && !validValue ? (
                  <FormHelperText error>
                    {field.validationRules[0].errorMessage}
                  </FormHelperText>
                ) : touched[field.key] && errors[field.key] ? (
                  <FormHelperText error>{errors[field.key]}</FormHelperText>
                ) : null}
                {/* {touched[field.key] && errors[field.key] && (
                  <FormHelperText error>{errors[field.key]}</FormHelperText>
                )} */}
              </FormControl>
            </Grid>
          );
        } else if (field.type === 'select') {
          return (
            <Grid
              item
              xs={12}
              md={12}
              key={`field_${field.key}`}
              lg={maxItemInRow && is1600Up ? 12 / maxItemInRow : 6}
            >
              <FormControl
                fullWidth
                error={Boolean(touched[field.key] && errors[field.key])}
                variant="outlined"
              >
                {field.label && (
                  <InputLabel htmlFor={`field_${field.key}`}>
                    {field.label}
                  </InputLabel>
                )}
                <Field name={field.key}>
                  {({ field: f }) => (
                    <Select
                      id={`field_${field.key}`}
                      name={field.key}
                      onChange={e => {
                        if (
                          field.key === 'sb_opt_strategy.strategy_sets.0.market'
                        ) {
                          setCookie('exchange', e.target.value);
                        }
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      label={field.label}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      value={f.value}
                      endAdornment={
                        field.helpText &&
                        field.helpText !== '' && (
                          <InputAdornment sx={{ mr: 2 }} position="end">
                            <Tooltip title={field.helpText}>
                              <InfoOutlined
                                sx={{ float: 'right' }}
                                fontSize="small"
                              />
                            </Tooltip>
                          </InputAdornment>
                        )
                      }
                    >
                      {field.values?.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
                {touched[field.key] && errors[field.key] && (
                  <FormHelperText error>{errors[field.key]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          );
        } else if (field.type === 'combobox') {
          return (
            <Grid
              item
              xs={12}
              md={12}
              key={`field_${field.key}`}
              lg={maxItemInRow && is1600Up ? 12 / maxItemInRow : 6}
            >
              <FormControl
                fullWidth
                error={Boolean(touched[field.key] && errors[field.key])}
                variant="outlined"
              >
                {field.label && (
                  <InputLabel htmlFor={`field_${field.key}`}>
                    {/* {field.label} */}
                  </InputLabel>
                )}
                <Field name={field.key}>
                  {({ field: f, form: { setFieldValue } }) => (
                    <Combobox
                      fieldData={field}
                      handleChange={selectedValues =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        setFieldValue(field.key, selectedValues)
                      }
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      field={f}
                    />
                  )}
                </Field>
                {touched[field.key] && errors[field.key] && (
                  <FormHelperText error>{errors[field.key]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          );
        } else if (field.type === 'radio') {
          return (
            <Grid
              item
              xs={12}
              md={12}
              key={`field_${field.key}`}
              lg={maxItemInRow && is1600Up ? 12 / maxItemInRow : 6}
            >
              <FormControl
                fullWidth
                error={Boolean(touched[field.key] && errors[field.key])}
                variant="outlined"
              >
                {field.label && (
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={1}
                    alignItems={'center'}
                  >
                    <FormLabel htmlFor={`field_${field.key}`}>
                      {field.label}
                    </FormLabel>
                    {field.helpText && (
                      <Tooltip title={field.helpText}>
                        <InfoOutlined
                          sx={{ float: 'right', color: '#0000008c' }}
                          fontSize="small"
                        />
                      </Tooltip>
                    )}
                  </Box>
                )}
                <Field name={field.key}>
                  {({ field: f }) => (
                    <RadioGroup
                      id={`field_${field.key}`}
                      name={field.key}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      value={f.value}
                    >
                      <Box display={'flex'} flexWrap={'wrap'}>
                        {field.values?.map(item => (
                          <FormControlLabel
                            control={<Radio />}
                            key={item.key}
                            value={item.key}
                            label={item.value}
                          />
                        ))}
                      </Box>
                    </RadioGroup>
                  )}
                </Field>
                {touched[field.key] && errors[field.key] && (
                  <FormHelperText error>{errors[field.key]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          );
        } else if (field.type === 'checkbox') {
          return (
            <Grid
              item
              xs={12}
              md={12}
              key={`field_${field.key}`}
              lg={maxItemInRow && is1600Up ? 12 / maxItemInRow : 6}
            >
              <FormControl
                fullWidth
                error={Boolean(touched[field.key] && errors[field.key])}
                variant="outlined"
              >
                {field.label && (
                  <FormLabel htmlFor={`field_${field.key}`}>
                    {field.label}
                  </FormLabel>
                )}
                <Field name={field.key}>
                  {({ field: f, form }) =>
                    field.values?.map(item => {
                      // Handler function for checkbox change event
                      const handleCheckboxChange = event => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        const newValue = event.target.value;
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        const isChecked = event.target.checked;

                        // Get the current value as an array
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        let currentValue = f.value || [];

                        if (isChecked) {
                          // If the checkbox is checked, add the value to the array if not already present
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                          if (!currentValue.includes(newValue)) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                            currentValue.push(newValue);
                          }
                        } else {
                          // If the checkbox is unchecked, remove the value from the array
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
                          currentValue = currentValue.filter(
                            value => value !== newValue,
                          );
                        }

                        // Update the form field with the new array
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        form.setFieldValue(field.key, currentValue);
                      };

                      return (
                        <FormControlLabel
                          key={item.key}
                          control={
                            <Checkbox
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
                              checked={f.value?.includes(item.value)}
                              value={item.value}
                              onChange={handleCheckboxChange}
                              color="primary"
                            />
                          }
                          label={item.key}
                        />
                      );
                    })
                  }
                </Field>

                {touched[field.key] && errors[field.key] && (
                  <FormHelperText error>{errors[field.key]}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          );
        } else if (field.type === 'object' && field.fields) {
          return (
            <FormControlItems
              maxItemInRow={maxItemInRow}
              fields={field.fields}
              errors={errors}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              key={`field_${field.key}`}
            />
          );
        } else return <></>;
      })}
    </>
  );
};

export default FormControlItems;
