import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useTranslation } from 'react-i18next';
import { formatDateString } from 'utils/GenericFunctions';
import OptionCustomTable from './OptionCustomTable';
import { OptionTabMetaData } from 'app/pages/Simulator';
import {
  GetOptionChainResultDataType,
  SymbolMetadata,
} from 'types/ApiServicesTypes';
import {
  OptionTableConfig,
  PositionBaseDataType,
  PositionData,
  PositionWithExpiryData,
} from '../types';
import { getLots } from '../utils';
import PositionRemoveAlert from './PositionRemoveAlert';
import { useState } from 'react';

const OptionTab = ({
  handleOptionClick,
  positionsWithExpiry,
  optionTabMetaData,
  setOptionTabMetaData,
  optionChainData,
  optionChainMetadata,
  removeAllPositions,
  tableConfig,
  setTableConfig,
  date,
}: {
  date: string;
  handleOptionClick: (positions: PositionData, expiry: string) => void;
  positionsWithExpiry: PositionWithExpiryData;
  optionTabMetaData: OptionTabMetaData;
  setOptionTabMetaData: (option: OptionTabMetaData) => void;
  optionChainData: GetOptionChainResultDataType | undefined;
  optionChainMetadata: SymbolMetadata | undefined;
  removeAllPositions: () => void;
  tableConfig: OptionTableConfig;
  setTableConfig: (tableConfig: OptionTableConfig) => void;
}) => {
  const { t } = useTranslation();

  const indexes = optionChainMetadata
    ? Object.keys(optionChainMetadata.lot_sizes)
    : [];

  const positions = positionsWithExpiry[optionTabMetaData.optionExpiry] || {};
  const [open, setOpen] = useState(false);
  const [symbol, setSymbol] = useState(optionTabMetaData.symbol);

  const optionTableType = ['ltp', 'greeks'];
  const handleNewPositionAdding = (
    newPosition: PositionBaseDataType,
    contractType: string,
    strike: string,
  ) => {
    const newPositions: PositionData = addNewPostion(
      newPosition,
      positions,
      contractType,
      strike,
      optionTabMetaData.optionExpiry,
      date,
    );
    handleOptionClick(newPositions, optionTabMetaData.optionExpiry);
  };

  const handleAlertClose = (success: boolean) => {
    if (success) {
      removeAllPositions();
      setOpen(false);
      setOptionTabMetaData({
        ...optionTabMetaData,
        symbol: symbol,
      });
    } else {
      setSymbol(optionTabMetaData.symbol);
      setOpen(false);
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Grid container columnGap={1} pl={1} rowGap={1} mb={1}>
        <Grid item>
          <FormControl>
            <Select
              labelId="symbol"
              name="Symbol"
              value={optionTabMetaData.symbol}
              onChange={e => {
                if (Object.values(positions).length > 0) {
                  setSymbol(e.target.value);
                  setOpen(true);
                } else {
                  setOptionTabMetaData({
                    ...optionTabMetaData,
                    symbol: e.target.value,
                  });
                }
              }}
              sx={{
                '& .MuiSelect-select': {
                  pt: '6px',
                  pb: '6px',
                },
              }}
              renderValue={selected => (
                <Typography variant="body2">{selected}</Typography>
              )}
            >
              {optionChainMetadata &&
                indexes.map((index, i) => (
                  <MenuItem key={i} value={index}>
                    {index}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <Select
              labelId="tableType"
              name="TableType"
              value={
                !tableConfig.isGreek ? optionTableType[0] : optionTableType[1]
              }
              onChange={e => {
                setTableConfig({
                  ...tableConfig,
                  isGreek: e.target.value == 'greeks' ? true : false,
                });
              }}
              sx={{
                '& .MuiSelect-select': {
                  pt: '6px',
                  pb: '6px',
                },
              }}
              renderValue={selected => (
                <Typography variant="body1">
                  {selected.toUpperCase()}
                </Typography>
              )}
            >
              {optionTableType.map((type, i) => (
                <MenuItem key={i} value={type}>
                  {type.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {tableConfig.isGreek && (
          <Grid item>
            <FormControl fullWidth>
              <Select
                labelId="contractType"
                name="ContractType"
                disabled={!tableConfig.isGreek}
                value={tableConfig.isCE ? 'ce' : 'pe'}
                onChange={e => {
                  setTableConfig({
                    ...tableConfig,
                    isCE: e.target.value === 'ce' ? true : false,
                  });
                }}
                sx={{
                  '& .MuiSelect-select': {
                    pt: '6px',
                    pb: '6px',
                  },
                }}
                renderValue={selected => (
                  <Typography variant="body2">
                    {selected.toUpperCase()}
                  </Typography>
                )}
                inputProps={{
                  style: {
                    paddingTop: '0px',
                  },
                }}
              >
                <MenuItem value={'ce'}>CE</MenuItem>
                <MenuItem value={'pe'}>PE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <FormControl>
            {/* <InputLabel id="option-expiry">Expiry</InputLabel> */}
            <Select
              labelId="option-expiry"
              name="Expiry Date"
              // label="Expiry"
              value={optionTabMetaData.optionExpiry}
              onChange={e => {
                setOptionTabMetaData({
                  ...optionTabMetaData,
                  optionExpiry: e.target.value,
                });
              }}
              sx={{
                '& .MuiSelect-select': {
                  pt: '6px',
                  pb: '6px',
                },
              }}
              renderValue={selected => (
                <Typography variant="body2">
                  {formatDateString(selected, 'DD MMM')}
                </Typography>
              )}
            >
              {optionChainMetadata &&
                optionChainMetadata.options[optionTabMetaData.symbol].map(
                  (expiry, i) => (
                    <MenuItem key={i} value={expiry}>
                      {formatDateString(expiry, 'DD MMM YY')}
                    </MenuItem>
                  ),
                )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <MuiButton variant="outlined" onClick={removeAllPositions}>
            {t('buttons.clear')}
          </MuiButton>
        </Grid>
      </Grid>

      {optionChainData && (
        <OptionCustomTable
          date={date}
          optionChainData={optionChainData.candles}
          handleOptionClick={handleNewPositionAdding}
          isGreeks={tableConfig.isGreek}
          isCE={tableConfig.isCE}
          positions={positions}
          currentStrike={optionChainData.spot_price}
        />
      )}
      <PositionRemoveAlert open={open} handleClose={handleAlertClose} />
    </Box>
  );
};

export default OptionTab;

// export const addNewPostion = (
//   position: PositionBaseDataType,
//   oldPositions: PositionData,
//   contractType: string,
//   strike: string,
//   expiryDate: string,
//   date: string,
// ): PositionData => {
//   if (contractType === 'ce') {
//     if (oldPositions.CE[strike]) {
//       oldPositions.CE[strike] = {
//         ...oldPositions.CE[strike],
//         positionCandleWise: {
//           ...oldPositions.CE[strike].positionCandleWise,
//           [position.entryTime]: position,
//         },
//         avgPrimium: getLots(
//           Object.values(oldPositions.CE[strike].positionCandleWise),
//           date,
//         ).avgPrimium,
//       };
//     } else {
//       oldPositions.CE = {
//         ...oldPositions.CE,
//         [strike]: {
//           isActive: true,
//           strike: strike,
//           contractType: contractType,
//           expiryDate: expiryDate,
//           positionCandleWise: {
//             [position.entryTime]: position,
//           },
//           avgPrimium: getLots([position], date).avgPrimium,
//         },
//       };
//     }
//   } else {
//     if (oldPositions.PE[strike]) {
//       oldPositions.PE[strike] = {
//         ...oldPositions.PE[strike],
//         positionCandleWise: {
//           ...oldPositions.PE[strike].positionCandleWise,
//           [position.entryTime]: position,
//         },
//         avgPrimium: getLots(
//           Object.values(oldPositions.PE[strike].positionCandleWise),
//           date,
//         ).avgPrimium,
//       };
//     } else {
//       oldPositions.PE = {
//         ...oldPositions.PE,
//         [strike]: {
//           isActive: true,
//           strike: strike,
//           contractType: contractType,
//           expiryDate: expiryDate,
//           positionCandleWise: {
//             [position.entryTime]: position,
//           },
//           avgPrimium: getLots([position], date).avgPrimium,
//         },
//       };
//     }
//   }
//   return oldPositions;
// };

export const addNewPostion = (
  position: PositionBaseDataType,
  oldPositions: PositionData,
  contractType: string,
  strike: string,
  expiryDate: string,
  date: string,
): PositionData => {
  // if (contractType === 'ce') {
  if (oldPositions[`${contractType.toUpperCase()}_${strike}`]) {
    oldPositions[`${contractType.toUpperCase()}_${strike}`] = {
      ...oldPositions[`${contractType.toUpperCase()}_${strike}`],
      positionCandleWise: {
        ...oldPositions[`${contractType.toUpperCase()}_${strike}`]
          .positionCandleWise,
        [position.entryTime]: position,
      },
      avgPrimium: getLots(
        Object.values(
          oldPositions[`${contractType.toUpperCase()}_${strike}`]
            .positionCandleWise,
        ),
        date,
      ).avgPrimium,
    };
  } else {
    oldPositions[`${contractType.toUpperCase()}_${strike}`] = {
      isActive: true,
      isSquareOff: false,
      strike: strike,
      contractType: contractType,
      expiryDate: expiryDate,
      positionCandleWise: {
        [position.entryTime]: position,
      },
      avgPrimium: getLots([position], date).avgPrimium,
    };
  }
  // }
  // else {
  //   if (oldPositions.PE[strike]) {
  //     oldPositions.PE[strike] = {
  //       ...oldPositions.PE[strike],
  //       positionCandleWise: {
  //         ...oldPositions.PE[strike].positionCandleWise,
  //         [position.entryTime]: position,
  //       },
  //       avgPrimium: getLots(
  //         Object.values(oldPositions.PE[strike].positionCandleWise),
  //         date,
  //       ).avgPrimium,
  //     };
  //   } else {
  //     oldPositions.PE = {
  //       ...oldPositions.PE,
  //       [strike]: {
  //         isActive: true,
  //         strike: strike,
  //         contractType: contractType,
  //         expiryDate: expiryDate,
  //         positionCandleWise: {
  //           [position.entryTime]: position,
  //         },
  //         avgPrimium: getLots([position], date).avgPrimium,
  //       },
  //     };
  //   }
  // }
  return oldPositions;
};
