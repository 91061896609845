// Coockie Constants
export const SESSION_ID = 'sessionId';
export const ACTIVE_USER = 'activeUser';
export const USER_NAME = 'userName';
export const USER_ID = 'userId';
export const IS_PORTFOLIO_PAPER_AVAIL = 'isPortfolioPaperAvail';
export const NIFTY_SYMBOLS = 'niftySymbolData';
export const SCHEDULED_EVENTS = 'scheduledEvents';

// Generate bot signal constants
export const SIGNAL_ORDER_TYPE = 'signalOrderType';
export const SIGNAL_LEVERAGE = 'signalLeverage';
export const SIGNAL_POSITION_SIZING = 'signalPositionSizing';
export const SIGNAL_INSTRUMENT_TYPE = 'signalInstrumentType';

// Api Constants
export const API_FETCH_TIMEOUT = 10 * 1000; //10 seconds

// Fees
export const MARKET_FEES = 250;

export const DEFAULT_COUNTRY_CODE = '91';

// NIFTY Symbols
export const NIFTY_SYMBOL = 'NIFTY_SPACE_50';
export const BANK_NIFTY_SYMBOL = 'NIFTY_SPACE_BANK';
export const FIN_NIFTY_SYMBOL = 'NIFTY_SPACE_FIN_SPACE_SERVICE';
export const MID_CAP_NIFTY_SYMBOL = 'MIDCPNIFTY';
export const SENSEX = 'SENSEX';
export const BANKEX = 'BANKEX';
export const CRUDEOIL = 'CRUDEOIL';
export const QQQ = 'QQQ';
export const SPY = 'SPY';
export const SPX = 'SPX++SPXW';
export const XSP = 'XSP';
// Add Broker
export const ADD_BROKER_REF = 'addBrokerRef';
export const CONVERT_MARKET_TO_USA = [
  QQQ,
  SPX,
  SPY,
  XSP,
  'IWM',
  'DIA',
  'NVDA',
  'TSLA',
  'AAPL',
  'SMCI',
  'GOOG',
  'PLTR',
  'MU',
  'GLD',
  'COIN',
  'AMAT',
  'SBUX',
  'DELL',
  'WFC',
  'C',
  'GS',
  'UBER',
  'MRK',
  'PYPL',
  'ARM',
  'SLV',
  'MSFT',
  'OIH',
  'XLE',
  'VXX',
  'UNG',
  'NFLX',
  'META',
  'AMD',
  'TLT',
  'JPM',
  'AMZN',
  'ADBE',
  'ZM',
  'MARA',
  'RIOT',
  'INTC',
  'NEM',
  'GOLD',
  'SLB',
  'COP',
  'XOM',
];
// Platform Cookie
export const PLATFORM = 'platform';
export const APP_VERSION = 'app_version';
