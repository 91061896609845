import {
  memo,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import {
  Box,
  Grid,
  Typography,
  Alert,
  // Tooltip,
  // InputAdornment,
} from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { SbStrategy, UserBot } from 'app/components/Bots/StrategyBuilder/types';
import {
  formatNumber,
  formatStringToDateString,
  getLastFridayDate,
  getStartEndDate,
  toTitleCase,
} from 'utils/GenericFunctions';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { useTranslation } from 'react-i18next';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import PlanUpgradeButton from '../BotDetail/PlanUpgradeButton';
import { CAPABILITIES } from 'types/Capability';
import { CapabilityFlag } from 'app/components/Common/CapabilityFlag';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import UserBackTestingInfo from './UserBackTestingInfo';
import UserBacktestLoader from './UserBacktestLoader';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { MessageAlerts } from 'app/components/Common/MessageAlerts';

// Services
import useGetProfile from 'services/Menu/useGetProfile';
import useEditUserBot from 'services/Bots/useEditUserBot';
import useGetStatisticsMutate from 'services/Statistics/useGetStatisticsMutate';
import {
  BrokerFormField,
  MessageAlertErrors,
  SaveUserBotResult,
} from 'types/ApiServicesTypes';
import BacktestingQueueTable from './BacktestingQueueTable';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import FormControlItems from 'app/components/Menu/FormControlItems';
import { merge } from 'lodash';
import { ToastContext } from 'app/components/Context/ToastContext';
import CreditEstimation from 'app/components/Common/CreditEstimation';
import { UserPlanType } from 'types/User';
import { Link as RouterLink } from 'react-router-dom';
import UserBacktestingFormGrid from './UserBacktestingFormGrid';
import useGetBotType from 'services/Bots/useGetBotType';
import BotDeployModal from '../BotDetail/BotDeployModal';
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import BotStopModal from '../BotDetail/BotStopModal';
import BotPaperTestModal from '../BotDetail/BotPaperTestModal';
import useGetSiteInfo from 'services/common/useGetSiteInfo';

let myInterval: NodeJS.Timeout;
let isCompUnMount = false;
let alertTimeoutVar: NodeJS.Timeout;
// const minDate = moment().subtract(5, 'years').toDate();
// const maxDate = moment().subtract(1, 'days').toDate();

const UserBacktestingForm = ({
  botId,
  subId,
  userBotDetails,
}: {
  botId: number;
  subId: number;
  userBotDetails: UserBot;
}) => {
  const breadcrumb = useBreadcrumbManager();
  const { t } = useTranslation();
  const { showAlert } = useContext(ToastContext);
  const isMdUp = useGetMediaQueryUp('md');
  const { data: siteInfo } = useGetSiteInfo();
  const [alertMessage, setAlertMessage] = useState('');
  const [isStartBacktesting, setIsStartBacktesting] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<MessageAlertErrors[]>([]);
  const [dropDownvalue, setDropDownValue] = useState('Custom');
  const { data: profileDetails, isLoading } = useGetProfile();
  const filteredSbstrategy =
    userBotDetails &&
    userBotDetails.sb_strategy &&
    // Object.entries(userBotDetails.sb_strategy)
    //   .filter(([key]) => key.startsWith('custom'))
    //   .reduce((obj, [key, value]: [string, SbStrategy]) => {
    //     obj[key] = value;
    //     return obj;
    //   }, {});
    Object.keys(userBotDetails.sb_strategy).filter(key =>
      key.startsWith('custom'),
    );
  const mutationStatus = useGetStatisticsMutate(
    `bots/${
      userBotDetails.strategy_id ? userBotDetails.strategy_id : 0
    }/backtest`,
  );

  const isStrategyParameterForm =
    useIsFeatureFlag([FEATURES.IS_STRATEGY_PARAMETER_FORM]) &&
    filteredSbstrategy &&
    filteredSbstrategy.length > 0;
  // userBotDetails.sb_strategy.custom_param_form.length > 0;
  const isBrokerageFees = useIsFeatureFlag([FEATURES.IS_BROKERAGE_FEES]);
  const isPaperTrading = useIsFeatureFlag([FEATURES.IS_PAPER_TRADING]);

  const customParamInitialValues = useMemo(() => {
    if (userBotDetails.sb_strategy === undefined) return {};
    const fields = Object.entries(userBotDetails.sb_strategy)
      .filter(([key]) => key.startsWith('custom'))
      .reduce((obj, [key, value]: [string, SbStrategy]) => {
        obj[key] = value;
        return obj;
      }, {});
    const allowedKeys =
      (fields &&
        Object.values(fields).map(value =>
          (value as BrokerFormField[]).map(item => item.key),
        )) ||
      [];
    return Object.keys(userBotDetails.sb_strategy).reduce((acc, crr) => {
      if (allowedKeys.flat().includes(crr.split('.')[0]))
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        acc[crr] = userBotDetails.sb_strategy![crr];
      return acc;
    }, {} as Record<string, string>);
  }, [userBotDetails.sb_strategy]);
  const isAddedExternalSignal =
    userBotDetails.sb_strategy?.type === 'option' &&
    Array.isArray(userBotDetails.sb_strategy?.sb_opt_strategy.strategy_sets) &&
    userBotDetails.sb_strategy?.sb_opt_strategy.strategy_sets.some(
      set => set && set['external_signal'] && set['external_signal']['enable'],
    );

  const editUserBotSuccessCallback = (res?: SaveUserBotResult) => {
    if (res && res.queued === undefined) {
      showAlert!('Parameters updated', 'success');
      return;
    }
    if (res && res.queued) {
      setAlertMessage(t('user_backtesting.backtesting_added_to_queue'));
      alertTimeoutVar = setTimeout(() => {
        setAlertMessage('');
      }, 7000);
      // showAlert!(t('user_backtesting.backtesting_added_to_queue'), 'success');
      // breadcrumb.push(
      //   `/bots/${userBotDetails.strategy_id}/backtest/${subId}/${userBotDetails.name}`,
      //   PAGES.BOT_BACK_TESTING_FORM,
      //   `/bot/${botId}/backtesting/${subId ? subId : ''}`,
      //   t('buttons.backtesting'),
      // );
      return;
    }

    //For avoid call after component unmount
    if (!isCompUnMount) {
      setIsStartBacktesting(true);
      setErrorMessages([]);

      myInterval = setInterval(() => {
        mutationStatus.mutate(undefined, {
          onSuccess: resSuccess => {
            if (
              resSuccess.result_ready &&
              resSuccess.errors &&
              resSuccess.errors.length
            ) {
              clearInterval(myInterval);
              setIsStartBacktesting(false);
              setErrorMessages(resSuccess.errors);
              mutation.reset();
              mutationStatus.reset();
            } else if (resSuccess.result_ready) {
              clearInterval(myInterval);
              breadcrumb.push(
                `/bots/${userBotDetails.strategy_id}/backtest/${subId}/${userBotDetails.name}`,
                PAGES.BOT_BACK_TESTING_FORM,
                `/bot/${botId}/backtesting/${subId ? subId : ''}`,
                t('buttons.backtesting'),
              );
            }
          },
        });
      }, 5 * 1000); // 5 seconds
    }
  };
  const [openStopModal, setOpenStopModal] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [message, setMessage] = useState<string>('');
  const [skipTerms, setSkipTerms] = useState<boolean>(false);
  const { data: botDetails /* refetch */ } = useGetBotDetails(
    userBotDetails.strategy_id ? userBotDetails.strategy_id : 0,
    subId,
  );

  const [openDeployModal, setOpenDeployModal] = useState<boolean>(false);
  const [openPaperModal, setOpenPaperModal] = useState<boolean>(false);

  const handleDeployModalOpen = () => {
    setMessage('');
    setOpenDeployModal(true);
  };
  const handleDeployModalClose = (isSuccess: boolean, message?: string) => {
    // if (isSuccess) void refetch();
    if (message) setMessage(message);
    setOpenDeployModal(false);
    setSkipTerms(false);
  };
  const handleStopModalOpen = useCallback(() => {
    setMessage('');
    setOpenStopModal(true);
  }, [setOpenStopModal, setMessage]);
  const handleStopModalClose = useCallback(
    (isSuccess: boolean, message?: string) => {
      // if (isSuccess) ;
      if (message) setMessage(message);
      setOpenStopModal(false);
    },
    [setOpenStopModal, setMessage],
  );
  const handlePaperModalOpen = () => {
    setMessage('');
    setOpenPaperModal(true);
  };
  const handlePaperModalClose = (isSuccess: boolean, message?: string) => {
    // if (isSuccess) void refetch();
    if (message) setMessage(message);
    setOpenPaperModal(false);
  };
  useEffect(() => {
    //For manage and check that component is unmount or not
    isCompUnMount = false;
    return () => {
      isCompUnMount = true;
      if (myInterval) clearInterval(myInterval);
    };
  }, []);

  const mutation = useEditUserBot(botId, editUserBotSuccessCallback);
  const userPlan = profileDetails ? profileDetails.user_plan : null;
  const backtestingCreds =
    userPlan && userPlan.capabilities
      ? userPlan.capabilities.filter(x => x.key === 'backtesting')[0]
      : null;
  const isLivePaperInBacktest = useIsFeatureFlag([
    FEATURES.IS_LIVE_PAPER_IN_BACKTEST,
  ]);
  const modifiedBacktestingNote =
    siteInfo &&
    siteInfo.notes_backtesting_option &&
    siteInfo.notes_backtesting_option.replace(
      /<time>.*?<\/time>/g,
      `<time>${getLastFridayDate().format('Do MMM YYYY')}</time>`,
    );
  const isOptionType = userBotDetails.sb_strategy?.type === 'option';
  const { data: botType } = useGetBotType(subId);
  const handleSaveAndBacktest = useCallback(
    (
      values: {
        duration: string;
        slippage: number;
        trading_start_time?: string | undefined;
        trading_stop_time?: string | undefined;
        resolution?: string | undefined;
        initial_capital: string;
        start_date: string;
        end_date: string;
        brokerage_fees?: string;
        per_order_fees?: number;
      },
      isBacktest: boolean,
      isAddedExternalSignal?: boolean,
    ) => {
      if (!isAddedExternalSignal) {
        const objReq = { ...userBotDetails };
        const { start_date, end_date } = getStartEndDate(values.duration);
        if (!objReq.sb_strategy) return;
        objReq.sb_strategy = merge({ ...objReq.sb_strategy }, { ...values });
        delete objReq.sb_strategy.duration;
        objReq.sb_strategy.initial_capital = values.initial_capital;
        objReq.sb_strategy.start_date = formatStringToDateString(
          values.duration == 'Custom' ? values.start_date : start_date,
          'YYYY-MM-DD',
          'DD/MM/YYYY',
        );
        objReq.sb_strategy.end_date = formatStringToDateString(
          values.duration == 'Custom' ? values.end_date : end_date,
          'YYYY-MM-DD',
          'DD/MM/YYYY',
        );
        if (!isOptionType && objReq.sb_strategy) {
          objReq.sb_strategy.trading_start_time = values.trading_start_time!;
          objReq.sb_strategy.trading_stop_time = values.trading_stop_time!;
          objReq.sb_strategy.resolution = values.resolution!;
        }
        objReq.is_backtest = isBacktest;
        objReq.sb_strategy.slippage = values.slippage;
        if (isBrokerageFees) {
          objReq.sb_strategy.brokerage_fees = values.brokerage_fees;
          objReq.sb_strategy.per_order_fees = values.per_order_fees;
        }

        mutation.mutate(objReq, {
          onSuccess: res => {
            if (res.error || res.queued) {
              setIsStartBacktesting(false);
              mutation.reset();
            } else if (res.queued === false) {
              setIsStartBacktesting(true);
            }
          },
          onError: () => {
            setIsStartBacktesting(false);
            mutation.reset();
          },
        });
      } else {
        showAlert!(
          'Backtesting not available for added external signal bot',
          'warning',
        );
      }
    },
    [isOptionType, mutation, userBotDetails, isBrokerageFees, showAlert],
  );

  if (!userBotDetails.sb_strategy) return <></>;
  const maxEndDateStr = getLastFridayDate().format('YYYY-MM-DD');
  const maxEndDate = new Date(maxEndDateStr);
  let strStartDate = formatStringToDateString(
    userBotDetails.sb_strategy.start_date,
    'DD/MM/YYYY',
    'YYYY-MM-DD',
  );
  if (isOptionType && new Date(strStartDate) >= maxEndDate)
    strStartDate = maxEndDateStr;

  let strEndDate = formatStringToDateString(
    userBotDetails.sb_strategy.end_date,
    'DD/MM/YYYY',
    'YYYY-MM-DD',
  );
  if (isOptionType && new Date(strEndDate) >= maxEndDate)
    strEndDate = maxEndDateStr;

  const isPayAsYouGoPlan =
    (profileDetails && profileDetails.user_plan?.plan.plan_type) ===
    UserPlanType.PAY_AS_YOU_GO;
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          initial_capital:
            userBotDetails.sb_strategy.initial_capital ||
            userBotDetails.sb_strategy.initial_capital === '0'
              ? userBotDetails.sb_strategy.initial_capital
              : '100000',
          // start_date: userBotDetails.sb_strategy.start_date,
          // end_date: userBotDetails.sb_strategy.end_date,
          // start_date: formatStringToDateString(
          //   userBotDetails.sb_strategy.start_date,
          //   'DD/MM/YYYY',
          //   'YYYY-MM-DD',
          // ),
          // end_date: formatStringToDateString(
          //   userBotDetails.sb_strategy.end_date,
          //   'DD/MM/YYYY',
          //   'YYYY-MM-DD',
          // ),
          duration: dropDownvalue,
          start_date: strStartDate,
          end_date: strEndDate,
          ...(!isOptionType && {
            trading_start_time:
              userBotDetails.sb_strategy.trading_start_time || '09:00',
            trading_stop_time:
              userBotDetails.sb_strategy.trading_stop_time || '23:59',
            resolution: userBotDetails.sb_strategy.resolution,
          }),
          slippage: userBotDetails.sb_strategy.slippage || 0.1,
          ...(isStrategyParameterForm ? customParamInitialValues : {}),
          brokerage_fees:
            userBotDetails.sb_strategy.brokerage_fees || 'zerodha',
          per_order_fees: userBotDetails.sb_strategy.per_order_fees || 0,
        }}
        validationSchema={Yup.object().shape({
          initial_capital: Yup.number()
            .min(
              0,
              t('user_backtesting.valid_msg.valid', {
                name: t('user_backtesting.form.initial_capital'),
              }),
            )
            .required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_backtesting.form.initial_capital'),
              }),
            ),
          start_date: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_backtesting.form.start_date'),
            }),
          ),
          end_date: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_backtesting.form.end_date'),
            }),
          ),
          ...(!isOptionType && {
            trading_start_time: Yup.string().required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_backtesting.form.entry_time'),
              }),
            ),
            trading_stop_time: Yup.string().required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_backtesting.form.exit_time'),
              }),
            ),
            resolution: Yup.string().required(
              t('user_backtesting.valid_msg.req', {
                name: t('user_backtesting.form.interval'),
              }),
            ),
          }),
          slippage: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_backtesting.form.slippage'),
            }),
          ),
        })}
        onSubmit={values => {
          const objReq = { ...userBotDetails };
          const { start_date, end_date } = getStartEndDate(values.duration);
          if (!objReq.sb_strategy) return;

          objReq.sb_strategy.initial_capital = values.initial_capital;
          objReq.sb_strategy.start_date = formatStringToDateString(
            values.duration == 'Custom' ? values.start_date : start_date,
            'YYYY-MM-DD',
            'DD/MM/YYYY',
          );
          objReq.sb_strategy.end_date = formatStringToDateString(
            values.duration == 'Custom' ? values.end_date : end_date,
            'YYYY-MM-DD',
            'DD/MM/YYYY',
          );
          if (!isOptionType && objReq.sb_strategy) {
            objReq.sb_strategy.trading_start_time = values.trading_start_time!;
            objReq.sb_strategy.trading_stop_time = values.trading_stop_time!;
            objReq.sb_strategy.resolution = values.resolution!;
          }
          objReq.is_backtest = true;
          objReq.sb_strategy.slippage = values.slippage;
          if (isBrokerageFees) {
            objReq.sb_strategy.brokerage_fees =
              values.brokerage_fees.toLowerCase();
            objReq.sb_strategy.per_order_fees = Number(values.per_order_fees);
          }

          mutation.mutate(objReq, {
            onSuccess: res => {
              if (res.error || res.queued) {
                setIsStartBacktesting(false);
                mutation.reset();
              }
            },
            onError: () => {
              setIsStartBacktesting(false);
              mutation.reset();
            },
          });
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleBlur,
          touched,
          handleChange,
        }) => (
          <Box
            className="m-main-spacing"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // height: '100%',
            }}
          >
            <CircularLoader
              // open={isStartBacktesting || mutation.isLoading || isLoading}
              open={isLoading || mutation.isLoading}
            />
            {isOptionType && (
              <Alert severity="info" icon={false}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={400}
                >
                  <strong>
                    <FormattedMessage id="user_backtesting.note" />
                  </strong>
                  {/* <ul style={{ padding: '0 0.5rem !important', margin: 0 }}> */}
                  {siteInfo && siteInfo.notes_backtesting_option ? (
                    <Typography
                      component={'div'}
                      variant={'body2'}
                      sx={{ fontWeight: 400, color: 'text.secondary' }}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: String(modifiedBacktestingNote),
                      }}
                    ></Typography>
                  ) : (
                    <ul
                      style={{
                        padding: '0 0.5rem !important',
                        margin: 0,
                        paddingLeft: '1rem',
                        paddingTop: '0.25rem',
                      }}
                    >
                      <li style={{ marginBottom: '0.5rem' }}>
                        <FormattedMessage id="user_backtesting.info_initial_capital" />
                      </li>
                      <li>date:{getLastFridayDate().format('Do MMM YYYY')}</li>
                      <li>
                        <FormattedMessage id="user_backtesting.info_note2" />
                      </li>
                    </ul>
                  )}
                </Typography>
              </Alert>
            )}
            {isStartBacktesting ? (
              <UserBackTestingInfo
                currency_symbol={''}
                initial_capital={Number(values.initial_capital)}
                start_date={
                  values.duration == 'Custom'
                    ? values.start_date
                    : getStartEndDate(values.duration).start_date
                }
                end_date={
                  values.duration == 'Custom'
                    ? values.end_date
                    : getStartEndDate(values.duration).end_date
                }
                {...(!isOptionType && {
                  trading_start_time: values.trading_start_time,
                  trading_stop_time: values.trading_stop_time,
                  resolution: values.resolution,
                })}
              />
            ) : (
              <Box sx={{ my: 2, flex: 1, mt: 1 }}>
                {/* <LocalizationProvider dateAdapter={AdapterMoment}> */}
                <Box sx={{ mb: 3 }}>
                  <MessageAlerts errors={errorMessages} />
                </Box>
                <Box mb={3}>
                  <Typography variant="body2" color="text.secondary" pb={2}>
                    <FormattedMessage id="user_backtesting.backtesting_parameters" />
                  </Typography>
                  <ItemSeparator variant="2px" />
                </Box>
                <UserBacktestingFormGrid
                  errors={errors}
                  touched={touched}
                  values={values}
                  isOptionType={isOptionType}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  setDropDownValue={setDropDownValue}
                />
                {/* </LocalizationProvider> */}
              </Box>
            )}
            {/* <Alert severity="info" sx={{ mb: 1 }}>
              <Typography
                variant="body2"
                color="text.secondary"
                fontWeight={400}
              >
                <strong>
                  <FormattedMessage id="user_backtesting.note" />
                </strong>
                <FormattedMessage id="user_backtesting.info_note" />
              </Typography>
            </Alert> */}
            <FeatureFlag
              features={[FEATURES.IS_STRATEGY_PARAMETER_FORM]}
              fallback={<></>}
            >
              {userBotDetails.sb_strategy !== undefined &&
                userBotDetails.sb_strategy &&
                Object.entries(userBotDetails.sb_strategy)
                  .filter(([key]) => key.startsWith('custom'))
                  .map(([key, value]: [string, BrokerFormField[]]) => (
                    <Box my={2} key={key}>
                      <Box mb={3}>
                        {/* <Typography> */}
                        {/* <FormattedMessage id="user_backtesting.custom_parameters" /> */}
                        <Box display={'flex'} columnGap={0.5}>
                          {key !== 'custom_param_form' ? (
                            key
                              .replace('custom_', '')
                              .split('_')
                              .map((item, index) => (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  pb={2}
                                  key={index}
                                >
                                  {toTitleCase(item)}{' '}
                                </Typography>
                              ))
                          ) : (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              pb={2}
                            >
                              <FormattedMessage id="user_backtesting.custom_parameters" />
                            </Typography>
                          )}
                        </Box>
                        {/* </Typography> */}
                        <ItemSeparator variant="2px" />
                      </Box>
                      <Grid container spacing={2}>
                        <FormControlItems
                          maxItemInRow={3}
                          fields={value}
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched}
                          values={values}
                        />
                      </Grid>
                    </Box>
                  ))}
            </FeatureFlag>
            {!isPayAsYouGoPlan && (
              <CapabilityFlag
                capabilities={[CAPABILITIES.BACK_TESTING]}
                fallback={() => (
                  <Box textAlign={'center'} mb={2}>
                    <MuiButton
                      fullWidth={!isMdUp}
                      variant="contained"
                      disabled={
                        mutation.isLoading &&
                        mutation.variables?.is_backtest === false
                      }
                      sx={{ minWidth: '168px' }}
                      onClick={() =>
                        handleSaveAndBacktest(
                          values,
                          false,
                          // isAddedExternalSignal,
                        )
                      }
                    >
                      <FormattedMessage id="buttons.save" />
                    </MuiButton>
                  </Box>
                )}
              >
                {() => <></>}
              </CapabilityFlag>
            )}
            <Box sx={{ my: 2 }}>
              <Box display="flex" justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  <FormattedMessage
                    id={
                      isPayAsYouGoPlan
                        ? 'menu.credit_balance'
                        : 'user_backtesting.backtesting_credits'
                    }
                  />
                </Typography>
                <Typography variant="body3" fontWeight={400} component="span">
                  <Typography
                    variant="body2"
                    component="span"
                    color={
                      isPayAsYouGoPlan &&
                      profileDetails &&
                      profileDetails.wallet_balance < 0
                        ? 'error.main'
                        : 'text.primary'
                    }
                  >
                    {isPayAsYouGoPlan
                      ? profileDetails && profileDetails.wallet_balance
                        ? `${
                            profileDetails.wallet_balance < 0 ? '-' : ''
                          }${formatNumber(profileDetails.wallet_balance)}`
                        : 0
                      : backtestingCreds
                      ? backtestingCreds.balance
                      : 0}
                  </Typography>{' '}
                  {!isPayAsYouGoPlan && (
                    <FormattedMessage id="user_backtesting.availables" />
                  )}
                </Typography>
              </Box>
              {/* <Typography
                variant="body3"
                fontWeight={400}
                sx={{ mb: 2 }}
                component="div"
              >
                <FormattedMessage id="user_backtesting.backtesting_added_to_queue" />
              </Typography> */}
              <ItemSeparator variant="2px" />
            </Box>
            <Box
              sx={{
                mb: 0,
                gap: 1,
                textAlign: 'center',
                display: { xs: 'block', md: 'flex' },
                justifyContent: 'center',
                flexDirection: 'rowr-everse',
              }}
            >
              {isPayAsYouGoPlan ? (
                <CreditEstimation
                  action="go_backtest"
                  data={{
                    start_date:
                      values.duration !== 'Custom'
                        ? getStartEndDate(values.duration).start_date
                        : values.start_date,
                    end_date:
                      values.duration !== 'Custom'
                        ? getStartEndDate(values.duration).end_date
                        : values.end_date,
                  }}
                >
                  {({ isSufficientCredit, requiredCredits }) => (
                    <Box>
                      {isSufficientCredit ? (
                        <Typography variant="body2" fontWeight={400} mb={1}>
                          <Typography variant="body2" component={'span'}>
                            {requiredCredits}
                          </Typography>{' '}
                          credits will be used for this backtesting
                        </Typography>
                      ) : (
                        <Alert severity="error" sx={{ mb: 1 }}>
                          <Typography variant="body2">
                            You do not have sufficient credits to do
                            backtesting. Please add credit from{' '}
                            <RouterLink
                              to="/menu/wallet"
                              style={{ textDecoration: 'underline' }}
                            >
                              <FormattedMessage id="bot_deploy_modal.here" />
                            </RouterLink>{' '}
                            and try again. Credits required for backtesting:
                            <Typography
                              variant="body2"
                              component={'span'}
                              fontWeight={600}
                              ml={1}
                            >
                              {requiredCredits}
                            </Typography>
                          </Typography>
                        </Alert>
                      )}
                      <Box
                        display={'flex'}
                        flexDirection={isMdUp ? 'row' : 'column'}
                        gap={Number(isMdUp)}
                        justifyContent={isMdUp ? 'center' : 'normal'}
                        alignItems={isMdUp ? 'center' : 'normal'}
                      >
                        <Box>
                          <MuiButton
                            fullWidth={!isMdUp}
                            variant="contained"
                            sx={{ mb: { xs: 1, md: 0 } }}
                            disabled={
                              mutation.isLoading &&
                              mutation.variables?.is_backtest === false
                            }
                            onClick={() =>
                              handleSaveAndBacktest(
                                values,
                                false,
                                // isAddedExternalSignal,
                              )
                            }
                          >
                            <FormattedMessage id="buttons.save" />
                          </MuiButton>
                          <MuiButton
                            variant="contained"
                            color="primary"
                            fullWidth={!isMdUp}
                            disabled={
                              isStartBacktesting ||
                              !isSufficientCredit ||
                              mutation.isLoading
                            }
                            onClick={() =>
                              handleSaveAndBacktest(
                                values,
                                true,
                                isAddedExternalSignal,
                              )
                            }
                            sx={{
                              ml: { xs: 0, md: 1 },
                              mb: { xs: 1, md: 0 },
                            }}
                          >
                            <FormattedMessage id="buttons.save_and_backtest" />
                          </MuiButton>
                        </Box>
                        {isLivePaperInBacktest && (
                          <Box>
                            {botType?.type !== 'live' && (
                              <MuiButton
                                fullWidth={!isMdUp}
                                variant="outlined"
                                color={
                                  botType?.type === 'paper'
                                    ? 'error'
                                    : 'primary'
                                }
                                sx={{ mb: { xs: 1, md: 0 } }}
                                // disabled={
                                //   mutation.isLoading &&
                                //   mutation.variables?.is_backtest === false
                                // }
                                onClick={() =>
                                  botType?.type === 'paper'
                                    ? handleStopModalOpen()
                                    : handlePaperModalOpen()
                                }
                              >
                                {botType?.type === 'paper' ? (
                                  <FormattedMessage id="buttons.stop_paper" />
                                ) : (
                                  <FormattedMessage id="buttons.paper" />
                                )}
                              </MuiButton>
                            )}
                            <MuiButton
                              variant={
                                botType?.type === 'live'
                                  ? 'outlined'
                                  : 'contained'
                              }
                              color={
                                botType?.type === 'live' ? 'error' : 'primary'
                              }
                              fullWidth={!isMdUp}
                              // disabled={
                              //   isStartBacktesting ||
                              //   !isSufficientCredit ||
                              //   mutation.isLoading
                              // }
                              onClick={() =>
                                botType?.type == 'live'
                                  ? handleStopModalOpen()
                                  : handleDeployModalOpen()
                              }
                              sx={{
                                ml: { xs: 0, md: 1 },
                                mb: { xs: 2, md: 0 },
                              }}
                            >
                              {botType?.type == 'live' ? (
                                <FormattedMessage id="buttons.stop_live" />
                              ) : (
                                <FormattedMessage id="buttons.deploy" />
                              )}
                            </MuiButton>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </CreditEstimation>
              ) : (
                <CapabilityFlag
                  capabilities={[CAPABILITIES.BACK_TESTING]}
                  fallback={() => (
                    <FeatureFlag
                      features={[FEATURES.IS_PLANS]}
                      fallback={
                        <Box width={'100%'} textAlign={'left'}>
                          <Alert severity="error">
                            <Typography variant="body2">
                              Backtesting limit reached
                            </Typography>
                            <Typography variant="body2" fontWeight={400}>
                              You have reached the maximum capacity for{' '}
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                component={'span'}
                              >
                                Backtesting
                              </Typography>
                              . Please contact your Administrator for more
                              details.
                            </Typography>
                          </Alert>
                        </Box>
                      }
                    >
                      <PlanUpgradeButton
                        capability={CAPABILITIES.BACK_TESTING}
                      />
                    </FeatureFlag>
                  )}
                >
                  {() => (
                    <Box
                      display={'flex'}
                      flexDirection={isMdUp ? 'row' : 'column'}
                      gap={Number(isMdUp)}
                      justifyContent={isMdUp ? 'center' : 'normal'}
                      alignItems={isMdUp ? 'center' : 'normal'}
                    >
                      <Box display={'flex'} gap={1}>
                        <MuiButton
                          fullWidth={!isMdUp}
                          variant="contained"
                          sx={{ mb: { xs: 1, md: 0 } }}
                          disabled={
                            mutation.isLoading &&
                            mutation.variables?.is_backtest === false
                          }
                          onClick={() => {
                            handleSaveAndBacktest(
                              values,
                              false,
                              // isAddedExternalSignal,
                            );
                          }}
                        >
                          <FormattedMessage id="buttons.save" />
                        </MuiButton>
                        <MuiButton
                          variant="contained"
                          color="primary"
                          fullWidth={!isMdUp}
                          disabled={isStartBacktesting || mutation.isLoading}
                          onClick={() =>
                            handleSaveAndBacktest(
                              values,
                              true,
                              isAddedExternalSignal,
                            )
                          }
                          sx={{
                            mb: { xs: 2, md: 0 },
                          }}
                        >
                          <FormattedMessage id="buttons.save_and_backtest" />
                        </MuiButton>
                      </Box>
                      {isLivePaperInBacktest && (
                        <Box>
                          {botType?.type !== 'live' && isPaperTrading && (
                            <MuiButton
                              fullWidth={!isMdUp}
                              variant="outlined"
                              color={
                                botType?.type === 'paper' ? 'error' : 'primary'
                              }
                              sx={{ mb: { xs: 1, md: 0 } }}
                              // disabled={
                              //   mutation.isLoading &&
                              //   mutation.variables?.is_backtest === false
                              // }
                              onClick={() =>
                                botType?.type === 'paper'
                                  ? handleStopModalOpen()
                                  : handlePaperModalOpen()
                              }
                            >
                              {botType?.type === 'paper' ? (
                                <FormattedMessage id="buttons.stop_paper" />
                              ) : (
                                <FormattedMessage id="buttons.paper" />
                              )}
                            </MuiButton>
                          )}
                          <MuiButton
                            variant={
                              botType?.type === 'live'
                                ? 'outlined'
                                : 'contained'
                            }
                            color={
                              botType?.type === 'live' ? 'error' : 'primary'
                            }
                            fullWidth={!isMdUp}
                            // disabled={
                            //   isStartBacktesting ||
                            //   !isSufficientCredit ||
                            //   mutation.isLoading
                            // }
                            onClick={() =>
                              botType?.type == 'live'
                                ? handleStopModalOpen()
                                : handleDeployModalOpen()
                            }
                            sx={{
                              ml: { xs: 0, md: 1 },
                              mb: { xs: 2, md: 0 },
                            }}
                          >
                            {botType?.type == 'live' ? (
                              <FormattedMessage id="buttons.stop_live" />
                            ) : (
                              <FormattedMessage id="buttons.deploy" />
                            )}
                          </MuiButton>
                        </Box>
                      )}
                    </Box>
                  )}
                </CapabilityFlag>
              )}

              {/* <MuiButton
                variant="contained"
                color="secondary"
                fullWidth={!isMdUp}
                sx={{
                  mr: { xs: 0, md: 2 },
                }}
                component={RouterLink}
                to={
                  userBotDetails.result_ready
                    ? `/bots/${userBotDetails.strategy_id}/backtest/${userBotDetails.name}`
                    : `/bots/${userBotDetails.strategy_id}/details/Bots`
                }
              >
                <FormattedMessage id="buttons.cancel" />
              </MuiButton> */}
            </Box>
            {alertMessage && (
              <Alert
                severity="success"
                sx={{ mt: 2 }}
                onClose={() => {
                  setAlertMessage('');
                  clearTimeout(alertTimeoutVar);
                }}
              >
                <Typography variant="body2">{alertMessage}</Typography>
              </Alert>
            )}
            {isStartBacktesting && (
              <Box sx={{ mt: 2, mb: 1 }}>
                <UserBacktestLoader botId={String(botId)} />
              </Box>
            )}
          </Box>
        )}
      </Formik>
      {!isStartBacktesting && <BacktestingQueueTable botId={String(botId)} />}
      <BotDeployModal
        open={openDeployModal}
        handleClose={handleDeployModalClose}
        // id={parseInt(id)}
        exchanges={botDetails?.exchanges}
        botName={botDetails ? botDetails.name : ''}
        botId={userBotDetails ? (userBotDetails.strategy_id as number) : 0}
        openFrom="bots"
        skipTerms={skipTerms}
      />
      <BotStopModal
        open={openStopModal}
        bid={userBotDetails ? (userBotDetails.strategy_id as number) : 0}
        sid={subId}
        botName={botDetails ? botDetails.name : ''}
        handleClose={handleStopModalClose}
        openFrom="bots"
        category={
          botType?.type === 'live'
            ? 'active'
            : botType?.type === 'paper'
            ? 'paper'
            : ''
        }
      />
      <BotPaperTestModal
        open={openPaperModal}
        id={userBotDetails ? (userBotDetails.strategy_id as number) : 0}
        sid={subId}
        botName={botDetails ? botDetails.name : ''}
        handleClose={handlePaperModalClose}
        openFrom="bots"
      />
    </>
  );
};

export default memo(UserBacktestingForm);
