import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Typography,
} from '@mui/material';
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  memo,
  useEffect,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  OptionSetFieldsGroupWise,
  OptionLegFieldsGroupWise,
  OptionFutureLegFields,
  OptionFutureLegFieldsGroupWise,
} from './FieldsData';
import {
  CopiedStrategyParam,
  FieldParam,
  FieldParamValue,
  OptionLegParam,
  OptionSetParam,
} from './types';
import {
  createEmptyOptionLeg,
  createIronCondorSets,
  createIronFlySets,
  createSpreadSets,
  createStraddleSets,
  createStrangleSets,
  generateOptionValues,
  transformLegsOnChange,
} from './Utils/utils';
import { OptionLeg } from './OptionLeg';
import { OptionLegFields } from './FieldsData';
import { cloneDeep } from 'lodash';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  FeatureFlag,
  useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { v4 } from 'uuid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MuiBuilderButton from 'app/design/uiComponents/MuiBuilderButton';
import OptionSetTemplates from './OptionSetTemplates';
import {
  OptionSetIronCondorValueType,
  OptionSetIronFlyValueType,
  OptionSetSpreadValueType,
  OptionSetStraddleValueType,
  OptionSetStrangleValueType,
  OptionSetTeplateValueType,
} from './OptionSetTemplates/types';
import { FIN_NIFTY_SYMBOL } from 'constants/common';
import { FieldDataConditions } from '../Strategy/fields/types';
import { useTranslation } from 'react-i18next';
import IndicatorConditonsModal from 'app/components/Bots/Strategy/IndicatorConditionsModal';
import MuiButton from 'app/design/uiComponents/MuiButton';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { PositionCondition } from 'app/components/Bots/Strategy/PositionDirectionConditions';
import { StrategyMode } from '../Strategy/utils/strategy-data';
import { useParams } from 'react-router-dom';
import ExternalSignalModal from 'app/components/Bots/StrategyBuilder/ExternalSignal/ExternalSignalModal';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';
import TradingView from 'assets/images/signalguide/tradingView_logo.png';
import { ArrowForward, ExpandMore } from '@mui/icons-material';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import useGetOptionBuilderHelpData from 'services/Bots/useGetOptionBuilderHelpData';
import { callingBuilderType } from '../Strategy/fields/fieldsData';
import BetaChip from '../BetaChip';

interface Props {
  optionSet: OptionSetParam;
  initialFormikState: FieldDataConditions;
  strategyIndex: number;
  onChangeSet: (index: number, set: OptionSetParam) => void;
  onDeleteSet: (index: number) => void;
  PlValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  handleCopyStrategy: (strategyName: string, strategyData) => void;
  handlePasteStrategy: (
    strategyName: string,
    optionSetIndex?: number,
    optionLegIndex?: number,
    optionHedgeIndex?: number,
  ) => void;
  copiedOptionStrategy: CopiedStrategyParam;
  isShowOptionSetValidation?: boolean;
  isSetNameExist: (name: string, index: number) => boolean;
  formikState: (value: FieldDataConditions) => void;
  handleToggleSet: (index: number) => void;

  // getPositionValue?: (value: string) => void;
}

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '56px',
          width: '220px',
          fontSize: '16px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          top: '7px',
        },
        shrink: {
          top: '0px',
        },
      },
    },
  },
});

const OptionSet = (props: Props) => {
  const {
    optionSet,
    strategyIndex,
    onChangeSet,
    onDeleteSet,
    PlValidation,
    isReadOnly,
    handleCopyStrategy,
    handlePasteStrategy,
    copiedOptionStrategy,
    isShowOptionSetValidation,
    isSetNameExist,
    handleToggleSet,
  } = props;

  const validationDataRef = useRef({});
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const isSTBTOptionAvailable = useIsFeatureFlag([FEATURES.IS_STBT_OPTION]);
  const [indicatorModal, setIndicatorModal] = useState<boolean>(false);
  const [externalSignalModal, setExternalSignalModal] = useState(false);
  const { data: helpData } = useGetOptionBuilderHelpData();
  const tredingViewGuideText = 'opt_set_treding_view_guid';
  const isReEntryReExection = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER_REENTRY_REEXECUTION,
  ]);
  const isOptionBotBuilderPositional = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER_POSITIONAL,
  ]);
  // const isOptionBotBuilderMidCapSymbol = useIsFeatureFlag([
  //   FEATURES.IS_OPTION_BOTBUILDER_MID_CAP,
  // ]);
  const isOptionBotBuilderBankexSymbol = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER_BANKEX_SYMBOL,
  ]);
  const isOptionBotBuilderCrudeOilSymbol = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER_CRUDE_OIL,
  ]);
  const isOptionEnterIfMissed = useIsFeatureFlag([
    FEATURES.IS_OPTION_ENTER_IF_MISSED,
  ]);
  const isOptionBotBuilderStockSymbols = useIsFeatureFlag([
    FEATURES.IS_OPTION_BOTBUILDER_STOCK_SYMBOLS,
  ]);
  const isUSSymbolForOptionBuilder = useIsFeatureFlag([
    FEATURES.IS_US_SYMBOL_FOR_OPTION_BUILDER,
  ]);
  const isShowUSAStockSymbols = useIsFeatureFlag([
    FEATURES.IS_SHOW_US_STOCK_SYMBOLS,
  ]);
  const isMomentumEnabledAtSet = useIsFeatureFlag([
    FEATURES.IS_MOMENTUM_ENABLED_AT_SET,
  ]);
  const isLockProfit = useIsFeatureFlag([FEATURES.IS_LOCK_PROFIT]);
  const isTrailLockProfit = useIsFeatureFlag([FEATURES.IS_TRAIL_LOCK_PROFIT]);
  const isMaxEntryTime = useIsFeatureFlag([FEATURES.IS_MAX_ENTRY_TIME]);
  const [isEditName, setEditName] = useState(false);
  const [showMenu, setShowMenu] = useState<null | HTMLElement>(null);
  const [showEsTooltip, setShowEsTooltip] = useState<null | HTMLElement>(null);
  const { data: siteinfo } = useGetSiteInfo();
  const [indicators, setIndicators] = useState<boolean>(false);
  const conditions = optionSet?.indicator_extras as FieldDataConditions;

  const [formikData, setFormikData] =
    useState<Partial<FieldDataConditions>>(conditions);
  delete formikData.entry_on_underlying;
  delete formikData.exit_on_underlying;
  delete formikData.isConditionValid;
  const isConditionInOption = useIsFeatureFlag([
    FEATURES.IS_CONDITION_IN_OPTION,
  ]);
  const isExternalSignal = useIsFeatureFlag([FEATURES.IS_EXTERNAL_SIGNAL]);
  const open = Boolean(showMenu);
  const openEsPoper = Boolean(showEsTooltip);
  const isisOptionFutureLeg = useIsFeatureFlag([FEATURES.IS_OPTION_FUTURE_LEG]);
  const isOptionEquityLeg = useIsFeatureFlag([FEATURES.IS_OPTION_EQUITY_LEG]);
  const isContinuousSet = useIsFeatureFlag([FEATURES.IS_CONTINUOUS_SET]);

  // useEffect(() => {
  //   if (""
  //     validationDataRef.current &&
  //     Object.keys(validationDataRef.current).length > 0
  //   ) {
  //     let validationStatus = false;
  //     if (
  //       Object.keys(validationDataRef.current).filter(e => e.includes('lag'))
  //         .length > 0 &&
  //       !Object.values(validationDataRef.current).includes(false)
  //     ) {
  //       validationStatus = true;
  //     }
  //     PlValidation(`option${strategyIndex}`, validationStatus);
  //   } else {
  //     PlValidation(`option${strategyIndex}`, false);
  //   }
  // }, [validationDataRef, PlValidation, strategyIndex]);

  const onClickAddOptionLeg = () => {
    const legs = optionSet.legs as OptionLegParam[];
    legs.push(
      createEmptyOptionLeg(
        cloneDeep(OptionLegFields),
        legs.length,
        optionSet['position_type'] as string,
      ),
    );
    const fieldDataValidation = OptionLegFieldsGroupWise.reduce((acc, crr) => {
      acc = { ...acc, [crr.parentKey]: crr.validation };
      return acc;
    }, {});
    legs[legs.length - 1].legEnabled = 'optionleg';
    legs.map((leg, legIndex) => {
      const returnVal = Object.keys(leg).map(item => {
        if (fieldDataValidation[item]) {
          if (fieldDataValidation[item].isRequired) {
            if (leg[item]) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return true;
        }
      });
      sendValidation(`lag${legIndex}`, !returnVal.includes(false));
    });
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      let validationStatus = false;
      if (
        Object.keys(validationDataRef.current).filter(e => e.includes('lag'))
          .length > 0 &&
        !Object.values(validationDataRef.current).includes(false)
      ) {
        validationStatus = true;
      }
      PlValidation(`option${strategyIndex}`, validationStatus);
    } else {
      PlValidation(`option${strategyIndex}`, false);
    }
    onChangeSet(strategyIndex, {
      ...optionSet,
      indicator_extras: formikData,
      type:
        conditions.entry_conditions.length ||
        conditions.exit_conditions.length ||
        indicators
          ? 'condition_based'
          : 'time_based',
    });
  };
  const onClickAddFutureOptionLeg = (typeOfLeg: string) => {
    const legs = optionSet.legs as OptionLegParam[];
    legs.push(
      createEmptyOptionLeg(cloneDeep(OptionFutureLegFields), legs.length),
    );
    const fieldDataValidation = OptionFutureLegFieldsGroupWise.reduce(
      (acc, crr) => {
        acc = { ...acc, [crr.parentKey]: crr.validation };
        return acc;
      },
      {},
    );
    const equityOrFuture = typeOfLeg == 'future' ? 'futureleg' : 'equityleg';
    legs[legs.length - 1].legEnabled = equityOrFuture;
    legs.map((leg, legIndex) => {
      const returnVal = Object.keys(leg).map(item => {
        if (fieldDataValidation[item]) {
          if (fieldDataValidation[item].isRequired) {
            if (leg[item]) {
              return true;
            } else {
              return false;
            }
          }
        } else {
          return true;
        }
      });
      sendValidation(`lag${legIndex}`, !returnVal.includes(false));
    });
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      let validationStatus = false;
      if (
        Object.keys(validationDataRef.current).filter(e => e.includes('lag'))
          .length > 0 &&
        !Object.values(validationDataRef.current).includes(false)
      ) {
        validationStatus = true;
      }
      PlValidation(`option${strategyIndex}`, validationStatus);
    } else {
      PlValidation(`option${strategyIndex}`, false);
    }
    onChangeSet(strategyIndex, {
      ...optionSet,
      indicator_extras: formikData,
      type:
        conditions.entry_conditions.length ||
        conditions.exit_conditions.length ||
        indicators
          ? 'condition_based'
          : 'time_based',
    });
  };
  useEffect(() => {
    callingBuilderType('option');
  }, []);

  const handleAddOptionLeg = useCallback(
    (source: string, value: OptionSetTeplateValueType) => {
      const legs = optionSet.legs as OptionLegParam[];
      switch (source) {
        case 'STRADDLE': {
          const templateData = value as OptionSetStraddleValueType;
          const newLegs = createStraddleSets(templateData, legs.length);
          legs.push(newLegs[0], newLegs[1]);
          break;
        }
        case 'STRANGLE': {
          const templateData = value as OptionSetStrangleValueType;
          const newLegs = createStrangleSets(templateData, legs.length);
          legs.push(newLegs[0], newLegs[1]);
          break;
        }
        case 'SPREAD': {
          const templateData = value as OptionSetSpreadValueType;
          const newLegs = createSpreadSets(templateData, legs.length);
          legs.push(newLegs[0], newLegs[1]);
          break;
        }
        case 'IRON_FLY': {
          const templateData = value as OptionSetIronFlyValueType;
          const newLegs = createIronFlySets(templateData, legs.length);
          legs.push(newLegs[0], newLegs[1], newLegs[2], newLegs[3]);
          break;
        }
        case 'IRON_CONDOR': {
          const templateData = value as OptionSetIronCondorValueType;
          const newLegs = createIronCondorSets(templateData, legs.length);
          legs.push(newLegs[0], newLegs[1], newLegs[2], newLegs[3]);
          break;
        }
      }

      const fieldDataValidation = OptionLegFieldsGroupWise.reduce(
        (acc, crr) => {
          acc = { ...acc, [crr.parentKey]: crr.validation };
          return acc;
        },
        {},
      );
      legs.map((leg, legIndex) => {
        const returnVal = Object.keys(leg).map(item => {
          if (fieldDataValidation[item]) {
            if (fieldDataValidation[item].isRequired) {
              if (leg[item]) {
                return true;
              } else {
                return false;
              }
            }
          } else {
            return true;
          }
        });
        sendValidation(`lag${legIndex}`, !returnVal.includes(false));
      });
      if (
        validationDataRef.current &&
        Object.keys(validationDataRef.current).length > 0
      ) {
        let validationStatus = false;
        if (
          Object.keys(validationDataRef.current).filter(e => e.includes('lag'))
            .length > 0 &&
          !Object.values(validationDataRef.current).includes(false)
        ) {
          validationStatus = true;
        }
        PlValidation(`option${strategyIndex}`, validationStatus);
      } else {
        PlValidation(`option${strategyIndex}`, false);
      }
      onChangeSet(strategyIndex, {
        ...optionSet,
        indicator_extras: formikData,
        type:
          conditions.entry_conditions.length ||
          conditions.exit_conditions.length ||
          indicators
            ? 'condition_based'
            : 'time_based',
      });
    },
    [
      PlValidation,
      onChangeSet,
      optionSet,
      strategyIndex,
      formikData,
      conditions,
      indicators,
    ],
  );

  const onChange = (key: string, value: Record<string, FieldParamValue>) => {
    optionSet[key] = value;

    if (key === 'parent_holding_type') {
      optionSet['parent_is_stbt_btst']['is_stbt_btst'] =
        value['holding_type'] === 'cnc';
      optionSet['parent_position_type']['position_type'] =
        value['holding_type'] === 'mis' ? 'intraday' : 'stbt_btst';
    } else if (key === 'parent_is_stbt_btst') {
      optionSet['parent_holding_type']['holding_type'] =
        value['is_stbt_btst'] === true ? 'cnc' : 'mis';
      optionSet['parent_position_type']['position_type'] =
        value['is_stbt_btst'] === true ? 'stbt_btst' : 'intraday';
    } else if (key === 'parent_position_type') {
      optionSet['parent_holding_type']['holding_type'] =
        value['position_type'] === 'intraday' ? 'mis' : 'cnc';
      optionSet['parent_is_stbt_btst']['is_stbt_btst'] =
        value['position_type'] !== 'intraday';
      if (value['position_type'] === 'positional') {
        optionSet['parent_days_to_run']['days_to_run'] = [
          '1',
          '2',
          '3',
          '4',
          '5',
        ];
        if (
          (
            optionSet.parent_positional_expire_on as Record<
              string,
              FieldParamValue
            >
          ).positional_expire_on === 'monthly'
        ) {
          (optionSet.legs as OptionLegParam[]) = (
            optionSet.legs as OptionLegParam[]
          ).map(x => {
            if (
              (
                (
                  x.parent_combine_expiry_type_value as Record<
                    string,
                    FieldParamValue
                  >
                ).combine_expiry_type_value as string
              ).includes('weekly')
            )
              x.parent_combine_expiry_type_value = {
                combine_expiry_type_value: 'monthly_0',
              };
            return x;
          });
        }
      }
    }

    if (key === 'parent_symbol' && value) {
      optionSet.legs = transformLegsOnChange(
        value.symbol as string,
        optionSet.legs as OptionLegParam[],
      );
    }

    if (
      key === 'parent_square_off_all_legs' &&
      optionSet['parent_square_off_all_legs']['square_off_all_legs'] === 'true'
    ) {
      (optionSet.legs as OptionLegParam[]) = (
        optionSet.legs as OptionLegParam[]
      ).map(x => {
        x.parent_re_entry = {
          reentry_count: 0,
          reentry_type: 'none',
          toggleStatus: true,
        };
        return x;
      });
    }

    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      let validationStatus = false;
      if (
        Object.keys(validationDataRef.current).filter(e => e.includes('lag'))
          .length > 0 &&
        !Object.values(validationDataRef.current).includes(false)
      ) {
        validationStatus = true;
      }
      PlValidation(`option${strategyIndex}`, validationStatus);
    } else {
      PlValidation(`option${strategyIndex}`, false);
    }
    if (
      key === 'parent_entry_time' &&
      parseInt(optionSet['parent_exit_time']['days_to_expire_exit'] as string) >
        parseInt(
          optionSet['parent_entry_time']['days_to_expire_entry'] as string,
        )
    ) {
      optionSet['parent_exit_time']['days_to_expire_exit'] = optionSet[
        'parent_entry_time'
      ]['days_to_expire_entry'] as string;
    }
    if (
      key === 'parent_positional_expire_on' &&
      value['positional_expire_on'] === 'weekly'
    ) {
      if (
        parseInt(
          optionSet.parent_entry_time['days_to_expire_entry'] as string,
        ) > 4
      ) {
        optionSet.parent_entry_time['days_to_expire_entry'] = '4';
      }
      if (
        parseInt(optionSet.parent_exit_time['days_to_expire_exit'] as string) >
        4
      ) {
        optionSet.parent_exit_time['days_to_expire_exit'] = '4';
      }
    } else if (
      key === 'parent_positional_expire_on' &&
      value['positional_expire_on'] === 'monthly'
    ) {
      (optionSet.legs as OptionLegParam[]) = (
        optionSet.legs as OptionLegParam[]
      ).map(x => {
        if (
          (
            (
              x.parent_combine_expiry_type_value as Record<
                string,
                FieldParamValue
              >
            ).combine_expiry_type_value as string
          ).includes('weekly')
        )
          x.parent_combine_expiry_type_value = {
            combine_expiry_type_value: 'monthly_0',
          };
        return x;
      });
    }
    onChangeSet(strategyIndex, {
      ...optionSet,
      indicator_extras: formikData,
      type:
        conditions.entry_conditions.length ||
        conditions.exit_conditions.length ||
        indicators
          ? 'condition_based'
          : 'time_based',
    });
  };

  const sendValidation = (key: string, isValid: boolean) => {
    validationDataRef.current = {
      ...validationDataRef.current,
      [key]: isValid,
    };
  };

  const onChangeOptionLeg = (legIndex: number, optionLeg: OptionLegParam) => {
    const { legs } = optionSet;
    legs[legIndex] = optionLeg;
    if (
      Object.prototype.hasOwnProperty.call(
        optionLeg.parent_momentum,
        'toggleStatus',
      ) &&
      !optionLeg.parent_momentum['toggleStatus']
    ) {
      optionSet.parent_is_momentum_trade['is_momentum_trade'] = true;
    } else {
      if (
        +legs.length > 0 &&
        !(legs as OptionLegParam[]).find(
          element =>
            Object.prototype.hasOwnProperty.call(
              element.parent_momentum,
              'toggleStatus',
            ) && !element.parent_momentum['toggleStatus'],
        )
      ) {
        optionSet.parent_is_momentum_trade['is_momentum_trade'] = false;
      }
    }
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      let validationStatus = false;
      if (
        Object.keys(validationDataRef.current).filter(e => e.includes('lag'))
          .length > 0 &&
        !Object.values(validationDataRef.current).includes(false)
      ) {
        validationStatus = true;
      }
      PlValidation(`option${strategyIndex}`, validationStatus);
    } else {
      PlValidation(`option${strategyIndex}`, false);
    }
    onChangeSet(strategyIndex, {
      ...optionSet,
      indicator_extras: formikData,
      type:
        conditions.entry_conditions.length ||
        conditions.exit_conditions.length ||
        indicators
          ? 'condition_based'
          : 'time_based',
    });
  };

  const isLegNameExist = useCallback(
    (name: string, index: number) => {
      const existLegData = (optionSet.legs as OptionLegParam[]).filter(
        (x, i) => x.name === name && index !== i,
      );
      return existLegData.length > 0;
    },
    [optionSet.legs],
  );

  const renderOptionSetFields = (groupwiseFields: FieldParam[]) => {
    const resultData = groupwiseFields.reduce(
      (
        returnData: { currData: JSX.Element[]; finalResult: JSX.Element[] },
        field: FieldParam,
        index: number,
        fieldArray: FieldParam[],
      ) => {
        field = generateOptionValues(
          field,
          {},
          optionSet,
          {},
          {
            crudeOilSymbol: isOptionBotBuilderCrudeOilSymbol,
            stockSymbols: isOptionBotBuilderStockSymbols,
            bankex: isOptionBotBuilderBankexSymbol,
            QQQ: isUSSymbolForOptionBuilder,
            SPY: isUSSymbolForOptionBuilder,
            SPX: isUSSymbolForOptionBuilder,
            XSP: isUSSymbolForOptionBuilder,
            usaStockSymbols: isShowUSAStockSymbols,
            continuousSet: isContinuousSet,
          },
        );
        if (field.parentKey === 'parent_square_off_all_legs') {
          if (+optionSet.legs.length > 0) {
            returnData.currData.push(
              <Grid item xs={12} key={v4()}>
                <Box width={'100%'}>
                  {(optionSet.legs as OptionLegParam[]).map((leg, legIndex) => (
                    <OptionLeg
                      optionSet={optionSet}
                      // key={`option-leg-${index}`}
                      key={v4()}
                      optionLeg={leg}
                      legIndex={legIndex}
                      strategyIndex={strategyIndex}
                      strategyName={props.optionSet.name as string}
                      onChangeOptionLeg={onChangeOptionLeg}
                      onDeleteLeg={onDeleteLeg}
                      PlValidation={sendValidation}
                      isReadOnly={isReadOnly}
                      handleCopyStrategy={handleCopyStrategy}
                      handlePasteStrategy={handlePasteStrategy}
                      copiedOptionStrategy={copiedOptionStrategy}
                      isLegNameExist={isLegNameExist}
                      hasIndicators={(val: boolean) => setIndicators(val)}
                    />
                  ))}
                </Box>
                {/* hello */}
              </Grid>,
            );
          }

          if (!isReadOnly) {
            returnData.currData.push(
              <Grid item xs={12} key={v4()}>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  gap={1}
                  sx={{ mb: 2.5 }}
                >
                  <MuiBuilderButton
                    variant="outlined"
                    onClick={onClickAddOptionLeg}
                    startIcon={<AddIcon />}
                    disabled={isReadOnly}
                  >
                    Option Leg
                  </MuiBuilderButton>
                  {isisOptionFutureLeg && (
                    <MuiBuilderButton
                      variant="outlined"
                      onClick={() => onClickAddFutureOptionLeg('future')}
                      startIcon={<AddIcon />}
                      disabled={isReadOnly}
                    >
                      Future Leg (Beta)
                    </MuiBuilderButton>
                  )}
                  {isOptionEquityLeg && (
                    <MuiBuilderButton
                      variant="outlined"
                      onClick={() => onClickAddFutureOptionLeg('equity')}
                      startIcon={<AddIcon />}
                      disabled={isReadOnly}
                    >
                      Equity Leg
                    </MuiBuilderButton>
                  )}
                  <FeatureFlag
                    features={[
                      FEATURES.IS_OPTION_BOTBUILDER_STRATEGY_LEG_TEMPLATES,
                    ]}
                    fallback={<></>}
                  >
                    <Box sx={{ display: 'flex', gap: 1.5 }}>
                      <OptionSetTemplates
                        handleAddOptionLeg={handleAddOptionLeg}
                        isFinNify={
                          (optionSet.parent_symbol['symbol'] as string) ===
                          FIN_NIFTY_SYMBOL
                        }
                      />
                    </Box>
                  </FeatureFlag>
                </Box>
              </Grid>,
            );
          }
        }

        if (
          !(
            (field.parentKey === 'parent_is_stbt_btst' &&
              !isSTBTOptionAvailable) ||
            (field.parentKey === 'parent_reexecution' &&
              !isReEntryReExection) ||
            (field.parentKey === 'parent_position_type' &&
              !isOptionBotBuilderPositional) ||
            (field.parentKey === 'parent_holding_type' &&
              isOptionBotBuilderPositional) ||
            (field.parentKey === 'parent_enter_if_missed' &&
              !isOptionEnterIfMissed) ||
            (field.parentKey === 'parent_combined_momentum_type' &&
              !isMomentumEnabledAtSet) ||
            (field.parentKey === 'parent_mtm_lock_target_profit_unit' &&
              !isLockProfit) ||
            (field.parentKey === 'parent_mtm_trail_lock_profit_unit' &&
              !isTrailLockProfit) ||
            (field.parentKey === 'parent_max_allowed_entry_time' &&
              !isMaxEntryTime)
          )
        ) {
          if (!field.hide) {
            returnData.currData.push(
              <field.renderer
                param={field as Omit<FieldParam, 'renderer' | 'type'>}
                onChange={onChange}
                key={`option-set-${strategyIndex}-${field.parentKey}`}
                selected={
                  optionSet[field.parentKey] as Record<string, FieldParamValue>
                }
                sendValidation={sendValidation}
                isReadOnly={isReadOnly}
              />,
            );
          }
        }

        if (
          (fieldArray[index + 1] &&
            fieldArray[index + 1].group !== field.group) ||
          index === fieldArray.length - 1
        ) {
          returnData.finalResult.push(
            <Grid
              container
              columnSpacing={2}
              spacing={2}
              key={`option-set-grid-${v4()}`}
            >
              {returnData.currData}
            </Grid>,
          );
          returnData.currData = [];
        }

        return returnData;
      },
      { currData: [], finalResult: [] },
    );
    return resultData.finalResult;
  };

  const onChangeSetName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target;
    props.optionSet.name = value;
  };

  const onKeyPressSetName = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setEditName(false);
      onChangeSet(
        strategyIndex,
        cloneDeep({
          ...optionSet,
          indicator_extras: formikData,
          type:
            conditions.entry_conditions.length ||
            conditions.exit_conditions.length ||
            indicators
              ? 'condition_based'
              : 'time_based',
        }),
      );
    }
  };
  const onBlurSetName = () => {
    setEditName(false);
    onChangeSet(
      strategyIndex,
      cloneDeep({
        ...optionSet,
        indicator_extras: formikData,
        type:
          conditions.entry_conditions.length ||
          conditions.exit_conditions.length ||
          indicators
            ? 'condition_based'
            : 'time_based',
      }),
    );
  };

  const onClickDeleteSet = useCallback(
    (index: number) => {
      onDeleteSet(index);
    },
    [onDeleteSet],
  );
  const onDeleteLeg = (legIndex: number) => {
    const legs = optionSet.legs as OptionLegParam[];
    legs.splice(legIndex, 1);
    if (
      optionSet.parent_is_momentum_trade['is_momentum_trade'] &&
      legs &&
      (legs?.length === 0 ||
        !legs.find(
          element =>
            Object.prototype.hasOwnProperty.call(
              element.parent_momentum,
              'toggleStatus',
            ) && !element.parent_momentum['toggleStatus'],
        ))
    ) {
      optionSet.parent_is_momentum_trade['is_momentum_trade'] = false;
    }
    if (
      validationDataRef.current &&
      Object.keys(validationDataRef.current).length > 0
    ) {
      delete validationDataRef.current[`lag${legIndex}`];
      Object.keys(validationDataRef.current).forEach(elem => {
        if (elem.includes('lag')) {
          const elemIndex = parseInt(elem.split('lag')[1]);
          if (elemIndex && !isNaN(elemIndex) && strategyIndex < elemIndex) {
            validationDataRef.current[`lag${elemIndex - 1}`] = validationDataRef
              .current[elem] as boolean;
            delete validationDataRef.current[elem];
          }
        }
      });
      if (
        validationDataRef.current &&
        Object.keys(validationDataRef.current).length > 0
      ) {
        let validationStatus = false;
        if (
          Object.keys(validationDataRef.current).filter(e => e.includes('lag'))
            .length > 0 &&
          !Object.values(validationDataRef.current).includes(false)
        ) {
          validationStatus = true;
        }
        PlValidation(`option${strategyIndex}`, validationStatus);
      } else {
        PlValidation(`option${strategyIndex}`, false);
      }
    }
    onChangeSet(
      strategyIndex,
      cloneDeep({
        ...optionSet,
        indicator_extras: formikData,
        type:
          conditions.entry_conditions.length ||
          conditions.exit_conditions.length ||
          indicators
            ? 'condition_based'
            : 'time_based',
      }),
    );
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowMenu(event.currentTarget);
  };

  const handleEsToolipClick = (event: React.MouseEvent<HTMLElement>) => {
    if (id) {
      setExternalSignalModal(true);
    } else {
      setShowEsTooltip(event.currentTarget);
    }
  };

  const handleEsTooltipClose = () => {
    setShowEsTooltip(null);
  };
  const handleMenuClose = () => {
    setShowMenu(null);
  };

  const options = useMemo(() => {
    return [
      {
        text: 'Copy',
        icon: <ContentCopy fontSize="small" />,
        handler: () => {
          handleCopyStrategy('set', optionSet);
        },
      },
      {
        text: 'Replace with paste',
        icon: <ContentPaste fontSize="small" />,
        handler: () => {
          handlePasteStrategy('set', strategyIndex);
        },
      },
      {
        text: 'Rename',
        icon: <DriveFileRenameOutlineIcon fontSize="small" />,
        handler: () => setEditName(true),
      },
      {
        text: 'Delete',
        icon: <DeleteIcon fontSize="small" />,
        handler: strategyIndex => onClickDeleteSet(strategyIndex as number),
      },
    ];
  }, [
    onClickDeleteSet,
    setEditName,
    handleCopyStrategy,
    optionSet,
    strategyIndex,
    handlePasteStrategy,
  ]);

  const isErrorOptioSet = useMemo(() => {
    return Boolean(
      isShowOptionSetValidation &&
        (optionSet.legs as OptionLegParam[]).length === 0,
    );
  }, [isShowOptionSetValidation, optionSet.legs]);

  const isErrorExistName = useMemo(() => {
    return isSetNameExist(optionSet.name as string, strategyIndex);
  }, [isSetNameExist, optionSet.name, strategyIndex]);

  return (
    <>
      <Box
        sx={{
          border: '1px dashed rgba(0, 0, 0, 0.12)',
          padding: '24px',
          borderRadius: '12px',
          position: 'relative',
          marginTop: '16px',
          backgroundColor: '#fafafa',
          ...((isErrorOptioSet || isErrorExistName) && {
            borderColor: 'error.main',
          }),
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Box
            style={{
              position: 'relative',
              left: '0px',
              top: '0px',
              width: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
            className="edit-hover"
          >
            <Box
              onClick={() => handleToggleSet(strategyIndex)}
              sx={{
                '&:hover': {
                  background: 'rgba(0, 0, 0, 0.04)',
                },
                borderRadius: '50%',
                height: '30px',
                width: '30px',
                cursor: 'pointer',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <ExpandMore color="primary" />
            </Box>
            <span
              style={{
                fontSize: '16px',
                lineHeight: '32px',
                color: 'rgba(0, 0, 0, 0.6)',
                marginRight: '8px',
              }}
            >
              {`${(strategyIndex + 1).toString()}.`}
            </span>
            {isEditName ? (
              <ThemeProvider theme={theme}>
                <FormControl variant="outlined" className="comman-input">
                  <TextField
                    label={'Strategy Name'}
                    defaultValue={props.optionSet.name || ''}
                    variant="outlined"
                    onChange={e => onChangeSetName(e)}
                    onKeyPress={e => onKeyPressSetName(e)}
                    onBlur={onBlurSetName}
                    disabled={isReadOnly}
                    size={'small'}
                    autoFocus={true}
                  />
                </FormControl>
              </ThemeProvider>
            ) : (
              <>
                <span
                  style={{
                    fontSize: '16px',
                    lineHeight: '32px',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}
                >
                  {props.optionSet.name}
                </span>
                {isReadOnly ? (
                  <></>
                ) : (
                  <Button
                    onClick={() => setEditName(s => !s)}
                    variant="outlined"
                    style={{
                      width: '32px',
                      minWidth: '32px',
                      height: '32px',
                      padding: '0px',
                      marginLeft: '5px',
                      border: 'none',
                    }}
                    disabled={isReadOnly}
                  >
                    <EditIcon style={{ fontSize: '15px' }} />
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
        <Box>
          {isExternalSignal && (
            <Box
              sx={{
                position: 'absolute',
                right: `${
                  optionSet.external_signal['enable'] ? '45px' : '45px'
                }`,
                top: '10px',
                display: 'flex',
              }}
            >
              <BetaChip />
              <IconButton
                style={{
                  width: 'auto',
                  height: '50px',
                  border: 'none',
                }}
                disabled={isReadOnly}
                aria-label="more"
                id="long-button"
                // aria-controls={open ? 'long-menu' : undefined}
                // aria-expanded={open ? 'true' : undefined}
                // aria-haspopup="true"
                aria-describedby="es-guide-tooltip"
                onClick={handleEsToolipClick}
              >
                <img
                  src={TradingView}
                  style={{ height: '100%', width: '35px' }}
                />
                {optionSet.external_signal['enable'] && (
                  <>
                    <ArrowForward color={'primary'} />
                    <img
                      src={siteinfo?.small_logo}
                      style={{ height: '100%', width: '35px' }}
                    />
                  </>
                )}
              </IconButton>
              <Popover
                id="es-guide-tooltip"
                open={openEsPoper}
                anchorEl={showEsTooltip}
                onClose={handleEsTooltipClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  sx: {
                    width: '60ch',
                    p: 2,
                  },
                }}
              >
                <Typography
                  textAlign={'justify'}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: (helpData && helpData[tredingViewGuideText]) || '',
                  }}
                ></Typography>
              </Popover>
            </Box>
          )}
          {isReadOnly ? (
            <></>
          ) : (
            <>
              <IconButton
                style={{
                  width: '24px',
                  minWidth: '24px',
                  height: '24px',
                  fontSize: '16px',
                  border: 'none',
                  position: 'absolute',
                  right: '24px',
                  top: '24px',
                }}
                disabled={isReadOnly}
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={showMenu}
                  open={open}
                  onClose={handleMenuClose}
                  PaperProps={{
                    style: {
                      width: '20ch',
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                  {options.map(option => {
                    let disabled = false;
                    if (
                      option.text === 'Replace with paste' &&
                      Object.keys(copiedOptionStrategy['set']).length <= 0
                    ) {
                      disabled = true;
                    }
                    if (option.text === 'Delete' && !strategyIndex) {
                      disabled = true;
                    }
                    return (
                      <MenuItem
                        onClick={() => {
                          option.handler(strategyIndex);
                          handleMenuClose();
                        }}
                        key={option.text}
                        disabled={disabled}
                      >
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        <ListItemText>{option.text}</ListItemText>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Paper>
            </>
          )}
          {renderOptionSetFields(OptionSetFieldsGroupWise)}
          {
            // <PositionDirectionCondition
            //   title={'Indicatior Condition'}
            //   conditionsData={formik.values.indicator_extras.conditions}
            //   isConditionValid={formik.values.indicator_extras.isConditionValid}
            //   setConditionsDataWithValid={(values: FieldDataWithValidate) => {
            //     props.formikState(values);
            //     void formik.setFieldValue('indicator_extras', values);
            //     onChangeSet(strategyIndex, {
            //       ...optionSet,
            //       indicator_extras: formik.values.indicator_extras,
            //     });
            //   }}
            //   error={''}
            //   updateCopiedStrategy={setCopiedStrategy}
            //   copiedStrategy={copiedStrategy}
            // />
          }
          <Grid container>
            <Grid item>
              {isConditionInOption && (
                <>
                  {!isReadOnly && (
                    <Box>
                      <CustomTooltip tooltipKey="set_indicator">
                        {() => (
                          <MuiButton
                            sx={{ left: '-5px' }}
                            size="medium"
                            onClick={() => setIndicatorModal(true)}
                          >
                            {!conditions.entry_conditions.length ? (
                              <AddCircleOutlinedIcon
                                sx={{ mr: 1, p: 0 }}
                                fontSize="small"
                              />
                            ) : (
                              <EditIcon sx={{ mr: 1, p: 0 }} fontSize="small" />
                            )}
                            Indicators
                            {/* <MuiChip
                              sx={{ ml: 0.5 }}
                              size={'small'}
                              label={'Beta'}
                              color={'greylight'}
                            /> */}
                          </MuiButton>
                        )}
                      </CustomTooltip>
                    </Box>
                  )}
                  {!!conditions.entry_conditions.length && isReadOnly && (
                    <PositionCondition
                      direction="entry_condition"
                      mode={StrategyMode.TEXT}
                      conditions={conditions.entry_conditions}
                    />
                  )}
                  {!!conditions.exit_conditions.length && isReadOnly && (
                    <PositionCondition
                      direction="exit_condition"
                      mode={StrategyMode.TEXT}
                      conditions={conditions.exit_conditions}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>

        {isErrorOptioSet && (
          <Typography
            variant="body2"
            sx={{ color: 'error.main', fontWeight: 400 }}
          >
            Please add leg here
          </Typography>
        )}
        {isErrorExistName && (
          <Typography
            variant="body2"
            sx={{ color: 'error.main', fontWeight: 400 }}
          >
            Please define the unique name for each sets
          </Typography>
        )}
      </Box>

      <IndicatorConditonsModal
        handleClose={() => setIndicatorModal(false)}
        // conditionsData={formik.values.indicator_extras.conditions}
        // isConditionValid={formik.values.indicator_extras.isConditionValid}
        open={indicatorModal}
        // setConditionsDataWithValid={(values: FieldDataWithValidate) => {
        //   props.formikState(values);
        //   void formik.setFieldValue('indicator_extras', values);
        //   onChangeSet(
        //     strategyIndex,
        //     cloneDeep({ ...optionSet, indicator_extras: values }),
        //   );
        // }}

        saveData={(values: FieldDataConditions) => {
          const _optionSet = cloneDeep({
            ...optionSet,
            indicator_extras: {
              ...values,
              resolution: values.resolution ? values.resolution : '00:10:00',
            },
          });
          const isIndicator =
            conditions.entry_conditions.length ||
            conditions.exit_conditions.length;
          if (isIndicator) {
            _optionSet['class_name'] =
              'SbExecutor.Executor.SBIndicatorBasedSet';
          } else {
            _optionSet['class_name'] = 'SbExecutor.Executor.SBTimeBaseSet';
          }
          onChangeSet(strategyIndex, _optionSet);
          setFormikData(values);
        }}
        title={t('user_bot_startegy.modal.set_note')}
        error={''}
        optionData={optionSet.indicator_extras as FieldDataConditions}
        genericMessage={'Set Conditions'}
      />
      <ExternalSignalModal
        isReadonly={isReadOnly}
        initialData={optionSet}
        bId={parseInt(id)}
        open={externalSignalModal}
        handleClose={() => setExternalSignalModal(false)}
        saveData={value => {
          if (value.enable) {
            onChangeSet(strategyIndex, {
              ...optionSet,
              external_signal: {
                enable: value.enable,
                source: value.source,
                max_signals_entry: value.max_signals_entry,
                max_signals_exit: value.max_signals_exit,
                entry_webhook_url: value.webhook,
                entry_msg_data: {
                  strategy_id: parseInt(id),
                  signal_type: 101,
                  set_name: optionSet.name as string,
                },
                exit_webhook_url: value.webhook,
                exit_msg_data: {
                  strategy_id: Number(id),
                  signal_type: 102,
                  set_name: optionSet.name as string,
                },
              },
            });
          } else {
            onChangeSet(strategyIndex, {
              ...optionSet,
              external_signal: {
                enable: value.enable,
              },
            });
          }
        }}
      />
    </>
  );
};

export default memo(OptionSet);
