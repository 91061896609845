import { Box } from '@mui/material';
import MarketplaceSearchContainer from 'app/components/Marketplace/MarketplaceSearchContainer';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiBreadcrumbs from 'app/design/uiComponents/MuiBreadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
const ReportsSearchPage = () => {
  const isMdUp = useGetMediaQueryUp('md');
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Discover bots search</title>
        <meta name="description" content="Discover bots search" />
      </Helmet>
      {!isMdUp ? (
        <Box className="sticky-top">
          <PageHeader
            variant="inactive"
            buttonText={
              <FormattedMessage id="market.page_title.search.back_btn" />
            }
            title={<FormattedMessage id="market.page_title.search.title" />}
            buttonHandleClick="/discover/bots"
          />
        </Box>
      ) : (
        <Box sx={{ m: 3, mb: 0 }}>
          <MuiBreadcrumbs
            links={[
              {
                text: t('market.page_title.market'),
                to: '/discover/bots',
                active: true,
              },
            ]}
          />
        </Box>
      )}
      <MarketplaceSearchContainer />
    </>
  );
};

export default ReportsSearchPage;
