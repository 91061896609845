import { Backdrop, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';

type Dialogprops = {
  url: string;
  openReadDialog: boolean;
  handleClose: (val: boolean) => void;
  title: string;
  height: string;
};
const HelpGuideDialog = ({
  url,
  openReadDialog,
  handleClose,
  title,
  height,
}: Dialogprops) => {
  const handlecCloseDialog = () => {
    handleClose(false);
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 2 }}
        // fullWidth
        // maxWidth="lg"
        open={openReadDialog}
        onClick={handlecCloseDialog}
      >
        <DialogContent sx={{ p: 0, overflow: 'hidden' }}>
          <IconButton
            sx={{
              position: 'absolute',
              top: '16px',
              right: '24px',
              zIndex: 1,
              color: 'white',
            }}
            onClick={handlecCloseDialog}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              height: '80vh',
              // aspectRatio: '16/9',
              maxWidth: '80vh',
              mx: 'auto',
            }}
          >
            <iframe
              style={{
                width: '100%',
                height: height,
              }}
              src={url}
              //   title="Reader Dialog"
              title={title}
              // frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Box>
        </DialogContent>
      </Backdrop>
    </Box>
  );
};

export default HelpGuideDialog;
