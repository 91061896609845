import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { SbStrategy, UserBot } from './types';
import useSaveUserBot from 'services/Bots/useSaveUserBot';
import useEditUserBot from 'services/Bots/useEditUserBot';
import {
  Errors,
  GetActionParams,
  GetOtpResult,
  SaveUserBotResult,
} from 'types/ApiServicesTypes';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { Form, Formik } from 'formik';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import usePostUserBotsIsExist from 'services/Bots/usePostUserBotsIsExist';
import { useTranslation } from 'react-i18next';
import MuiButton from 'app/design/uiComponents/MuiButton';
import {
  useGetMediaQueryDown,
  useGetMediaQueryUp,
} from 'app/hooks/useGetMediaQuery';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { createEmptyOptionBuilder } from 'app/components/Builder/OptionsStrategy/Utils/utils';
import { OptionStrategyFields } from 'app/components/Builder/OptionsStrategy/FieldsData';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useMutation } from 'react-query';
import { PostAction } from 'services/apiServices';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import PageHeader from 'app/design/speedBot/PageHeader';
import { getQueryParam } from 'utils/GenericFunctions';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import {
  Brightness4,
  Brightness7,
  Fullscreen,
  FullscreenExit,
  PlayArrowOutlined,
  SaveOutlined,
  StopOutlined,
} from '@mui/icons-material';
import CodeStrategyLogsContent from './CodeStrategyLogsContent';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import CodeStrategyVersionControlCard from './CodeStrategyVersionControlCard';
import CodePastHistoryModal from './CodePastHistoryModal';
import PureIframe from './PureIframe';
import { StrategyBuiltWithTypes } from './util';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import Editor from '@monaco-editor/react';

const CodeStrategyBuilderContent = ({
  botId,
  subId,
  userBotDetails,
  isLoading,
  isTemplate,
}: {
  botId: number;
  subId: number;
  userBotDetails: UserBot | undefined;
  isLoading: boolean;
  isTemplate?: boolean;
}) => {
  const [ideCode, setIdeCode] = useState('');
  const breadcrumb = useBreadcrumbManager();
  const mutationIsExist = usePostUserBotsIsExist();
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');
  const is1600Down = useGetMediaQueryDown('max');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  // setIdeCode()
  const history = useHistory();
  const isIframeMonaco = useIsFeatureFlag([FEATURES.IS_IFRAME_MONACO]);
  const saveUserBotSuccessCallback = (
    res?: SaveUserBotResult,
    name?: string,
  ) => {
    if (res) {
      breadcrumb.push(
        `/bot-builder/${res.bot_id}/${subId ? subId : ''}`,
        PAGES.BOT_STRATEGY_BUILDER,
        `/bot-builder/${botId}/${subId ? subId : ''}?type=code`,
        name as string,
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).codeFromParent = () => {
    return userData?.sb_strategy?.code || '';
  };

  const mutationAdd = useSaveUserBot(saveUserBotSuccessCallback);
  const mutationEdit = useEditUserBot(botId, saveUserBotSuccessCallback);

  const setUserBotData = (userBotData: UserBot) => {
    // Call api here
    if (userBotData) {
      const objReq = userBotData;

      if (
        botId &&
        objReq.sb_strategy &&
        userBotDetails &&
        userBotDetails.sb_strategy
      ) {
        objReq.sb_strategy.start_date = userBotDetails.sb_strategy.start_date;
        objReq.sb_strategy.end_date = userBotDetails.sb_strategy.end_date;

        objReq.sb_strategy.initial_capital =
          userBotDetails.sb_strategy.initial_capital;
        objReq.sb_strategy.trading_start_time =
          userBotDetails.sb_strategy.trading_start_time;
        objReq.sb_strategy.trading_stop_time =
          userBotDetails.sb_strategy.trading_stop_time;
        objReq.sb_strategy.code = userBotData.sb_strategy?.code as string;
      }
      objReq.is_backtest = false;

      if (isTemplate) objReq.template_id = botId;

      if (botId && !isTemplate) mutationEdit.mutate(objReq);
      else {
        mutationAdd.mutate(objReq);
      }
    }
  };

  // window.addEventListener('message', receiveMessage, false);
  const selected_version = getQueryParam('versionid');

  const source = getQueryParam('source');

  const mutation_get_action = useMutation<
    GetOtpResult,
    Errors,
    Pick<GetActionParams, 'action'>
  >('getAction', values =>
    PostAction(values.action, botId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      }),
  );
  const userData =
    userBotDetails && userBotDetails.sb_strategy ? userBotDetails : null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sbStrategy, setSbStrategy] = useState<SbStrategy>(
    userData && userData.sb_strategy
      ? userData.sb_strategy
      : createEmptyOptionBuilder(OptionStrategyFields),
  );
  const toggleTheme = () => {
    sendMessage();
    setIsDarkTheme(!isDarkTheme);
  };

  const toggleFullscreen = () => {
    // sendMessage();
    const element = document.getElementById('root'); // Get the root element of the document

    if (element) {
      try {
        // if (!isFullscreen) {
        //   if (element.requestFullscreen) {
        //     await element.requestFullscreen();
        //   }
        // } else {
        //   if (document.exitFullscreen) {
        //     await document.exitFullscreen();
        //   }
        // }

        setIsFullscreen(!isFullscreen);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error toggling fullscreen:', error);
      }
    }
  };
  const receiveMessage = useCallback(
    event => {
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data.event === 'codeChange') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const receivedValue = event.data;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setIdeCode(receivedValue.code as string);
      } else {
        setIdeCode(ideCode);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ideCode, selected_version],
  );
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    setIdeCode(ideCode);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [ideCode, receiveMessage]);

  const handleClose = () => {
    setOpenModal(false);
  };
  function sendMessage() {
    const iframe = document.getElementById('iframe') as HTMLIFrameElement;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
    (iframe as any).contentWindow.postMessage(
      'themeChange:' + (!isDarkTheme ? 'vs-dark' : 'vs'),
      '*',
    );
    // iframe && iframe?.contentWindow?.location.reload();
  }
  return (
    <Box
      sx={
        isFullscreen
          ? {
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 99,
              width: '100%',
              height: '100%',
            }
          : {}
      }
    >
      <CircularLoader open={isLoading} />

      <Formik
        initialValues={{
          ideCode: ideCode || userData?.sb_strategy?.code,
          name: userData?.name || '',
          underlyingFrom:
            userData &&
            userData.sb_strategy &&
            userData.sb_strategy.sb_opt_strategy &&
            userData.sb_strategy.sb_opt_strategy['underlying_from'] ===
              'future',
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            t('user_backtesting.valid_msg.req', {
              name: t('user_bot_startegy.form.bot_name'),
            }),
          ),
        })}
        onSubmit={values => {
          const isNameChanged =
            !userData || (userData && userData.name !== values.name);

          sbStrategy.sb_opt_strategy['underlying_from'] = values.underlyingFrom
            ? 'future'
            : 'spot';
          if (isNameChanged) {
            mutationIsExist.mutate(
              { name: values.name },
              {
                onSuccess: resSuccess => {
                  if (resSuccess.success)
                    setUserBotData({
                      ...values,
                      sb_strategy: {
                        ...sbStrategy,
                        code: ideCode,
                        type: 'code',
                        built_with: StrategyBuiltWithTypes.code_builder,
                      },
                    });
                },
              },
            );
          } else {
            setUserBotData({
              ...values,
              sb_strategy: {
                ...sbStrategy,
                code: ideCode,
                type: 'code',
                built_with: StrategyBuiltWithTypes.code_builder,
              },
            });
          }
        }}
      >
        {({ values, errors, handleBlur, touched, setFieldValue }) => {
          // eslint-disable-next-line no-console
          const nameExistError =
            mutationIsExist.data && mutationIsExist.data.error
              ? mutationIsExist.data.error
              : '';
          return (
            <Form>
              {!isFullscreen && (
                <Box>
                  <PageHeader
                    variant="inactive"
                    buttonText={
                      userBotDetails && Object.keys(userBotDetails).length > 0
                        ? t('buttons.backtesting')
                        : source
                        ? source
                        : t('bots.page_title.bots')
                    }
                    title={t('user_bot_startegy.bot_builder')}
                    buttonHandleClick={() => history.goBack()}
                    breadcrumb={
                      <Breadcrumbs
                        page={PAGES.BOT_STRATEGY_BUILDER}
                        data={{
                          subId,
                          botId: userBotDetails?.strategy_id,
                          userBotId: botId,
                          botName: userBotDetails?.name,
                        }}
                      />
                    }
                  />
                  {/* <Box className="p-main-spacing">
          <Typography variant="body3">
            <FormattedMessage id="user_bot_startegy.info_message" />
          </Typography>
        </Box> */}
                </Box>
              )}
              <Box
                className={isFullscreen ? '' : 'p-main-spacing'}
                // onSubmit={handleSubmit}
                sx={{ display: { max: 'flex' } }}
              >
                <Box
                  width={
                    isFullscreen ? { md: '100%' } : { xl: '100%', max: '100%' }
                  }
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      backgroundColor: isFullscreen
                        ? isDarkTheme
                          ? '#1e1e1e'
                          : '#fffffe'
                        : 'transparent',
                    }}
                  >
                    <Grid item xs={12} md={5} lg={6}>
                      {!isFullscreen && (
                        <FormControl
                          sx={
                            isFullscreen
                              ? {
                                  mb: 0,
                                  backgroundColor: 'black',
                                  mt: 0.5,
                                  color: 'white',
                                }
                              : { mb: 2 }
                          }
                          fullWidth={!isFullscreen}
                          error={
                            Boolean(touched.name && errors.name) ||
                            Boolean(touched.name && nameExistError)
                          }
                          variant="outlined"
                        >
                          <InputLabel htmlFor="label-name">
                            <FormattedMessage id="user_bot_startegy.form.bot_name" />
                          </InputLabel>
                          <OutlinedInput
                            id="label-name"
                            name="name"
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={e => {
                              if (nameExistError) mutationIsExist.reset();
                              setFieldValue('name', e.target.value);
                            }}
                            label={t('user_bot_startegy.form.bot_name')}
                            sx={
                              isFullscreen ? { height: 50, color: 'white' } : {}
                            }
                          />
                          {touched.name && errors.name && (
                            <FormHelperText error>{errors.name}</FormHelperText>
                          )}
                          {touched.name && nameExistError && (
                            <FormHelperText error>
                              {nameExistError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Grid>

                    <Grid item xs={12} md={7} lg={6}>
                      <Box
                        sx={
                          !isFullscreen
                            ? {
                                mb: 2,
                                mt: 2,
                                textAlign: 'center',
                                alignItems: 'center',
                                display: { xs: 'block', md: 'flex' },
                                flexDirection: 'row-reverse',
                              }
                            : {
                                mt: 2,
                                alignItems: 'center',
                                textAlign: 'center',
                                display: { xs: 'block', md: 'flex' },
                                flexDirection: 'row-reverse',
                              }
                        }
                      >
                        {/* {!!botId && (
                          <Box>
                            <MuiButton
                              variant="contained"
                              color="secondary"
                              fullWidth={!isMdUp}
                              onClick={() => {
                                breadcrumb.push(
                                  `/bots/backtesting/logs/${botId}`,
                                  PAGES.BOTS,
                                  `/bot-builder/${botId}`,
                                  t('user_bot_startegy.bot_builder'),
                                );
                              }}
                              sx={{
                                mr: { xs: 0, md: 2 },
                              }}
                            >
                              See Logs
                              <ArticleOutlined />
                            </MuiButton>
                          </Box>
                        )} */}
                        <Box display={'flex'} mb={{ xs: 1, md: 0 }}>
                          {botId > 0 && is1600Down && (
                            <MuiButton
                              type="button"
                              variant="contained"
                              color="primary"
                              onClick={() => setOpenModal(true)}
                              fullWidth={!isMdUp}
                              sx={{ mr: { xs: 1, md: 2 } }}
                              // disabled={!isValid || !isValidForm}
                            >
                              Code History
                            </MuiButton>
                          )}
                          <MuiButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              errors.name ||
                              values.name == '' ||
                              values.name == undefined
                                ? setIsFullscreen(false)
                                : null
                            }
                            fullWidth={!isMdUp}
                            sx={{ mr: { xs: 0, md: 2 } }}
                            // disabled={!isValid || !isValidForm}
                          >
                            Save{'  '}
                            <SaveOutlined />
                          </MuiButton>
                        </Box>
                        {botId > 0 ? (
                          <Box display={'flex'}>
                            <MuiButton
                              variant="contained"
                              color="secondary"
                              fullWidth={!isMdUp}
                              onClick={() => {
                                mutation_get_action.mutate({
                                  action: 'stop',
                                });
                              }}
                              sx={{
                                mr: { xs: 1, md: 2 },
                              }}
                            >
                              Stop{'  '}
                              <StopOutlined color="error" />
                            </MuiButton>
                            <MuiButton
                              variant="contained"
                              color="primary"
                              fullWidth={!isMdUp}
                              onClick={() => {
                                mutation_get_action.mutate({
                                  action: 'start',
                                });
                              }}
                              sx={{
                                mr: { xs: 0, md: 2 },
                              }}
                            >
                              Run{'  '}
                              <PlayArrowOutlined color="success" />
                            </MuiButton>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Use Field component to handle form fields in Formik */}
                  {/* <Field name="ideCode"> */}
                  {/* {({ field }) => ( */}
                  <Box sx={{ position: 'relative', height: '100%' }}>
                    {isIframeMonaco ? (
                      <PureIframe />
                    ) : (
                      <Editor
                        height={isFullscreen ? '100vh' : '70vh'}
                        defaultLanguage="python"
                        theme={isDarkTheme ? 'vs-dark' : 'vs'}
                        value={values.ideCode}
                        onChange={value => setFieldValue('ideCode', value)}
                        options={{
                          fontFamily: "'Inter', sans-serif",
                          lineHeight: 1.6,
                          lineNumbers: 'on',
                          fontSize: 16,
                          scrollBeyondLastLine: false,
                          readOnly: false,
                          cursorStyle: 'line',
                          renderLineHighlight: 'gutter',
                          hideCursorInOverviewRuler: true,
                          overviewRulerBorder: false,
                          wordWrap: 'on',
                          wrappingIndent: 'indent',
                          cursorBlinking: 'blink',
                          contextmenu: true,
                          mouseWheelZoom: true,
                          automaticLayout: true,
                          glyphMargin: true,
                          folding: true,
                          showUnused: true,
                          renderWhitespace: 'none',
                          scrollbar: {
                            vertical: 'auto',
                            horizontal: 'auto',
                            useShadows: true,
                            verticalHasArrows: true,
                            horizontalHasArrows: true,
                            verticalScrollbarSize: 12,
                            horizontalScrollbarSize: 12,
                            arrowSize: 16,
                          },
                          overviewRulerLanes: 2,
                          fixedOverflowWidgets: true,
                        }}
                      />
                    )}
                    {/* <Box>
                    <Typography color={'white'}>
                      Heloo this is log
                    </Typography>
                  </Box> */}
                    <IconButton
                      onClick={toggleTheme}
                      sx={{
                        borderRadius: '0.5rem',
                        border: '1px solid rgba(0, 0, 0, 0.06)',
                        backgroundColor: 'transparent',
                        ml: 1.5,
                        height: '3.5rem',
                        width: '3.5rem',
                        position: 'absolute',
                        top: 60,
                        right: 0,
                      }}
                    >
                      {isDarkTheme ? (
                        <Brightness7 color="secondary" />
                      ) : (
                        <Brightness4 color="primary" />
                      )}
                    </IconButton>
                    <Box
                      onClick={toggleFullscreen}
                      sx={{
                        mr: { xs: 0, md: 2 },
                        position: 'absolute',
                        top: 0,
                        right: -15,
                      }}
                    >
                      <IconButton
                        sx={{
                          borderRadius: '0.5rem',
                          border: '1px solid rgba(0, 0, 0, 0.06)',
                          backgroundColor: 'transparent',
                          ml: 1.5,
                          height: '3.5rem',
                          width: '3.5rem',
                        }}
                      >
                        {!isFullscreen ? (
                          <Fullscreen color="secondary" />
                        ) : (
                          <FullscreenExit color="secondary" />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                {botId > 0 && !is1600Down && !isFullscreen && (
                  <Card
                    sx={{
                      width: '20%',
                      marginLeft: '10px',
                      height: '78vh',
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      '&::-webkit-scrollbar': {
                        width: '0.4em',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '1em',
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        background: '#555',
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        py: 2,
                        paddingLeft: 1,
                        borderBottom: '1px solid #e0e0e0',
                        textAlign: 'center',
                      }}
                    >
                      Version History
                    </Typography>
                    {userData?.sb_strategy?.versions && (
                      <CodeStrategyVersionControlCard
                        versions={userData.sb_strategy.versions}
                        selectedVersionId={selected_version}
                      />
                    )}
                  </Card>
                )}
                {/* )} */}
                {/* </Field> */}
              </Box>
            </Form>
          );
        }}
      </Formik>
      {botId > 0 && (
        <>
          <Box className="p-main-spacing" mt={2} mb={1}>
            <Typography variant="h5">Logs</Typography>
            <ItemSeparator variant="2px" orientation="horizontal" />
          </Box>
          <CodeStrategyLogsContent botId={String(botId)} type="backtesting" />
        </>
      )}
      <CodePastHistoryModal
        open={openModal}
        handleClose={handleClose}
        versions={userData?.sb_strategy?.versions}
        selectedVersionId={selected_version}
      />
    </Box>
  );
};

export default CodeStrategyBuilderContent;
// import React, { useEffect } from 'react';
// import * as monaco from 'monaco-editor';
// import { startPythonClient } from '../../../../python/client/main';

// export const PythonEditor = () => {
//   useEffect(() => {
//     startPythonClient();
//   }, []); // Run once when the component mounts

//   return (
//     <div>
//       <h2>Python IDE</h2>
//       <div id="container" style={{ width: '100%', height: '850px', border: '1px solid black' }} />
//     </div>
//   );
// };

// const MonacoWrapper = () => {
//   useEffect(() => {
//     // Initialize Monaco Editor
//     monaco.editor.defineTheme('vs-dark', {
//       base: 'vs-dark',
//       inherit: true,
//       rules: [],
//       colors: {},
//     });

//     // Start Python language server
//     startPythonClient();
//   }, []);

//   return <PythonEditor />;
// };

// export default MonacoWrapper;
