import React, { useEffect } from 'react';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
} from '@mui/material';
import {
  FieldParam,
  RadioFieldValue,
  RadioButtonParam,
  FieldParamValue,
  ControlsType,
  OptionLegParam,
} from '../types';
import { v4 } from 'uuid';
import GlobalStyles from '@mui/material/GlobalStyles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';

type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, string>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          height: '30px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '13px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87) !important',
        },
      },
    },
  },
});

export const RadioButtonsRenderer = (props: Props) => {
  const { param, onChange, sendValidation, isReadOnly } = props;
  const selected = props.selected as Record<string, string>;
  const { name, controls, parentKey, myStyle, addClass, width } =
    param as RadioButtonParam;

  useEffect(() => {
    sendValidation(parentKey, true);
  }, [sendValidation, parentKey]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControlsType,
  ) => {
    selected[field.key] = event.target.value;
    sendValidation(parentKey, true);
    onChange(parentKey, selected);
  };

  // const validate = useCallback(
  //   (formData: Record<string, string>) => {
  //     let validStatus = {
  //       status: false,
  //       error: '',
  //     };
  //     Object.keys(formData).forEach((item, index) => {
  //       if (controls[index].isRequired && !formData[item]) {
  //         validStatus = {
  //           status: true,
  //           error: 'Please enter valid data',
  //         };
  //       }
  //     });
  //     return validStatus;
  //   },
  //   [controls],
  // );

  // useEffect(() => {
  //   const validStatus = validate(selected);
  //   setIsValidStatus(validStatus);
  //   sendValidation(parentKey, !validStatus.status);
  // }, [validate, parentKey, sendValidation, selected]);

  // const handleChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   control: ControlsType,
  // ) => {
  //   selected[control.key] = event.target.value;
  //   const validStatus = validate(selected);
  //   sendValidation(parentKey, !validStatus.status);
  //   onChange(parentKey, selected);
  // };

  return (
    <Grid
      item
      className={addClass}
      style={
        width
          ? { marginBottom: '24px', width: `${width}px` }
          : { marginBottom: '24px' }
      }
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            [theme.breakpoints.down('md')]: myStyle?.small
              ? myStyle.small
              : myStyle?.root
              ? myStyle.root
              : myStyle,
            [theme.breakpoints.up('md')]: myStyle?.medium
              ? myStyle.medium
              : myStyle?.root
              ? myStyle.root
              : myStyle,
            [theme.breakpoints.up('lg')]: myStyle?.large
              ? myStyle.large
              : myStyle?.root
              ? myStyle.root
              : myStyle,
          }}
        />
        <FormControl
          style={{ width: '100%' }}
          //  error={isValidStatus.status}
        >
          {controls.map(control => (
            <div key={v4()}>
              <FormLabel id={name}>{name}</FormLabel>
              <RadioGroup
                value={
                  (selected[control.key] || control.defaultValue) as string
                }
                name={name}
                onChange={event => handleChange(event, control)}
                row
              >
                {control.values?.map((fieldValue: RadioFieldValue) => (
                  <FormControlLabel
                    key={v4()}
                    value={fieldValue.key}
                    control={<Radio />}
                    label={fieldValue.label as string}
                    disabled={isReadOnly}
                  />
                ))}
                {control.tooltip && (
                  <CustomTooltip tooltipKey={control.tooltip}>
                    {({ isTooltipAvailable }) =>
                      isTooltipAvailable ? (
                        <IconButton sx={{ bottom: '4px' }}>
                          <InfoOutlinedIcon />
                        </IconButton>
                      ) : (
                        <></>
                      )
                    }
                  </CustomTooltip>
                )}
              </RadioGroup>
            </div>
          ))}
        </FormControl>
      </ThemeProvider>
      {name}
    </Grid>
  );
};
