import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, BottomNavigationAction, Avatar } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import NotificationButton from '../../Portfolio/NotificationButton';
import MuiBottomNavigation from 'app/design/uiComponents/MuiBottomNavigation';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { isUserLoggedIn } from 'utils/auth';
import { useEffect, useState } from 'react';
import SignInModal from 'app/components/Auth/SignInModal';
import {
  getFirstSecondLetterfromName,
  getQueryParam,
} from 'utils/GenericFunctions';
import { AccountCircle, AccountCircleOutlined } from '@mui/icons-material';
import { GetProfileResult } from 'types/ApiServicesTypes';
function AppbarNotification({
  activeNav,
  profile,
}: {
  activeNav: string;
  profile?: GetProfileResult;
}) {
  const theme = useTheme();
  const history = useHistory();
  const isMdUp = useGetMediaQueryUp('md');
  const isLoggedIn = isUserLoggedIn();
  const [isOpenLoginModal, setOpenLoginModal] = useState(false);

  useEffect(() => {
    const modalopen = getQueryParam('modal');

    if (modalopen === 'india' || modalopen === 'usa') {
      setOpenLoginModal(true);
    }
  }, []);
  return (
    <>
      {isMdUp && (
        <Box display="flex" alignItems="center">
          {isLoggedIn ? (
            <>
              <MuiBottomNavigation
                showLabels
                value={activeNav === 'profile' ? 'menu' : activeNav}
                onChange={(event, newValue) => {
                  history.push(`/${newValue}`);
                }}
                sx={{
                  height: theme => theme.spacing(8),
                  backgroundColor: theme => theme.palette.grey[50],
                  [theme.breakpoints.up('md')]: {
                    flex: 1,
                    mx: 1,
                  },
                }}
              >
                <BottomNavigationAction
                  // label="Menu"
                  icon={
                    profile && profile.name.trim().length > 0 ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Avatar
                          sx={{
                            backgroundColor: 'black',
                            color: 'white',
                            width: 30,
                            height: 30,
                            fontSize: 14,
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                            border: '1px solid black',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSmooth: 'auto',
                          }}
                        >
                          {getFirstSecondLetterfromName(profile?.name)}
                        </Avatar>
                      </Box>
                    ) : activeNav === 'menu' ? (
                      <AccountCircle />
                    ) : (
                      <AccountCircleOutlined />
                    )
                  }
                  value="menu"
                  sx={{ mt: '-2px' }}
                />
              </MuiBottomNavigation>
              <NotificationButton />
            </>
          ) : (
            <MuiBottomNavigation
              showLabels
              onChange={() => {
                // history.push(`/${newValue}`);
                // open login modal
                // showAlert!('Please login to the app to continue', 'warning');
                setOpenLoginModal(true);
              }}
              sx={{
                height: theme => theme.spacing(8),
                backgroundColor: theme => theme.palette.grey[50],
                [theme.breakpoints.up('md')]: {
                  flex: 1,
                  mx: 1,
                },
              }}
            >
              <BottomNavigationAction
                label="Login"
                icon={<LoginIcon />}
                sx={{ mt: '-2px' }}
              />
            </MuiBottomNavigation>
          )}
        </Box>
      )}
      <SignInModal
        open={isOpenLoginModal}
        handleClose={() => setOpenLoginModal(false)}
      />
    </>
  );
}

export default AppbarNotification;
