import {
  ControlsType,
  FieldParam,
  FieldParamValue,
  OptionLegParam,
} from '../types';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Checkbox,
  Box,
  IconButton,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { v4 } from 'uuid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';

type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, boolean>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          height: '30px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '13px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87) !important',
        },
      },
    },
  },
});

export const CheckFieldRenderer = (props: Props) => {
  const { param, onChange, sendValidation, isReadOnly } = props;
  const selected = props.selected as Record<string, boolean>;
  const { name, controls, parentKey, myStyle, addClass, width } = param;
  const checked: Array<boolean> = controls.map(control => {
    if (typeof selected[control.key] === 'boolean') {
      return selected[control.key];
    } else {
      return control.defaultValue as boolean;
    }
  });

  useEffect(() => {
    sendValidation(parentKey, true);
  }, [sendValidation, parentKey]);

  const handleChange = (e, checked, field: ControlsType) => {
    selected[field.key] = checked as boolean;
    sendValidation(parentKey, true);
    onChange(parentKey, selected);
  };

  return (
    <Grid
      item
      className={addClass}
      style={
        width
          ? { marginBottom: '24px', width: `${width}px` }
          : { marginBottom: '24px' }
      }
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            [theme.breakpoints.down('md')]: myStyle?.small
              ? myStyle.small
              : myStyle?.root
              ? myStyle.root
              : myStyle,
            [theme.breakpoints.up('md')]: myStyle?.medium
              ? myStyle.medium
              : myStyle?.root
              ? myStyle.root
              : myStyle,
            [theme.breakpoints.up('lg')]: myStyle?.large
              ? myStyle.large
              : myStyle?.root
              ? myStyle.root
              : myStyle,
          }}
        />
        {controls.map((field, index) => (
          <FormGroup key={v4()}>
            <FormControlLabel
              control={
                <Checkbox checked={checked[index]} disabled={isReadOnly} />
              }
              label={
                <Box display={'flex'}>
                  <Box alignSelf={'center'}>{name as string}</Box>
                  <Box>
                    {field.tooltip && (
                      <CustomTooltip tooltipKey={field.tooltip}>
                        {isTooltipAvailable =>
                          isTooltipAvailable ? (
                            <IconButton>
                              <InfoOutlinedIcon />
                            </IconButton>
                          ) : (
                            <></>
                          )
                        }
                      </CustomTooltip>
                    )}
                  </Box>
                </Box>
              }
              onChange={(event, checked) => {
                handleChange(event, checked, field);
              }}
            />
          </FormGroup>
        ))}
      </ThemeProvider>
    </Grid>
  );
};
