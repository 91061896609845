import { deepPurple } from '@mui/material/colors';
import i18next from 'i18next';
import {
  TableColumns,
  TableRow,
  TableRowCellsData,
} from 'app/components/Common/CommonTable/type';
import MuiChip from 'app/design/uiComponents/MuiChip';
import {
  Position,
  Order,
  GetBotPositionsResult,
  BotSignal,
} from 'types/ApiServicesTypes';
import {
  PositionsListLayout,
  PositionsListLayoutProps,
  OrdersListLayoutProps,
} from 'types/ComponentTypes';
import {
  decimalFormat,
  formatNumber,
  getArrowDirByNum,
  getColorByNum,
  getDate,
  getTime,
  replaceAllChars,
  toTitleCase,
} from 'utils/GenericFunctions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChipColor } from 'types/Generic';
import { SymbolsData } from 'types/Symbols';
import {
  calculateProfitLossAndPerChange,
  getCurrentSymbolPrice,
} from '../Portfolio/utils';

export const positionTypes = {
  intraday: 'Intraday',
  delivery: 'Delivery',
};

export const riskTypes = {
  moderate_risk: 'Moderate Risk',
  low_risk: 'Low Risk',
  high_risk: 'High Risk',
};

export const orderStatus = {
  '0': { value: 'New', icon: <InfoOutlinedIcon />, chipColor: 'infolight' },
  '1': {
    value: 'Submitted',
    icon: <InfoOutlinedIcon />,
    chipColor: 'infolight',
  },
  '2': {
    value: 'Partially Filled',
    icon: <InfoOutlinedIcon />,
    chipColor: 'infolight',
  },
  '3': { value: 'Filled', icon: <CheckIcon />, chipColor: 'successlight' },
  '5': {
    value: 'Canceled',
    icon: <WarningAmberIcon />,
    chipColor: 'warninglight',
  },
  '6': { value: 'None', icon: <WarningAmberIcon />, chipColor: 'warninglight' },
  '7': {
    value: 'Invalid',
    icon: <WarningAmberIcon />,
    chipColor: 'warninglight',
  },
  '8': {
    value: 'Cancel Pending',
    icon: <InfoOutlinedIcon />,
    chipColor: 'infolight',
  },
  '9': {
    value: 'Update Submitted',
    icon: <InfoOutlinedIcon />,
    chipColor: 'infolight',
  },
};

export const orderTypes = {
  '0': 'Market',
  '1': 'Limit or Stop',
  '2': 'Market',
  '3': 'Limit',
  '4': 'Stop Market',
  '5': 'Stop Limit',
  '6': 'Market On Open',
  '7': 'Market On Close',
  '8': 'Option Exercise',
};

export const tags = {
  EN: 'Entry',
  EX: 'Exit',
  SL: 'Stop Loss',
  TG: 'Target ',
  LQ: 'Liquidated',
  LE: 'Long Entry',
  LX: 'Long Exit',
  SE: 'Short Entry',
  SX: 'Short Exit ',
};

export const createBotPositionTableRows = (
  data: PositionsListLayoutProps | Position[] | GetBotPositionsResult,
  isChargesAndProfit: boolean,
  handleClick?: (position: Position) => void,
): { rows: TableRow<Position>[]; columns: TableColumns[] } => {
  const rows: TableRow<Position>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  const columns: TableColumns[] = [
    { text: i18next.t('summary_labels.symbol'), key: 'trading_symbol' },
    {
      text: i18next.t('summary_labels.position'),
      align: 'center',
      key: 'direction',
    },
    {
      text: i18next.t('summary_labels.qty_short'),
      align: 'center',
      key: 'quantity',
    },
    { text: i18next.t('summary_labels.entry_time'), key: 'entry_time' },
    {
      text: i18next.t('summary_labels.entry_price'),
      align: 'right',
      key: 'entry_price',
    },
    { text: i18next.t('summary_labels.exit_time'), key: 'exist_time' },
    {
      text: i18next.t('summary_labels.exit_price'),
      align: 'right',
      key: 'exit_price',
    },
    {
      text: i18next.t('summary_labels.net_profit'),
      align: 'right',
      hide: !isChargesAndProfit,
      key: 'profit_loss',
    },
    {
      text: `${i18next.t('summary_labels.profit')}/${i18next.t(
        'summary_labels.loss',
      )}`,
      align: 'right',
      hide: isChargesAndProfit,
    },
  ];

  if (!data) return { rows, columns };

  (data as PositionsListLayout[]).map(grp => {
    grp.data &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      grp.data.map((position: Position) => {
        rowCellsData = [];
        const isBuy = position.direction === '0';
        const netProfitLoss = position.profit_loss - position.totalfees;
        const netProfitLossPer =
          (netProfitLoss * 100) / (position.entry_Price * position.quantity);

        rowCellsData = [
          {
            content: position.trading_symbol,
            fontWeight: 500,
          },
          {
            content: (
              <MuiChip
                label={isBuy ? 'L' : 'S'}
                color={isBuy ? 'infolight' : 'purplelight'}
                variant="filled"
                size="singleLetter"
              />
            ),
            align: 'center',
          },
          {
            content: position.quantity,
            color: isBuy ? 'info.main' : deepPurple[500],
            align: 'center',
          },
          {
            content: (
              <>
                {getTime(position.entry_time)}
                <br />
                {getDate(position.entry_time, false)}
              </>
            ),
          },
          {
            content: position.entry_Price
              ? `${position.currency_symbol}${formatNumber(
                  position.entry_Price,
                )}`
              : '',
            align: 'right',
          },
          {
            content:
              position.exit_time !== '1000-01-01 00:00:00' ? (
                <>
                  {getTime(position.exit_time)}
                  <br />
                  {position.exit_time !== '1000-01-01 00:00:00'
                    ? getDate(position.exit_time, false)
                    : '-'}
                </>
              ) : (
                '-'
              ),
          },
          {
            content: `${position.currency_symbol}${formatNumber(
              position.exit_price,
            )}`,
            align: 'right',
          },
          {
            content: (
              <>
                {getArrowDirByNum(netProfitLoss)} {position.currency_symbol}
                {formatNumber(netProfitLoss, true)}
                <br />({netProfitLossPer > 0 ? '+' : ''}
                {decimalFormat(netProfitLossPer)}%)
              </>
            ),
            align: 'right',
            color: getColorByNum(netProfitLoss),
            fontWeight: 500,
            hide: !isChargesAndProfit,
          },
          {
            content: position.exit_price ? (
              <>
                {getArrowDirByNum(position.profit_loss)}{' '}
                {position.currency_symbol}{' '}
                {formatNumber(position.profit_loss, true)}
                <br /> ({position.percentage_change > 0 ? '+' : ''}
                {decimalFormat(position.percentage_change)}%)
              </>
            ) : (
              '-'
            ),
            align: 'right',
            color: getColorByNum(position.profit_loss),
            fontWeight: 500,
            hide: isChargesAndProfit,
          },
        ];

        rows.push({
          rowCellsData,
          rowData: position,
          handleClick: () => handleClick?.(position),
        });
      });
  });

  return { rows, columns };
};
export const createAllTodaysPositionTableRows = (
  data: PositionsListLayoutProps | Position[] | GetBotPositionsResult,
  isChargesAndProfit: boolean,
  liveFeedData?: SymbolsData,
  handleClick?: (position: Position) => void,
): { rows: TableRow<Position>[]; columns: TableColumns[] } => {
  const rows: TableRow<Position>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  const columns: TableColumns[] = [
    { text: i18next.t('summary_labels.bot_name'), key: 'bot_name' },

    { text: i18next.t('summary_labels.symbol'), key: 'trading_symbol' },
    {
      text: i18next.t('summary_labels.status'),
      key: 'status',
    },
    {
      text: i18next.t('summary_labels.position'),
      align: 'center',
      key: 'direction',
    },
    {
      text: i18next.t('summary_labels.qty_short'),
      align: 'center',
      key: 'quantity',
    },
    { text: i18next.t('summary_labels.entry_time'), key: 'entry_time' },
    {
      text: i18next.t('summary_labels.entry_price'),
      align: 'right',
      key: 'entry_price',
    },
    { text: i18next.t('summary_labels.exit_time'), key: 'exist_time' },
    {
      text: i18next.t('summary_labels.exit_price'),
      align: 'right',
      key: 'exit_price',
    },

    // {
    //   text: i18next.t('summary_labels.net_profit'),
    //   align: 'right',
    //   hide: !isChargesAndProfit,
    //   key: 'profit_loss',
    // },
    {
      text: i18next.t('summary_detail.todays_change'),
      align: 'right',
      key: 'profit_loss',
    },
    {
      text: `${i18next.t('summary_labels.profit')}/${i18next.t(
        'summary_labels.loss',
      )}`,
      align: 'right',
      hide: isChargesAndProfit,
    },
  ];

  if (!data) return { rows, columns };

  (data as PositionsListLayout[]).map(grp => {
    grp.data &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      grp.data.map((position: Position) => {
        rowCellsData = [];
        const isBuy = position.direction === '0';
        // const netProfitLoss = position.profit_loss - position.totalfees;
        // const netProfitLossPer =
        //   (netProfitLoss * 100) / (position.entry_Price * position.quantity);
        const currentPrice = getCurrentSymbolPrice(
          position.exit_price,
          position.entry_Price,
          liveFeedData,
          position.trading_symbol,
        );
        const { profitLoss, percentChange } = calculateProfitLossAndPerChange(
          currentPrice,
          position,
          true,
        );

        rowCellsData = [
          {
            content: position.bot_name,
            fontWeight: 500,
          },
          {
            content: position.trading_symbol,
            fontWeight: 500,
          },
          {
            content: toTitleCase(position.status),
            fontWeight: 500,
            // align: 'center',
          },
          {
            content: (
              <MuiChip
                label={isBuy ? 'L' : 'S'}
                color={isBuy ? 'infolight' : 'purplelight'}
                variant="filled"
                size="singleLetter"
              />
            ),
            align: 'center',
          },
          {
            content: position.quantity,
            color: isBuy ? 'info.main' : deepPurple[500],
            align: 'center',
          },
          {
            content: (
              <>
                {getTime(position.entry_time)}
                <br />
                {getDate(position.entry_time, false)}
              </>
            ),
          },
          {
            content: position.entry_Price
              ? `${position.currency_symbol}${formatNumber(
                  position.entry_Price,
                )}`
              : '',
            align: 'right',
          },
          {
            content:
              position.exit_time !== '1000-01-01 00:00:00' ? (
                <>
                  {getTime(position.exit_time)}
                  <br />
                  {position.exit_time !== '1000-01-01 00:00:00'
                    ? getDate(position.exit_time, false)
                    : '-'}
                </>
              ) : (
                '-'
              ),
          },
          {
            content: `${position.currency_symbol}${formatNumber(
              position.exit_price,
            )}`,
            align: 'right',
          },
          // {
          //   content: (
          //     <>
          //       {getArrowDirByNum(netProfitLoss)} {position.currency_symbol}
          //       {formatNumber(netProfitLoss, true)}
          //       <br />({netProfitLossPer > 0 ? '+' : ''}
          //       {decimalFormat(netProfitLossPer)}%)
          //     </>
          //   ),
          //   align: 'right',
          //   color: getColorByNum(netProfitLoss),
          //   fontWeight: 500,
          //   hide: !isChargesAndProfit,
          // },
          {
            content: position.exit_price ? (
              <>
                {getArrowDirByNum(position.profit_loss)}{' '}
                {position.currency_symbol}{' '}
                {formatNumber(position.profit_loss, true)}
                <br /> ({position.profit_loss > 0 ? '+' : ''}
                {decimalFormat(position.percentage_change)}%)
              </>
            ) : (
              '-'
            ),
            align: 'right',
            color: getColorByNum(position.profit_loss),
            fontWeight: 500,
            hide: isChargesAndProfit,
          },
          {
            content: (
              <>
                <Box>
                  <Box>
                    <Typography
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="body2"
                      fontWeight={500}
                    >
                      {getArrowDirByNum(profitLoss)} {position.currency_symbol}
                      {formatNumber(profitLoss, true)}
                    </Typography>
                  </Box>
                  <Typography variant="body3">
                    ({percentChange > 0 ? '+' : ''}
                    {decimalFormat(percentChange)}%)
                  </Typography>
                </Box>
              </>
            ),
            // textVariant: 'body3',
            align: 'right',
            color: getColorByNum(profitLoss),
          },
        ];

        rows.push({
          rowCellsData,
          rowData: position,
          handleClick: () => handleClick?.(position),
        });
      });
  });

  return { rows, columns };
};

export const createBotOrderTableRows = (
  data: OrdersListLayoutProps,
  handleOrderRowClick?: (orderData: Order) => void,
): { rows: TableRow<Order>[]; columns: TableColumns[] } => {
  const rows: TableRow<Order>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  const columns: TableColumns[] = [
    { text: i18next.t('summary_labels.date_time'), key: 'order_date' },
    {
      text: i18next.t('summary_labels.status'),
      align: 'center',
      key: 'status',
    },
    { text: i18next.t('summary_labels.tag'), align: 'center', key: 'tag' },
    { text: i18next.t('summary_labels.symbol'), key: 'trading_symbol' },
    {
      text: i18next.t('summary_labels.type'),
      align: 'center',
      key: 'direction',
    },
    { text: i18next.t('summary_labels.qty'), align: 'center', key: 'quantity' },
    {
      text: i18next.t('summary_labels.limit_price'),
      align: 'right',
      key: 'limit_price',
    },
    {
      text: i18next.t('summary_labels.order_price'),
      align: 'right',
      key: 'price',
    },
    {
      text: i18next.t('summary_labels.stop_price'),
      align: 'right',
      key: 'stop_price',
    },
    {
      text: i18next.t('summary_labels.order_value'),
      align: 'right',
      key: 'value',
    },
  ];

  if (!data) return { rows, columns };

  data.map(order => {
    rowCellsData = [];
    const isBuy = order.direction === '0';

    rowCellsData = [
      {
        // content: getDate(order.order_date, true),
        content: (
          <>
            {getTime(order.order_date)}
            <br />
            {getDate(order.order_date, false)}
          </>
        ),
      },
      {
        content: order.status ? (
          <MuiChip
            icon={<>{orderStatus[order.status].icon}</>}
            label={<>{orderStatus[order.status].value}</>}
            size="xsmall"
            color={orderStatus[order.status].chipColor as ChipColor}
          />
        ) : (
          // <MuiChip label={order.tag} size="xsmall" color="greylight" />
          ''
        ),
        align: 'center',
      },
      {
        content: order.tag ? (
          <MuiChip label={order.tag} size="xsmall" color="greylight" />
        ) : (
          ''
        ),
        align: 'center',
      },
      {
        content: order.trading_symbol,
      },
      {
        content: (
          <MuiChip
            label={isBuy ? 'Buy' : 'Sell'}
            color={isBuy ? 'infolight' : 'purplelight'}
            size="xsmall"
            variant="filled"
          />
        ),
        align: 'center',
      },
      {
        content: order.quantity,
        align: 'center',
      },
      {
        content:
          order.type === '1' || order.type === '3'
            ? `${order.currency_symbol}` + formatNumber(order.limit_price)
            : '-',
        align: 'right',
      },
      {
        content: `${order.currency_symbol}` + formatNumber(order.price),
        align: 'right',
      },
      {
        content:
          order.type === '3'
            ? `${order.currency_symbol}` + formatNumber(order.stop_price)
            : '-',
        align: 'right',
      },
      {
        content: `${order.currency_symbol}` + formatNumber(order.value),
        align: 'right',
      },
    ];

    rows.push({
      rowCellsData,
      rowData: order,
      handleClick: () => handleOrderRowClick?.(order),
    });
  });

  return { rows, columns };
};

export const createBotSignalTableRows = (
  data: BotSignal[],
  handleClickRegenerate: (BotSignal) => void,
  handleClickModify: (BotSignal) => void,
  handleClickExit: (BotSignal) => void,
  isModifySignal: boolean,
  isSelectedSignalExit: boolean,
  isCrypto: boolean,
  isLoading?: boolean,
  handleClickSelect?: (signal: BotSignal) => void,
  selectedItems?: number[] | null,
): { rows: TableRow<BotSignal>[]; columns: TableColumns[] } => {
  // const isStartswithportfolio = () => {
  //   return window.location.pathname.startsWith('/portfolio');
  // };
  // const isPortfolio = isStartswithportfolio();
  const rows: TableRow<BotSignal>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  const columns: TableColumns[] = [
    {
      text: '',
      align: 'center',
      hide: !isSelectedSignalExit,
    },
    { text: i18next.t('bot_signals.form.symbol') },
    { text: i18next.t('bot_signals.form.signal_id') },
    { text: i18next.t('bot_signals.form.trade_id') },
    { text: i18next.t('bot_signals.template_form.value'), align: 'right' },
    { text: i18next.t('bot_signals.form.product_type'), hide: isCrypto },
    { text: i18next.t('bot_signals.form.transaction_type') },
    { text: i18next.t('bot_signals.form.order_type') },
    { text: i18next.t('bot_signals.form.position_type') },
    { text: i18next.t('bot_signals.date') },
    { text: '', hide: !isModifySignal },
    { text: '' },
    { text: '' },
  ];

  if (!data) return { rows, columns };

  data.map((signal: BotSignal) => {
    rowCellsData = [
      {
        content: (
          <>
            {signal.position_type === 'EN' && (
              <Checkbox
                checked={
                  selectedItems ? selectedItems.includes(signal.id) : false
                }
                onChange={() => handleClickSelect?.(signal)}
                onClick={e => e.stopPropagation()}
                sx={{ p: 0 }}
              />
            )}
          </>
        ),
        align: 'center',
        hide: !isSelectedSignalExit,
      },
      {
        content: signal.trading_symbol,
        fontWeight: 500,
      },
      {
        content: signal.signal_id,
      },
      {
        content: signal.trade_id,
      },
      {
        content: ['available_balance', 'deployed_capital'].includes(
          signal.position_sizing,
        )
          ? `${signal.quantity}%`
          : signal.position_sizing === 'absolute_capital'
          ? `${signal.currency_symbol}${signal.quantity}`
          : signal.quantity,
        align: 'right',
      },
      { content: signal.product_type, hide: isCrypto },
      {
        content: (
          <MuiChip
            label={signal.transaction_type === 'BUY' ? 'Buy' : 'Sell'}
            color={
              signal.transaction_type === 'BUY' ? 'infolight' : 'purplelight'
            }
            size="xsmall"
            variant="filled"
          />
        ),
      },
      { content: replaceAllChars(signal.order_type, '_', ' ') },
      { content: signal.position_type },
      {
        content: (
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {getTime(signal.signal_date)}
            <br />
            {getDate(signal.signal_date, false)}
          </Typography>
        ),
      },
      {
        content: (
          <Box
            className="hover-visible"
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Tooltip title="Edit">
              <IconButton
                disabled={isLoading}
                onClick={e => {
                  handleClickModify(signal);
                  e.stopPropagation();
                }}
              >
                {isLoading ? (
                  <CircularProgress size={'24px'} color="primary" />
                ) : (
                  <ModeEditIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        ),
        hide: !isModifySignal,
      },
      {
        content: (
          <Box
            className="hover-visible"
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Tooltip title="Regenerate">
              <IconButton
                disabled={isLoading}
                onClick={e => {
                  handleClickRegenerate(signal);
                  e.stopPropagation();
                }}
              >
                {isLoading ? (
                  <CircularProgress size={'24px'} color="primary" />
                ) : (
                  <ContentCopyIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        ),
        // hide: isPortfolio,
      },
      {
        content: (
          <Box
            className="hover-visible"
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Tooltip title="Exit">
              <IconButton
                disabled={isLoading}
                onClick={e => {
                  handleClickExit(signal);
                  e.stopPropagation();
                }}
              >
                {isLoading ? (
                  <CircularProgress size={'24px'} color="primary" />
                ) : (
                  <LogoutIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        ),
        hide: signal.position_type === 'EX',
      },
    ];

    rows.push({
      rowCellsData,
      rowData: signal,
      visibleOnHover: true,
    });
  });

  return { rows, columns };
};
