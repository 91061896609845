import { ChangeEvent, memo } from 'react';
import { Box, InputLabel, IconButton, Input } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const ProfileUploadButton = ({
  id,
  handleChange,
  value,
  isEditable,
}: {
  id: string;
  handleChange: (file: File | null) => void;
  value: File | string | null;
  isEditable: boolean;
}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.files ? event.target.files[0] : null);
  };

  let fileUrl = '';
  const url =
    value && value instanceof File ? URL.createObjectURL(value) : value;
  if (fileUrl !== url) fileUrl = url ? url : '';

  return (
    <>
      <Box
        sx={{
          height: '110px',
          width: '110px',
          borderRadius: '50%',
          border: '3px solid #eeeeee',
          marginTop: '-55px',
          backgroundColor: '#eeeeee !important',
          position: 'relative',
          mx: 'auto',
          ...(fileUrl && { background: `url(${fileUrl})` }),
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        {isEditable && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <InputLabel>
              <IconButton color="primary" component="span" sx={{ zIndex: 1 }}>
                <CameraAltIcon />
              </IconButton>
              <Input
                id={`icon-button-file-${id}`}
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                inputProps={{ accept: 'image/*' }}
              />
            </InputLabel>
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(ProfileUploadButton);
