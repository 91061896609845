import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiModal from 'app/design/uiComponents/MuiModal';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { BotSignal, BotSignalParams } from 'types/ApiServicesTypes';
import { useTranslation } from 'react-i18next';
import useGetInstruments from 'services/Bots/useGetInstruments';
import { useHistory, useParams } from 'react-router-dom';
import usePostBotSignal from 'services/Bots/usePostBotSignal';
import { ToastContext } from 'app/components/Context/ToastContext';
import BotGenerateSignalCommonForm from './BotGenerateSignalCommonForm';
import BotBasketSignalList from './BotBasketSignalList';
import { Instrument } from 'types/Instrument';

export type SignalModalTitleData = {
  symbol: string;
  qty: number;
  transactionType: string;
};

export type SignalFormParameter = {
  isCrypto: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleChangeTitleData: (data: SignalModalTitleData) => void;
  signal?: BotSignal | null;
  type: string;
  instruments: Instrument[];
  isModify: boolean;
  isDebugMode?: boolean;
  onSave: (data: BotSignalParams[], successCallback?: () => void) => void;
};
export const SignalFormDataContext = createContext<SignalFormParameter>(
  {} as SignalFormParameter,
);

const BotGenerateSignalModal = ({
  botId,
  open,
  handleClose,
  signal,
  exchanges,
  isModify,
  isDebugMode,
  setInstrumentLoading,
}: {
  botId: number;
  exchanges: string[];
  open: boolean;
  handleClose: () => void;
  signal?: BotSignal | null;
  isModify: boolean;
  isDebugMode?: boolean;
  from?: string;
  setInstrumentLoading?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}) => {
  const isMdUp = useGetMediaQueryUp('md');
  const { t } = useTranslation();
  const history = useHistory();
  const { showAlert } = useContext(ToastContext);
  const mutation = usePostBotSignal(botId);
  const [type, setType] = useState('SINGLE');
  const [includesManual, setIncludeManual] = useState<boolean>(false);
  const { bid, title, sid } =
    useParams<{ bid: string; title: string; sid: string }>();
  const [titleData, setTitleData] = useState<SignalModalTitleData>({
    symbol: '',
    qty: 1,
    transactionType: 'BUY',
  });

  const { data: instruments, isLoading: instrumentLoading } = useGetInstruments(
    `exchange=${exchanges?.join(',') || 'all'}&type=all`,
  );
  useEffect(() => {
    setInstrumentLoading?.(instrumentLoading);
  }, [instrumentLoading, setInstrumentLoading]);
  const handleChangeTitleData = useCallback((data: SignalModalTitleData) => {
    setTitleData(data);
  }, []);
  useEffect(() => {
    setIncludeManual(window.location.pathname.includes('manual'));
  }, []);

  useEffect(() => {
    if (!signal) {
      setTitleData({
        symbol: '',
        qty: 1,
        transactionType: 'BUY',
      });
    }
  }, [signal]);

  const saveData = (data: BotSignalParams[], successCallback?: () => void) => {
    mutation.mutate(
      { signals: data },
      {
        onSuccess: res => {
          if (res.success) {
            handleClose();
            if (successCallback !== undefined) successCallback();
            {
              showAlert!(t('bot_signals.signal_generated'), 'success');
              void handleClose();
            }
            if (!includesManual) {
              history.replace({
                pathname: `/bots/${bid}/signals/${sid}/todays-positions/${title}`,
                search: isDebugMode ? '?debug=true' : '',
              });
            }
          }
        },
      },
    );
  };

  const initialFormParameterData: SignalFormParameter = {
    isModify: isModify,
    isCrypto: exchanges.includes('CCXT'),
    handleClose: handleClose,
    handleChangeTitleData: handleChangeTitleData,
    signal: signal,
    type: type,
    instruments: instruments ? instruments : [],
    isDebugMode: isDebugMode,
    onSave: saveData,
    isLoading: mutation.isLoading,
  };

  return (
    <MuiModal
      open={open}
      handleClose={handleClose}
      isFullScreen={!isMdUp}
      maxWidth="lg"
      header={
        <Box
          sx={{
            pt: 2,
            backgroundColor:
              titleData.transactionType === 'BUY'
                ? 'success.light'
                : 'error.light',
          }}
          color="#fff"
        >
          <PageHeader
            variant="withIcon"
            titleElipsis
            title={
              // titleData.symbol ? (
              //   `${titleData.symbol} ${
              //     titleData.qty ? 'X ' + titleData.qty.toString() : ''
              //   }`
              // ) : (
              //   <FormattedMessage id="bot_signals.generate_new_signal" />
              // )
              titleData.symbol ? (
                <>
                  <Typography
                    variant={isMdUp ? 'h6' : 'body2'}
                    className="text-ellipsis"
                    display="flex"
                    flexWrap="wrap"
                  >
                    <Box className="text-ellipsis">{titleData.symbol}</Box>
                    <Box sx={{ ml: 0.5 }}>
                      {titleData.qty ? ' X ' + titleData.qty.toString() : ''}
                    </Box>
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    display: 'inline-block',
                    whiteSpace: 'pre-line',
                  }}
                >
                  <FormattedMessage id="bot_signals.generate_new_signal" />
                </Typography>
              )
            }
            iconProps={{
              handleClick: () => handleClose(),
              icon: <CloseIcon fontSize="small" />,
            }}
          />
          <Box className="p-main-spacing" sx={{ mb: 2 }}>
            <ItemSeparator
              variant="2px"
              borderColor={
                titleData.transactionType === 'BUY'
                  ? 'success.light'
                  : 'error.light'
              }
            />
          </Box>
        </Box>
      }
      body={
        <Box
          sx={{
            pb: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          {(!signal || !signal.instrument_id) && (
            <FormControl sx={{ mb: 1 }} className="m-main-spacing">
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={type}
                onChange={e => {
                  setType(e.target.value);
                }}
              >
                <FormControlLabel
                  value="SINGLE"
                  control={<Radio />}
                  label={`${t('bot_signals.single')}`}
                />
                <FormControlLabel
                  value="MULTIPLE"
                  control={<Radio />}
                  label={`${t('bot_signals.basket')}`}
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* <BotGenerateSignalForm
              isModify={isModify}
              isCrypto={exchanges.includes('CCXT')}
              handleClose={handleClose}
              botId={botId}
              handleChangeTitleData={handleChangeTitleData}
              signal={signal}
              type={type}
              instruments={instruments ? instruments : []}
              isDebugMode={isDebugMode}
            /> */}
          {type === 'SINGLE' || signal ? (
            <BotGenerateSignalCommonForm
              isModify={isModify}
              isCrypto={exchanges.includes('CCXT')}
              handleClose={handleClose}
              handleChangeTitleData={handleChangeTitleData}
              signal={signal}
              type={type}
              instruments={instruments ? instruments : []}
              isDebugMode={isDebugMode}
              onSave={saveData}
              isLoading={mutation.isLoading}
            />
          ) : (
            <SignalFormDataContext.Provider value={initialFormParameterData}>
              <BotBasketSignalList />
            </SignalFormDataContext.Provider>
          )}
        </Box>
      }
    />
  );
};

export default memo(BotGenerateSignalModal);
