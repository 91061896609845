import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { Box } from '@mui/system';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import { useTranslation } from 'react-i18next';
import { GetPortfolioShareResult } from 'types/ApiServicesTypes';
import {
  decimalFormat,
  formatNumber,
  getColorByNum,
  getDate,
  getTime,
} from 'utils/GenericFunctions';

const PublicPageTable = ({
  tableData,
}: {
  tableData?: GetPortfolioShareResult;
}) => {
  const { t } = useTranslation();
  const isMdDown = useGetMediaQueryDown('md');

  return (
    <Box
      // className="p-main-spacing"
      sx={{ mt: 1, border: '1px solid #e0e0e0', p: 1, borderRadius: '0.5rem' }}
    >
      {tableData?.data.portfolio.bots.map((position, j) => {
        return (
          <TableContainer key={j}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 2,
                mb: 1,
              }}
            >
              <Typography variant="h6">{position.bot_name}</Typography>
              <Box
                sx={{
                  display: { sx: 'block', md: 'inline-flex' },
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: '#777', mr: 0.5, whiteSpace: 'nowrap' }}
                >
                  {t('portfolio.public.total_pnl')}
                </Typography>
                <Typography
                  variant="body1"
                  color={getColorByNum(position.total_pnl)}
                >
                  {position.currency_symbol}
                  {formatNumber(position.total_pnl)}
                </Typography>
              </Box>
            </Box>
            <ItemSeparator variant="2px" />
            <Table
              sx={{
                '& th': { whiteSpace: 'nowrap' },
                '& td': { fontWeight: 400 },

                '& th,td': {
                  '&:first-child': {
                    pl: 0,
                  },
                  '&:last-child': {
                    pr: 0,
                  },
                },
              }}
            >
              <TableHead>
                {!isMdDown && (
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2">
                        {t('summary_labels.symbol')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {t('summary_labels.qty_short')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {t('summary_labels.position')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">
                        {t('summary_labels.entry_time')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        {t('summary_labels.entry_price')}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">
                        {t('summary_labels.exit_time')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        {t('summary_labels.exit_price')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        {t('summary_labels.profit_loss')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {isMdDown && (
                  <TableRow>
                    <TableCell>
                      Name
                      <br />
                      Qty
                    </TableCell>
                    <TableCell align="right">
                      P&L
                      <br />
                      Price
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              {!isMdDown && (
                <TableBody>
                  {position.positions.map((trade, j) => {
                    return (
                      <TableRow
                        key={j}
                        sx={{
                          '&:last-child td': {
                            borderBottom: 'none',
                          },
                        }}
                      >
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          <Typography variant="body2">
                            {trade.trading_symbol}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={
                            trade.direction
                              ? { color: 'info.main' }
                              : { color: deepPurple[500] }
                          }
                        >
                          {trade.quantity}
                        </TableCell>
                        <TableCell align="center">
                          <MuiChip
                            size="small"
                            label={trade.direction ? 'L' : 'S'}
                            variant="filled"
                            color={
                              trade.direction ? 'infolight' : 'purplelight'
                            }
                          />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          <>
                            {getTime(trade.entry_time)}
                            <br />
                            {getDate(trade.entry_time, false)}
                          </>
                        </TableCell>
                        <TableCell align="right">
                          {position.currency_symbol}
                          {formatNumber(trade.entry_Price)}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          <>
                            {getTime(trade.exit_time)}
                            <br />
                            {getDate(trade.exit_time, false)}
                          </>
                        </TableCell>
                        <TableCell align="right">
                          {position.currency_symbol}
                          {formatNumber(trade.exit_price)}
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{
                            color: getColorByNum(trade.profit_loss),
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          <>
                            {position.currency_symbol}
                            {formatNumber(trade.profit_loss, true)}
                            <br />({trade.profit_loss > 0 ? '+' : ''}
                            {decimalFormat(trade.percentage_change)}%)
                          </>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

              {isMdDown && (
                <TableBody>
                  {position.positions.map((trade, k) => {
                    return (
                      <TableRow
                        key={k}
                        sx={{
                          '&:last-child td': {
                            borderBottom: 'none',
                          },
                        }}
                      >
                        <TableCell>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            pb={1}
                            // whiteSpace={'nowrap'}
                          >
                            <Box mr={1} sx={{ fontWeight: '600' }}>
                              {trade.trading_symbol}
                            </Box>

                            <MuiChip
                              size="small"
                              label={trade.direction ? 'L' : 'S'}
                              variant="filled"
                              color={
                                trade.direction ? 'infolight' : 'purplelight'
                              }
                            />
                          </Box>
                          <Box
                            sx={
                              trade.direction
                                ? { color: 'info.main' }
                                : { color: deepPurple[500] }
                            }
                          >
                            {trade.quantity}
                          </Box>
                          {/* </Box> */}
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            sx={{
                              whiteSpace: 'nowrap', // Add this line
                              overflow: 'hidden', // Add this line to hide overflow if needed
                              textOverflow: 'ellipsis',
                            }}
                            pb={1}
                            variant="body2"
                            color={
                              trade.profit_loss === 0
                                ? 'primary.main'
                                : trade.profit_loss > 0
                                ? 'success.main'
                                : 'error.main'
                            }
                          >
                            {position.currency_symbol}
                            {formatNumber(trade.profit_loss)}
                          </Typography>
                          <Typography>
                            <Typography
                              variant="body2"
                              color={'text.secondary'}
                              component={'span'}
                              mr={0.5}
                            >
                              {position.currency_symbol}
                              {trade.entry_Price} &#10140;
                            </Typography>
                            <Typography variant="body2" component={'span'}>
                              {position.currency_symbol}
                              {trade.exit_price}
                            </Typography>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        );
      })}
    </Box>
  );
};

export default PublicPageTable;
