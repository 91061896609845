import {
  SymbolOptionChainData,
  SymbolOptionChainDataType,
} from 'types/ApiServicesTypes';
import {
  PositionBaseDataType,
  PositionMetaData,
  PositionWithExpiryData,
} from './types';
import moment from 'moment';

export const getMTM = (
  positions: PositionBaseDataType[] | undefined,
  lotSize: number,
  currenTLTP: number,
) => {
  let mtm = 0;
  if (positions) {
    positions.map(position => {
      if (position.direction === 'long') {
        mtm += (currenTLTP - position.premium) * lotSize * position.lots;
      } else if (position.direction === 'short') {
        mtm -= (currenTLTP - position.premium) * lotSize * position.lots;
      }
    });
    return mtm;
  }
  return mtm;
};

export const getLots = (
  positions: PositionBaseDataType[] | undefined,
  date: string,
): { lots: number; avgPrimium: number; direction: string } => {
  if (positions) {
    let buylots = 0;
    let buyAvgPrimium = 0;
    let selllots = 0;
    let sellAvgPrimium = 0;
    positions.map(position => {
      if (moment(date).diff(position.entryTime, 'minute') >= 0) {
        if (position.direction === 'long') {
          buylots += position.lots;
          buyAvgPrimium += position.premium * position.lots;
        } else if (position.direction === 'short') {
          selllots += position.lots;
          sellAvgPrimium += position.premium * position.lots;
        }
      }
    });
    return {
      lots: Math.abs(buylots - selllots),
      avgPrimium:
        buylots > selllots
          ? buyAvgPrimium / buylots
          : sellAvgPrimium / selllots,
      direction: buylots > selllots ? 'short' : 'long',
    };
  }
  return {
    lots: 0,
    avgPrimium: 0,
    direction: '',
  };
};

export const squarOffPosition = (
  position: PositionMetaData,
  currentContractDetails: SymbolOptionChainData,
  date: string,
  lotSize: number,
) => {
  const { direction, lots } = getLots(
    Object.values(position.positionCandleWise),
    date,
  );
  if (
    position.positionCandleWise[date] &&
    position.positionCandleWise[date].direction == direction
  ) {
    position = {
      ...position,
      isSquareOff: true,
      positionCandleWise: {
        ...position.positionCandleWise,
        [`${date}`]: {
          contractDetails:
            position.positionCandleWise[`${date}`].contractDetails,
          direction: position.positionCandleWise[`${date}`].direction,
          entryTime: date,
          iv: position.positionCandleWise[`${date}`].iv,
          lots: position.positionCandleWise[`${date}`].lots + lots,
          premium: position.positionCandleWise[`${date}`].premium,
          quantity:
            (position.positionCandleWise[`${date}`].lots + lots) * lotSize,
        },
      },
    };
  } else if (position.positionCandleWise[date]) {
    position = {
      ...position,
      isSquareOff: true,
      positionCandleWise: {
        ...position.positionCandleWise,
        [`${date}`]: {
          contractDetails:
            position.positionCandleWise[`${date}`].contractDetails,
          direction: direction,
          entryTime: date,
          iv: position.positionCandleWise[`${date}`].iv,
          lots: lots - position.positionCandleWise[`${date}`].lots,
          premium: position.positionCandleWise[`${date}`].premium,
          quantity:
            (lots - position.positionCandleWise[`${date}`].lots) * lotSize,
        },
      },
    };
  } else {
    position = {
      ...position,
      isSquareOff: true,
      positionCandleWise: {
        ...position.positionCandleWise,
        [`${date}`]: {
          contractDetails:
            currentContractDetails || ({} as SymbolOptionChainData),
          direction: direction,
          entryTime: date,
          iv: currentContractDetails?.iv,
          lots: lots,
          premium: currentContractDetails?.close,
          quantity: lots * lotSize,
        },
      },
    };
  }
  return position;
};

export const getExpiredPositions = (
  expiryDate: Array<string>,
  positionsWithExpiry: PositionWithExpiryData,
) => {
  return expiryDate
    .map(expiry => Object.values(positionsWithExpiry[expiry]))
    .flat();
};

export const expiredDateSquarOffPosition = (
  positions: PositionMetaData[],
  optionChain: Record<string, Record<string, SymbolOptionChainDataType>>,
  lotSize: number,
) => {
  return positions.map(position => {
    if (!position.isSquareOff) {
      if (
        optionChain[position.expiryDate!] &&
        optionChain[position.expiryDate!][position.strike]
      )
        return squarOffPosition(
          position,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          optionChain[position.expiryDate!][position.strike][
            `${position.contractType.toUpperCase()}`
          ],
          moment(position.expiryDate)
            .hour(15)
            .minute(30)
            .format('YYYY-MM-DD HH:mm')
            .toString(),
          lotSize,
        );
    }
  });
};
