import { Box, Button, ButtonGroup } from '@mui/material';
import FinancialSummary from '../FinancialSummary';
import PayoffChart from '..';
import { PositionWithExpiryData } from '../../OptionChain/types';
import { OptionChainWithExpiryType } from 'app/pages/Simulator';
import { useState } from 'react';
import PositionGreeksTable from '../GreeksTable/PositionGreeksTable';
import CommonMessage from '../CommonMessage';
import { convertPositionsMetaData } from '../../OptionChain/PositionTab/PositionTab';
import { getMTM } from '../../OptionChain/utils';
type OptionPayoffProps = {
  maxProfitLoss: {
    max_profit: string | number;
    max_loss: string | number;
    breakevenPoints: number[];
  };

  setMaxProfitLoss: (value: {
    max_profit: string | number;
    max_loss: string | number;
    breakevenPoints: number[];
  }) => void;
  date: string;
  optionChainDatawithExpiry: OptionChainWithExpiryType;
  position: PositionWithExpiryData;
  lotSize: number;
};
// const convertPositionsMetaData = (
//   positionsWithExpiryData: PositionWithExpiryData,
// ) => {
//   // eslint-disable-next-line no-console
//   console.time('option');

//   let positions: PositionMetaData[] = [] as PositionMetaData[];
//   positions = Object.values(positionsWithExpiryData)
//     .map(position => Object.values(position))
//     .flat();

//   // eslint-disable-next-line no-console
//   console.timeEnd('option');

//   return positions;
// };
const OptionPayoff = ({
  maxProfitLoss,
  setMaxProfitLoss,
  date,
  optionChainDatawithExpiry,
  position,
  lotSize,
}: OptionPayoffProps) => {
  const [active, setActive] = useState('payoff');

  const graphPosition = convertPositionsMetaData(position);
  const filterredGraphPosition = graphPosition.filter(item => item.isActive);

  let totalMTM = 0;
  graphPosition.forEach(position => {
    let current_ltp = 0;
    const current_ltp_opt =
      optionChainDatawithExpiry &&
      optionChainDatawithExpiry[`${position.expiryDate}`];
    const current_ltp_pos = current_ltp_opt && current_ltp_opt[position.strike];
    if (position.contractType === 'ce') {
      current_ltp = current_ltp_pos?.CE.close || 0;
    } else {
      current_ltp = current_ltp_pos?.PE.close || 0;
    }
    totalMTM += getMTM(
      position.positionCandleWise && Object.values(position.positionCandleWise),
      lotSize,
      current_ltp,
    );
  });
  return (
    <>
      <Box borderBottom={'1px solid rgb(0 0 0 / 23%)'}>
        {' '}
        <Box
          sx={{
            paddingTop: 2,
            paddingX: 2,
            // borderBottom: 1,
            // borderColor: 'divider',
            // borderRadius: '1',
          }}
        >
          <ButtonGroup
            size="small"
            disableElevation
            aria-label="small button group"
            orientation="horizontal"
            sx={{
              mb: 2,
              '& .MuiButtonGroup-grouped': {
                border: '1px solid rgba(0, 0, 0, 0.12)',
                minWidth: 80,
                '&.active': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                  borderColor: 'primary.main',
                },
              },
            }}
            className="toggle-button-group"
          >
            <Button
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.87)',
                borderRadius: '0.8em',
                color: 'rgb(0, 0, 0)',
                '&.active': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                  borderColor: 'primary.main',
                },
              }}
              onClick={() => {
                setActive('payoff');
              }}
              className={active === 'payoff' ? 'active' : ''}
            >
              Payoff
            </Button>
            <Button
              sx={{
                borderRadius: '0.8em',
                border: '1px solid rgba(0, 0, 0, 0.87)',
                color: 'rgb(0, 0, 0)',
                '&.active': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                  borderColor: 'primary.main',
                },
              }}
              onClick={() => {
                setActive('greeks');
              }}
              className={active === 'greeks' ? 'active' : ''}
            >
              Greeks
            </Button>
          </ButtonGroup>
        </Box>
        {filterredGraphPosition.length > 0 && (
          <FinancialSummary
            optionChainData={optionChainDatawithExpiry}
            maxProfitLoss={maxProfitLoss}
            // financialPositionData={financialPositionData}
            positionData={filterredGraphPosition}
            totalMTM={totalMTM}
            lotSize={lotSize}
          />
        )}
      </Box>
      {
        <Box height={'70%'}>
          {active === 'payoff' ? (
            filterredGraphPosition.length > 0 ? (
              <PayoffChart
                date={date}
                setMaxProfitLoss={setMaxProfitLoss}
                positions={filterredGraphPosition}
                lotSize={lotSize}
              />
            ) : (
              <CommonMessage
                title="Check out Payoff Graphs, Statistics, and additional details..."
                desc1="Choose trades from the Option Chain or utilize a"
                desc2="Prebuilt strategy from the Positions tab to see the analysis."
              />
            )
          ) : filterredGraphPosition.length > 0 ? (
            <PositionGreeksTable positions={filterredGraphPosition} />
          ) : (
            <CommonMessage
              title={"You'll find the Greeks here."}
              desc1={'Select trades from the Option Chain or use a'}
              desc2={
                'Prebuilt strategy from the Positions tab to see the Greeks.'
              }
            />
          )}
        </Box>
      }
    </>
  );
};

export default OptionPayoff;
