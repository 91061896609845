import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

const ButtonCard = ({
  position,
  text,
  image,
  link,
  bgColor,
  borderRadius,
  handleClick,
  description,
}: {
  position: string;
  text: React.ReactElement;
  image: string;
  link?: string;
  bgColor?: string;
  borderRadius?: number;
  handleClick?: () => void;
  description?: string;
}): React.ReactElement => {
  return (
    <Box
      component={link ? RouterLink : 'div'}
      {...(link && { to: link })}
      {...(handleClick && { onClick: handleClick })}
      sx={{
        mb: 1,
        ...(link && { display: 'block' }),
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: position,
          px: 1.5,
          py: 2,
          backgroundColor: bgColor ? bgColor : 'grey.100',
          borderRadius: borderRadius ? borderRadius : 0,
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            alignSelf={'flex-start'}
            variant="body1"
            sx={{
              lineHeight: '26px',
              letterSpacing: '0.46px',
              // marginBottom: '', // Add margin between text and description
            }}
          >
            {text}
          </Typography>
          <Typography variant="body3">{description}</Typography>
        </Box>
        <img src={image} />
      </Box>
    </Box>
  );
};

export default ButtonCard;
