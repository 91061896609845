import { useMemo, useState } from 'react';
import { Badge, Box } from '@mui/material';
import PageHeader from 'app/design/speedBot/PageHeader';
import CloseIcon from '@mui/icons-material/Close';
import NotificationListItem from 'app/design/itemsComponent/NotificationListItem';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { GetNotificationsResult } from 'types/ApiServicesTypes';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { transformCategoryNotifications } from './utils';

export type CategoryNotification = {
  [category: string]: {
    notifications: GetNotificationsResult;
    isUnread?: boolean;
  };
};

const FilterChips = ({
  activeTab,
  setActiveTab,
  notifications,
}: {
  activeTab: string;
  setActiveTab: (val: string) => void;
  notifications: CategoryNotification;
}) => {
  return (
    <>
      {Object.keys(notifications).map(item => {
        if (
          !notifications[item].notifications ||
          !notifications[item].notifications.length
        )
          return <></>;

        const chipComp = (
          <MuiChip
            label={item}
            key={item}
            onClick={() => setActiveTab(item)}
            size="medium"
            color={activeTab === item ? 'greyselected' : 'greylight'}
          />
        );

        if (notifications[item].isUnread)
          return (
            <Badge
              color="primary"
              overlap="circular"
              variant="dot"
              key={item}
              sx={{ '& .MuiBadge-dot': { right: '0.35rem' } }}
            >
              {chipComp}
            </Badge>
          );
        return chipComp;
      })}
    </>
  );
};

const NotificationContentBody = ({
  loading,
  unreadCount,
  handleReadAll,
  notificationsData,
  handleItemClick,
  handleCloseModal,
}: {
  loading: boolean;
  unreadCount: number;
  handleReadAll: () => void;
  notificationsData: GetNotificationsResult | undefined;
  handleItemClick: (
    id: number,
    type?: string,
    subscriptionId?: number,
    redirectUrl?: string,
    notificationType?: string,
    brokerId?: number,
  ) => void;
  handleCloseModal: () => void;
}) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('All');

  const isAnyUnread = !loading && unreadCount === 0;

  const notifications = useMemo(() => {
    return transformCategoryNotifications(notificationsData, unreadCount);
  }, [notificationsData, unreadCount]);

  const isShowFilterChips = useMemo(() => {
    if (notifications) {
      return (
        Object.keys(notifications).filter(
          item =>
            notifications[item] &&
            notifications[item].notifications &&
            notifications[item].notifications.length,
        ).length >= 3
      );
    }
    return false;
  }, [notifications]);

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
          zIndex: 1,
          pt: { xs: 0, md: 2 },
        }}
      >
        {isAnyUnread || isConfirm ? (
          <PageHeader
            variant="withButton"
            title={
              <FormattedMessage id="portfolio.page_title.notification.title" />
            }
            buttonText={
              <FormattedMessage
                id={
                  isAnyUnread
                    ? 'portfolio.page_title.notification.all_read'
                    : 'portfolio.page_title.notification.read_all'
                }
              />
            }
            buttonAction={() => {
              if (!isAnyUnread) handleReadAll();
            }}
            {...(notifications &&
              isShowFilterChips && {
                filterChips: (
                  <FilterChips
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    notifications={notifications}
                  />
                ),
              })}
          />
        ) : (
          <PageHeader
            variant="withIcon"
            title={
              <FormattedMessage id="portfolio.page_title.notification.title" />
            }
            iconProps={{
              handleClick: () => setIsConfirm(true),
              icon: <CloseIcon fontSize="small" />,
            }}
            {...(notifications &&
              isShowFilterChips && {
                filterChips: (
                  <FilterChips
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    notifications={notifications}
                  />
                ),
              })}
          />
        )}
      </Box>
      <Box sx={{ mb: 4 }}>
        {notifications && notifications[activeTab] && (
          <NotificationListItem
            items={notifications[activeTab].notifications}
            handleItemClick={handleItemClick}
          />
        )}
      </Box>
      <Box className="m-main-spacing">
        <MuiButton
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCloseModal}
        >
          <FormattedMessage id="buttons.close" />
        </MuiButton>
      </Box>
    </>
  );
};

export default NotificationContentBody;
