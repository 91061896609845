/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import Routes from '../routes';
import { queryClient } from 'services/QueryClient';
import { SocketProvider } from './components/Context/SocketContext';
import { AppMessageProvider } from './components/Context/AppMessage';
import { QueryCacheInvalidationOnAppState } from './components/Common/QueryCacheInvalidation/QueryCacheInvalidationOnAppState';
import { UserProvider } from './components/Context/UserContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import '../firebase';
import { Suspense } from 'react';
import { NotificationPopoverContextProvider } from './components/Context/NotificationPopoverContext';

export default function App() {
  return (
    <BrowserRouter>
      {/* <Helmet
            titleTemplate={`%s - ${data.name}`}
            defaultTitle={data.name}
            htmlAttributes={{ lang: i18n.language }}
          >
            <meta name="description" content={data.name} />
          </Helmet> */}

      <QueryClientProvider client={queryClient}>
        <NotificationPopoverContextProvider>
          <UserProvider>
            <SocketProvider>
              <AppMessageProvider>
                <GoogleOAuthProvider
                  clientId={process.env.REACT_APP_CLIENT_ID!}
                >
                  {/* <FeatureFlagProvider> */}
                  <QueryCacheInvalidationOnAppState />
                  <Suspense fallback={<></>}>
                    <Routes />
                  </Suspense>
                  {/* </FeatureFlagProvider> */}
                </GoogleOAuthProvider>
              </AppMessageProvider>
            </SocketProvider>
          </UserProvider>
        </NotificationPopoverContextProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </BrowserRouter>
  );
}
