import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import InfoModal from 'app/components/Common/InfoModal';
import UserBacktestingForm from 'app/components/Bots/Backtesting/UserBacktestingForm';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

// Service
import useGetUserBot from 'services/Bots/useGetUserBot';
import { UserBacktestingInfoModalData } from '../utils';
import {
  StrategyBuilderTypes,
  StrategyBuiltWithTypes,
} from 'app/components/Bots/StrategyBuilder/util';
import { getQueryParam } from 'utils/GenericFunctions';
import ExportStrategyButton from 'app/components/Bots/ExportStrategyButton/ExportStrategyButton';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export function UserBotBacktestingPage() {
  const breadcrumb = useBreadcrumbManager();
  const { id, sid } = useParams<{ id: string; sid: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const userBotId = parseInt(id);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const selected_version = getQueryParam('versionid');
  const { data: userBotDetails, isLoading } = useGetUserBot(
    userBotId,
    selected_version,
  );
  const botId = userBotDetails?.strategy_id;
  const botDetails = userBotDetails && userBotDetails.sb_strategy;
  const infoClickHandler = () => {
    setOpenInfo(true);
  };
  const handleInfoClose = () => {
    setOpenInfo(false);
  };
  const isImportExportStrategy = useIsFeatureFlag([
    FEATURES.IS_IMPORT_EXPORT_STRATEGY,
  ]);
  const strategyType = userBotDetails?.sb_strategy?.type || '';
  const isShowEdit = strategyType in StrategyBuilderTypes;
  const isBuiltWithApp = botDetails
    ? (botDetails.built_with === null ||
        botDetails.built_with! in StrategyBuiltWithTypes) &&
      botDetails.type in StrategyBuilderTypes
    : false;
  return (
    <>
      <Helmet>
        <title>User bot backtesting</title>
        <meta name="description" content="User bot backtesting" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="withIconWithBack"
        buttonText={userBotDetails ? userBotDetails.name : ''}
        title={
          <FormattedMessage id="user_backtesting.page_title.create_backtest_title" />
        }
        buttonHandleClick={
          userBotDetails?.result_ready
            ? () => history.goBack()
            : `/bots/${userBotDetails?.strategy_id}/details/Bots`
        }
        iconProps={{
          icon: <InfoOutlinedIcon fontSize="small" />,
          handleClick: infoClickHandler,
        }}
        {...(isShowEdit &&
          isBuiltWithApp && {
            subButton: {
              icon: <EditIcon />,
              text: t('buttons.edit_strategy'),
              handleClick: () => {
                breadcrumb.push(
                  `/bot-builder/${id}/${sid ? sid : ''}`,
                  PAGES.BOT_BACK_TESTING_FORM,
                  `/bot/${id}/backtesting/${sid ? sid : ''}`,
                  t('buttons.backtesting'),
                );
              },
              color: 'secondary',
            },
          })}
        breadcrumb={
          <Breadcrumbs
            page={PAGES.BOT_BACK_TESTING_FORM}
            data={{
              botName: userBotDetails?.name,
              userBotId: userBotId,
              botId: botId,
              subId: sid ? sid : 0,
            }}
          />
        }
        secondButton={
          isImportExportStrategy && userBotDetails ? (
            <ExportStrategyButton strategyId={userBotDetails.strategy_id} />
          ) : (
            <></>
          )
        }
      />

      {userBotDetails && (
        <UserBacktestingForm
          userBotDetails={userBotDetails}
          botId={userBotId}
          subId={sid ? parseInt(sid) : 0}
        />
      )}

      <InfoModal
        data={UserBacktestingInfoModalData}
        open={openInfo}
        handleClose={handleInfoClose}
        closeBtn={true}
      />
    </>
  );
}
