import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import PageHeader from 'app/design/speedBot/PageHeader';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useParams } from 'react-router-dom';
import useGetBotTemplateDetails from 'services/BotTemplates/useGetTemplateDetails';
import BotDescription from 'app/design/speedBot/ComponentItem/Bots/BotDescription';
import StrategyDetailContent from 'app/components/Bots/Strategy/StrategyDetailContent';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { Box } from '@mui/material';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useState } from 'react';
import IframeDialog from 'app/components/Common/IframeDialog';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SignInModal from 'app/components/Auth/SignInModal';
import useSaveUserBot from 'services/Bots/useSaveUserBot';
import { SaveUserBotResult } from 'types/ApiServicesTypes';
import { UserBot } from 'app/components/Bots/StrategyBuilder/types';
import { isUserLoggedIn } from 'utils/auth';

export function BotTemplateDetailsPage() {
  const { t } = useTranslation();
  const { data: siteInfo } = useGetSiteInfo();
  const [open, setOpen] = useState(false);
  const isMdUp = useGetMediaQueryUp('md');
  const breadcrumb = useBreadcrumbManager();
  const { id } = useParams<{ id: string; title: string }>();
  const { data: templateDetail, isLoading } = useGetBotTemplateDetails(id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const saveUserBotSuccessCallback = (
    res?: SaveUserBotResult,
    // name?: string,
  ) => {
    if (res?.success) {
      templateDetail &&
        breadcrumb.push(
          `/bot/${res.bot_id}/backtesting/0`,
          PAGES.TEMPLATES_DETAIL,
          `/templates/bot/${id}/${encodeURI(templateDetail.name)}`,
          templateDetail.name,
        );
    } else {
      // showAlert!(
      //   res?.error ? res.error : 'Something went wrong. Please try again.',
      // );
    }
  };
  const isLoggedIn = isUserLoggedIn();
  const mutationAdd = useSaveUserBot(saveUserBotSuccessCallback);

  return (
    <>
      <Helmet>
        <title>Bot Template Details</title>
        <meta name="description" content="Bot Template Details" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <PageHeader
        variant="inactive"
        buttonText={t('templates.page_title.templates')}
        title={templateDetail?.name || ''}
        buttonHandleClick={'/templates'}
        breadcrumb={<Breadcrumbs page={PAGES.TEMPLATES_DETAIL} data={{}} />}
        {...(isMdUp &&
          templateDetail && {
            rightElement: (
              <Box>
                <MuiButton
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpen(true)}
                  startIcon={<HelpOutlineIcon />}
                  sx={{ minWidth: '192px' }}
                >
                  {t('buttons.how_to')}
                </MuiButton>
                <MuiButton
                  variant="contained"
                  disabled={
                    templateDetail.strategy_type === 'custom' && !isLoggedIn
                  }
                  color="primary"
                  onClick={() => {
                    // isLoggedIn
                    // breadcrumb.push(
                    //   `/template-bot-builder/${id}`,
                    //   PAGES.TEMPLATES_DETAIL,
                    //   `/templates/bot/${id}/${encodeURI(templateDetail.name)}`,
                    //   templateDetail.name,
                    if (templateDetail.strategy_type === 'custom') {
                      mutationAdd.mutate(
                        {
                          sb_strategy: { ...templateDetail.params },
                          name: templateDetail.name,
                        } as UserBot,
                        {
                          onSuccess: res => {
                            if (res.success)
                              breadcrumb.push(
                                `/bot/${res.bot_id}/backtesting/0`,
                                PAGES.TEMPLATES_DETAIL,
                                `/templates/bot/${id}/${encodeURI(
                                  templateDetail.name,
                                )}`,
                                templateDetail.name,
                              );
                          },
                        },
                      );
                    } else {
                      breadcrumb.push(
                        `/template-bot-builder/${id}`,
                        PAGES.TEMPLATES_DETAIL,
                        `/templates/bot/${id}/${encodeURI(
                          templateDetail.name,
                        )}`,
                        templateDetail.name,
                      );
                    }

                    // );
                    // : setIsModalOpen(true);
                  }}
                  sx={{ ml: 1, minWidth: '192px' }}
                >
                  {t('templates.detail.clone_bot')}
                </MuiButton>
              </Box>
            ),
          })}
      />
      {templateDetail && (
        <>
          {!isMdUp && (
            <Box className="p-main-spacing" display={'flex'} gap={1}>
              <MuiButton
                variant="contained"
                color="secondary"
                onClick={() => setOpen(true)}
                startIcon={<HelpOutlineIcon />}
                sx={{ width: '100%' }}
              >
                {t('buttons.how_to')}
              </MuiButton>
              <MuiButton
                disabled={
                  templateDetail.strategy_type === 'custom' && !isLoggedIn
                }
                variant="contained"
                color="primary"
                onClick={() => {
                  if (templateDetail.strategy_type === 'custom') {
                    mutationAdd.mutate(
                      {
                        sb_strategy: { ...templateDetail.params },
                        name: templateDetail.name,
                      } as UserBot,
                      {
                        onSuccess: res => {
                          breadcrumb.push(
                            `/bot/${res.bot_id}backtesting/0`,
                            PAGES.TEMPLATES_DETAIL,
                            `/templates/bot/${id}/${encodeURI(
                              templateDetail.name,
                            )}`,
                            templateDetail.name,
                          );
                        },
                      },
                    );
                  } else {
                    breadcrumb.push(
                      `/template-bot-builder/${id}`,
                      PAGES.TEMPLATES_DETAIL,
                      `/templates/bot/${id}/${encodeURI(templateDetail.name)}`,
                      templateDetail.name,
                    );
                  }
                }}
                sx={{ width: '100%' }}
              >
                {t('templates.detail.clone_bot')}
              </MuiButton>
            </Box>
          )}
          <BotDescription
            expandedDesc
            youTubeUrl={templateDetail.youtube_video_url}
            image={templateDetail.image}
            desc={templateDetail.description}
            showMoreLength={15}
          />
          <StrategyDetailContent
            userBotDetails={{
              name: templateDetail.name,
              sb_strategy: templateDetail.params,
            }}
          />
        </>
      )}
      <IframeDialog
        open={open}
        link={
          siteInfo && siteInfo.bot_template_help_video_link
            ? siteInfo.bot_template_help_video_link
            : 'https://www.youtube.com/embed/uV6bmoy-QDk'
        }
        handleClose={handleClose}
      />
      <SignInModal
        handleClose={() => setIsModalOpen(false)}
        open={isModalOpen}
      />
    </>
  );
}
