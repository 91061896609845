import { Box, Divider } from '@mui/material';
import TradeFilter from 'app/components/TradeIdeas/TradeFilter';
import TradeTable from 'app/components/TradeIdeas/TradeTable';
import PageHeader from 'app/design/speedBot/PageHeader';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import usePostTradeIdeasData from 'services/TradeIdeas/useGetTradeIdeasData';
import { getQueryParams } from 'utils/GenericFunctions';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { TradesType } from 'app/components/TradeIdeas/types';
import TradeRowDetailsModal from './TradeRowDetailsModal';

const TradeIdeas = () => {
  const mutation = usePostTradeIdeasData();

  const { symbol, view } = getQueryParams(['symbol', 'view']);
  const [tradeData, setTradeData] = useState<TradesType | null>(null);
  const [open, setOpen] = useState(false);

  const handleFilter = (filter: Record<string, string>) => {
    mutation.mutate({
      symbol: symbol || 'NIFTY 50',
      view: view || 'bullish',
      ...filter,
    });
  };
  useEffect(() => {
    mutation.mutate({ symbol: symbol || 'NIFTY 50', view: view || 'bullish' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickTradeRowClick = (trade: TradesType) => {
    setTradeData(trade);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <CircularLoader open={false} zIndex={1302} />
      <Helmet>
        <title>Trade Ideas</title>
        <meta name="description" content="TradeIdeas Page" />
      </Helmet>
      <Box className="sticky-top">
        <PageHeader
          variant="inactive"
          buttonText={'Trade Ideas'}
          title={'Trade Table'}
          buttonHandleClick={`/trade-options?symbol=${symbol}&view=${view}`}
          breadcrumb={<Breadcrumbs page={PAGES.TRADE_OPTIONS} data={{}} />}
        />
        <Divider sx={{ borderBottom: 2, borderColor: 'grey.300' }} />
      </Box>
      <TradeFilter
        handleFilter={handleFilter}
        defaultValue={{ symbol: symbol || 'NIFTY 50', view: view || 'bullish' }}
      />
      <TradeTable
        tradeData={mutation.data?.trades}
        handleTradeRowClick={handleClickTradeRowClick}
      />
      <TradeRowDetailsModal
        open={open}
        handleClose={handleClose}
        tradeData={tradeData}
      />
    </Box>
  );
};

export default TradeIdeas;
