import { styled } from '@mui/material/styles';
import SwitchUnstyled from '@mui/base/SwitchUnstyled';
// import { grey } from '@mui/material/colors';

const MuiSwitchUnstyled = styled(SwitchUnstyled)(({ theme }) => ({
  fontSize: 0,
  position: 'relative',
  display: 'inline-block',
  width: '42px',
  height: '22px',
  cursor: 'pointer',

  '&.Mui-disabled': {
    opacity: '0.4',
    cursor: 'not-allowed',
  },

  '& .MuiSwitch-track': {
    background: theme.palette.grey[300],
    borderRadius: '11px',
    display: 'block',
    height: '100%',
    width: '100%',
    position: 'absolute',
  },

  '& .MuiSwitch-thumb': {
    display: 'block',
    width: '16px',
    height: '16px',
    top: '3px',
    left: '4px',
    borderRadius: '16px',
    backgroundColor: '#fff',
    position: 'relative',
    transition: 'all 200ms ease',
  },

  '&.Mui-focusVisible .MuiSwitch-thumb': {
    backgroundColor: theme.palette.grey[300],
    boxShadow: '0 0 1px 8px rgba(0, 0, 0, 0.25)',
  },

  '&.Mui-checked': {
    '.MuiSwitch-thumb': {
      left: '22px',
      top: '3px',
      backgroundColor: '#fff',
    },
    '.MuiSwitch-track': {
      background: theme.palette.grey[700],
    },
  },

  '& .MuiSwitch-input': {
    cursor: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    opacity: '0',
    zIndex: '1',
    margin: '0',
  },
}));

export default MuiSwitchUnstyled;
