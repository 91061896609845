import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { getCookie } from 'typescript-cookie';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import MarketPlaceBox from 'app/design/layouts/MarketPlaceBox';
import HoldingBanner from 'app/design/uiComponents/HoldingBanner';
import PageHeader from 'app/design/speedBot/PageHeader';
import { IS_PORTFOLIO_PAPER_AVAIL } from 'constants/common';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import HoldingBanner2 from '../../../../assets/images/banner/HoldingBanner2.png';

// Service
import { GetMarketplaceBots } from 'services/apiServices';
import { Errors, GetMarketplaceBotsResult } from 'types/ApiServicesTypes';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { ToastContext } from 'app/components/Context/ToastContext';
import {
  useIsFeatureFlag,
  FeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

export function MarketplacePage() {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const { showAlert } = useContext(ToastContext);

  const [isSelectable, setIsSelectable] = useState(false);
  const [selectedBots, setSelectedBots] = useState<number[]>([]);

  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);

  const query = useQuery<GetMarketplaceBotsResult, Errors>(
    ['GetMarketplaceBots', 'marketplace'],
    () => GetMarketplaceBots('marketplace', 'GET', !isChargesAndProfit),
  );
  const { data: marketplaceBotsData, isLoading } = query;

  const isMarketPaperAvail = getCookie(IS_PORTFOLIO_PAPER_AVAIL)
    ? getCookie(IS_PORTFOLIO_PAPER_AVAIL) === 'true'
    : false;

  useEffect(() => {
    breadcrumb.resetBreadcrumpPaths();
  }, [breadcrumb]);

  const handleSelectBot = useCallback(
    (id: number) => {
      const selectedBotsData = [...selectedBots];
      const index = selectedBots.findIndex(x => x === id);
      if (index === -1) {
        if (selectedBots.length >= 2) selectedBotsData.splice(0, 1);
        selectedBotsData.push(id);
      } else selectedBotsData.splice(index, 1);

      setSelectedBots(selectedBotsData);
    },
    [selectedBots],
  );

  const handleClickCompare = () => {
    if (selectedBots.length === 2) {
      breadcrumb.push(
        `/discover/bots/compare/${selectedBots[0]}/${selectedBots[1]}`,
        PAGES.MARKETPLACE,
        `/discover/bots`,
        t('market.page_title.market'),
      );
    } else {
      showAlert!('Select at least 2 bots', 'warning');
    }
  };

  const isFeatureEquity = useIsFeatureFlag([FEATURES.IS_EQUITY_BOTBUILDER]);
  const isFeatureOption = useIsFeatureFlag([FEATURES.IS_OPTION_BOTBUILDER]);

  return (
    <>
      <Helmet>
        <title>Discover</title>
        <meta name="description" content="Discover Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <Box className="sticky-top">
        <PageHeader
          variant="market"
          title={<FormattedMessage id="market.page_title.market" />}
          marketIconProps={{
            // link: '/discover/bots/search',
            icon: (
              <SearchIcon
                fontSize="medium"
                color="primary"
                sx={{ ml: { md: 1 } }}
              />
            ),
            iconClick: () =>
              breadcrumb.push(
                '/discover/bots/search',
                PAGES.MARKETPLACE,
                `/discover/bots`,
                t('market.page_title.market'),
              ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent={{ xs: 'end', md: 'space-between' }}
        gap={2}
        className="p-main-spacing"
        sx={{ mt: 1 }}
        alignItems="center"
      >
        <FeatureFlag features={[FEATURES.IS_COMPARE_BOTS]} fallback={<></>}>
          <Box display={{ xs: 'none', md: 'flex' }} gap={2}>
            {isSelectable ? (
              <>
                <MuiButton
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    display: { xs: 'none', md: 'block' },
                  }}
                  onClick={() => {
                    setIsSelectable(false);
                  }}
                >
                  <FormattedMessage id="buttons.cancel" />
                </MuiButton>
                <MuiButton
                  variant="contained"
                  color="primary"
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    display: { xs: 'none', md: 'block' },
                  }}
                  onClick={handleClickCompare}
                >
                  <FormattedMessage id="buttons.compare" />
                </MuiButton>
              </>
            ) : (
              <MuiButton
                variant="contained"
                color="primary"
                sx={{
                  width: { xs: '100%', md: 'auto' },
                  display: { xs: 'none', md: 'block' },
                }}
                onClick={() => {
                  setIsSelectable(true);
                }}
              >
                <FormattedMessage id="buttons.compare_bots" />
              </MuiButton>
            )}
          </Box>
        </FeatureFlag>

        {(isFeatureEquity || isFeatureOption) && (
          <FeatureFlag features={[FEATURES.IS_BACKTESTING]} fallback={<></>}>
            <Box
              display="flex"
              justifyContent="end"
              gap={2}
              sx={{
                width: { xs: '100%', md: 'auto' },
              }}
            >
              {isFeatureEquity && isFeatureOption ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="end"
                    gap={2}
                    sx={{
                      width: { xs: '100%', md: 'auto' },
                    }}
                  >
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      sx={{ width: { xs: '100%', md: 'auto' } }}
                      onClick={() => {
                        breadcrumb.push(
                          '/bot-builder?type=equity&source=Portfolio',
                          PAGES.MARKETPLACE,
                          `/discover/bots`,
                          t('market.page_title.market'),
                        );
                      }}
                    >
                      <FormattedMessage id="buttons.create_equity_bot" />
                    </MuiButton>
                    <MuiButton
                      variant="contained"
                      color="secondary"
                      sx={{ width: { xs: '100%', md: 'auto' } }}
                      onClick={() => {
                        breadcrumb.push(
                          '/bot-builder?type=option&source=Portfolio',
                          PAGES.MARKETPLACE,
                          `/discover/bots`,
                          t('market.page_title.market'),
                        );
                      }}
                    >
                      <FormattedMessage id="buttons.create_option_bot" />
                    </MuiButton>
                  </Box>
                </>
              ) : (
                <>
                  <MuiButton
                    variant="contained"
                    color="secondary"
                    sx={{ width: { xs: '100%', md: 'auto' }, alignSelf: 'end' }}
                    onClick={() => {
                      breadcrumb.push(
                        `/bot-builder?type=${
                          isFeatureEquity ? 'equity' : 'option'
                        }&source=Portfolio`,
                        PAGES.MARKETPLACE,
                        `/discover/bots`,
                        t('market.page_title.market'),
                      );
                    }}
                  >
                    <FormattedMessage id="buttons.create_bot" />
                  </MuiButton>
                </>
              )}
            </Box>
          </FeatureFlag>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="end"
        gap={2}
        className="p-main-spacing"
        sx={{ mt: 1, display: { xs: 'flex', md: 'none' } }}
      >
        {isSelectable ? (
          <>
            <MuiButton
              variant="contained"
              color="secondary"
              sx={{ width: { xs: '100%', md: 'auto' } }}
              onClick={() => {
                setIsSelectable(false);
              }}
            >
              <FormattedMessage id="buttons.cancel" />
            </MuiButton>
            <MuiButton
              variant="contained"
              color="primary"
              sx={{ width: { xs: '100%', md: 'auto' } }}
              onClick={handleClickCompare}
            >
              <FormattedMessage id="buttons.compare" />
            </MuiButton>
          </>
        ) : (
          <MuiButton
            variant="contained"
            color="primary"
            sx={{ width: { xs: '100%', md: 'auto' } }}
            onClick={() => setIsSelectable(true)}
          >
            <FormattedMessage id="buttons.compare_bots" />
          </MuiButton>
        )}
      </Box>
      {!isMarketPaperAvail ? (
        <Box sx={{ mt: 1 }}>
          <HoldingBanner
            position="start"
            text={
              <>
                <FormattedMessage id="market.banner.select" />
                <br />
                <FormattedMessage id="market.banner.to" />
                <br />
                <FormattedMessage id="market.banner.where" />
              </>
            }
            image={HoldingBanner2}
            link="/discover/bots"
          />
        </Box>
      ) : (
        <></>
      )}
      <Box>
        {marketplaceBotsData && marketplaceBotsData.length > 0 && (
          <MarketPlaceBox
            type="market"
            categories={marketplaceBotsData[0].category_data}
            isVisibleCheckbox={isSelectable}
            selectedBots={selectedBots}
            handleSelectBot={handleSelectBot}
          />
        )}
      </Box>
    </>
  );
}
