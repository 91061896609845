import { createTheme } from '@mui/material/styles';
import { PaletteColorOptions } from '@mui/material';
import { red, grey, green, blue, deepPurple } from '@mui/material/colors';
import React from 'react';

declare module '@mui/material/styles' {
  interface CustomPalette {
    greylight: PaletteColorOptions;
  }
  interface BreakpointOverrides {
    max: true;
  }
  // interface Palette extends CustomPalette {}
  // interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xlarge: true;
    xxlarge: true;
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    purplelight: true;
    greyselected: true;
    greylight: true;
    infolight: true;
    successlight: true;
    errorlight: true;
    warninglight: true;
  }
  interface ChipPropsSizeOverrides {
    singleLetter: true;
    xsmall: true;
    xsmallicon: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    h3: false;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = mainColor =>
  augmentColor({ color: { main: mainColor as string } });

const paletteData = {
  purplelight: createColor(deepPurple[50]),
  greyselected: createColor(grey[800]),
  greylight: createColor(grey[200]),
  infolight: createColor(blue[50]),
  successlight: createColor(green[50]),
  errorlight: createColor(red[50]),
  warninglight: createColor('#FDF0E6'),
  common: {
    black: '#000',
    white: '#fff',
  },
  primary: {
    main: 'rgba(0, 0, 0, 0.87)',
    dark: '#000',
    light: 'rgba(0, 0, 0, 0.6)',
    contrastText: '#fff',
  },
  secondary: {
    main: grey['200'],
    dark: grey['500'],
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  error: {
    // light: red[50],
    main: red[500],
    dark: red[700],
  },
  warning: {
    main: '#ED6C02',
    dark: '#C77700',
  },
  success: {
    // light: green[50],
    main: green[500],
    dark: green[700],
  },
  info: {
    main: blue[500],
    dark: blue[700],
    // light: blue[50],
  },
  grey: {
    50: grey[50],
    100: grey[100],
    200: grey[200],
    300: grey[300],
    500: grey[500],
    600: grey[600],
    700: grey[700],
    800: grey[800],
    900: grey[900],
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  background: {
    paper: '#fff',
    default: '#fff',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
};

// Create a theme instance.
const theme = createTheme({
  // palette: themePalette(themeOption),
  palette: paletteData,
  breakpoints: {
    values: {
      // xs: 0,
      // sm: 600,
      // md: 900,
      // lg: 1200,
      // xl: 1536,
      xs: 0,
      sm: 352,
      md: 640,
      lg: 880,
      xl: 1040,
      max: 1600,
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontWeight: '300',
      fontSize: '96px',
      lineHeight: '112px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: '300',
      fontSize: '60px',
      lineHeight: '72px',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontWeight: '400',
      fontSize: '48px',
      lineHeight: '56px',
    },
    h4: {
      fontWeight: '400',
      fontSize: '34px',
      lineHeight: '42px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '32px',
    },
    h6: {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.15px',
    },
    body3: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '15.6px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '28px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    overline: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '32px',
      letterSpacing: '1px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.4px',
      color: paletteData.grey?.[600],
    },
  },
  components: {
    // MuiChip: {
    //   styleOverrides: {
    //     root: {
    //       letterSpacing: '0.16px',
    //       textTransform: 'capitalize',
    //       '& .MuiChip-label': {
    //         letterSpacing: '0.16px',
    //         padding: '6px 10px',
    //       },
    //     },
    //   },
    // },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': { height: '3px', zIndex: 1 },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiList-padding': { paddingTop: '4px', paddingBottom: '4px' },
          '& .MuiListItemText-root': { margin: 0 },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: paletteData.grey?.[200],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardWarning': {
            backgroundColor: '#FDF0E6',
            '& .MuiAlert-icon': {
              color: paletteData.warning.main,
            },
            '& .MuiAlert-message': {
              color: '#5F2B01',
            },
          },
        },
      },
    },
  },
});

export default theme;
