import { Box, Typography } from '@mui/material';
import SnakbarMessageModal from 'app/design/uiComponents/SnakbarMessageModal';
import { PositionMetaData } from './types';
import { formatDateString } from 'utils/GenericFunctions';
import { getLots } from './utils';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { SymbolOptionChainDataType } from 'types/ApiServicesTypes';

type PositionSquareOffModalProps = {
  open: boolean;
  handleClose: (success: boolean) => void;
  positions: PositionMetaData[];
  currentLTP: Record<string, Record<string, SymbolOptionChainDataType>>;
};

const PositionSquareOffModal = ({
  open,
  handleClose,
  positions,
  currentLTP,
}: PositionSquareOffModalProps) => {
  return (
    <SnakbarMessageModal
      open={open}
      handleClose={() => handleClose(false)}
      body={
        <Box>
          <Typography>Expiry Alert</Typography>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography>Positions</Typography>
            <Typography>Expiry Price</Typography>
          </Box>
          {positions.map(position => {
            return (
              <Box
                key={position.expiryDate}
                display={'flex'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'}>
                  <Typography variant="body2">
                    {formatDateString(position.expiryDate!, 'DD MMM')}
                  </Typography>
                  <Typography variant="body2">
                    {Number(position.strike)}
                  </Typography>
                  <Typography variant="body2">
                    {position.contractType.toUpperCase()}
                  </Typography>
                  <Typography variant="body2">
                    x{' '}
                    {
                      getLots(
                        Object.values(position.positionCandleWise),
                        position.expiryDate!,
                      ).lots
                    }
                  </Typography>
                </Box>
                <Typography>
                  {currentLTP[position.expiryDate!] &&
                    currentLTP[position.expiryDate!][position.strike] &&
                    currentLTP[position.expiryDate!][position.strike][
                      `${position.contractType.toUpperCase()}`
                    ].close}
                </Typography>
              </Box>
            );
          })}
        </Box>
      }
      footer={
        <>
          <MuiButton
            fullWidth
            variant="contained"
            sx={{ mb: 2 }}
            onClick={() => handleClose(true)}
          >
            <FormattedMessage id="buttons.continue_with_squareoff" />
          </MuiButton>
          <MuiButton
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => handleClose(false)}
          >
            <FormattedMessage id="buttons.jump_to_expiry" />
          </MuiButton>
        </>
      }
    />
  );
};

export default PositionSquareOffModal;
