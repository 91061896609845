import React from 'react';
import { Theme } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { IconButton } from '@mui/material';
import { FieldData } from './fields/types';
import { Condition } from './types';
import { FieldWrapper } from './FieldWrapper';
import { MathFieldWrapper } from './MathFieldWrapper';
import { SelectField } from './fields/SelectField';
import useClasses from './utils/useClasses';
import { StrategyMode } from './utils/strategy-data';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getValidateErrorIndex } from './utils/StrategyValidation';

// Style const
const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    border: '1px dashed #d3d3d3',
    borderRadius: '8px',
  },
  invalid: {
    border: '1px dashed #f44336 !important',
  },
  label: {
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 0.5, 0),
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    margin: '8px 0px 0px 0px',
    padding: '0px 10px',
    // padding: theme.spacing(0, 2),
  },
  AddIconVisible: {
    display: 'block !important',
    color: 'red !important',
  },
  addFieldIcon: {
    color: '#616161',
    padding: '0px',
    marginBottom: '8px',
  },
  icon: {
    width: '4px',
    height: '16px',
    padding: 0,
  },
});

interface Props {
  condition: Condition;
  conditionIndex: number;
  onAddField: (
    conditionIndex: number,
    field: FieldData,
    fieldIndex: number,
  ) => void;
  // onAddFieldAtPosition: (
  //   conditionIndex: number,
  //   field: FieldData,
  //   fieldIndex: number,
  // ) => void;
  onEditField: (
    conditionIndex: number,
    field: FieldData,
    fieldIndex: number,
  ) => void;
  onRemoveField: (conditionIndex: number, fieldIndex: number) => void;
  onRemoveCondition: (conditionIndex: number) => void;
  onCopyCondition: (conditionIndex: number) => void;
  onPasteCondition: (conditionIndex: number) => void;
  getPossibleValues: (fieldIndex: number, fields: FieldData[]) => string[];
  copiedStrategy?: Condition;
  mode: string;
  index: number;
  defaultResolution?: string;
}

export function ConditionItem(props: Props) {
  const classes = useClasses(styles);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const {
    condition,
    conditionIndex,
    onAddField,
    onRemoveField,
    onEditField,
    onRemoveCondition,
    onCopyCondition,
    onPasteCondition,
    getPossibleValues,
    copiedStrategy,
    mode,
    index,
    defaultResolution,
  } = props;
  const { fields } = condition;

  // We can directly compute add field index using fields length.
  // But, using useState for addFieldIndex because if we want to allow user to
  //  move select between existing fields then updated add field index needs
  //  to be stored somewhere.
  // ConditionItem component, won't recreate state variable addFieldIndex every item
  //  it re-renderer.
  // Now, because we have store initial add field index value to fields.length,
  //  whenever, we add new field, we should set add field index (doing that in useEffect)
  //  to new fields length.
  // If we don't do that, ConditionItem will still be having old addFieldIndex.
  const [addFieldIndex, setAddFieldIndex] = React.useState<number>(
    fields.length,
  );
  const [showSelectField, setShowSelectField] = React.useState<boolean>(false);
  // const selectFields = React.useState<any[]>([]);
  const [possibleValues, setPossibleValues] = React.useState<string[]>([]);
  // const [expErrorStatus, setExpErrorStatus] = React.useState<{
  //   status: boolean;
  //   errorIndex: number;
  // }>(
  //   fields.length
  //     ? getValidateErrorIndex(fields)
  //     : {
  //         status: false,
  //         errorIndex: -1,
  //       },
  // );
  const expErrorStatus: { status: boolean; errorIndex: number } = fields.length
    ? getValidateErrorIndex(fields)
    : { status: false, errorIndex: -1 };

  const onAddIconClick = () => {
    setAddFieldIndex(fields.length);
    setShowSelectField(true);
    const possibleValues = getPossibleValues(fields.length, fields);
    setPossibleValues(possibleValues);
  };
  const onRemove = React.useCallback(
    (fieldIndex: number) => {
      onRemoveField(conditionIndex, fieldIndex);
    },
    [conditionIndex, onRemoveField],
  );

  const onRemoveConditionClick = React.useCallback(() => {
    onRemoveCondition(conditionIndex);
    setAnchorEl(null);
  }, [conditionIndex, onRemoveCondition]);

  const onCopyClick = React.useCallback(() => {
    onCopyCondition(conditionIndex);
    setAnchorEl(null);
  }, [conditionIndex, onCopyCondition]);

  const onPasteClick = React.useCallback(() => {
    onPasteCondition(conditionIndex);
    setAnchorEl(null);
  }, [conditionIndex, onPasteCondition]);

  const onDone = React.useCallback(
    (fieldData: FieldData) => {
      onAddField(conditionIndex, fieldData, addFieldIndex);
      setShowSelectField(false);
    },
    [conditionIndex, addFieldIndex, onAddField],
  );

  const onAddFieldAtPosition = (fieldIndex: number) => {
    setAddFieldIndex(fieldIndex);
    setShowSelectField(true);
    const possibleValues = getPossibleValues(fieldIndex, fields);
    setPossibleValues(possibleValues);
  };

  const onEditClicked = (fieldIndex: number) => {
    const possibleValues = getPossibleValues(fieldIndex, fields);
    setPossibleValues(possibleValues);
  };

  const onEdit = React.useCallback(
    (fieldIndex: number, fieldData: FieldData) => {
      onEditField(conditionIndex, fieldData, fieldIndex);
    },
    [conditionIndex, onEditField],
  );

  const onCancel = React.useCallback(() => {
    setShowSelectField(false);
  }, [setShowSelectField]);

  React.useEffect(() => {
    // Calling setAddFieldIndex to update addFieldIndex, otherwise it will still use old one.
    // Remember, addFieldIndex does not initalised every time component re-render. It's useState.
    setAddFieldIndex(fields.length);
  }, [fields]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      className={classes.container}
      sx={{
        flexDirection: mode === StrategyMode.VIEW ? 'row' : 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: mode === StrategyMode.EDIT ? 'center' : 'auto',
          justifyContent: 'space-between',
          padding: mode === StrategyMode.EDIT ? '10px' : '0px',
        }}
      >
        <Box
          sx={{
            display: mode === StrategyMode.EDIT ? 'flex' : '',
            alignItems: 'center',
            paddingLeft: '10px',
            width: mode === StrategyMode.EDIT ? 'auto' : '90px',
            marginTop: mode === StrategyMode.EDIT ? '0px' : '8px',
          }}
        >
          <Typography variant="body2" color="text.secondary" mr={'10px'}>
            {index}.
          </Typography>
          <Typography variant="body3" color="text.disabled">
            Condition
          </Typography>
        </Box>
        {mode === StrategyMode.EDIT ? (
          <IconButton id="menu" onClick={handleClick} className={classes.icon}>
            <MoreVertIcon fontSize={'small'} />
          </IconButton>
        ) : (
          <></>
        )}
      </Box>
      <Box className={classes.fieldsContainer}>
        {fields.map((field, index) => {
          const output: JSX.Element[] = [];

          if (showSelectField && index === addFieldIndex) {
            output.push(
              <SelectField
                onDone={onDone}
                onCancel={onCancel}
                possibleValues={possibleValues}
                onRemove={onCancel}
                defaultResolution={defaultResolution}
              />,
            );
          }
          if (field.type === 'mathfunctions') {
            output.push(
              <MathFieldWrapper
                key={index}
                fieldData={field}
                fieldIndex={index}
                onRemove={onRemove}
                onAddFieldAtPosition={onAddFieldAtPosition}
                onEdit={onEdit}
                mode={mode}
                isValid={expErrorStatus}
              />,
            );
          } else {
            output.push(
              <FieldWrapper
                key={index}
                fieldData={field}
                fieldIndex={index}
                onRemove={onRemove}
                onAddFieldAtPosition={onAddFieldAtPosition}
                onEdit={onEdit}
                possibleValues={possibleValues}
                onEditClicked={onEditClicked}
                mode={mode}
                isValid={expErrorStatus}
              />,
            );
          }

          return <>{output}</>;
        })}
        {mode === StrategyMode.EDIT ? (
          (showSelectField && addFieldIndex === fields.length && (
            <SelectField
              onDone={onDone}
              onCancel={onCancel}
              possibleValues={possibleValues}
              onRemove={onCancel}
              defaultResolution={defaultResolution}
            />
          )) || (
            <IconButton
              className={`${classes.addFieldIcon} ${
                expErrorStatus &&
                expErrorStatus.status &&
                expErrorStatus.errorIndex === fields.length - 1
                  ? classes.AddIconVisible
                  : ''
              }`}
              color="secondary"
              onClick={onAddIconClick}
            >
              <AddCircleSharpIcon fontSize={'medium'} />
            </IconButton>
          )
        ) : (
          <></>
        )}
      </Box>
      {/* {mode === StrategyMode.EDIT ? (
        <>
          <DeleteButton onClick={onRemoveConditionClick} />
          <CopyButton onClick={onCopyClick} />
          <PasteButton onClick={onPasteClick} copiedStrategy={copiedStrategy} />
        </>
      ) : (
        <></>
      )} */}
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <MenuItem onClick={onCopyClick}>Copy</MenuItem>
        {copiedStrategy && copiedStrategy.id ? (
          <MenuItem onClick={onPasteClick}>Replace with paste</MenuItem>
        ) : (
          <div></div>
        )}
        <MenuItem onClick={onRemoveConditionClick}>Remove</MenuItem>
      </Menu>
    </Box>
  );
}
