import { Helmet } from 'react-helmet-async';
import PageHeader from 'app/design/speedBot/PageHeader';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { Box } from '@mui/material';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import MuiBreadcrumbs from 'app/design/uiComponents/MuiBreadcrumbs';
import { useTranslation } from 'react-i18next';
import MarketplaceSearchContainer from 'app/components/Marketplace/MarketplaceSearchContainer';

export function TradersSearchPage() {
  const { t } = useTranslation();
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      <Helmet>
        <title>Search Traders</title>
        <meta name="description" content="Search Traders Page" />
      </Helmet>
      {/* <CircularLoader open={isLoading} /> */}
      {!isMdUp ? (
        <Box className="sticky-top">
          <PageHeader
            variant="inactive"
            buttonText={<FormattedMessage id="traders.page_title.traders" />}
            title={<FormattedMessage id="traders.page_title.search_traders" />}
            buttonHandleClick="/traders"
          />
        </Box>
      ) : (
        <Box sx={{ m: 3, mb: 0 }}>
          <MuiBreadcrumbs
            links={[
              {
                text: t('traders.page_title.traders'),
                to: '/traders',
                active: true,
              },
            ]}
          />
        </Box>
      )}
      <MarketplaceSearchContainer />
    </>
  );
}
