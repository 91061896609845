import { InfoOutlined } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FEATURES } from 'types/Feature';
import { IntervalsData } from '../StrategyBuilder/util';
import { formatDateString, getLastFridayDate } from 'utils/GenericFunctions';
type FormikValueTypes = {
  brokerage_fees: string;
  per_order_fees: number;
  slippage: number;
  trading_start_time?: string | undefined;
  trading_stop_time?: string | undefined;
  resolution?: string | undefined;
  initial_capital: string;
  duration: string;
  start_date: string;
  end_date: string;
};
type UserBacktestingProps = {
  values: FormikValueTypes;
  errors: FormikErrors<FormikValueTypes>;
  touched: FormikTouched<FormikValueTypes>;
  isOptionType: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleBlur: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: React.FocusEvent<any>): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  setDropDownValue: React.Dispatch<React.SetStateAction<string>>;
};
const durations = [
  'Last 1 Month',
  'Last 3 Months',
  'Last 6 Months',
  'Last 1 Year',
];
const UserBacktestingFormGrid = ({
  errors,
  values,
  touched,
  isOptionType,
  setFieldValue,
  handleBlur,
  setDropDownValue,
}: UserBacktestingProps) => {
  const getPopoverContent = selectedValue => {
    switch (selectedValue) {
      case 'zerodha':
      case 'standard':
        return t('user_backtesting.brokerage_tip.standard');
      case 'custom':
        return t('user_backtesting.brokerage_tip.custom');
      case 'percentage':
        return t('user_backtesting.brokerage_tip.percentage');
      default:
        return '';
    }
  };
  const is1600Up = useMediaQuery('(min-width:1600px)');
  const { t } = useTranslation();
  const maxEndDateStr = getLastFridayDate().format('YYYY-MM-DD');
  const maxEndDate = new Date(maxEndDateStr);
  const isBrokerageFees = useIsFeatureFlag([FEATURES.IS_BROKERAGE_FEES]);
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={isOptionType ? 12 : 6}
        lg={is1600Up ? 4 : 6}
        order={1}
      >
        <FormControl
          fullWidth
          error={Boolean(touched.initial_capital && errors.initial_capital)}
          variant="outlined"
        >
          <InputLabel htmlFor="label-initial_capital">
            <FormattedMessage id="user_backtesting.form.initial_capital" />
          </InputLabel>
          <OutlinedInput
            id="label-initial_capital"
            name="initial_capital"
            type="number"
            value={values.initial_capital}
            onBlur={handleBlur}
            onChange={e => setFieldValue('initial_capital', e.target.value)}
            label={t('user_backtesting.form.initial_capital')}
            inputProps={{ min: 0, step: '5' }}
            endAdornment={
              <InputAdornment sx={{ mr: 2 }} position="end">
                <Tooltip title={`${t('user_backtesting.initial_capital_tip')}`}>
                  <InfoOutlined sx={{ float: 'right' }} fontSize="small" />
                </Tooltip>
              </InputAdornment>
            }
          />
          {touched.initial_capital && errors.initial_capital && (
            <FormHelperText error>{errors.initial_capital}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={is1600Up ? 4 : 6} order={2}>
        <FormControl fullWidth sx={{ mb: 0 }}>
          <InputLabel id="Duration">
            <FormattedMessage id="user_backtesting.form.duration" />
          </InputLabel>
          <Select
            labelId="label-duration"
            name="duration"
            value={values.duration}
            label={<FormattedMessage id="user_backtesting.form.duration" />}
            onChange={e => {
              setFieldValue('duration', e.target.value);
              setDropDownValue(e.target.value);
            }}
            endAdornment={
              <InputAdornment sx={{ mr: 2 }} position="end">
                <Tooltip title={`${t('user_backtesting.duration_tip')}`}>
                  <InfoOutlined sx={{ float: 'right' }} fontSize="small" />
                </Tooltip>
              </InputAdornment>
            }
          >
            {durations.map((duration, i) => (
              <MenuItem value={duration} key={i}>
                {duration}
              </MenuItem>
            ))}
            <MenuItem value="Custom">
              <FormattedMessage id="user_backtesting.form.custom" />
            </MenuItem>
          </Select>
          {touched.duration && errors.duration && (
            <FormHelperText error>{errors.duration}</FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6} lg={is1600Up ? 4 : 6} order={5}>
        <FormControl
          fullWidth
          error={Boolean(touched.slippage && errors.slippage)}
          variant="outlined"
        >
          <InputLabel htmlFor="label-slippage">
            <FormattedMessage id="user_backtesting.form.slippage" />
          </InputLabel>
          <OutlinedInput
            id="label-slippage"
            name="slippage"
            type="number"
            value={values.slippage}
            onBlur={handleBlur}
            onChange={e => setFieldValue('slippage', e.target.value)}
            label={t('user_backtesting.form.slippage')}
            inputProps={{ step: '0.1' }}
            endAdornment={
              <InputAdornment sx={{ mr: 2 }} position="end">
                <Tooltip title={`${t('user_backtesting.slippage_tip')}`}>
                  <InfoOutlined sx={{ float: 'right' }} fontSize="small" />
                </Tooltip>
              </InputAdornment>
            }
          />
          {touched.slippage && errors.slippage && (
            <FormHelperText error>{errors.slippage}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      {!isOptionType && (
        <Grid item xs={12} md={6} lg={is1600Up ? 4 : 6} order={6}>
          <FormControl
            fullWidth
            error={Boolean(touched.resolution && errors.resolution)}
          >
            <InputLabel id="label-resolution">
              <FormattedMessage id="user_backtesting.form.interval" />
            </InputLabel>
            <Select
              labelId="label-resolution"
              name="resolution"
              value={values.resolution}
              label={t('user_backtesting.form.interval')}
              onChange={e => {
                setFieldValue('resolution', e.target.value);
              }}
            >
              {IntervalsData.map(interval => (
                <MenuItem value={interval.value} key={interval.value}>
                  {interval.label}
                </MenuItem>
              ))}
            </Select>
            {touched.resolution && errors.resolution && (
              <FormHelperText error>{errors.resolution}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      )}
      {/* <Grid item xs={12} md={6}></Grid> */}
      {values.duration == 'Custom' && (
        <>
          <Grid item xs={12} md={6} lg={is1600Up ? 4 : 6} order={3}>
            <TextField
              fullWidth
              variant="outlined"
              id="label-start_date"
              name="start_date"
              label={t('user_backtesting.form.start_date')}
              type="date"
              value={values.start_date}
              onBlur={handleBlur}
              onChange={e => setFieldValue('start_date', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  // min: formatDateString(
                  //   new Date(
                  //     new Date().setFullYear(
                  //       new Date().getFullYear() - 5,
                  //     ),
                  //   ),
                  //   'YYYY-MM-DD',
                  // ),
                  min: isOptionType ? '2015-01-01' : '2017-01-01',
                  max:
                    isOptionType && new Date(values.end_date) > maxEndDate
                      ? maxEndDateStr
                      : values.end_date,
                },
                endAdornment: (
                  <InputAdornment sx={{ mr: 2 }} position="end">
                    <Tooltip title={`${t('user_backtesting.start_date_tip')}`}>
                      <InfoOutlined sx={{ float: 'right' }} fontSize="small" />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.start_date && errors.start_date)}
              helperText={touched.start_date && errors.start_date}
            />
            {/* <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={Boolean(touched.start_date && errors.start_date)}
        >
          <DesktopDatePicker
            label={t('user_backtesting.form.start_date')}
            inputFormat="DD/MM/YYYY"
            value={
              !values.start_date
                ? null
                : convertStringToDate(
                    values.start_date,
                    'DD/MM/YYYY',
                  )
            }
            onChange={(newValue: Date | null) => {
              if (newValue)
                setFieldValue(
                  'start_date',
                  formatDateString(newValue, 'DD/MM/YYYY'),
                );
              else setFieldValue('start_date', '');
            }}
            // minDate={minDate}
            // maxDate={convertStringToDate(
            //   values.end_date,
            //   'DD/MM/YYYY',
            // )}
            
            renderInput={params => <TextField {...params} />}
          />
          {touched.start_date && errors.start_date && (
            <FormHelperText error>
              {errors.start_date}
            </FormHelperText>
          )}
        </FormControl> */}
          </Grid>
          <Grid item xs={12} md={6} lg={is1600Up ? 4 : 6} order={4}>
            <TextField
              fullWidth
              variant="outlined"
              id="label-end_date"
              label={t('user_backtesting.form.end_date')}
              type="date"
              value={values.end_date}
              onBlur={handleBlur}
              onChange={e => setFieldValue('end_date', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  min: values.start_date,
                  max: isOptionType
                    ? maxEndDateStr
                    : formatDateString(
                        new Date(new Date().setDate(new Date().getDate() - 1)),
                        'YYYY-MM-DD',
                      ),
                },
                endAdornment: (
                  <InputAdornment sx={{ mr: 2 }} position="end">
                    <Tooltip title={`${t('user_backtesting.end_date_tip')}`}>
                      <InfoOutlined sx={{ float: 'right' }} fontSize="small" />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.end_date && errors.end_date)}
              helperText={touched.end_date && errors.end_date}
            />

            {/* <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={Boolean(touched.end_date && errors.end_date)}
        >
          <DesktopDatePicker
            label={t('user_backtesting.form.end_date')}
            inputFormat="DD/MM/YYYY"
            value={
              !values.end_date
                ? null
                : convertStringToDate(
                    values.end_date,
                    'DD/MM/YYYY',
                  )
            }
            onChange={(newValue: Date | null) => {
              if (newValue)
                setFieldValue(
                  'end_date',
                  formatDateString(newValue, 'DD/MM/YYYY'),
                );
              else setFieldValue('end_date', '');
            }}
            // minDate={convertStringToDate(
            //   values.start_date,
            //   'DD/MM/YYYY',
            // )}
            maxDate={maxDate}
            renderInput={params => <TextField {...params} />}
          />
          {touched.end_date && errors.end_date && (
            <FormHelperText error>
              {errors.end_date}
            </FormHelperText>
          )}
        </FormControl> */}
          </Grid>
        </>
      )}
      {!isOptionType && (
        <>
          <Grid item xs={12} md={6} lg={is1600Up ? 4 : 6} order={7}>
            <TextField
              fullWidth
              variant="outlined"
              id="label-trading_start_time"
              label={t('user_backtesting.form.entry_time')}
              type="time"
              value={values.trading_start_time}
              onBlur={handleBlur}
              onChange={e =>
                setFieldValue('trading_start_time', e.target.value)
              }
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              error={Boolean(
                touched.trading_start_time && errors.trading_start_time,
              )}
              helperText={
                touched.trading_start_time && errors.trading_start_time
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={is1600Up ? 4 : 6} order={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="label-trading_stop_time"
              label={t('user_backtesting.form.exit_time')}
              type="time"
              value={values.trading_stop_time}
              onBlur={handleBlur}
              onChange={e => setFieldValue('trading_stop_time', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              error={Boolean(
                touched.trading_stop_time && errors.trading_stop_time,
              )}
              helperText={touched.trading_stop_time && errors.trading_stop_time}
            />
          </Grid>
        </>
      )}
      {isBrokerageFees && (
        <>
          <Grid item xs={12} md={6} lg={is1600Up ? 4 : 6} order={9}>
            <FormControl
              fullWidth
              sx={{
                mb: 0,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <InputLabel id="Brokerage Fees">
                <FormattedMessage id="user_backtesting.form.brokerage_fees" />
              </InputLabel>
              <Select
                fullWidth
                labelId="label-brokerage_fees"
                name="Brokerage Fees"
                value={values.brokerage_fees}
                label={
                  <FormattedMessage id="user_backtesting.form.brokerage_fees" />
                }
                endAdornment={
                  <InputAdornment sx={{ mr: 2 }} position="end">
                    {getPopoverContent(values.brokerage_fees) && (
                      <Tooltip title={getPopoverContent(values.brokerage_fees)}>
                        <InfoOutlined
                          sx={{ float: 'right' }}
                          fontSize="small"
                        />
                      </Tooltip>
                    )}
                  </InputAdornment>
                }
                onChange={e => {
                  setFieldValue('brokerage_fees', e.target.value.toLowerCase());
                  // setDropDownValue(e.target.value);
                }}
                // onOpen={() => setMenuOpen(false)}
                // onClose={() => setMenuOpen(true)}
              >
                <MenuItem value="zerodha">
                  <FormattedMessage id="user_backtesting.form.standard" />
                </MenuItem>
                <MenuItem value="custom">
                  <FormattedMessage id="user_backtesting.form.custom" />
                </MenuItem>
              </Select>

              {touched.duration && errors.duration && (
                <FormHelperText error>{errors.duration}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {values.brokerage_fees !== 'standard' && (
            <Grid item xs={12} md={6} lg={is1600Up ? 4 : 6} order={10}>
              <FormControl
                fullWidth
                error={Boolean(
                  touched.initial_capital && errors.initial_capital,
                )}
                variant="outlined"
              >
                <InputLabel htmlFor="label-per_order_fees">
                  {values.brokerage_fees === 'zerodha' ? (
                    <FormattedMessage id="user_backtesting.form.per_order_fees" />
                  ) : (
                    <FormattedMessage id="user_backtesting.form.per_order_plus_other_charges" />
                  )}
                </InputLabel>
                <OutlinedInput
                  id="label-per_order_fees"
                  name="per_order_fees"
                  type="number"
                  value={values.per_order_fees.toString()}
                  onBlur={handleBlur}
                  onChange={e =>
                    setFieldValue('per_order_fees', Number(e.target.value))
                  }
                  label={
                    values.brokerage_fees === 'zerodha'
                      ? t('user_backtesting.form.per_order_fees')
                      : t('user_backtesting.form.per_order_plus_other_charges')
                  }
                  inputProps={{ min: 0, step: '5' }}
                />
                {touched.initial_capital && errors.initial_capital && (
                  <FormHelperText error>
                    {errors.initial_capital}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default UserBacktestingFormGrid;
