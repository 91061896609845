import { Box, Tooltip } from '@mui/material';
import useGetOptionBuilderHelpData from 'services/Bots/useGetOptionBuilderHelpData';

type CustomTooltipProps = {
  tooltipKey?: string;
  children: ({
    isTooltipAvailable,
  }: {
    isTooltipAvailable: boolean;
  }) => JSX.Element;
};

function CustomTooltip({ tooltipKey, children }: CustomTooltipProps) {
  const { data: helpData } = useGetOptionBuilderHelpData();

  const isTooltipAvailable: boolean =
    helpData && helpData[tooltipKey!] ? true : false;
  if (tooltipKey && helpData)
    return (
      <Tooltip
        PopperProps={{
          style: {
            display: helpData && helpData[tooltipKey] ? '' : 'none',
          },
        }}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: '#555555',
              maxWidth: '500px',
              maxHeight: '30vh',
              overflow: 'auto',
            },
          },
          arrow: {
            sx: {
              color: '#555555',
            },
          },
        }}
        enterTouchDelay={0}
        leaveTouchDelay={2500}
        arrow
        title={
          <div
            dangerouslySetInnerHTML={{
              //eslint-disable-next-line
              __html: helpData[tooltipKey],
            }}
          />
        }
      >
        <Box>{children({ isTooltipAvailable })}</Box>
      </Tooltip>
    );
  return <>{children({ isTooltipAvailable })}</>;
}

export default CustomTooltip;
