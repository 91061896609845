import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import SymbolCard from './SymbolCard';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useState } from 'react';
import TradeDetailsCard from './TradeDetailsCard';
import useGetTradeOptionsData from 'services/TradeIdeas/useGetTradeOptionsData';
import moment from 'moment';
import { SYMBOL } from '../utils';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { getQueryParams } from 'utils/GenericFunctions';

type TradeOptionsCardProps = {
  selectedSymbol: string;
  description: string;
  expiry: string;
};

export default function TradeOptionsCard({
  selectedSymbol,
  description,
  expiry,
}: TradeOptionsCardProps) {
  const { symbol, view } = getQueryParams(['symbol', 'view']);
  const [selectedView, setSelectedView] = useState(
    symbol === selectedSymbol ? view : 'bullish',
  );
  const breadcrumb = useBreadcrumbManager();

  const isMdDown = useGetMediaQueryDown('md');

  const { data: trade_options } = useGetTradeOptionsData(
    selectedSymbol,
    selectedView || 'bullish',
  );
  // eslint-disable-next-line no-console
  console.log('trade cards', trade_options, view, selectedView);
  const expiryDayDiff = moment(expiry).diff(
    moment().format('YYYY-MM-DD'),
    'day',
  );

  const handleViewAll = () => {
    breadcrumb.push(
      `/trade-options/trade-table?symbol=${selectedSymbol}&view=${selectedView}`,
      PAGES.TRADE_OPTIONS,
      `/trade-options?symbol=${symbol}&view=${selectedView}`,
      'Trade Ideas',
    );
  };
  return (
    <Box
      sx={{
        borderRadius: 2,
        border: '2px solid grey.300',
        boxShadow: 4,
        m: 2,
        py: 2,
        bgcolor: 'white',
      }}
    >
      <Box
        display={'flex'}
        className="p-main-spacing"
        justifyContent={'space-between'}
      >
        <Typography>{`Where do you think ${
          SYMBOL[`${selectedSymbol}`]
        } will go ${
          expiryDayDiff === 0
            ? 'today'
            : expiryDayDiff === 1
            ? 'tomorrow'
            : `in ${expiryDayDiff} days`
        } ?`}</Typography>
        <Box
          sx={{
            display: 'flex',
            p: 0,
            cursor: 'pointer',
            alignItems: 'center',
            color: '#1877F2',
          }}
          onClick={handleViewAll}
        >
          <Typography variant="subtitle2">View All</Typography>
          <KeyboardDoubleArrowRight sx={{ fontSize: '17px' }} />
        </Box>
      </Box>
      <Grid container my={2}>
        <Grid item md={12}>
          <SymbolCard symbol={selectedSymbol} description={description} />
        </Grid>
      </Grid>
      <Box mx={1} display={'flex'} justifyContent={'space-around'}>
        {['Bullish', 'Bearish', 'Sideways', 'Directional'].map(
          (view_type, index) => (
            <Box
              key={index}
              sx={{
                flex: 1,
                p: 2,
                borderRadius: 2,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                background: `${
                  selectedView === view_type.toLowerCase()
                    ? ButtonBackgroundColorValue[selectedView]
                    : ''
                }`,
              }}
            >
              <MuiButton
                variant="outlined"
                fullWidth
                color="inherit"
                sx={{
                  border:
                    selectedView === view_type.toLowerCase()
                      ? `1px solid ${BorderColorValue[selectedView]}`
                      : '',
                  // bgcolor: 'red',
                }}
                size={isMdDown ? 'small' : 'medium'}
                onClick={() => {
                  view === view_type.toLowerCase()
                    ? setSelectedView('')
                    : setSelectedView(view_type.toLowerCase());
                }}
              >
                {view_type}
              </MuiButton>
            </Box>
          ),
        )}
      </Box>
      {selectedView && (
        <Box
          sx={{
            // bgcolor: '#e5effb',
            background: `${CardBackgroundColorValue[selectedView]}`,
            p: 2,
            mx: 1,
            display: 'flex',
            flex: 1,
            flexDirection: isMdDown ? 'column' : 'row',
            justifyContent: 'space-between',
            borderRadius:
              selectedView === 'bullish'
                ? '0px 8px 8px 8px'
                : selectedView === 'directional'
                ? '8px 0px 8px 8px'
                : 1,
          }}
        >
          {trade_options && trade_options.trades ? (
            Object.keys(trade_options.trades).map((trade, index) => {
              return (
                <>
                  {trade_options.trades[trade] && (
                    <TradeDetailsCard
                      key={index}
                      symbol={selectedSymbol}
                      trade_type={trade}
                      trade_details={trade_options.trades[trade]}
                      view={selectedView}
                    />
                  )}
                </>
              );
            })
          ) : (
            <CircularProgress />
          )}
        </Box>
      )}
    </Box>
  );
}

const CardBackgroundColorValue = {
  bullish: '#22ab9411',
  bearish: '#c3090911',
  sideways: 'linear-gradient(90deg, #01874911 1.24%, #e3da0811 28.8%)',
  directional: 'linear-gradient(90deg, #01874911 14.24%, #e3da0811 70.8%)',
};

const ButtonBackgroundColorValue = {
  bullish: '#22ab9411',
  bearish: '#c3090911',
  sideways: '#e3da0811',
  directional: '#e3da0811',
};

const BorderColorValue = {
  bullish: '#67e1cc',
  bearish: '#f63434',
  sideways: '#e9df08',
  directional: '#e9df08',
};
