import { Box, Typography } from '@mui/material';
import {
  TableColumns,
  TableRow,
  TableRowCellsData,
} from 'app/components/Common/CommonTable/type';
import i18next from 'i18next';
import {
  SymbolOptionChainData,
  SymbolOptionChainDataType,
} from 'types/ApiServicesTypes';
import { PositionBaseDataType, PositionData } from '../types';
import { ShoppingBag } from '@mui/icons-material';
import { getLots } from '../utils';
import WarningAmber from '@mui/icons-material/WarningAmber';

//#region Option chain buysell button and table
type BuySellProps = {
  handleClick: (
    position: PositionBaseDataType,
    contractType: string,
    strike: string,
  ) => void;
  strike: string;
  contractDetails: SymbolOptionChainData;
  contractType: string;
  position?: PositionBaseDataType;
  date: string;
  lots?: number;
};

const BuySellButton = ({
  strike,
  handleClick,
  contractDetails,
  contractType,
  date,
  position,
  lots,
}: BuySellProps) =>
  // useMemo(() =>
  {
    const handleButtonClick = (clickedButton: string) => {
      if (clickedButton === 'long' || clickedButton === 'short') {
        if (position) {
          handleClick(
            {
              ...position,
              direction: clickedButton,
              lots: Number(position.lots) + 1,
            },
            contractType,
            strike,
          );
        } else {
          handleClick(
            {
              direction: clickedButton,
              lots: 1,
              iv: contractDetails.iv,
              premium: contractDetails.close,
              entryTime: date,
              contractDetails: contractDetails,
            },
            contractType,
            strike,
          );
        }
      }
    };
    return (
      <Box
        display={'flex'}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
          }}
          className={position?.entryTime === date ? '' : 'hover-visible'}
        >
          {position && position.direction === 'long' && position.lots > 0 && (
            <Typography variant="caption">{position.lots}X</Typography>
          )}
          <Box
            sx={{
              borderRadius: 0.7,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'success.main',
              mx: 0.3,
              my: 0.5,
              cursor: 'pointer',
              height: '1.2rem',
              width: '1.2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transitionDuration: '0ms',
              transition: 'none',
              backgroundColor:
                position && position.direction === 'long' ? 'success.main' : '',
              '&:hover': {
                backgroundColor:
                  position && position.direction === 'long'
                    ? 'success.dark'
                    : '',
              },
            }}
            onClick={() => {
              handleButtonClick('long');
            }}
          >
            <Typography
              sx={{
                color:
                  position && position.direction === 'long'
                    ? 'white'
                    : 'success.main',
                fontSize: 14,
              }}
            >
              B
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: 0.7,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'error.main',
              mx: 0.3,
              my: 0.5,
              cursor: 'pointer',
              height: '1.2rem',
              width: '1.2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transitionDuration: '0ms',
              transition: 'none',
              backgroundColor:
                position && position.direction === 'short' ? 'error.main' : '',
              '&:hover': {
                backgroundColor:
                  position && position.direction === 'short'
                    ? 'error.dark'
                    : '',
              },
            }}
            onClick={() => {
              handleButtonClick('short');
            }}
          >
            <Typography
              sx={{
                color:
                  position && position.direction === 'short'
                    ? 'white'
                    : 'error.main',
                fontSize: 14,
              }}
            >
              S
            </Typography>
          </Box>
          {position && position.direction === 'short' && position.lots > 0 && (
            <Typography variant="caption">X{position.lots}</Typography>
          )}
        </Box>
        {lots && lots > 0 ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShoppingBag sx={{ fontSize: '15px', color: 'grey' }} />
            <Typography sx={{ fontSize: '15px', color: 'grey' }}>
              {lots}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
// , [handleClick, date]);

const optionRows = (
  rowData: SymbolOptionChainDataType,
  strike: string,
  isATM: boolean,
  isITM: boolean,
  isOTM: boolean,
  isGreeks: boolean,
  handleClick: (
    position: PositionBaseDataType,
    contractType: string,
    strike: string,
  ) => void,
  positionData: PositionData,
  isCE: boolean,
  date: string,
): TableRowCellsData[] => {
  const ceLTP = rowData.CE.close ? rowData.CE.close.toFixed(2) : 0;
  const peLTP = rowData.PE.close ? rowData.PE.close.toFixed(2) : 0;
  const cePositions = positionData[`CE_${strike}`];
  const pePositions = positionData[`PE_${strike}`];

  const isShowBS = !isGreeks
    ? Number(ceLTP) > 0
      ? true
      : false
    : isCE
    ? Number(ceLTP) > 0
      ? true
      : false
    : Number(peLTP) > 0
    ? true
    : false;
  return [
    {
      content: rowData.CE.delta ? rowData.CE.delta.toFixed(2) : '-',
      backgroudColor: isATM ? '#0782da4d' : isITM ? '#fffbe5' : '',
      hide: isGreeks,
    },
    {
      content: isGreeks ? (
        isCE ? (
          Number(ceLTP) > 0 ? (
            ceLTP
          ) : (
            <WarningAmber />
          )
        ) : Number(peLTP) > 0 ? (
          peLTP
        ) : (
          <WarningAmber />
        )
      ) : Number(ceLTP) > 0 ? (
        ceLTP
      ) : (
        <WarningAmber />
      ),
      align: 'center',
      color: isGreeks
        ? isCE
          ? Number(ceLTP) > 0
            ? ''
            : '#FFB200'
          : Number(peLTP) > 0
          ? ''
          : '#FFB200'
        : Number(ceLTP) > 0
        ? ''
        : '#FFB200',
      backgroudColor: isATM ? '#0782da4d' : isITM ? '#fffbe5' : '',
    },
    {
      content: isShowBS
        ? BuySellButton({
            handleClick: handleClick,
            strike: strike,
            contractDetails: !isGreeks
              ? rowData.CE
              : isCE
              ? rowData.CE
              : rowData.PE,
            contractType: !isGreeks ? 'ce' : isCE ? 'ce' : 'pe',
            position: !isGreeks
              ? cePositions && cePositions.positionCandleWise[date]
              : isCE
              ? cePositions && cePositions.positionCandleWise[date]
              : pePositions && pePositions.positionCandleWise[date],
            date: date,
            lots: !isGreeks
              ? getLots(
                  cePositions && Object.values(cePositions.positionCandleWise),
                  date,
                ).lots
              : isCE
              ? getLots(
                  cePositions && Object.values(cePositions.positionCandleWise),
                  date,
                ).lots
              : getLots(
                  pePositions && Object.values(pePositions.positionCandleWise),
                  date,
                ).lots,
          })
        : '',
      backgroudColor: isATM ? '#0782da4d' : isITM ? '#fffbe5' : '',
    },
    {
      content: Number(strike),
      fontWeight: 700,
      backgroudColor: isATM ? '#0782da4d' : '',
    },
    {
      content: isCE
        ? rowData.CE.iv
          ? rowData.CE.iv.toFixed(2)
          : 0
        : rowData.PE.iv
        ? rowData.PE.iv.toFixed(2)
        : 0,
      backgroudColor: isATM ? '#0782da4d' : '',
      hide: !isGreeks,
    },
    {
      content:
        Number(peLTP) > 0
          ? BuySellButton({
              handleClick: handleClick,
              strike: strike,
              contractDetails: rowData.PE,
              contractType: 'pe',
              position:
                pePositions &&
                pePositions.positionCandleWise &&
                pePositions.positionCandleWise[date],
              date: date,
              lots: getLots(
                pePositions &&
                  pePositions.positionCandleWise &&
                  Object.values(pePositions.positionCandleWise),
                date,
              ).lots,
            })
          : '',
      backgroudColor: isATM ? '#0782da4d' : isOTM ? '#fffbe5' : '',
      hide: isGreeks,
    },
    {
      content: rowData.PE.close ? (
        rowData.PE.close.toFixed(2)
      ) : (
        <WarningAmber />
      ),
      backgroudColor: isATM ? '#0782da4d' : isOTM ? '#fffbe5' : '',
      hide: isGreeks,
      align: 'center',
      color: !rowData.PE.close ? '#FFB200' : '',
    },
    {
      content: isCE
        ? rowData.CE.delta
          ? rowData.CE.delta.toFixed(2)
          : '-'
        : rowData.PE.delta
        ? rowData.PE.delta.toFixed(2)
        : '-',
      backgroudColor: isATM ? '#0782da4d' : isOTM ? '#fffbe5' : '',
      hide: !isGreeks,
    },
    {
      content: isCE
        ? rowData.CE.theta
          ? rowData.CE.theta.toFixed(2)
          : '-'
        : rowData.PE.theta
        ? rowData.PE.theta.toFixed(2)
        : '-',
      backgroudColor: isATM ? '#0782da4d' : '',
      hide: !isGreeks,
    },
    {
      content: isCE
        ? rowData.CE.vega
          ? rowData.CE.vega.toFixed(2)
          : '-'
        : rowData.PE.vega
        ? rowData.PE.vega.toFixed(2)
        : '-',
      backgroudColor: isATM ? '#0782da4d' : '',
      hide: !isGreeks,
    },
    {
      content: isCE
        ? rowData.CE.gamma
          ? rowData.CE.gamma.toFixed(2)
          : '-'
        : rowData.PE.gamma
        ? rowData.PE.gamma.toFixed(2)
        : '-',
      backgroudColor: isATM ? '#0782da4d' : '',
      hide: !isGreeks,
    },
    {
      content: rowData.PE.delta ? rowData.PE.delta.toFixed(2) : '-',
      backgroudColor: isATM ? '#0782da4d' : isOTM ? '#fffbe5' : '',
      hide: isGreeks,
    },
  ];
};

export const createOptionTableRows = (
  optionChainData: Record<string, SymbolOptionChainDataType>,
  handleOptionClick: (
    position: PositionBaseDataType,
    contractType: string,
    strike: string,
  ) => void,
  isGreeks: boolean,
  currentStrike: number,
  positions: PositionData,
  isCE: boolean,
  date: string,
): { rows: TableRow<SymbolOptionChainDataType>[]; columns: TableColumns[] } => {
  const rows: TableRow<SymbolOptionChainDataType>[] = [];
  let rowCellsData: TableRowCellsData[] = [];
  const columns: TableColumns[] = [
    { text: i18next.t('simulator.table_column.delta'), hide: isGreeks },
    {
      text: isGreeks
        ? isCE
          ? i18next.t('simulator.table_column.call_ltp')
          : i18next.t('simulator.table_column.put_ltp')
        : i18next.t('simulator.table_column.call_ltp'),
      align: 'center',
    },
    { text: i18next.t('simulator.table_column.lots') },
    { text: i18next.t('simulator.table_column.strike') },
    { text: i18next.t('simulator.table_column.iv'), hide: !isGreeks },
    { text: i18next.t('simulator.table_column.lots'), hide: isGreeks },
    {
      text: i18next.t('simulator.table_column.put_ltp'),
      hide: isGreeks,
      align: 'center',
    },
    {
      text: isGreeks
        ? isCE
          ? i18next.t('simulator.table_column.call_delta')
          : i18next.t('simulator.table_column.put_delta')
        : i18next.t('simulator.table_column.call_delta'),
      hide: !isGreeks,
    },
    { text: i18next.t('simulator.table_column.theta'), hide: !isGreeks },
    { text: i18next.t('simulator.table_column.vega'), hide: !isGreeks },
    { text: i18next.t('simulator.table_column.gamma'), hide: !isGreeks },
    { text: i18next.t('simulator.table_column.delta'), hide: isGreeks },
  ];

  if (!optionChainData) return { rows, columns };

  const strikePrices = Object.keys(optionChainData);
  strikePrices.map((keyStrike: string) => {
    const strike = Number(keyStrike);
    const strikeDiff = Number(strikePrices[1]) - Number(strikePrices[0]);
    const atmStrike = Math.round(currentStrike / strikeDiff) * strikeDiff;
    const isATM = atmStrike === strike;
    const isITM = atmStrike >= strike;
    const isOTM = atmStrike <= strike;
    (rowCellsData =
      // useMemo(
      //   () =>
      optionRows(
        optionChainData[keyStrike],
        keyStrike,
        isATM,
        isITM,
        isOTM,
        isGreeks,
        handleOptionClick,
        positions,
        isCE,
        date,
      )),
      // [optionChainData[keyStrike]],
      // );
      rows.push({
        rowCellsData,
        rowData: optionChainData[keyStrike],
        pt: 0.5,
        pb: 0.5,
        visibleOnHover: true,
      });
  });
  return { rows, columns };
};

//#endregion
