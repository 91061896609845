import { Box, Typography } from '@mui/material';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import moment from 'moment';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { formatNumber, getColorByNum } from 'utils/GenericFunctions';
interface HistoryCard {
  profit: number;
  time: string;
  isActive?: boolean;
}
export function utcToLocalUsingMoment(utcDate: string) {
  const utcMoment = moment.utc(utcDate);

  const localMoment = utcMoment.local();
  return localMoment;
}
function HistoryCard({ time, isActive, profit }: HistoryCard) {
  const history = useHistory();
  const handleClick = () => {
    const urlParams = new URLSearchParams();
    urlParams.set(
      'date',
      utcToLocalUsingMoment(time).format('YYYY-MM-DD').toString(),
    );
    history.replace({
      search: urlParams.toString(),
    });
  };

  return (
    // <NavLink to={`${utcToLocalUsingMoment(time).format('YYYY-MM-DD')}`}>
    <Box
      sx={{
        borderLeft: isActive ? '4px solid #007bff' : '4px solid transparent',
        backgroundColor: isActive ? '#f5faff' : 'transparent',
        transition: 'background-color 0.3s',
        ':hover': {
          backgroundColor: '#f5faff',
        },
        borderBottom: '1px solid #e0e0e0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: 2,
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          {utcToLocalUsingMoment(time).format('DD MMM YYYY')}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: '600' }}>
          {utcToLocalUsingMoment(time).format('hh:mm A')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          <FormattedMessage id="portfolio.public.total_pnl" />:
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: '600' }}
          color={getColorByNum(profit)}
        >
          {profit > 0 ? '+' : ''}
          {formatNumber(profit)}
        </Typography>
      </Box>
    </Box>
    // </NavLink>
  );
}

export default memo(HistoryCard);
