import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import { encodeUrlData } from 'utils/GenericFunctions';
import PageHeader from 'app/design/speedBot/PageHeader';
import Item from 'app/design/speedBot/EntityItem/Item';
import { ItemSeparator } from 'app/design/speedBot/EntityItem';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import Breadcrumbs from 'app/design/uiComponents/Breadcrumbs';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';

import useGetProfile from 'services/Menu/useGetProfile';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useState } from 'react';
import DeleteAccountModal from 'app/components/Menu/DeleteAccountModal';
// Service

export function ProfilePage() {
  const { t } = useTranslation();
  const breadcrumb = useBreadcrumbManager();
  const { data: profileDetails, isLoading } = useGetProfile();
  const [open, setOpen] = useState(false);
  const handleModalClose = (success: boolean) => {
    if (success) {
      setOpen(false);
    }
    setOpen(false);
  };
  return (
    <>
      <Helmet>
        <title>Profile Page</title>
        <meta name="description" content="Profile Page" />
      </Helmet>
      <CircularLoader open={isLoading} />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box className="sticky-top">
          <PageHeader
            variant="inactive"
            title={<FormattedMessage id="menu.page_title.profile" />}
            buttonHandleClick="/menu"
            buttonText={<FormattedMessage id="menu.page_title.menu" />}
            breadcrumb={<Breadcrumbs page={PAGES.PROFILE} data={{}} />}
          />
        </Box>
        {profileDetails && (
          <>
            <Item
              variant="itemSubtextIcon"
              title={<FormattedMessage id="menu.name" />}
              subtext={profileDetails.name ? profileDetails.name : '-'}
              icon={<ChevronRightIcon />}
              handleClick={() =>
                breadcrumb.push(
                  `/profile/edit/name/${encodeUrlData(profileDetails.name)}`,
                  PAGES.PROFILE,
                  `/profile`,
                  t('menu.page_title.profile'),
                )
              }
            />
            <ItemSeparator variant="1px" className="m-main-spacing" />
            <Item
              variant="itemIcon"
              title={<FormattedMessage id="menu.change_pass" />}
              icon={<ChevronRightIcon />}
              handleClick={() =>
                breadcrumb.push(
                  `/change-password`,
                  PAGES.PROFILE,
                  `/profile`,
                  t('menu.page_title.profile'),
                )
              }
            />
            <ItemSeparator variant="1px" className="m-main-spacing" />
            <Item
              variant="itemSubtextIcon"
              title={<FormattedMessage id="menu.mobile" />}
              subtext={profileDetails.mobile ? profileDetails.mobile : '-'}
              icon={<ChevronRightIcon />}
              handleClick={() =>
                breadcrumb.push(
                  `/profile/edit/mobile/${encodeUrlData(
                    profileDetails.country_code || '91',
                  )}/${encodeUrlData(profileDetails.mobile)}`,
                  PAGES.PROFILE,
                  `/profile`,
                  t('menu.page_title.profile'),
                )
              }
            />
            <ItemSeparator variant="1px" className="m-main-spacing" />
            <Item
              variant="itemSubtextIcon"
              title={<FormattedMessage id="menu.email" />}
              subtext={profileDetails.email ? profileDetails.email : '-'}
              icon={!profileDetails.google_user ? <ChevronRightIcon /> : <></>}
              {...(!profileDetails.google_user && {
                handleClick: () =>
                  breadcrumb.push(
                    `/profile/edit/email/${encodeUrlData(
                      profileDetails.email,
                    )}`,
                    PAGES.PROFILE,
                    `/profile`,
                    t('menu.page_title.profile'),
                  ),
              })}
            />
            <ItemSeparator variant="1px" className="m-main-spacing" />
            <Item
              variant="itemSubtextIcon"
              title={<FormattedMessage id="menu.gst" />}
              subtext={profileDetails.gstin ? profileDetails.gstin : '-'}
              icon={<ChevronRightIcon />}
              handleClick={() =>
                breadcrumb.push(
                  `/profile/edit/gstin/${encodeUrlData(
                    profileDetails.gstin ? profileDetails.gstin : '-',
                  )}`,
                  PAGES.PROFILE,
                  `/profile`,
                  t('menu.page_title.profile'),
                )
              }
            />
            <ItemSeparator variant="1px" className="m-main-spacing" />
            <Box className="m-main-spacing">
              <MuiButton
                fullWidth
                variant="text"
                color="error"
                size="xxlarge"
                onClick={() => setOpen(true)}
              >
                <FormattedMessage id="buttons.delete_acc" />
              </MuiButton>
            </Box>
          </>
        )}
      </Box>
      <DeleteAccountModal open={open} handleClose={handleModalClose} />
    </>
  );
}
