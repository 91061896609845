import { Box } from '@mui/material';
import CommonTable from 'app/components/Common/CommonTable';
import { createPositionTableRows } from './utils';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';

// Service
import { PortfolioBot } from 'types/ApiServicesTypes';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const PortfolioTable = ({
  bots,
  ptype,
  liveFeedTodaysPosData,
}: {
  bots: PortfolioBot[];
  ptype: string;
  liveFeedTodaysPosData: { [id: number]: number };
}) => {
  const isLgUp = useGetMediaQueryUp('lg');
  const breadcrumb = useBreadcrumbManager();

  const isDisplayUser = useIsFeatureFlag([
    FEATURES.IS_DISPLAY_BROKER_IN_PORTFOLIO,
  ]);

  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);

  const { rows, columns } = createPositionTableRows(
    bots,
    ptype,
    liveFeedTodaysPosData,
    breadcrumb.push,
    isDisplayUser,
    isChargesAndProfit,
    !isLgUp,
  );
  // eslint-disable-next-line no-console
  // console.log('asdfasdf', bots, ptype);
  return (
    <Box
      sx={{
        px: 3,
        mx: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CommonTable rows={rows} columns={columns} />
    </Box>
  );
};

export default PortfolioTable;
